import api from "../../config/apiConfig";

const dashboardChartFilter = async (data: any) => {
    try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await api.post("/user/getChartInfo", data, {
            headers: {
                Authorization: accessToken,
            },
        });
        return response.data.data;
    } catch (error: any) {
        throw new Error("Error in fetching data: " + error.message);
    }
};

const getDashboardData = async (data: any) => {
    try {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
            throw new Error("Access token is missing");
        }

        const response = await api.post("/user/getInvestmentData", data , {
            headers: {
                Authorization: accessToken,
            },
        });
        return response;
    } catch (error: any) {
        throw new Error("Error in fetching data: " + error.message);
    }
};


const mainDashboardChartData = { dashboardChartFilter, getDashboardData };
export default mainDashboardChartData;