import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UnrealisedReportModel from "../../models/ReportUnrealised.modal";
import ReportService from "./ReportService";

export const UnrealisedReport = createAsyncThunk(
    "funds/getUnrealisedReport",
    async (data: any, thunkAPI: any) => {
        try {
            if (!data) {
                throw new Error("No refresh token provided");
            }
            
            const response = await ReportService.getUnrealisedReport(data);
            console.log("the data is ", response);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const MFAssetHoldings = createAsyncThunk(
    "funds/getMFHoldings", // Unique action type for MF holdings
    async (data:any, thunkAPI: any) => {
        try {
            // if (!data) {
            //     throw new Error("Invalid parameter provided");
            // }
            const response = await ReportService.getAllMFHoldings(data);
            console.log('Getting data for',response);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const getAif = createAsyncThunk(
    "funds/getAif",
    async (data:any, thunkAPI: any) => {
        try {
            const response = await ReportService.getAif(data);
            console.log('Getting data for',response);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const getPms = createAsyncThunk(
    "funds/getPms",
    async (data:any, thunkAPI: any) => {
        try {
            const response = await ReportService.getPms(data);
            console.log('Getting data for',response);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const getBonds = createAsyncThunk(
    "funds/getBonds",
    async (data:any, thunkAPI: any) => {
        try {
            const response = await ReportService.getBonds(data);
            console.log('Getting data for',response);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

const initialState = {
    ReportUnrealised: [],
    AllMFAssets: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
};

export const ReportSlice = createSlice({
    name: "mutualFund",
    initialState: initialState,
    reducers: {
        resetState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(UnrealisedReport.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(UnrealisedReport.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.ReportUnrealised = action.payload;
            })
            .addCase(UnrealisedReport.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            }) 
            .addCase(MFAssetHoldings.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(MFAssetHoldings.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.AllMFAssets = action.payload;
            })
            .addCase(MFAssetHoldings.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(getAif.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAif.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.AllMFAssets = action.payload;
            })
            .addCase(getAif.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(getPms.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPms.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.AllMFAssets = action.payload;
            })
            .addCase(getPms.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(getBonds.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getBonds.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.AllMFAssets = action.payload;
            })
            .addCase(getBonds.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            ;
    },
});

export const { resetState } = ReportSlice.actions;
export const { reducer: ReportSliceReducer } = ReportSlice;
