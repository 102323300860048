import React, { FC, useEffect, useRef, useState } from "react";
import { colors } from "../../constants/colors";
import { Button } from "../Button";
import MFDetailCard from "./MFDetailCard";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { FormInput } from "../FormInput/FormInput";
import AmountCard from "./AmountCard";
import { useUserData } from "../../hooks/useUserData";
import { hideLoading, showLoading } from "../../redux/loader/loaderSlice";
import { InvestRequest, redeemMutualFund } from "../../redux/MF/MFSlice";
import toast from "react-hot-toast";
import MFFooterCard from "./MFFooterCard";
import { getAmountInWords } from "../../utils";
import { Button as Btn, Dialog, DialogTitle } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { showInterest } from "../../redux/user/userSlice";
import SuccessfullModal from "../modals/SuccessfullModal";
import APIloader from "../AppLoader/APIloader";
import CustomDialog from "../modals/BsePaymentAutherizationModal";
import { BrowserPopUpModal } from "../../utils/BrowserPopUpModal";

interface MFProps {
  activeSection: string;
  fundInfo?: any;
  onClose?: any;
}

const Redeem: FC<MFProps> = ({ fundInfo, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { id: fundId } = useParams();
  const { bank } = useUserData()|| { bank: [] };
  const [investmentPlan, setInvestmentPlan] = useState<string>("Amount");
  const [redeemType, setRedeemType] = useState<string>("Full");
  const [bankAccounts, setBankAccounts] = useState<any[]>([]);
  const formikRef = useRef<any>(null);
  const [fundParams, setFundParams] = useState<any>({
    "Total Units": fundInfo?.totalUnits,
    "Current Value": fundInfo?.currentValue,
    "Min Redemption Amt": fundInfo?.minimunRedemptionAmnt,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [summaryData, setSummaryData] = useState<any>({});
  const [Open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errormessage, setErrorMessage] = useState<string>("");
  const [popupWindow, setPopupWindow] = useState<Window | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadmessage, setLoadMessage] = useState<string>("");
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [authLink, setAuthLink] = useState("");
  const [orderID, setOrderID] = useState("");

  const redeemSchema = yup.object({
    folioNo: yup.string().required("Folio Number is required"),
    amount: yup.string().required("Amount is required"),
    bankId: yup.string().required("BankId is required"),
  });

  const [redeemValues, setredeemValues] = useState({
    orderType: 5, // 1 for lumpsump, 2 for sip, 3 for swp, 4 for stp
    newOrder: 1,
    fundId: 0,
    amount: "",
    bankId: 0,
    folioNumber: "",
    dividend: "",
    startDate: "",
    frequency: 0,
    noOfInstallment: 0,
    firstOrderToday: "",
    units: 0,
    transType: "",
    STPType: "",
    buySellType: "",
    toSchemeId: 0,
    folioNo: "",
    sourceScheme: "",
    allUnits:0,
  });

  // const redeemValues = {
  //   folioNo: "",
  //   amount: "",
  //   bankId: "",
  // };

  useEffect(() => {
    if (fundInfo && formikRef.current) {
      formikRef.current?.setFieldValue(
        "folioNo",
        fundInfo?.folioNumber[0]?.folio || "112233"
      );
    }
  }, [fundInfo]);

  useEffect(() => {
    if (Object.keys(bank).length > 0) {
      const banks = [
        {
          label: `${bank?.bankName} - ${bank?.accountNumber}`,
          value: bank?.id,
        },
      ];
      setBankAccounts(banks);
    }
  }, [bank]);


  useEffect(() => {
    if (redeemType === "Full") {
      formikRef.current?.setFieldValue("amount", fundInfo?.currentValue);
    } else {
      formikRef.current?.setFieldValue("amount", "");
    }
  }, [redeemType]);

  const renderRadio = (
    option: string,
    onChange: () => void,
    checked: boolean
  ) => (
    <label key={option} className="flex items-center">
      <input
        type="radio"
        checked={checked}
        onChange={onChange}
        className="mr-2"
      />
      {option}
    </label>
  );

  const calculateUnits = (amount: string): number => {
    const numericAmount = parseFloat(amount);
    if (fundInfo?.currentNAV > 0) {
      return numericAmount / fundInfo?.currentNAV;
    }
    return 0;
  };
  
  useEffect(() => {
    if (investmentPlan === "Units" && formikRef.current?.values.amount) {
      const units = calculateUnits(formikRef.current?.values.amount);
      formikRef.current?.setFieldValue("amount", units.toFixed(2));
    }
  }, [investmentPlan, fundInfo?.currentValue]);
  

  const validateAmount = (value: string) => {
    if (investmentPlan === "Amount") {
      if (!value) {
        return "Amount is a required field";
      } else if (fundInfo?.currentValue <= 0) {
        return "You don't have sufficient funds to redeem";
      } else if (value > fundInfo?.currentValue) {
        return "Amount should be less than " + fundInfo?.currentValue;
      } else if (value < fundInfo?.minimunRedemptionAmnt) {
        return (
          "Amount should be greater than " + fundInfo?.minimunRedemptionAmnt
        );
      }
    } else if (investmentPlan === "Units") {
      if (!value) {
        return "Units is a required field";
      } else if (fundInfo?.totalUnits <= 0) {
        return "You don't have sufficient units to redeem";
      } else if (parseFloat(value) > fundInfo?.totalUnits) {
        return "Units should be less than " + fundInfo?.totalUnits;
      }
    }
    return "";
  };
  

  const redeemMF = async (values: any) => {
    try {
      dispatch(showLoading());
      const allRedeem = 
      (investmentPlan === "Units" && parseFloat(values.amount) === fundInfo?.totalUnits) ||
      (investmentPlan === "Amount" && parseFloat(values.amount) === fundInfo?.currentValue)
        ? "Y"
        : "N";

    const folios = fundInfo?.folioNumber || [];
    const isAllRedeem = allRedeem === "Y";
    const payloads = [];

    if (isAllRedeem) {
      // If allRedeem is "Y", loop through all folios and set doRedeemAuth for the last folio.
      payloads.push(
        ...folios.map((folioItem: any, index: number) => ({
          folioNo: folioItem.folio,
          amount: "",
          units: "",
          allRedeem,
          fundId,
          bankId: parseInt(bank[0]?.id),
          doRedeemAuth: index === folios.length - 1 ? 1 : 0, // Last folio has doRedeemAuth as 1
        }))
      );
    } else {
      // If allRedeem is "N", calculate based on entered amount
      let runningTotal = 0;
      let amountToRedeem = parseFloat(values.amount);

      for (let i = 0; i < folios.length; i++) {
        const folioItem = folios[i];
        if(investmentPlan === "Units"){
          const folioUnit = parseFloat(folioItem.units);

        if (amountToRedeem <= runningTotal + folioUnit) {
          // When the total amount surpasses the target, set doRedeemAuth to 1 for this folio and stop
          payloads.push({
            folioNo: folioItem.folio,
            amount: "",
            units: investmentPlan === "Units" ? (amountToRedeem-runningTotal).toFixed(2).toString() : "",
            allRedeem,
            fundId,
            bankId: parseInt(bank[0]?.id),
            doRedeemAuth: 1, // Final folio to redeem has doRedeemAuth as 1
          });
          break;
        } else {
          // Accumulate folio amounts until the target amount is reached
          payloads.push({
            folioNo: folioItem.folio,
            amount: "",
            units: investmentPlan === "Units" ? folioItem.units : "",
            allRedeem,
            fundId,
            bankId: parseInt(bank[0]?.id),
            doRedeemAuth: 0, // Intermediate folios have doRedeemAuth as 0
          });
          runningTotal += folioUnit;
        }
        }else{
          const folioAmount = parseFloat(folioItem.amount);

        if (amountToRedeem <= runningTotal + folioAmount) {
          // When the total amount surpasses the target, set doRedeemAuth to 1 for this folio and stop
          payloads.push({
            folioNo: folioItem.folio,
            amount: investmentPlan === "Amount" ?(amountToRedeem-runningTotal).toString() : "",
            units: "",
            allRedeem,
            fundId,
            bankId: parseInt(bank[0]?.id),
            doRedeemAuth: 1, // Final folio to redeem has doRedeemAuth as 1
          });
          break;
        } else {
          // Accumulate folio amounts until the target amount is reached
          payloads.push({
            folioNo: folioItem.folio,
            amount: investmentPlan === "Amount" ? folioItem.amount : "",
            units:"",
            allRedeem,
            fundId,
            bankId: parseInt(bank[0]?.id),
            doRedeemAuth: 0, // Intermediate folios have doRedeemAuth as 0
          });
          runningTotal += folioAmount;
        }

        }
      }
    };

      for (const payload of payloads) {
      const res = await dispatch(redeemMutualFund(payload));
      setLoading(true);
        setLoadMessage("Processing your request, please wait 30s to 40s...");
        handleCloseDialog();
        setOrderID(res?.payload?.data?.data?.orderId);
        if (res?.payload?.success === 200 && res?.payload?.data?.authLink) {
          setLoading(false);
          const authLink = res?.payload?.data?.authLink;
            setAuthLink(authLink);
            setAuthModalOpen(true); // Open modal with auth link
        } else {
          setLoading(false);
          setOpen(true);
          setErrorMessage(
            "Error in executing your order."
          );
          break;
        }
      }
    } catch (error) {
      setOpen(true);
      setErrorMessage("Some error in redeem request!");
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    // Check if the popup window is closed every 1000 milliseconds
    const checkPopupClosed = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
        setLoading(false);
        clearInterval(checkPopupClosed); // Stop checking once the popup is closed
        setOpen(true);
        setMessage("Redeem request has been submitted!");
      }
    }, 1000);

    // Clear the interval if the component unmounts
    return () => clearInterval(checkPopupClosed);
  }, [popupWindow]);

  const openAuthLink = () => {
    setAuthModalOpen(false);
    setLoadMessage("Please authenticate your redeem request and close the bse tab...");
    setLoading(true);
    if (authLink) {
      const newPopupWindow =  BrowserPopUpModal(authLink, "BSE Autherization",1250,550);
      setPopupWindow(newPopupWindow);
    }
  };

  const handleAuthModalClose =()=>{
    setOpen(true);
    setMessage("Redeem request has not been submitted!");
  }
  
  const handleClose=()=>{
    setOpen(false);
    onClose();
  }

  const handleRedeemType = (type: string) => {
    setRedeemType(type);
  };

  const handleOpenDialog = () => {
    let summary={};
    if(investmentPlan === "Units"){
      const units = parseFloat(formikRef.current?.values.amount || "0");
    const currentNAV = parseFloat(fundInfo?.currentNAV || "0");
  
    const amount = currentNAV > 0 ? (units * currentNAV).toFixed(2) : 0;
      summary = {
        FolioNumber: formikRef.current?.values.folioNo,
        Amount: amount,
        RedeemType: redeemType,
        InvestmentPlan: investmentPlan,
        Units: units,
      };
    }else{
    const amount = parseFloat(formikRef.current?.values.amount || "0");
    const currentNAV = parseFloat(fundInfo?.currentNAV || "0");
  
    const units = currentNAV > 0 ? (amount / currentNAV).toFixed(2) : "N/A";
      summary = {
        FolioNumber: formikRef.current?.values.folioNo,
        Amount: amount.toFixed(2),
        RedeemType: redeemType,
        InvestmentPlan: investmentPlan,
        Units: units,
      };

    }
  
    setSummaryData(summary);
    setOpenDialog(true);
  };
  

  useEffect(() => {
    console.log("Current NAV:", fundInfo?.currentNAV);
  }, [fundInfo]);
  
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDialog = async () => {
    handleCloseDialog();
    const values = formikRef.current?.values;
    if (validateAmount(values.amount)) return;
    await redeemMF(values);
  };

  const showingInterest = async () => {
    try {
      const response = await dispatch(showInterest({ id:fundId, holdingType:2 }));

      if (response?.payload?.statusCode === 200) {
        setOpen(true);
        setMessage("Your Interest is submitted successfully");
      } else {
        setOpen(true);
        setErrorMessage("Something wents wrong.Please try again later!");
        console.warn('Unexpected response structure:', response);
      }
    } catch (error) {
      setOpen(true);
      setErrorMessage("Something wents wrong.Please try again later!");
      console.error("Error fetching users:", error);
    }
  };

  return (
    <>
    <div className="bg-lightBg pb-10 ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white md:px-8 py-10">
          <MFDetailCard fundInfo={fundInfo} />
          <Formik
            innerRef={formikRef}
            initialValues={redeemValues}
            validationSchema={redeemSchema}
            onSubmit={(values, { setSubmitting }) => {
              if (validateAmount((values.amount))) return;
              handleOpenDialog();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} className="w-full mt-4">
                <div className="flex flex-col sm:flex-row sm:justify-between mb-4">
                  <div className="w-full sm:w-1/2">
                    <FormInput
                      label="Folio Number"
                      name="folioNo"
                      type="text"
                      value={values.folioNo}
                      disabled={true}
                      onChange={handleChange("folioNo")}
                    />
                  </div>
                  <div className="w-full sm:w-1/2 px-8">
                    <h6 className="mr-5 text-textGrey text-lg">Exit Load: </h6>
                    <p className="mt-4 font-inter">{fundInfo?.exitLoad}</p>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row sm:justify-between mb-4">
                  <div className="w-full sm:w-1/2">
                    <div className="flex flex-col md:flex-row text-start justify-start text-sm md:text-lg gap-2 mb-10">
                      <h6 className="mr-5 text-textGrey">
                        Select Redeem Type:{" "}
                      </h6>
                      {["Full", "Partial"].map((type) =>
                        renderRadio(
                          type,
                          () => handleRedeemType(type),
                          redeemType === type
                        )
                      )}
                    </div>
                  </div>
                  <div className="w-full sm:w-1/2 px-8">
                    <h6 className="mr-5 text-textGrey text-lg">Free Units: </h6>
                    <p className="mt-4 font-inter">{fundInfo?.freeUnits}</p>
                  </div>
                </div>
                {redeemType === "Partial" && (
                  <div className="flex flex-col md:flex-row text-start justify-start text-sm md:text-lg gap-2 mb-10">
                    <h6 className="mr-5 text-textGrey">
                      Select Redeem Method:{" "}
                    </h6>
                    {["Amount", "Units"].map((option) =>
                      renderRadio(
                        option,
                        () => setInvestmentPlan(option),
                        investmentPlan === option
                      )
                    )}
                  </div>
                )}
                <div className="flex flex-col sm:flex-row sm:justify-between mb-4">
                  <div className="w-full sm:w-1/2">
                    <FormInput
                      label={investmentPlan}
                      name="amount"
                      type="text"
                      value={values.amount}
                      onChange={handleChange("amount")}
                      error={validateAmount(values.amount)}
                      touched={touched.amount}
                      disabled={redeemType === "Full"}
                      hintText={
                        investmentPlan === "Amount"
                          ? getAmountInWords(values.amount)
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-wrap gap-5 pt-5 justify-center md:justify-start">
                  {Object.keys(fundParams).map(
                    (label: any) =>
                      fundParams[label] !== "" &&
                      fundParams[label] != null && (
                        <AmountCard label={label} value={fundParams[label]} />
                      )
                  )}
                </div>
                {/* <div className="w-full mt-10">
                  <MFFooterCard
                    onBankChange={handleChange("bankId")}
                    bankValue={values.bankId}
                    bankError={errors.bankId}
                    bankTouched={touched.bankId}
                  />
                </div> */}
                <section className="mt-10 mb-5 max-md:mt-5 max-md:mr-2.5 max-md:max-w-full">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                        <div className="flex gap-5 justify-between lg:mt-2 text-sm max-md:mt-2 lg:mb-5">
                          <Button
                            type="submit"
                            className="grow justify-center px-8 py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary"
                          >
                            Redeem
                            {/* Proceed to payment */}
                          </Button>
                          <Button
                          type="button"
                                    className={`grow justify-center px-8 py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary`}
                                    onClick={showingInterest}
                                  >
                                    Show Interest
                                    {/* Proceed to payment */}
                                  </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
    <APIloader loadingModal={loading} message={loadmessage} />
    <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
          >
            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 max-w-2xl relative flex flex-col">
                <Typography variant="h4" className="font-bold mb-4">
                  Summary
                </Typography>
                <DialogContent className="flex flex-col">
                  {summaryData ? (
                    <>
                        <div className="flex justify-between mb-4">
                          <span className="font-semibold text-gray-700">
                            Folio Number:
                          </span>
                          <span>{summaryData.FolioNumber}</span>
                        </div>
                        <div className="flex justify-between mb-4">
                        <span className="font-semibold text-gray-700">
                          Redeem Type:
                        </span>
                        <span>{summaryData.RedeemType}</span>
                      </div>
                      <div className="flex justify-between mb-4">
                        <span className="font-semibold text-gray-700">
                          Amount:
                        </span>
                        <span>Rs. {summaryData.Amount}</span>
                      </div>
                       <div className="flex justify-between mb-4">
                        <span className="font-semibold text-gray-700">Approx. Units:</span>
                        <span>{summaryData.Units}</span>
                      </div>
                    </>
                  ) : (
                    <div className="flex justify-center items-center">
                      <span>Loading...</span>
                    </div>
                  )}
                </DialogContent>

                <DialogActions>
                <Btn
                    onClick={handleConfirmDialog}
                    color="primary"
                    sx={{
                      backgroundColor: colors.primary,
                      borderRadius: "40px",
                      color: colors.lightBg,
                      padding: "10px",
                      "&:hover": {
                        backgroundColor: colors.primary,
                        color: colors.lightBg,
                      },
                    }}
                  >
                    Confirm
                  </Btn>
                  <Btn
                    onClick={handleCloseDialog}
                    sx={{
                      backgroundColor: colors.primary,
                      borderRadius: "40px",
                      color: colors.lightBg,
                      padding: "10px",
                      "&:hover": {
                        backgroundColor: colors.primary,
                        color: colors.lightBg,
                      },
                    }}
                  >
                    Cancel
                  </Btn>
                </DialogActions>
                {/* <Typography
                  variant="body2"
                  className="mb-4"
                  sx={{ marginTop: 4 }}
                >
                 Redeem request submitted
                </Typography> */}
              </div>
            </div>
          </Dialog>
          <SuccessfullModal
        open={Open}
        message={message!==""?message:errormessage}
        handleClose={handleClose}
      />
              <Dialog open={authModalOpen} onClose={handleAuthModalClose}>
  <DialogContent>
    <Typography>
      Click below to complete your authorization on the BSE STAR MF platform and also close the tab after autherized yourself.
    </Typography>
  </DialogContent>
  <DialogActions>
    <Btn onClick={openAuthLink} sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}>
      Open Authorization Link
    </Btn>
    <Btn onClick={handleAuthModalClose} sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}>
      Close
    </Btn>
  </DialogActions>
        </Dialog>
    </>
  );
};

export default Redeem;
