import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import getInitials from "../../utils/getInitailasIMGUtils";
import { useNavigate } from "react-router-dom";
import TimeStamptoExp from "../../utils/timestampConverter";
import { colors } from "../../constants/colors";
import SortingComponent from "../ShortingFN/ShortingComponent";
import {
  BorderBottom,
  InfoOutlined,
  MoreVert,
  ReportProblem,
} from "@mui/icons-material";
interface TableComponentProps {
  headData: Array<{ key: string; value: string }>;
  subcategory?: any;
  bodyData: Array<any>;
  unSortdata?: any;
  findHighestInterestRates?: (schemes: any) => number;
  navigateDetails?: (id: string) => void;
  handleMenuClick?: (
    event: React.MouseEvent<HTMLElement>,
    rate1: any,
    rate2: any
  ) => void;
  modalMenuClick?: any;
  segment?: string;
  reportType?: string;
  initials?: boolean;
}

const MainTableLayout: React.FC<TableComponentProps> = ({
  headData,
  subcategory,
  bodyData,
  unSortdata,
  modalMenuClick,
  initials,
}) => {
  const [open, setOpen] = React.useState<{ [key: string]: boolean }>({});
  const navigate = useNavigate();
  const handleToggle = (id: string) => {
    setOpen((prevOpen) => ({ ...prevOpen, [id]: !prevOpen[id] }));
  };

  const navigateDetails = (id: number) => {
    // console.log('on click', id);
    navigate(`/fd-details/${id}`);
  };
  const convertDaysToReadableFormat = (days: number,schemeName:string): string => {
    if (schemeName && schemeName?.includes("HDFC")) {
      if (days <= 90) {
        return `${days} day${days > 1 ? "s" : ""}`;
      }
    } else {
      return days<=1?days +" month":days+" months" ;// Dynamic value for 12 months, approximated as 12 * 30 = 360 days
    }
    const today = new Date();
    let currentYear = today.getFullYear();
    let currentMonth = today.getMonth(); // Month is 0-based (0 = January, 11 = December)
    let currentDay = today.getDate();

    // Helper function to get the number of days in a given month and year
    const daysInMonth = (month: number, year: number): number => {
      return new Date(year, month + 1, 0).getDate();
    };

    // Determine the month length to use based on the current day
    let monthLength = daysInMonth(currentMonth, currentYear);
    if (currentDay > 20) {
      currentMonth += 1;
      if (currentMonth > 11) {
        currentMonth = 0;
        currentYear += 1;
      }
      monthLength = daysInMonth(currentMonth, currentYear);
    }

    let totalMonths = 0;
    let remainingDays = days;
    let futureDate = new Date(today);

    // Calculate total months and remaining days
    while (remainingDays >= monthLength) {
      totalMonths += 1;
      remainingDays -= monthLength;
      currentMonth += 1;
      if (currentMonth > 11) {
        currentMonth = 0;
        currentYear += 1;
      }
      monthLength = daysInMonth(currentMonth, currentYear);
    }

    // Calculate the exact future date
    futureDate.setMonth(today.getMonth() + totalMonths);
    futureDate.setDate(today.getDate() + remainingDays);

    // Format the readable string
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    let readableFormat = "";
    if (years > 0) readableFormat += `${years} yr${years > 1 ? "s" : ""} `;
    if (months > 0) readableFormat += `${months} mon `;
    if (remainingDays > 0) readableFormat += `${remainingDays} day${remainingDays > 1 ? "s" : ""} `;

    return readableFormat.trim();
  };

  const formatCellData = (key: string, data: any) => {
    switch (key) {
      case "grossInterest": // Key for 'Interest Earned'
        const interestEarned = data.returnAmount - data.amount;
        return `Rs. ${interestEarned.toFixed(2)}`; // Show calculated value with "Rs." prefix
      case "amount":
        return `Rs. ${data.amount}`;
      case "investmentTenure":
        return `${convertDaysToReadableFormat(data.investmentTenure,data.schemeName)}`;
      case "returnAmount":
        return `Rs. ${data.returnAmount}`;
      case "interesetRate":
        return `${data.interesetRate}`;
      case "initials":
        return getInitials(data.bankName || data.name);
      case "createdAt":
        return data.createdAt;
      case "endDate":
        return data?.endDate
          ? /^\d{2}\/\d{2}\/\d{4}$/.test(data.endDate.toString()) // Check if the endDate is in DD/MM/YYYY format
            ? data.endDate // If already in correct format, display the date as is
            : TimeStamptoExp.formatDateFromTimestamp(Number(data.endDate)) // Otherwise, format it
          : "-";
      case "schemeName":
        return (
          <div className="flex gap-x-1 items-center">
            {initials && (
              <span className="flex w-12 h-12 items-center justify-center bg-lightGrey p-4 rounded-[100%] mr-4 text-primary font-semibold">
                {getInitials(data.schemeName)}
              </span>
            )}
            <span className="font-bold text-primary">{data.schemeName}</span>
          </div>
        );
      default:
        return data[key] || "-";
    }
  };

  let myPaddingStyle = {
    paddingTop: 10,
    paddingBottom: 10,
  };
  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: 400,
        overflowY: "auto",
        overflowX: "auto",
        boxShadow: "none",
        border: "none",
      }}
    >
      <Table aria-label="collapsible table" >
        <TableHead
           sx={{
            backgroundColor: colors.lightBg,
            borderBottom: "2px solid #ccc", // Keep the border consistent
            position: "sticky", // Sticky position for the header
            top: 0, // Stick at the top when scrolling
            zIndex: 1000, // Ensure the header stays above the body
            "& th": {
              backgroundColor: colors.lightBg,
              fontWeight: 600,
              color: colors.primary,
              fontSize: "16px",
              borderBottom: "2px solid #ccc", // Apply the border only here
            },
          }}
        >
          <TableRow
            sx={{
                position: "sticky",
                top: 0,
                zIndex: 1000,
                borderBottom: "2px solid #ccc",
                backgroundColor: colors.lightBg,
                "& th": {
                  backgroundColor: colors.lightBg,
                  border: "none",
                  paddingTop: "2%",
                  paddingBottom: "2%",
                  borderBottom: "2px solid #ccc",
                },
              }}
          >
            {headData.map(({ key, value }) => (
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize: "16px",
                  width:
                    key === "investmentFrequency" || key === "amcCode"
                      ? "100px"
                      : "auto",
                  // backgroundColor: key === 'investmentFrequency' || key === 'amcCode' ? '#ff0000' : ''
                }}
                key={key}
              >
                <SortingComponent title={value} data={bodyData} keyProp={key} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ border: "none  " }} className="">
          <TableRow sx={{ height: "5%" }}>
            <TableCell
              sx={{ border: "none" }}
              colSpan={8}
              align="center"
            ></TableCell>
          </TableRow>
          {bodyData &&
            bodyData.map((data, index) => (
              <React.Fragment key={index}>
                <TableRow
                  style={myPaddingStyle}
                  className="border-[1px] border-lightGrey hover:border-primary hover:bg-lightBg"
                >
                  {headData.map(({ key }) =>
                    key === "action" ? (
                      <TableCell sx={{ border: "none" }}>
                        <IconButton
                          onClick={(e) => {
                            modalMenuClick(e, data.id);
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleToggle(data.id)}
                        >
                          {open[data.id] ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      </TableCell>
                    ) : (
                      <TableCell
                        key={key}
                        sx={{
                          border: "none!important",
                          paddingBottom: "2rem",
                          paddingTop: "2rem",
                        }}
                        className={`text-wrap ${
                          key === "providerName" ||
                          key === "amcCode" ||
                          key === "investmentFrequency"
                            ? "max-w-2 overflow-hidden text-wrap "
                            : "w-auto"
                        }`}
                      >
                        {formatCellData(key, data)}
                      </TableCell>
                    )
                  )}
                </TableRow>
                <TableRow sx={{ height: "5%" }}>
                  <TableCell
                    sx={{ border: "none" }}
                    colSpan={8}
                    align="center"
                  ></TableCell>
                </TableRow>
                {data.schemes && (
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={headData.length + 1}
                    >
                      <Collapse in={open[data.id]} timeout="auto" unmountOnExit>
                        <Table>
                          <TableBody>
                            {data.schemes.map((scheme: any) => (
                              <TableRow key={scheme.id}>
                                {headData.map(({ key }) => (
                                  <TableCell key={key}>
                                    {scheme[key] || "-"}
                                  </TableCell>
                                ))}
                                <TableCell
                                  onClick={() => navigateDetails(scheme.id)}
                                >
                                  {scheme.schemeName}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MainTableLayout;
