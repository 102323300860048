import React, {useRef, useState } from "react";
import InfoSection from "../../../components/InfoSection/InfoSection";

import person from '../../../assets/icons/f7_person-2-normal.png'
import person24 from '../../../assets/icons/octicon_person-24 grey (1).png'
import bankDetails from '../../../assets/icons/Company.png'
import demat from '../../../assets/icons/jam_document blue.png'
import preference from '../../../assets/icons/grommet-icons_checkbox-selectedblue.png'
import sign from '../../../assets/icons/la_file-signature blue.png'

import { colors } from '../../../constants/colors';
import upload from '../../../assets/icons/lucide_upload.svg';
import Buttons from '../../../components/Buttons/IndividualButtons/Buttons'
import { Img, Text } from "../../../components";
import { Button } from "@mui/material";


import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { addDematDetails } from "../../../redux/demat/dematSlice";
import {checkFileSizeAndReset} from "../../../utils/index";
import ConfirmationModal from "../../../components/modals/PopupModal";

interface InputFieldProps {
  label: string;
  altText?: string;
  onChange?: any;
  value?: any;
  name: string;
}

interface DematDetailsFormProps {
  onDematToGeneral: () => void;
  activeSection: string;
  allowToProceed: () => void;
}

const dematValues = {
  dpName: "",
  dpId: "",
  clientId: "",
  depositoryName: "",
};

const dematSchema = yup.object({
  dpName: yup.string().required(),
  dpId: yup.string().required(),
  clientId: yup.string().required(),
  depositoryName: yup.string().required(),
});





const InputField: React.FC<InputFieldProps> = ({ label, altText, name, onChange, value }) => {
  if (label === "Depository Names") {
    return (
      <div className="flex flex-col lg:text-lg md:text-sm mb-10" style={{ color: colors.darkGrey }}>
        <label className="" style={{ color: colors.darkGrey }}>{label}</label>
        <select
          className="shrink-0 mt-3 rounded-lgp-4  p-4"
          style={{ color: colors.darkGrey, backgroundColor: colors.white, border: `1px solid ${colors.darkBg}` }}
          aria-label={altText || label}
          onChange={onChange}
          value={value}
          name={name}
        >
          <option value="Select Account Type"></option>
          <option value="savings">Option 1</option>
          <option value="checking">Option 2</option>
          <option value="investment">Option 3</option>
        </select>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col lg:text-lg md:text-sm mb-10" style={{ color: colors.darkGrey }}>
        <label className="" style={{ color: colors.darkGrey }}>{label}</label>
        <input
          className="shrink-0 mt-3 rounded-lg p-4"
          style={{ color: colors.darkGrey, backgroundColor: colors.white, border: `1px solid ${colors.darkBg}` }}
  
          aria-label={altText || label}
          onChange={onChange}
          value={value}
          name={name}
        />
      </div>
    );
  }
};

const FileInputField: React.FC<InputFieldProps> = ({ label, onChange  }) => {
  const [fileName, setFileName] = React.useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const isValid = checkFileSizeAndReset(file, event); // Validate the file size
      if (isValid) {
        setFileName(file.name); // Update the displayed file name
      } else {
        setFileName(null); // Reset the displayed file name if invalid
      }
    }  
  };
  
  return (
    <div className="flex flex-col mt-10 mb-10 grow text-lg text-neutral-500">
      <div className="mb-5 text-base lg:text-lg sm:text-sm md:text-sm">{label}</div>
      <label className="shrink-0 rounded-lg p-3 flex items-center justify-between " style={{ color: colors.darkGrey, backgroundColor: colors.white, border: `1px solid ${colors.darkBg}` }}>
        <input
          type="file"
          className="hidden sm:w-full"
          style={{ backgroundColor: colors.white }}
          onChange={(e) => {
            onChange(e);
            handleFileChange(e);
          }}
        />
        <span>{fileName ? fileName : ""}</span>
        <img
          src={upload} // Update with your image path
          alt="Upload Icon"
          className="h-7 w-7 ml-2 cursor-pointer"
        />
      </label>
    </div>
  );
};

const DematForm: React.FC<DematDetailsFormProps> = ({ onDematToGeneral, allowToProceed }) => {
  const [completedSections, setCompletedSections] = useState<boolean[]>(Array(6).fill(false));
  const dispatch = useDispatch<any>();
  const formikRef = useRef<any>();

  const [cmlFile, setCmlFile] = useState<File>();
  const [isModalVisible, setIsModalVisible] = useState(false);


  const infoSections = [
    { icon: person24, title: 'General Details' },
    { icon: bankDetails, title: 'Bank Details' },
    { icon: person, title: 'Nominee Details' },
    { icon: demat, title: 'Demat Details' },
    { icon: preference, title: 'Preferences' },
    { icon: sign, title: 'Signatures' },
  ];

  const onAllow = (): void => {
    // Mark the current section as completed
    const updatedSections = [...completedSections];
    updatedSections[5] = true;
    setCompletedSections(updatedSections);

    // Call the function to allow to proceed
    allowToProceed();
  };

  const handleButtonClick = () => {
    setIsModalVisible(true); // Open the modal when "Save and Proceed" is clicked
  };

  const handleModalConfirm = () => {
    setIsModalVisible(false); // Hide the modal and proceed with form submission
    if (formikRef.current) {
      formikRef.current.submitForm(); // Submit the form upon confirmation
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Hide the modal without proceeding
  };



  return (
    <section className="flex w-full">
      <main className={`flex flex-col w-full sm:w-full`} style={{ backgroundColor: colors.lightBg }}>
        {/* Inputs for screens above 768px */}
        <Formik
          innerRef={formikRef}
          initialValues={dematValues}
          validationSchema={dematSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values, "demat values");
            if (cmlFile) {
              // Create a Company object from form values
              const formData:any = new FormData();
              formData.append("dpName", values.dpName);
              formData.append("dpId", values.dpId);
              formData.append("clientId", values.clientId);
              formData.append("depositoryName", values.depositoryName);
              formData.append("cmlFile", cmlFile)
              dispatch(addDematDetails(formData))
              // Call the function to proceed to the next step
              onDematToGeneral();
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className='flex justify-between'>
                <div className="hidden sm:flex flex-col w-full gap-5 pl-10 pr-10 lg:pr-36 lg:pl-36 pb-5">
                  {/* Row for Name of Nominee and Phone */}
                  <div className="flex flex-col sm:gap-8 sm:flex-row  lg:gap-48">
                    <div className="w-full sm:w-1/2">
                      <InputField label="DP Name *"  name="dpName"
                        onChange={handleChange("dpName")}
                        value={values.dpName} />
                    </div>
                    <div className="w-full sm:w-1/2">
                      <InputField label="DP ID *"  name="dpId"
                        onChange={handleChange("dpId")}
                        value={values.dpId} />
                    </div>
                  </div>

                  {/* Row for Email and Relationship */}
                  <div className="flex flex-col sm:flex-row  sm:gap-8 lg:gap-48">
                    <div className="w-full sm:w-1/2">
                      <InputField label="Client ID *" name="clientId"
                        onChange={handleChange("clientId")}
                        value={values.clientId} />
                    </div>

                    <div className="w-full sm:w-1/2">
                      <InputField label="Depository Names *" name="depositoryName"
                        onChange={handleChange("depositoryName")}
                        value={values.depositoryName} />
                    </div>
                  </div>

                  {/* Row for Upload Aadhar Copy */}
                  <div className="flex flex-col sm:flex-row  sm:gap-8 lg:mr-48">
                    <div className="w-full sm:w-1/2 ">
                      <FileInputField label="Upload CML Copy *" name={"cmlFile"} onChange={(e: any) =>
                          setCmlFile(e.target.files[0])
                        }/>
                    </div>
                  </div>

                  <div className="flex items-center">
                    <a href="" className="flex items-center">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/3ba9d573cbb000cc1e61ded9b73e398cc12d85c7f3f9f0c69419126880ffa1c6?apiKey=427d052184b84d30b7eba5c7e76648a1&"
                        className="shrink-0 w-8 aspect-square fill-red-500 mr-2 h-6"
                        alt="Icon Placeholder"
                      />
                      <span>Don't have DEMAT account?</span>
                    </a>
                  </div>
                  <div className="">
                    <button
                      style={{ backgroundColor: colors.secondary, color: colors.white }}
                      className={`font-semibold grow justify-center px-24 py-4 whitespace-nowrap rounded-md border border-solid `}
                      tabIndex={0}
                    >
                      Create One Now
                    </button>
                  </div>
                </div>
              </div>
              <section className="mt-10 mb-5 max-md:mt-5 max-md:mr-2.5 max-md:max-w-full">
                      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col max-md:ml-0 max-md:w-full">
                          <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                            <div className="flex gap-5 justify-between lg:ml-32  lg:mt-2 text-sm max-md:mt-2 lg:mb-5">
                              {/* Enable/disable buttons based on radioSelected */}
                              <button
                                style={{
                                  backgroundColor: colors.white,
                                  color: colors.darkGrey,
                                  border: `1px solid ${colors.darkGrey}`,
                                }}
                                className={`grow justify-center px-8 py-4 whitespace-nowrap rounded-md border border-solid `}
                                tabIndex={0}
                                onClick={() => {}} // Add onClick event handler // Disable button if radio is not selected
                              >
                                Skip
                              </button>
                               <button
                                type="button"
                                style={{ backgroundColor: colors.darkGrey, color: colors.white }}
                                className={`grow justify-center px-8 py-4 rounded-md border-3 border-${colors.darkGrey} border-solid`}
                                tabIndex={0}
                                onClick={handleButtonClick} // Open the confirmation modal
                              >
                                Save and Proceed
                              </button>
                              <ConfirmationModal
                                isVisible={isModalVisible} // Render conditionally based on state
                                message="Are you sure you want to proceed? After submission, changes cannot be made."
                                onConfirm={handleModalConfirm} // Proceed with submission
                                onCancel={handleModalCancel} // Cancel without proceeding
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
            </form>
          )}
        </Formik>
      </main>
    </section>
  );
};

export default DematForm;
