import React from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSelector } from "react-redux";

interface AppLoaderProps {
  isActive?: boolean;
  text?: string;
  children: any;
}

const AppLoader = ({ isActive, text, children }: AppLoaderProps) => {
  const isLoading = useSelector((state: any) => state.loaderReducer.isLoading);
  return (
    <LoadingOverlay
      className={isLoading ? "loading_overlay" : ""}
      active={isLoading}
      spinner
      text="Loading..."
    >
      {children}
    </LoadingOverlay>
  );
};

export default AppLoader;
