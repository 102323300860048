import { Grid } from "@mui/material";
import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import getInitials from "../../utils/getInitailasIMGUtils";
import { colors } from "../../constants/colors";

interface InvestmentDataProps {
  schemeName: string;
  totalAmount: string;
  units?: string; // Only units are required
}

interface DetailsProps {
  holdingsData: any; // New prop for holdings
}

const SummaryDetails: React.FC<DetailsProps> = ({ holdingsData }) => {
  console.log("holdings data mf", holdingsData);

  // Check if holdingsData is empty
  if (!holdingsData || holdingsData.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        <h6 className="font-bold text-lg text-gray-500">No Data Available</h6>
      </div>
    );
  }

  // Check if holdingsData length is greater than 5 for scrollable
  const isScrollable = holdingsData.length > 5;

  return (
    <div
      className={`space-y-2 mt-4 ${isScrollable ? 'overflow-y-scroll' : ''}`}
      style={isScrollable ? { maxHeight: '600px' } : {}}
    >
      {holdingsData.map((holding: any, index: number) => (
        <InvestmentData
          key={index}
          schemeName={holding.schemeName} // Ensure 'schemeName' exists in holding
          totalAmount={holding.currentMktValue} // Ensure 'totalAmount' exists in holding
          units={holding.units} // Ensure 'units' exists in holding, if applicable
        />
      ))}
    </div>
  );
};

const InvestmentData: React.FC<InvestmentDataProps> = ({
  schemeName,
  totalAmount,
  units, // Using units prop
}) => {
  const navigate = useNavigate();

  const handleIconClick = () => {
    navigate("/asset-holdings");
  };

  const formattedTotalAmount = totalAmount
    ? Number(totalAmount).toFixed(2)
    : "0.00";
  const formattedUnits = units ? Number(units).toFixed(2) : "0.00"; // Format units

  return (
    <div className="px-4 py-5 border border-primary rounded-lg">
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between" // Ensure even spacing
      >
        {/* Circle with initials and fund name */}
        <Grid item xs={12} md={7} className="flex items-center">
          <div
            className="bg-lightBg rounded-full flex items-center justify-center text-textGrey font-bold mr-2 text-2xl"
            style={{ width: "56px", height: "56px", lineHeight: "56px" }} // Set fixed size
          >
            {getInitials(schemeName)}
          </div>

          <h6 className="text-gray-700_01 font-bold text-xs">{schemeName}</h6>
        </Grid>

        {/* Investment value */}
        <Grid item xs={12} md={3} textAlign="center">
          <p className="text-gray-700_01 text-md">Investment Value</p>
          <p className="font-bold">{formattedTotalAmount}</p>
        </Grid>

        {/* Units */}
        <Grid item xs={12} md={1} textAlign="center">
          <p className="text-gray-700_01 text-md">Units</p>
          <p className="font-bold">{formattedUnits}</p>
        </Grid>

        {/* Arrow Icon */}
        <Grid
          item
          xs={12}
          md={1} // Reduce width for icon
          className="flex justify-center items-center" // Flex alignment
        >
          <p
            className="text-gray-700_01 cursor-pointer m-0" // Remove margin
            onClick={handleIconClick}
            style={{ fontSize: "1.5rem" }} // Adjust font size
          >
            <MdKeyboardArrowRight />
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default SummaryDetails;
