import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colors } from "../../constants/colors";
import { useNavigate } from "react-router-dom";

interface InvestmentDataProps {
  title: string;
  totalAmount: string;
  profitAmount: string;
  unrealised: string;
  roi: string;
  alignment?: any;
  setAlignment?: any;
  handleChange?: any;
  MFData?: any;
}

interface DetailsProps {
  data: InvestmentDataProps[];
  additionalData: any;
}

const Details: React.FC<DetailsProps> = ({ data, additionalData }) => {
  const [alignment, setAlignment] = useState("web");
  const [MFData, setMFData] = useState(additionalData);
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {
    if (additionalData) {
      setMFData(additionalData);
    }
    // console.log('MFData', MFData);
  }, [additionalData]);

  return (
    <div className="space-y-2 mt-4">
      {data.map((investment, index) => (
        <InvestmentData
          key={index}
          {...investment}
          alignment={alignment}
          setAlignment={setAlignment}
          handleChange={handleChange}
          MFData={additionalData}
        />
      ))}
    </div>
  );
};

const InvestmentData: React.FC<InvestmentDataProps> = ({
  title,
  totalAmount,
  profitAmount,
  unrealised,
  roi,
  handleChange,
  setAlignment,
  alignment,
  MFData,
}) => {
  const navigate = useNavigate();

  const handleIconClick = () => {
    // navigate("/asset-holdings");
    console.log(title,"");
    if (title === 'Mutual fund') {
        navigate('/mf-summary');
    } else if (title === 'AIF') {
      navigate('/aif-summary');
    } else if (title === 'PMS') {
      navigate('/pms-summary');
    } else if (title === 'BOND') {
      navigate('/bond-summary');
    } else if (title === 'FD') {
      navigate('/fd-summary');
    } else {
        navigate('/asset-holdings');
    }
  };

  const formattedTotalAmount = totalAmount
    ? Number(totalAmount).toFixed(2)
    : "0.00";
  const formattedProfitAmount = profitAmount
    ? Number(profitAmount).toFixed(2)
    : "0.00";
  const formattedUnrealised = unrealised
    ? Number(unrealised).toFixed(2)
    : "0.00";
  const formattedRoi = roi ? Number(roi).toFixed(2) : "0.00";
  return (
    <div className="px-4 py-5 border border-primary rounded-lg">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={2}>
          <h2 className="text-gray-700_01 font-bold text-xl text-center md:text-left">
            {title}
          </h2>
        </Grid>
        <Grid item xs={12} md={2} textAlign="center">
          <p className="text-gray-700_01 text-md">Invested</p>
          <p className="font-bold">{formattedTotalAmount}</p>
        </Grid>
        <Grid item xs={12} md={2} textAlign="center">
          <p className="text-gray-700_01 text-md md:whitespace-nowrap">
            {title === "FD" ? "Amount Redeem" : "Current Value"}
          </p>
          <p className="font-bold">{formattedProfitAmount}</p>
        </Grid>

        <Grid item xs={12} md={3} textAlign="center">
          <p className="text-gray-700_01 text-md">
            {title === "Mutual fund" ? "Unrealised Gain P/L" : "Earnings"}
          </p>
          <p className="font-bold">{formattedUnrealised}</p>
        </Grid>
        <Grid item xs={12} md={2} textAlign="center">
          <p className="text-gray-700_01 text-md">ROI</p>
          <p className="font-bold">{formattedRoi}%</p>
        </Grid>
        <Grid
          item
          xs={12}
          md={1}
          textAlign="center"
          className="flex md:justify-start justify-center"
        >
          <p
            className="text-gray-700_01 text-md text-2xl cursor-pointer"
            onClick={handleIconClick}
          >
            <MdKeyboardArrowRight />
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default Details;
