const convertMoneyStringToNumber = (moneyString: string): number => {
    // console.log('', moneyString);

    // Define the units and their corresponding multipliers
    const units: { [key: string]: number } = {
        'cr': 10000000,  // Crore
        'lakh': 100000,  // Lakh
        'k': 1000,       // Thousand
        '': 1            // No unit
    };

    // Ensure moneyString is a valid string
    if (typeof moneyString !== 'string' || !moneyString.trim()) {
        return 0;
    }

    // Extract the numeric part and the unit part
    const regex = /^([\d,.]+)\s*(cr|lakh|k)?$/i;
    const match = moneyString.match(regex);

    if (!match) {
        throw new Error("Invalid money string format");
    }

    const numberPart = match[1].replace(/,/g, ''); // Remove commas from the numeric part
    const unitPart = match[2] ? match[2].toLowerCase() : ''; // Convert unit part to lowercase

    // Convert the numeric part to a number
    const numericValue = parseFloat(numberPart);

    if (isNaN(numericValue)) {
        throw new Error("Invalid numeric value");
    }

    // Multiply by the corresponding unit multiplier
    const multiplier = units[unitPart as keyof typeof units];

    if (multiplier === undefined) {
        throw new Error("Unknown unit");
    }

    const Value = numericValue * multiplier;
    // console.log('moneyString', Value);
    return Value;

};

export default convertMoneyStringToNumber;
