import styles from './GeneralDetails.module.css'; // Import the CSS Module
import { colors } from "../../constants/colors";
import{AumSampannHeader as HeaderText ,Buttons, StepIndicator as StepInd} from "../../components/index"
const textBlack = colors.black
const textWhite = colors.white
const background = colors.white
const textColorSkip = colors.black;
const bgColorSkip = colors.white;
const textColorSave = colors.white;
const bgColorSave = colors.black
const inputFieldColor = colors.inputLabel;

const LargeSectionHeader = ({ title }: { title: string }) => (
  <header style={{color : textBlack}} className={`${styles.largeHeader} mt-6 mr-8 mb-8 text-4xl max-md:mr-2.5 max-md:max-w-full`}>
    {title}
  </header>
);

const SmallSectionHeader = ({ title }: { title: string }) => (
  <header style={{color : textBlack}} className={`${styles.smallHeader} mt-6 mr-8 mb-10 text-3xl max-md:mr-2.5 max-md:max-w-full`}>
    {title}
  </header>
);
<StepInd stepNumber="1" description="General Details" isActive={true} />

const InputField = ({ label }: { label: string }) => (
  <div className="flex flex-col flex-1 text-lg max-md:max-w-full">
    <label htmlFor={label.toLowerCase().replace(/\s+/g, '-')} style={{color: inputFieldColor, fontSize:18}} className="text-sm text-neutral-600">{label}</label>
    <input
      type="text"
      id={label.toLowerCase().replace(/\s+/g, '-')}
      style={{backgroundColor:background}}
      className={`shrink-0 mt-2 rounded-md border border-solid h-[50px] max-md:max-w-full`}
      aria-label={label}
    />
  </div>
);

const GeneralDetailsForm = () => {
  return (
    <main className="flex flex-col px-16 py-12 bg-white max-md:px-5">

      {/* Reusable components for better structure and reduced repetition */}
      <HeaderText/>

      <nav id='stepInd' className="flex justify-between items-start mt-16 mr-36 whitespace-nowrap max-md:flex-wrap max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
        <StepInd stepNumber="1" description="General Details" isActive={true} />
        <StepInd stepNumber="2" description="Company Details" isActive={false} />
        <StepInd stepNumber="3" description="Demat Details" isActive={false} />
        <StepInd stepNumber="4" description="Preferences" isActive={false} />
        <StepInd stepNumber="5" description="Signatures" isActive={false} />
      </nav>

      <section className="flex gap-5 justify-between mt-24 mr-8 max-md:flex-wrap max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
        <InputField label="Company Name" />
        <div className="flex flex-col flex-1 text-lg text-neutral-400 max-md:max-w-full">
          <label htmlFor="business-type" style={{ color: inputFieldColor, fontSize: 18 }} className="text-sm text-neutral-600">Business Type</label>
          <select
            id="business-type"
            className={`shrink-0 mt-2 bg-white rounded-md border border-solid border-zinc-300 h-[50px] max-md:max-w-full ${styles.selectWrapper}`}
            aria-label="Business Type"
          >
            <option value="" selected disabled>Select Business Type</option>
            <option value="business-type-1">Option1</option>
            <option value="business-type-2">Option2</option>
            <option value="business-type-3">Option3</option>
          </select>
        </div>
      </section>

      <section className="flex gap-5 justify-between mt-10 mr-8 text-lg text-neutral-400 max-md:flex-wrap max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
        <InputField label="Authorised Signatory Name" />
        <InputField label="Designation" />
      </section>

      <section className="flex gap-5 justify-between mt-10 mr-8 text-lg text-neutral-400 max-md:flex-wrap max-md:mr-2.5 max-md:max-w-full">
        <InputField label="Official Email ID" />
        <InputField label="Mobile Number" />
      </section>

      <section className="flex gap-5 justify-between mt-10 mr-8 text-lg text-neutral-400 max-md:flex-wrap max-md:mr-2.5 max-md:max-w-full">
        <InputField label="Company Address" />
        <InputField label="Website" />
      </section>


      {/* Reusable components for better structure and reduced repetition */}
      <Buttons/>


    </main>
  );
};

export default GeneralDetailsForm;
