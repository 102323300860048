import Chatbot, { createChatBotMessage } from "react-chatbot-kit";
import MessageParser from "./MessageParser";
import 'react-chatbot-kit/build/main.css'
import configChatbot from "./config";
import { useState } from "react";
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import { useDispatch } from "react-redux";
import ActionProvider from './ActionProvider';
const ChatbotMain = () => {
    const [showChatbot, setShowChatbot] = useState(false);
    const [messages, setMessages] = useState({});
    const dispatch = useDispatch();

    const handleButtonClick = () => {
        setShowChatbot(!showChatbot);
    };
    return (


        <>
            {showChatbot && (
                <div style={{ position: "fixed", bottom: 70, right: 10, zIndex: 101 }}>
                    <div className="border border-2 border-secondary rounded-md shadow-lg">
                        <Chatbot
                            config={configChatbot}
                            messageParser={MessageParser}
                            actionProvider={ActionProvider}
                            headerText="Your personal assistant..."
                            placeholderText="Ask me Anything..."
                        />
                    </div>
                    <div className="items-center">
                        <button
                            onClick={handleButtonClick}
                            className="bg-white text-secondary font-semibold border border-darkGrey rounded-2xl text-md p-2 mt-10 shadow-md"
                            style={{ position: "fixed", bottom: 10, right: 20, zIndex: 100 }} // Position button outside chatbot overlay
                        >
                            Close Assistant!
                        </button>
                    </div>

                </div>
            )}

            {!showChatbot && (
                <div style={{ position: "fixed", bottom: 70, right: 10, zIndex: 101 }}>
                    <button
                        onClick={handleButtonClick}
                        className="flex gap-x-2 bg-white text-secondary font-semibold border border-darkGrey rounded-2xl text-md p-2 shadow-md items-center"
                        style={{ position: "absolute", bottom: "-60px", right: "10px" }} // Adjust bottom and right position
                    >
                        <span>
                            <MapsUgcIcon style={{ fontSize: 35 }} />
                        </span>
                        ask me Anything!
                    </button>
                </div>
            )}
        </>
    );
};

export default ChatbotMain