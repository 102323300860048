import React, { useState } from 'react';
import HUFComponent from '../../pages/NonIndividualPages/CompanyDetails/HUF';
import CorporateComponent from '../../pages/NonIndividualPages/CompanyDetails/Corporate';
import LLPComponent from '../../pages/NonIndividualPages/CompanyDetails/Llp';
import TrustComponent from '../../pages/NonIndividualPages/CompanyDetails/Trust';

type SectionKey = 'HUF' | 'Corporate' | 'LLP' | 'Trust';

const sectionConfig: Record<SectionKey, React.FC<any>> = {
  HUF: HUFComponent,
  Corporate: CorporateComponent,
  LLP: LLPComponent,
  Trust: TrustComponent,
};

interface NonIndividualCompanyLayoutProps {
  businessType: SectionKey;
  activeSection: string;
  onSectionChange: (section: string) => void;
  completedSections: boolean[];
  children?: React.ReactNode;
  allowToProceed: () => void;
  setBusinessType: (businessType: SectionKey) => void;
}

const NonIndividualCompanyLayout: React.FC<NonIndividualCompanyLayoutProps> = ({ businessType, setBusinessType, allowToProceed }) => {
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedBusinessType = e.target.value as SectionKey;
    setBusinessType(selectedBusinessType);
  };

  const renderSectionContent = (section: SectionKey) => {
    const Component = sectionConfig[section];
    if (Component) {
      return (
        <Component
          activeSection={businessType}
          allowToProceed={allowToProceed}
        />
      );
    }
    return null;
  };

  return (
    <>
      <div>
        <label htmlFor="section-select" className="sr-only">Select Section</label>
        <select
          id="section-select"
          onChange={handleSelectChange}
          value={businessType}
          className="w-[400px]"
        >
          <option value="" disabled>Select a section</option>
          <option value="HUF">HUF</option>
          <option value="Corporate">Corporate</option>
          <option value="LLP">LLP</option>
          <option value="Trust">Trust</option>
        </select>
      </div>
      <div className="w-full">
        {renderSectionContent(businessType)}
      </div>
    </>
  );
};

export default NonIndividualCompanyLayout;
