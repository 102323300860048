import toast from "react-hot-toast";

interface MFinvest {
    investmentAmount: number;
    timePeriodValue: string;
    investmentFrequency: string;
    investmentType: string;
    expectedReturn: number;
}

interface InvestmentResult {
    TotalReturn: number;
    TotalInvestment: number;
    FutureValue: string;
    NumOfInstallments?: number;
}

export const calculateInvestment = ({
    investmentAmount,
    timePeriodValue,
    investmentFrequency,
    investmentType,
    expectedReturn
}: MFinvest): InvestmentResult => {
    // console.log('investmentAmount', investmentAmount, 'timePeriodValue', timePeriodValue, 'investmentType', investmentType);

    if (!investmentAmount || !timePeriodValue || !investmentFrequency || !investmentType || !expectedReturn) {
        return {
            TotalReturn: 0,
            TotalInvestment: 0,
            FutureValue: '0',
        };
    }

    const calculateLumpsum = () => {
        const futureValue = investmentAmount * Math.pow((1 + expectedReturn / 100), parseInt(timePeriodValue));
        const totalInvestment = investmentAmount;
        const totalReturns = futureValue - totalInvestment;
        return { futureValue, totalInvestment, totalReturns };
    };

    const calculateSIP = () => {
        const monthlyReturnRate = (expectedReturn / 100) / 12;
        const n = parseInt(timePeriodValue) * 12;

        const futureValue = investmentAmount * ((Math.pow(1 + monthlyReturnRate, n) - 1) / monthlyReturnRate) * (1 + monthlyReturnRate);
        const numOfInstallments = n;
        const totalInvestment = investmentAmount * n;
        const totalReturns = futureValue - totalInvestment;

        return { futureValue, totalInvestment, totalReturns, numOfInstallments };
    };

    let result: { futureValue: number; totalInvestment: number; totalReturns: number; numOfInstallments?: number };
    if (investmentType === 'Lumpsum') {
        result = calculateLumpsum();
    } else if (investmentType === 'SIP') {
        result = calculateSIP();
    } else {
        throw new Error('Invalid investment type');
    }

    return {
        TotalReturn: result.totalReturns,
        TotalInvestment: result.totalInvestment,
        FutureValue: result.futureValue.toFixed(2),
        NumOfInstallments: result.numOfInstallments,
    };
};
