import { ChangeEvent } from "react";

// All utility functions in this module
export const formatDate = {};

// Define the maximum allowed file size (1 MB)
const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1 MB

// Utility function to validate file size and clear the input if it exceeds the limit
export const checkFileSizeAndReset = (
  file: File,
  event: ChangeEvent<HTMLInputElement>
): boolean => {
  if (file.size > MAX_FILE_SIZE) {
    alert("File size exceeds the allowed limit. (Max: 1mb)"); // Customize this message if needed
    event.target.value = ""; // Clear the file input
    return false; // Indicate that the file is too large
  }

  return true; // Indicate that the file size is within the allowed limit
};

export const getAmountInWords = (amount: any) => {
  // Ensure the input is a valid number
  if (amount === undefined || amount === null || isNaN(Number(amount))) {
    return "Invalid amount"; // Handle undefined or invalid input
  }

  var a = [
    "",
    "One ",
    "Two ",
    "Three ",
    "Four ",
    "Five ",
    "Six ",
    "Seven ",
    "Eight ",
    "Nine ",
    "Ten ",
    "Eleven ",
    "Twelve ",
    "Thirteen ",
    "Fourteen ",
    "Fifteen ",
    "Sixteen ",
    "Seventeen ",
    "Eighteen ",
    "Nineteen ",
  ];
  var b = [
    "",
    "",
    "Twenty ",
    "Thirty ",
    "Forty ",
    "Fifty ",
    "Sixty ",
    "Seventy ",
    "Eighty ",
    "Ninety ",
  ];


  if ((amount = amount.toString()).length > 9) return "overflow";
  let n: any = ("000000000" + amount)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        "Only "
      : "";
  return str ? "Amount in words: " + str : "";
};
