import { createChatBotMessage } from 'react-chatbot-kit';
import MyAvatar from './Style/MyAvatar';

const configChatbot = {
  initialMessages: [createChatBotMessage(`Hello`)],
  botName: 'AUM',
  botAvatar: (props) => <MyAvatar {...props} />,
  // customStyles: {
  //   botMessageBox: {
  //     backgroundColor: '#376B7E',
  //   },
  //   chatButton: {
  //     backgroundColor: '#5ccc9d',
  //   },
  // },
};

export default configChatbot;