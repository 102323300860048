import React, { FC, useState, useRef, useEffect } from 'react';
import { colors } from '../../../constants/colors';
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { addOrganization } from "../../../redux/NonInd_GeneralDetails/generalSlice";
import ConfirmationModal from '../../../components/modals/PopupModal';
import PhoneInput from "react-phone-number-input";
import { CustomFormInput } from "../../../components/FormInput/CustomFormInput";
import toast from 'react-hot-toast';

interface InfoSectionProps {
  icon: string;
  title: string;
  bgColor: string;
}

interface FormInputProps {
  label: string;
  onChange?: any;
  value?: any;
  name?: string;
  type?: string;
  disabled?: boolean;
}
type SectionKey = 'HUF' | 'Corporate' | 'LLP' | 'Trust';
interface GeneralDetailsFormProps {
  onGeneralToCompany: () => void; // Prop to handle transition to bank details section
  activeSection: string; // Prop to indicate the active section
  allowToProceed: () => void;
  onBusinessTypeChange: (businessType: SectionKey) => void;
  userData: any;
}



const generalValues = {
  companyName: "",
  businessType: "",
  authSignName: "",
  designation: "",
  email: '',
  mobile: "",
  address: "",
  website: "",
};

const generalSchema = yup.object({
  companyName: yup.string().required(),
  authSignName: yup.string().required(),
  designation: yup.string().required(),
  email: yup.string().required(),
  mobile: yup.string().required(),
  address: yup.string().required(),
  website: yup.string().required(),
  // businessType: yup
  //   .string()
  //   .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN format")
  //   .required("PAN is required"),
});

const FormInput: FC<FormInputProps> = ({ label, onChange, value, name, disabled, type }) => {
  if (type === "phone") {
    return (
      <div className="flex-1 mb-4">
        <div className="flex flex-col mb-10">
          <label htmlFor={label.replace(/ /g, '').toLowerCase()} style={{ color: colors.darkGrey }}>{label}</label>
          <PhoneInput
            className={`w-full mt-3 ${disabled ? 'bg-gray-200' : 'bg-white'} rounded-lg border border-solid p-4`}
            style={{
              color: disabled ? colors.lightGrey : colors.darkGrey,
              borderColor: colors.lightGrey,
              borderWidth: '1px',
            }}
            defaultCountry="IN"
            onChange={onChange}
            value={value}
            name={name}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1 mb-4">
      <div className="flex flex-col mb-10">
        <label htmlFor={label.replace(/ /g, '').toLowerCase()} style={{ color: colors.darkGrey }}>{label}</label>
        <input
          className={`w-full mt-3 ${disabled ? 'bg-gray-200' : 'bg-white'} rounded-lg border border-solid p-4`}
          style={{
            color: disabled ? colors.lightGrey : colors.darkGrey,
            borderColor: colors.lightGrey,
            borderWidth: '1px',
          }}
          type="text"
          id={label.replace(/ /g, '').toLowerCase()}
          aria-label={label}
          onChange={disabled ? undefined : onChange}
          value={value}
          name={name}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

const GeneralDetails: FC<GeneralDetailsFormProps> = ({ onGeneralToCompany, activeSection, allowToProceed, onBusinessTypeChange, userData }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [completedSections, setCompletedSections] = useState<boolean[]>(Array(6).fill(false));
  const formikRef = useRef<any>();
  const dispatch = useDispatch<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailDisabled, setEmailDisabled] = useState(true);
  const [panErrorMessage, setPanErrorMessage] = useState<string | null>(null);
  const { kyc } = userData;
  const GeneralFormRef = useRef<any>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const onAllow = (): void => {
    const updatedSections = [...completedSections];
    updatedSections[2] = true;
    setCompletedSections(updatedSections);
  };



  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalConfirm = () => {
    setIsModalVisible(false);
    if (formikRef.current) {

      console.log('handle submit', formikRef?.current);

      formikRef?.current?.handleSubmit();
    }
  };


  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleNameDisabled = () => {
    setEmailDisabled(!emailDisabled);
  };

  const businessTypeMap: any = {
    C: 'Corporate',
    F: 'LLP',
    H: 'HUF',
    T: 'Trust',
  };

  useEffect(() => {
    if (userData && userData.kyc && userData.kyc.pancardNumber) {
      const panNumber: string = userData.kyc.pancardNumber;
      if (userData?.email) {
        formikRef.current?.setFieldValue("email", userData?.email);
      }
      const buisnessType: string = panNumber[3];
      const businessTypeLabel: string = businessTypeMap[buisnessType] || "Unknown business type";
  

      formikRef.current?.setFieldValue("businessType", businessTypeLabel);
  
      if (buisnessType === 'P') {
        setPanErrorMessage("This is an individual PAN number. Please provide a valid business PAN.");
      } else {
        setPanErrorMessage(null); 
      }

      formikRef.current?.setFieldValue("pancardNumber", panNumber);
    }
  }, [userData]);
  

  return (
    <>

      <div style={{ backgroundColor: colors.lightBg }} className='flex flex-col w-full max-w-screen-xl my-8'>
        <Formik
          innerRef={formikRef}
          initialValues={generalValues}
          validationSchema={generalSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values, "company values");
            // if (panErrorMessage) {
            //   toast.error(panErrorMessage); // Show the error message using toast
            //   setSubmitting(false); // Prevent submission if PAN is invalid
            //   return;
            // }
            if (values.authSignName) {
              const formData: any = new FormData();
              formData.append("companyName", values.companyName);
              formData.append("businessType", values.businessType);
              formData.append("authSignName", values.authSignName);
              formData.append("designation", values.designation);
              formData.append("email", values.email);
              formData.append("mobile", values.mobile);
              formData.append("address", values.address);
              formData.append("website", values.website);

              const response = dispatch(addOrganization(formData))
                .then((response: any) => {
                  if (response.payload.message === "success" || response.payload.message === 'Your organization already exists.') {
                    toast.success("Organization added successfully!");
                    allowToProceed()
                  } else {
                    toast.error("Failed to add organization. Please try again.");
                  }
                  console.log(response.payload.message, "response");
                })
                .catch((error: any) => {
                  toast.error("An error occurred. Please try again.");
                  console.error(error);
                })
                .finally(() => {
                  setSubmitting(false);
                });

            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} className="w-full flex flex-col gap-8">
              <div className="flex flex-wrap -mx-2">
                <div className="w-full md:w-1/2 px-2">
                  <FormInput label="Company Name *" name="companyName" onChange={handleChange("companyName")} value={values.companyName} disabled={false} />
                </div>
                <div className="w-full md:w-1/2 px-2">
                  <FormInput label="PAN Number *" name="businessType" onChange={handleChange('businessType')} value={kyc.pancardNumber} disabled={true} />
                </div>
                <div className="w-full md:w-1/2 px-2">
                  <FormInput label="Authorised Signatory Name *" name="authSignName" onChange={handleChange("authSignName")} value={values.authSignName} disabled={false} />
                </div>
                <div className="w-full md:w-1/2 px-2">
                  <FormInput label="Designation *" name="designation" onChange={handleChange("designation")} value={values.designation} disabled={false} />
                </div>
                <div className="w-full md:w-1/2 px-2">
                  <CustomFormInput
                    label="Official Email ID *"
                    type="text"
                    name="Email"
                    id="email"
                    onChange={(e: any) => setFieldValue("email", e.target.value)}
                    value={userData.email}
                    error={errors.email}
                    disabled={emailDisabled}
                    touched={touched.email}
                    suffix={<span className="text-sm text-secondary cursor-pointer font-semibold" onClick={handleNameDisabled}>{emailDisabled ? "Edit" : "Save"}</span>}
                  />
                </div>
                <div className="w-full md:w-1/2 px-2">
                  <FormInput label="Mobile Number *" type="phone" name="mobile" onChange={handleChange("mobile")} value={values.mobile} disabled={false} />
                </div>
                <div className="w-full md:w-1/2 px-2">
                  <FormInput label="Address *" name="address" onChange={handleChange("address")} value={values.address} disabled={false} />
                </div>
                <div className="w-full md:w-1/2 px-2">
                  <FormInput label="Website *" name="website" onChange={handleChange("website")} value={values.website} disabled={false} />
                </div>
              </div>
              <div className="flex gap-x-4 w-[40%]">
                <button
                  style={{
                    backgroundColor: colors.white,
                    color: colors.darkGrey,
                    border: `1px solid ${colors.darkGrey}`,
                  }}
                  className="grow justify-center px-8 py-4 whitespace-nowrap rounded-md border border-solid"
                  tabIndex={0}
                  onClick={() => {}}
                >
                  Skip
                </button>
                <button
                  type="button"
                  style={{ backgroundColor: colors.darkGrey, color: colors.white }}
                  className="grow justify-center px-8 py-4 rounded-md border-3 border-darkGrey border-solid"
                  tabIndex={0}
                  onClick={handleButtonClick}
                >
                  Save and Proceed
                </button>

              </div>
            </form>
          )}
        </Formik>
      </div>
      <ConfirmationModal
        isVisible={isModalVisible}
        message="Are you sure you want to proceed? After submission, changes cannot be made."
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
      />
    </>
  );
};

export default GeneralDetails;
