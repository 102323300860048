import React from 'react';
import avatar from '../../../assets/icons/manualkyc.png'
const MyAvatar = (props: any) => {
    return (
        <img
            src={avatar}
            alt="Bot Avatar"
            style={{ width: '50px', height: '50px', borderRadius: '50%' }} // You can customize the styles here
            {...props}
        />
    );
};

export default MyAvatar;
