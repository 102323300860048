import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../../constants/colors";
import {
  addSignature,
  createBSEUser,
  getAUFForm,
  getUserById,
  verifyBseOTP,
} from "../../../redux/user/userSlice";
import { useNavigate } from "react-router-dom";
import AUFFormPDF from "../../../assets/forms/AUF_Form.pdf";
import VideoRecorder from "../../../components/VideoRecorder/VideoRecorder";
import {
  eSign_kyc,
  uploadAufForm,
  uploadVideo,
} from "../../../redux/eSign/eSignSlice";
import toast from "react-hot-toast";
import userService from "../../../redux/user/userService";
import { checkFileSizeAndReset } from "../../../utils/index";
import ConfirmationModal from "../../../components/modals/PopupModal";
import { hideLoading, showLoading } from "../../../redux/loader/loaderSlice";
import SignatureCanvas from "../../../components/Signature/SignatureCanvas";



interface SignFormProps {
  allowToProceed: () => void;
  userData: any;
}

interface InputFieldProps {
  label: string;
  type: string;
  id: string;
  altText?: string;
  onChange: any;
}

const FileInputField: React.FC<InputFieldProps> = ({ label, onChange }) => {
  const [fileName, setFileName] = React.useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const isValid = checkFileSizeAndReset(file, event); // Validate the file size
      if (isValid) {
        setFileName(file.name); // Update the displayed file name
      } else {
        setFileName(null); // Reset the displayed file name if invalid
      }
    }
  };

  return (
    <div
      className="flex flex-col mt-10 mb-10 grow text-lg"
      style={{ color: colors.darkGrey }}
    >
      <div className="mb-5 text-base sm:text-base md:text-xl">{label}</div>
      <label
        className="shrink-0 rounded-lg p-3 flex items-center justify-between "
        style={{
          color: colors.darkGrey,
          backgroundColor: colors.white,
          border: `1px solid ${colors.darkBg}`,
        }}
      >
        <input
          type="file"
          className="hidden sm:w-full"
          style={{ color: colors.white }}
          onChange={(e) => {
            handleFileChange(e);
            onChange(e);
          }}
        />
        <span>{fileName ? fileName : ""}</span>
        <img
          src="assets/icons/UploadBtn.png" // Update with your image path
          alt="Upload Icon"
          className="h-10 w-10 ml-2 cursor-pointer"
        />
      </label>
    </div>
  );
};

const SignForm: FC<SignFormProps> = ({ allowToProceed, userData }) => {
  const [signatureDoc, setSignatureDoc] = useState<any>();
  const [signatureUrl, setSignatureUrl] = useState<string | null>(null);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [showAUFForm, setShowAUFForm] = useState<boolean>(false);
  const [KycDone, setKycDone] = useState<boolean>(true);
  const [AUFForm, setAUFForm] = useState<any>({});
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [recordedVideo, setRecordedVideo] = useState<Blob | null>(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [longitude, setLongitude] = useState<number>(0); // Add longitude state
  const [latitude, setLatitude] = useState<number>(0); // Add latitude state


  const handleLocationChange = (latitude: number, longitude: number) => {
    setLatitude(latitude);
    setLongitude(longitude);
  };

  const handleButtonClick = () => {
    if(!KycDone){
      setIsModalVisible(true);
    }else{
      setShowAUFForm(true);
    }
  };

  const handleSignatureSave = (file: File) => {
    setSignatureDoc(file);
    setSignatureUrl(URL.createObjectURL(file)); 
  };

  useEffect(() => {
    const kycCheck = async () => {
      try {
        const response = await userService.getUserById();
        console.log(response?.data[0]?.kycVideo,"123");
          if (response?.data[0]?.kycVideo==="" || response?.data[0]?.isSignatureAdd===0) {
            setKycDone(false);
          }
          console.log(KycDone,"KYC");
      } catch (err) {
       console.log(err);
      }
    };
    kycCheck();
  }, []);

  useEffect(() => {
      const VerifyUser = async () => {
        try {
          const response = await userService.getAUFForm();
            if (response?.error?.message) {
              console.log("something wents wrong");
              return;
            }
            setPdfUrl(response?.data?.pdf);
        } catch (err) {
         console.log(err);
        }
      };
      VerifyUser();
  }, []);

  

  const onAddSignature = async () => {
    try {
      if (signatureDoc) {
        dispatch(showLoading());
        const formData = new FormData();
        formData.append("signature", signatureDoc);

        dispatch(addSignature(formData));

        if (recordedVideo && longitude && latitude) {
          console.log("recordedVideo, randomCode", recordedVideo);

          const formData = new FormData();
          formData.append("video", recordedVideo);
          formData.append("longitude", longitude.toString());
          formData.append("latitude", latitude.toString());

          const videoupload = await dispatch(uploadVideo(formData));

          const response = await userService.getAUFForm();
          if (response?.error?.message) {
            toast.error("Please upload a signature");
            return;
          }
          setPdfUrl(response?.data?.pdf);
          setShowAUFForm(true);
        } else {
          toast.error("Please record a verification video");
        }
      } else {
        toast.error("Please upload a signature");
      }
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    } finally {
      dispatch(hideLoading());
    }
  };

  const downloadAUFForm = () => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${pdfUrl}`;
      link.download = "auf_form.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const fetchData = async () => {
    try {
      console.log("ESign API calling begin");
      const response = await dispatch(eSign_kyc());
      if (response?.error?.message) {
        toast.error(response?.payload?.message);
        return;
      }
      console.log(response?.payload?.data?.urlInfo?.signer_url);
      const url = response?.payload?.data?.urlInfo?.signer_url;
      if (url) {
        toast.success("Please check your email for esign link");
        window.location.href = url;
      } else {
        toast.error("URL not found in response");
      }
      //  onUploadAUFForm();
    } catch (error) {
       //onUploadAUFForm();
      toast.error("Something went wrong!");
      console.error("API Error:", error);
    }
  };

  const handleModalConfirm = () => {
    onAddSignature();
    setIsModalVisible(false); // Close the modal after confirming
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Just close the modal without taking action
  };


  const [showSignature, setShowSignature] = useState<string | null>(null); // Initialize to null

  const [showUpload, setShowUpload] = useState<string | null>(null);

  // Function to handle toggle change
  const handleToggleChange = (event: any, newOption: string | null) => {
    if (newOption !== null) {
      setShowSignature(newOption === "signature" ? "signature" : null);
      setShowUpload(newOption === "upload" ? "upload" : null);
    }
  };

  return (
    <>
      <section className="w-full max-w-[1200px] mx-auto  max-md:mt-10 max-md:max-w-full flex flex-col justify-between">
        <div className="flex justify-between w-full ">
          {/* Left side: Main content with video recorder and file inputs */}
          <div className="flex flex-col mx-auto w-full px-5">
            {!showAUFForm ? (
              <div className="flex justify-between gap-8 items-center">
                {!KycDone?(
                  <div className="w-full max-w-[600px] mx-auto">
                  <div className="flex justify-evenly items-center mt-5 mb-5">
                    <h2 className="text-xl font-bold text-primary">
                      Your Signature
                    </h2>
                  </div>

                  {!signatureDoc && (
                    <>
                      <SignatureCanvas onSignatureSave={handleSignatureSave} />
                      <h6 className="my-4 text-darkGrey font-inter text-center">
                        Please ensure that you draw a valid signature
                      </h6>
                    </>
                  )}

                  {showUpload && !signatureDoc && (
                    <div className="mt-4">
                      <FileInputField
                        label="Upload Signature *"
                        type="file"
                        id="signature"
                        onChange={(e: any) =>
                          setSignatureDoc(e.target.files[0])
                        }
                      />
                    </div>
                  )}

                  {signatureDoc && (
                    <div className="mt-5 mb-5 flex justify-center">
                      <img
                        src={URL.createObjectURL(signatureDoc)}
                        alt="Saved Signature"
                        className="border border-gray-300 rounded p-2 bg-white"
                      />
                    </div>
                  )}

                  <VideoRecorder
                    recordedVideo={recordedVideo}
                    setRecordedVideo={setRecordedVideo}
                    onLocationChange={(latitude, longitude) =>
                      handleLocationChange(latitude, longitude)
                    }
                  />
                  <h6 className="my-4 text-darkGrey font-inter text-center">
                    Video recording is required to verify your liveliness.
                    Choose a spot with good lighting and remove any accessories
                    like – hat, glasses etc.
                  </h6>
                </div>
                ):(
                  <h6 className="my-4 text-darkGrey font-inter text-center">
                    Signature and video for KYC is already uploaded.
                  </h6>
                )}
              </div>
            ) : (
              <>
                <div
                  className="w-full flex flex-col mt-10 mb-10 grow text-lg"
                  style={{ color: colors.darkGrey }}
                >
                  <div className="w-full mb-5 text-base sm:text-base md:text-xl">
                    <div className="w-full my-4">
                      <button
                        style={{
                          backgroundColor: colors.primary,
                          color: colors.white,
                        }}
                        type="submit"
                        className={`grow  justify-center px-8 py-2 rounded-md border-3 border-${colors.darkGrey} border-solid `}
                        tabIndex={0}
                        onClick={fetchData}
                      >
                        Esign AUF Form
                      </button>
                      <p className="font-bold text-secondary text-xl font-inter mt-5">
                        *Note: Click on Esign AUF Form and upload the signed
                        form. You will get the esigned form to your email after
                        esigning.
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {/* Buttons to proceed or skip */}
        <div className="ml-3 lg:mb-5 sm:mt-10">
          <section className="mb-5 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col max-md:w-full">
                  {showAUFForm?(<></>):(<button
                  style={{
                    backgroundColor: colors.darkGrey,
                    color: colors.white,
                  }}
                  type="submit"
                  className={`grow justify-center px-8 py-4 rounded-md border-3 border-${colors.darkGrey} border-solid`}
                  tabIndex={0}
                  onClick={handleButtonClick}
                >
                  Save and Proceed
                </button>)}
                
                {!KycDone ?(<ConfirmationModal
                  isVisible={isModalVisible} // Modal visibility control
                  message="Are you sure all fields are correct and verified? After you proceed to the next screen, fields cannot be edited again."
                  onConfirm={handleModalConfirm} // Confirm action
                  onCancel={handleModalCancel} // Cancel action
                />):(<></>)}
                {/* </div> */}
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default SignForm;
