import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Modal,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import FilterAlt from "@mui/icons-material/FilterAltOutlined";
import { Add, Close, Search } from "@mui/icons-material";
import { colors } from "../../constants/colors";
import { LineChart } from "@mui/x-charts";
import Elipse from "../../assets/images/Ellipse.png";
import riskometer from "../../assets/icons/new-riskometer.png";
import { BookmarkBorder, Download } from "@mui/icons-material";
import LineChartComponent from "../../components/LineChart/lineChart";
import Details from "./Details";
import Doubtnut from "../../components/AllCharts/pieChart";

import Transactions from "../../components/Transactions/Transactions";
import { useUserData } from "../../hooks/useUserData";
import { useDispatch } from "react-redux";
import { fetchDashboardData } from "../../redux/Dashboard/DashboardSlice";
import toast, { LoaderIcon } from "react-hot-toast";
import PieChart_Component from "../../components/PieChart/PieChart";
import getInitials from "../../utils/getInitailasIMGUtils";
import { importFunds, mfSendOTP, mfVerifyOTP } from "../../redux/MF/MFSlice";
import VerifyOTPModal from "../../components/modals/VerifyOTPModal";
import AppLoader from "../../components/AppLoader/AppLoader";
import { hideLoading, showLoading } from "../../redux/loader/loaderSlice";
import ConfirmationModal from "../../components/modals/PopupModal";
import HorizontalLine from "../../components/AllCharts/HorizontalLine";
import HallowChart from "../../components/AllCharts/HollowChart";
import { getUserById } from "../../redux/user/userSlice";
import ChatbotMain from "../../components/Chatbot/Chatbot";

interface MutualFundTransaction {
  id: number;
  schemeName: string;
  orderType: "MF";
  amount: number;
  units: number;
  purchaseNAV: number;
  currentValue: number;
  isBuy: number;
  orderStatus: string;
  paymentStatus: string | null;
  createdAt: string;
  transactionDate:string;
  investedAmount:number;
}

interface FixedDepositTransaction {
  id: number;
  name: string;
  amount: number;
  interesetRate: number;
  returnAmount: number;
  fdStatus: number;
  createdAt: string;
  orderType: "FD";
  transactionDate:string;
  investedAmount:number;
}

interface DashboardData {
  AMC: any[];
  totalValueInvestedMF: number;
  currentValueMF: number;
  unrealisedGainLossMF: number;
  rioMF: number;
  totalValueInvestedFD: number;
  totalValueInvestedMFAumCapital: number;
  currentValueMFAumCapital: number;
  roiMFAumCapital: number;
  unrealisedGainLossMFAumCapital: number;
  totalFinalValueFD: number;
  unrealisedGainLossFD: number;
  roiFD: number;
  totalValueAtCost: number;
  recentTransactions: [MutualFundTransaction[], FixedDepositTransaction[]];
  roiAif: number;
  roiBond: number;
  roiPms: number;
  roiStock: number;
  totalFinalValueAif: number;
  totalFinalValueBond: number;
  totalFinalValuePms: number;
  totalFinalValueStock: number;
  totalValueInvestedAif: number;
  totalValueInvestedBond: number;
  totalValueInvestedPms: number;
  totalValueInvestedStock: number;
  unrealisedGainLossAif: number;
  unrealisedGainLossBond: number;
  unrealisedGainLossPms: number;
  unrealisedGainLossStock: number;
  totalPortfolioValue:number;
  totalUnrealisedGainLoss: number;
  totalUnrealisedGainLossPercentage: number;
}
interface Data {
  title: string;
  totalAmount: string;
  profitAmount: string;
  unrealised: string;
  roi: string;
}

const MainDashboard = () => {
  const user = useUserData();
  const { todayImportDone } = useUserData();
  // const [alignment, setAlignment] = useState<string | null>("1Y");
  const [dashboard, setMDData] = useState<DashboardData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tabState, setTabState] = useState<"AumCapital" | "Consolidated">(
    "Consolidated"
  );
  const [finalData, setFinalData] = useState<Data[]>([]);
  const dispatch = useDispatch<any>();

  const [DataTransfer, setDataTransfer] = useState({
    riskmeter: "Moderately High",
    portfolioSummary: "Portfolio Summary",
    totalValueCost: "1,24,00,750.00",
    UnrealisedGL: "2,23,00,250.00",
    PortfolioValue: "3,48,00,500.00",
    ROI: "23",
  });
  const [OTPData, setOTPData] = useState({
    otpRef: "",
    clientRefNo: "",
    reqId: 0,
  });
  const [showSummary, setShowSummary] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [otp, setOtp] = useState("");

  const handleOtpChange = (event: any) => {
    setOtp(event.target.value);
  };

  const calculateDates = () => {
    const endDate = new Date(); // Current date
    const startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1); // Set to one year ago
  
    return {
      startDate: startDate.getTime(), // Convert to timestamp
      endDate: endDate.getTime(),       // Convert to timestamp
    };
  };
  

  const fetchData = async () => {
    const { startDate, endDate } = calculateDates();
    const data = {
      fetchFor: tabState.toLowerCase(),
      startDate,
      endDate,
    };

    try {
      const response = await dispatch(fetchDashboardData(data));
      if (response.payload.data) {
        setMDData(response.payload.data);
      }
    } catch (error) {
      console.error("Fetch dashboard data failed:", error);
      toast.error("Please Refresh");
      // setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [tabState])
  

  const handleSubmit = async () => {
    if (OTPData && otp) {
      const updatedOTPData = { ...OTPData, otp: otp };
      setOTPData(updatedOTPData);

      if (updatedOTPData) {
        try {
          const verifyResponse = await dispatch(mfVerifyOTP(updatedOTPData));
          if (verifyResponse.payload.data.success === 400) {
            toast.error("Invalid OTP");
          }

          if (verifyResponse.payload.data.success === 200) {
            setShowSummary(false);
            setLoadingModal(true);
            let fetechImport;
            const importData = {
              clientRefNo: updatedOTPData.clientRefNo,
              reqId: updatedOTPData.reqId,
              isRefresh: 0,
            };

            const fetchImportData = async () => {
              // console.log('function');

              fetechImport = await dispatch(importFunds(importData));
              // console.log('fetct import data', fetechImport);

              if (
                fetechImport?.payload.success === 400 &&
                fetechImport.payload.message ===
                  "We are in process of generating the CAS. Please visit after sometime."
              ) {
                // toast.error(fetechImport?.payload.message);
                // Retry after 20 seconds
                // console.log('retry');
                toast.success(
                  "We are in process of generating the CAS Please Refresh the after 20s."
                );
                dispatch(getUserById({}));
                fetchData();
              } else if (fetechImport?.payload.data.success === 200) {
                toast.success("Mutual fund Data Sync Request submitted");
                // console.log('fulfil');
                dispatch(getUserById({}));
                fetchData();
                setLoadingModal(false);
              } else {
                toast.error("Request Failed");
              }
            };

            setTimeout(fetchImportData, 20000);
            // setLoadingModal(true)
            // console.log('after fetchImportData');
            setOTPData({
              otpRef: "",
              clientRefNo: "",
              reqId: 0,
            });
            setOtp("");
          } else {
            toast.error("Request Failed");
          }
        } catch (error) {
          toast.error("An error occurred while processing your request.");
        } finally {
          // setLoadingModal(false);
          // dispatch(hideLoading())
        }
      }
    } else {
      toast.error("Please enter the OTP.");
    }
  };

  useEffect(() => {
    const sendOtp = async () => {
      if (todayImportDone === 1) {
        fetchData();
      } else if (todayImportDone === 0) {
        try {
          setShowSummary(true);
          const response = await dispatch(mfSendOTP());

          if (response.payload.reqId) {
            toast.success("OTP Sent Successfully");
            setOTPData(response.payload);
          } else {
            toast.error("No mutual fund found on provided pan card and mobile number.");
            setShowSummary(false);
          }
        } catch (error) {
          toast.error("OTP Send Failed");
          setShowSummary(false);
        }
      }
    };

    sendOtp();
  }, [dispatch, todayImportDone]);

  console.log("dashboard", dashboard);
  const currentDate = new Date();
  const formattedDate = currentDate
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    })
    .toUpperCase();

  const formattedDateWithMonthInCaps = formattedDate.replace(
    /\b\w+\b/,
    (month) => month.toUpperCase()
  );

  useEffect(() => {
    const verticalBar: Data[] = [
      {
        title: "FD",
        totalAmount: (dashboard?.totalValueInvestedFD ?? 0).toString(),
        profitAmount: (dashboard?.totalFinalValueFD ?? 0).toString(),
        unrealised: (dashboard?.unrealisedGainLossFD ?? 0).toString(),
        roi: (dashboard?.roiFD ?? 0).toString(),
      },
      {
        title: "AIF",
        totalAmount: (dashboard?.totalValueInvestedAif ?? 0).toString(),
        profitAmount: (dashboard?.totalFinalValueAif ?? 0).toString(),
        unrealised: (dashboard?.unrealisedGainLossAif ?? 0).toString(),
        roi: (dashboard?.roiAif ?? 0).toString(),
      },
      {
        title: "PMS",
        totalAmount: (dashboard?.totalValueInvestedPms ?? 0).toString(),
        profitAmount: (dashboard?.totalFinalValuePms ?? 0).toString(),
        unrealised: (dashboard?.unrealisedGainLossPms ?? 0).toString(),
        roi: (dashboard?.roiPms ?? 0).toString(),
      },
      {
        title: "BOND",
        totalAmount: (dashboard?.totalValueInvestedBond ?? 0).toString(),
        profitAmount: (dashboard?.totalFinalValueBond ?? 0).toString(),
        unrealised: (dashboard?.unrealisedGainLossBond ?? 0).toString(),
        roi: (dashboard?.roiBond ?? 0).toString(),
      },
    ];

    let data: Data[] = [];
    if (tabState === "AumCapital") {
      data = [
        {
          title: "Mutual fund",
          totalAmount: (
            dashboard?.totalValueInvestedMFAumCapital ?? 0
          ).toString(),
          profitAmount: (dashboard?.currentValueMFAumCapital ?? 0).toString(),
          unrealised: (
            dashboard?.unrealisedGainLossMFAumCapital ?? 0
          ).toString(),
          roi: (dashboard?.roiMFAumCapital ?? 0).toString(),
        },
      ];
    } else {
      data = [
        {
          title: "Mutual fund",
          totalAmount: (dashboard?.totalValueInvestedMF ?? 0).toString(),
          profitAmount: (dashboard?.currentValueMF ?? 0).toString(),
          unrealised: (dashboard?.unrealisedGainLossMF ?? 0).toString(),
          roi: (dashboard?.rioMF ?? 0).toString(),
        },
      ];
    }

    setFinalData([...data, ...verticalBar]);
  }, [tabState, dashboard]);

  return (
    <>
      {loading ? (
        <LoaderIcon />
      ) : (
        <div className="pb-20">
          <div className=" bg-lightBg">
            <div className="p-8 md:p-[60px] max-w-[1440px] w-full mx-auto">
              <div className="flex flex-col md:flex-row gap-x-16 items-center">
                <div className="bg-white p-4 rounded-xl items-center border border-gray-500">
                  {/* border-b-2 border-bgGrey  pb-4*/}
                  <div className="flex flex-row gap-4   justify-center items-center">
                    <div className="bg-primary rounded-full w-1/2 h-12 flex items-center justify-center text-white mr-2 text-xl">
                      {getInitials(user?.firstName)}
                    </div>
                    <div className="flex flex-col">
                      <text className="font-bold text-sm">
                        Welcome, {user?.firstName}
                      </text>
                      {/* <text className="text-sm">Head of the family</text> */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-y-3 flex-1 w-full ">
                  <div className="border-b-2 border-lightblueBorder pb-3 sm:text-start text-center">
                    <h5 className="text-3xl font-medium text-primary">
                      {`${
                        DataTransfer.portfolioSummary || "Portfolio Summary"
                      }`}
                    </h5>
                    <text className="font-semibold">
                      As on {formattedDateWithMonthInCaps}
                    </text>
                  </div>

                  <div className="flex flex-wrap w-[60%] items-center gap-4 my-3 justify-between ">
                    <div className="flex flex-col">
                      <text className="text-gray-700_01 text-xl">
                        Total Value at Cost
                      </text>
                      <span className="text-gray-700_01 font-bold">
                        
                          {Number(dashboard?.totalValueAtCost).toFixed(2)}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <text className="text-gray-700_01 text-xl">
                        Portfolio Value
                      </text>
                      <text className="text-gray-700_01 font-bold">
                      {Number(dashboard?.totalPortfolioValue).toFixed(2)}
                      </text>
                    </div>

                    <div className="flex flex-col">
                      <text className="text-gray-700_01 text-xl">
                        Unrealised Gain P/L
                      </text>
                      <text className="text-gray-700_01 font-bold">
                      {Number(dashboard?.totalUnrealisedGainLoss).toFixed(2)}
                      </text>
                    </div>
                    <div className="flex flex-col">
                      <text className="text-gray-700_01 text-xl">ROI</text>
                      <text className="text-gray-700_01 font-bold">
                      {Number(dashboard?.totalUnrealisedGainLossPercentage).toFixed(2)}% 
                      </text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tab button */}
          <div className="px-8 md:px-[60px] max-w-[1440px] w-full mx-auto pt-8">
            <div className="flex justify-end">
              <div className="w-3/5 text-[10px] sm:text-[16px] md:w-1/5 flex items-center justify-center border-[1px] border-lightGrey gap-x-1 rounded-lg">
                <span
                  onClick={() => setTabState("AumCapital")}
                  className={`flex p-2 rounded-lg w-full items-center justify-center  ${
                    tabState === "AumCapital"
                      ? "bg-primary text-white"
                      : "text-primary"
                  }`}
                >
                  Aum Capital
                </span>
                <span
                  onClick={() => setTabState("Consolidated")}
                  className={`flex p-2 rounded-lg w-full items-center justify-center ${
                    tabState === "Consolidated"
                      ? "bg-primary text-white"
                      : "text-primary"
                  }`}
                >
                  Consolidated
                </span>
              </div>
            </div>
            {/* Investment Summary & Investment Breakup */}
            <div className="flex flex-col sm:flex-row md:flex-row h-full">
              <div className="sm:w-2/5 md:w-1/2 flex flex-col border border-gray-300 rounded-lg my-4 p-4 overflow-auto">
                <h1 className="text-primary font-bold text-2xl text-left mb-6">
                  Investment Summary
                </h1>
                <PieChart_Component
                  data={dashboard}
                  additionalData={finalData}
                />
              </div>
              <div className="flex flex-col border border-gray-300 rounded-lg my-4 w-full sm:ml-4 p-4 overflow-auto">
                <div className="flex items-center w-full justify-between">
                  <h1 className="text-primary font-bold text-2xl">
                    Investment Breakup
                  </h1>
                </div>
                <div>
                  <Details data={finalData} additionalData={dashboard} />
                </div>
              </div>
            </div>
            <div className="sm:mt-20 mt-4">
              {dashboard?.recentTransactions && 
              (dashboard?.recentTransactions[0]?.length > 0 || dashboard?.recentTransactions[1]?.length > 0) && (
                <div className="w-2/5 mb-8">
                  <h1 className="text-primary font-bold text-2xl">
                    Transactions
                  </h1>
                </div>
              )}
              <Transactions data={dashboard?.recentTransactions || [[], []]} />
            </div>
          </div>
        </div>
      )}
      <VerifyOTPModal
        title="Verify OTP Sent to your mobile and email"
        showModal={showSummary}
        setShowModal={setShowSummary}
        otp={otp}
        setOtp={setOtp}
        onClick={handleSubmit}
      />

      <Modal
        open={loadingModal}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div className="w-[30%] h-[30%] flex flex-col items-center justify-center bg-lightBg rounded-lg">
            <Typography
              id="loading-modal-title"
              variant="subtitle1"
              component="h2"
              marginBottom={5}
            >
              {` MF Report generation can take upto 5 minutes, please wait`}
            </Typography>
            <CircularProgress />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default MainDashboard;
