import React from "react";
import InfoSection from "../../components/InfoSection/InfoSection";
import person24 from "../../assets/icons/octicon_person-24 grey (1).png";
import entity from "../../assets/icons/entity.svg";
import bankDetails from "../../assets/icons/ph_bank_normal.png";
import demat from "../../assets/icons/jam_document_normal.png";
import preference from "../../assets/icons/grommet-icons_checkbox-selectedblue.png";
import sign from "../../assets/icons/la_file-signature blue.png";
import { colors } from "../../constants/colors";
import CreditCard from "../../assets/icons/Credit_Card_01.svg";

const NonIndividualFormLayout = ({ completedSections, activeSection, children }:any) => {
  // Define sections with a unified Preferences icon for both "Preferences" and "Preferences2"
  const infoSections = [
    { icon: CreditCard, title: "PAN", key: "pan", index: 0 },
    { icon: person24, title: "General Details", key: "general", index: 1},
    { icon: entity, title: "Company Details", key: "company", index: 2 },
    { icon: bankDetails, title: "Bank Details", key: "bank", index: 3 },
    { icon: demat, title: "Demat Details", key: "demat", index: 4 },
    { icon: preference, title: "Preferences", key: "preference", index: 5 },
    { icon: sign, title: "Signatures", key: "sign", index: 6 },
  ];

  // Determine the correct index for the current active section
  const actualActiveSection = activeSection === "preference2" ? "preference" : activeSection;
  const activeIndex = infoSections.findIndex((section) => section.key === actualActiveSection);

  return (
    <main className="flex flex-col items-center pl-2 pr-2 sm:pl-5 mb-5" style={{ backgroundColor: colors.lightBg }}>
      <div className="font-bold max-md:max-w-full text-center sm:text-left md:text-left sm:ml-28 md:ml-10 sm:text-3xl text-base mt-16 mb-10" style={{ color: colors.primary }}>
        Non-Individual or Business Form
      </div>
      <div className="flex flex-wrap text-center justify-evenly gap-10 sm:justify-center sm:flex-row sm:row-span-3 mb-10 mx-5 mt-10">
        {infoSections.map((section) => (
          <InfoSection
            icon={section.icon}
            title={section.title}
            isActive={
              section.key === actualActiveSection || 
              (section.key === "company" && ["corporate", "llp", "trust"].includes(activeSection)) ||
              (section.key === "preference" && ["preference2"].includes(activeSection)) // Added condition for Preference2
            }
            isCompleted={completedSections[section.index]}
            isPrevious={section.index < activeIndex} // Ensures that previous sections are greyed out
          />
        ))}
      </div>
      {children}
    </main>
  );
};

export default NonIndividualFormLayout;
