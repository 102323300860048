import React from 'react';
import { Table, TableBody } from '@mui/material';
import TableRowComponent from './TableRowComponent';



const MainTableComponent = ({ data, getInitials, findHighestInterestRates, navigateDetails, handleMenuClick, modalMenuClick }: any) => {
    // console.log('map Data', data);

    return (
        <>
            {data.map((row: any) => (
                <TableRowComponent
                    // key={row.scheme.id}
                    data={row}
                    getInitials={getInitials}
                    findHighestInterestRates={findHighestInterestRates}
                    navigateDetails={navigateDetails}
                    handleMenuClick={handleMenuClick}
                    modalMenuClick={modalMenuClick}
                />
            ))
            }
        </>
    );
};

export default MainTableComponent;
