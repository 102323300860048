import api from "../../config/apiConfig";
import { BankData } from "../../models/bank.model";

const addBankDetails = async (data: FormData) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/bank/add", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
};

const verifyBankDetails = async (data: BankData) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/bank/verify", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
}


const bankService = { addBankDetails, verifyBankDetails };
export default bankService;