import { useState } from "react";
import { checkFileSizeAndReset } from "../../utils";

type FileInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  Partial<{
    label: string;
    onChange?: any;
    value?: any;
    name?: string;
    type?: string;
    disabled?: boolean;
    error?: string;
    touched?: boolean;
    id?: string;
    className?: string;
    suffix?: any;
  }>;

const FileInput: React.FC<FileInputProps> = ({
  label,
  onChange,
  disabled,
  error,
  touched,
  placeholder
}) => {
  const [fileName, setFileName] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const isValid = checkFileSizeAndReset(file, event); // Validate the file size
      if (isValid) {
        setFileName(file.name); // Update the displayed file name
      } else {
        setFileName(null); // Reset the displayed file name if invalid
      }
    }
  };

  return (
    <div className={`w-full mx-auto mb-4 sm:mb-0`}>
      {" "}
      {/*//CH11/5 removed mb-10 */}
      <div className="mb-3 text-base sm:text-base md:text-lg">{label}</div>
      <label className="shrink-0 bg-white rounded-lg border border-solid border-stone-300  bg-white-A700 p-3 flex items-center justify-between ">
        <input
          type="file"
          className="hidden sm:w-full bg-white-A700"
          onChange={(e) => {
            handleFileChange(e);
            onChange(e);
          }}
          disabled={disabled}
          placeholder={placeholder}
        />
        <span>{fileName ? fileName : ""}</span>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b68ad659401f77e25e3e5619f719db2c16e9ada8836fddb64d373e828ce07709?apiKey=427d052184b84d30b7eba5c7e76648a1&" // Update with your image path
          alt="Upload Icon"
          className="h-10 w-10 ml-2 cursor-pointer"
        />
      </label>
      {!!error && touched ? (
        <p className="text-secondary h-1.5">{error}</p>
      ) : (
        <p className="text-secondary h-1.5"></p>
      )}
    </div>
  );
};

export default FileInput;
