import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { Button, Heading, Input, Text } from "../../components";
import { colors } from "../../constants/colors";
import * as yup from "yup";
import { Formik } from "formik";
import toast from "react-hot-toast";
import Footer from "../../components/Footer/Footer";
import { useDispatch } from "react-redux";
import { sendResetPasswordLink } from "../../redux/user/userSlice";
import { CheckCircleOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required."),
});

const forgotPasswordValues = {
  email: "",
};

const ForgotPassword = () => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const [isEmailSent, setIsEmailSent] = useState(false);
  return (
    <div className="flex flex-col items-center justify-start w-full bg-white">
      <div className="flex flex-col items-center justify-start w-full px-5 py-8 md:px-14 md:py-12 bg-primary shadow-xs min-h-screen">
        <div className="flex flex-col items-center justify-center w-full gap-[31px] max-w-[962px]">
          {!isEmailSent ? <>
            <Heading as="h1" className=" text-white text-lg md:text-3xl">
            Forgot Password
          </Heading>
          <Formik
            initialValues={forgotPasswordValues}
            validationSchema={forgotPasswordSchema}
            onSubmit={async (values, { setSubmitting }) => {
              console.log(values);
                const response = await dispatch(
                  sendResetPasswordLink(values)
                );
                if(response?.error?.message) {
                    toast.error(response?.payload?.message);
                    return;
                }
                setIsEmailSent(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              setFieldValue,
              /* and other goodies */
            }) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!isValid && errors.email) {
                    toast.error(errors.email);
                    return;
                  }
                  handleSubmit();
                }}
                className="w-full flex flex-col gap-[31px]"
              >
                <>
                  <div className="flex flex-col items-start justify-start w-[48%] mx-auto gap-2">
                    <Text
                      size="s"
                      as="p"
                      className="text-center text-white text-sm md:text-lg"
                    >
                      User email
                    </Text>
                    <Input
                      size="sm"
                      name="email"
                      type="email"
                      placeholder="Enter your registered email"
                      onChange={handleChange("email")}
                      value={values.email}
                      className="w-full bg-white"
                    />
                  </div>
                  <Button
                    className="w-full md:w-48 font-semibold text-white bg-darkGrey hover:bg-secondary transition-all mx-auto mt-5 py-2"
                    color={colors.darkGrey}
                    variant="fill"
                    type="submit"
                  >
                    <span className="text-sm md:text-lg">Proceed</span>
                  </Button>
                </>
              </form>
            )}
          </Formik>
          </> :   <>
              <div className="flex flex-col gap-y-2 mt-5">
                <CheckCircleOutline
                  style={{ fontSize: "8rem", color: "lightgreen" }}
                />
              </div>
              <div className="flex flex-col gap-y-8 items-center justify-center">
                <p className="text-white font-inter text-2xl text-center">
                  Reset Password link has been sent to your email. <br /> Please check your email.
                </p>
              </div>
            </>}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
