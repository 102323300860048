import api from "../../config/apiConfig";
import {Company} from "../../models/nonGeneral.model"

const addOrganization = async (data: Company) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/org/add", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
};

const generalService = { addOrganization };
export default generalService;