import React from "react";
import PhoneInput from "react-phone-number-input";

const shapes = {
  round: "rounded-lg",
} as const;
const variants = {
  fill: {
    white_A700: "bg-white-A700 text-gray-700_01",
  },
} as const;
const sizes = {
  xs: "h-[60px] px-5 text-lg",
  sm: "h-[60px] px-5",
} as const;

type InputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "size" | "prefix" | "type" | "onChange"
> &
  Partial<{
    className: string;
    name: string;
    placeholder: string;
    type: string;
    label: string;
    outline: string;
    prefix: React.ReactNode;
    suffix: React.ReactNode;
    onChange: any;
    value: any;
    shape: keyof typeof shapes;
    variant: keyof typeof variants;
    size: keyof typeof sizes;
    color: string;
    error: string;
    touched: boolean;
    autoComplete: string; 
  }>;
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      outline = "none",
      children,
      label = "",
      prefix,
      suffix,
      onChange,
      value,
      shape = "round",
      variant = "fill",
      size = "xs",
      color = "white",
      error,
      touched,
      autoComplete = "off",
      ...restProps
    },
    ref
  ) => {
    // const handleChange: React.ChangeEventHandler<HTMLInputElement> | any = (
    //   e: any
    // ) => {
    //   if (onChange) onChange(e?.target?.value);
    // };

    return (
      <>
        <div
          className={`${className} flex items-center justify-between border-blue_gray-100 border border-solid bg-white-A700 rounded-lg ${
            shapes[shape] || ""
          } ${
            variants[variant]?.[
              color as keyof (typeof variants)[typeof variant]
            ] ||
            variants[variant] ||
            ""
          } ${sizes[size] || ""}`}
        >
          {!!label && label}
          {!!prefix && prefix}
          {type === "phone" ? (
            <PhoneInput
              type={type}
              name={name}
              defaultCountry="IN"
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              className={`outline-none w-full focus:outline-none input-phone-number`}
            />
          ) : (
            <input
              ref={ref}
              type={type}
              name={name}
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              {...restProps}
              className={`outline-${outline} w-full focus:outline-none`}
              // onFocus={()=>{}}
              autoComplete='chrome-off'
              autoCorrect="false"
              
            />
          )}
          {!!suffix && suffix}
        </div>
        {!!error && touched ? <p className="text-secondary h-1.5">{error}</p> : <p className="text-secondary h-1.5"></p>}
      </>
    );
  }
);

export { Input };
