import React, { FC, useEffect, useState } from 'react';
import { colors as color } from '../../constants/colors';

type InfoSectionProps = {

  title: string;
  isActive: boolean;
  onClick: () => void; // Prop for indicating active state
  style?: React.CSSProperties; 
};

const InfoSection: FC<InfoSectionProps> = ({ title, isActive, onClick,style }) => {
  const [bgColor, setBgColor] = useState<string>(color.white);
  const [textColor, setTextColor] = useState<string>(color.primary);

  useEffect(() => {
    // Set background color and text color based on isActive, isCompleted, and isPrevious states
    if (isActive) {
      setBgColor(color.primary); // Active state background color is primary
      setTextColor(color.white); // Active state text color is white
    } else {
      setBgColor('transparent');
      setTextColor(color.textGrey); // Default inactive state text color is primary
    }
  }, [isActive]);

  return (
    <div className="flex flex-col items-center justify-center cursor-pointer" onClick={onClick} style={style} >
      <div
        className={`text-center text-xs lg:text-base px-2 py-2 sm:px-4 sm:py-1 mt-2 w-20 lg:w-24 ml-3`}
        style={{ backgroundColor: bgColor, color: textColor }}
      >
        {title}
      </div>
    </div>
  );
};

export default InfoSection;
