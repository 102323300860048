import React, { useState } from 'react'
import DynamicBreadcrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Container, FormControlLabel, IconButton, InputAdornment, Menu, TextField, Typography } from '@mui/material';
import { Add, Close, FilterAlt, Search } from '@mui/icons-material';
import { colors } from '../../constants/colors';
import dropdown from '../../assets/icons/dropdown.svg';
import TableComponent from '../Tables/TableRow';
import { LineChart } from '@mui/x-charts';
import CloseIcon from '@mui/icons-material/Close';




function ListingComponent(
    { title, breadcrumb, filterOps, totalEnteries, dataEnteries, controlBarObj, summary, setDataEnteries, SmallChart, QuickFilterOption, quickFilterOptionFn, clearFilterOptionFn, inputData, setInputData, subcategory, subCategoryTable, initials }:

        { title: string, breadcrumb: any, filterOps: any, totalEnteries: any, dataEnteries: any, controlBarObj: any[], summary: string, setDataEnteries: any, SmallChart?: boolean, QuickFilterOption?: any[], quickFilterOptionFn?: any, clearFilterOptionFn?: any, inputData?: any, setInputData?: any, subcategory?: any[], subCategoryTable?: boolean, initials?: boolean }) {

    const [totalFound, setTotalFound] = useState(totalEnteries);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const [filterOptions, setFilterOptions] = useState(filterOps);



    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange1 = (
        event: React.ChangeEvent<HTMLInputElement>,
        filterIndex: number
    ) => {
        const { checked } = event.target;
        const updatedOptions = [...filterOptions];
        updatedOptions[filterIndex].options.map(
            (option: any) => (option.isChecked = checked)
        );
        // console.log(updatedOptions, event.target.checked, "options");
        setFilterOptions(updatedOptions);
    };

    const handleChange2 = (
        event: React.ChangeEvent<HTMLInputElement>,
        filterIndex: number,
        optionIndex: number
    ) => {
        const { checked } = event.target;
        const updatedOptions = [...filterOptions];
        updatedOptions[filterIndex].options[optionIndex].isChecked = checked;
        setFilterOptions(updatedOptions);
    };
    const handleFilter = () => {
        handleClose();
        // fetchData();
    }
    console.log('inputData', inputData);



    return (
        <div>
            <div className="bg-lightBg">
                <div className="md:py-[30px] md:px-[30px] max-w-[1440px] justify-center items-center mx-auto">
                    <div className="pb-4 border-b border-lightGrey mb-8">
                        <DynamicBreadcrumbs items={breadcrumb} />
                    </div>
                    <div className="w-full flex md:flex-row gap-x-6 ">
                        <div className="hidden sm:flex md:w-[400px] md:h-[150px] md:bg-white items-center justify-center align-middle relative rounded-full">
                            {/* Center image */}
                            <div className="flex items-center justify-center w-full h-full">
                                <img
                                    className="w-[80px]"
                                    src={dropdown}
                                    alt="center-image"
                                />
                            </div>
                        </div>

                        <div className="flex flex-col gap-y-3 ">
                            <div className="flex gap-x-8 items-center border-b-2">
                                <div className="flex flex-row w-full justify-between pb-4">
                                    <div>
                                        <h3 className="text-3xl font-medium text-primary">{title}</h3>
                                    </div>
                                    <div className="justify-center px-3 py-2 text-md border border-solid rounded-[35px] max-md:max-w-full bg-gray-700_01 text-white">
                                        Total found: {totalFound || '0'}
                                    </div>
                                </div>
                            </div>
                            <p className="mt-1 text-lg text-stone-500 max-md:max-w-full">
                                {summary}
                            </p>
                        </div>
                    </div>
                    {SmallChart &&
                        <div className='flex justify-between mt-8'>
                            <div className='w-[46%] flex flex-row border border-[1px] rounded-xl border-lightGrey items-center justify-center bg-white p-2'>
                                <Box>
                                    <Typography variant='h5' className='text-primary font-extrabold '>NIFTY 50</Typography>
                                    <Typography variant='h6' className='text-darkGrey '>21,862.82</Typography>
                                    <div className='flex gap-x-4'>
                                        <span className='text-red-600'>-36.21%</span>
                                        <span className='text-red-600'>-36.21%</span>
                                    </div>
                                </Box>
                                <Box>
                                    <LineChart
                                        xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                                        series={[
                                            {
                                                data: [2, 5.5, 2, 8.5, 1.5, 5],
                                            },
                                        ]}
                                        width={300}
                                        margin={{
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            top: 0
                                        }}
                                        height={150}
                                        axisHighlight={{
                                            y: 'none',
                                            x: 'none'
                                        }}
                                        slotProps={{
                                            mark: {
                                                display: "none"
                                            },
                                            axisLine: {
                                                display: "none"
                                            },
                                            axisTickLabel: {
                                                display: 'none'
                                            },
                                            axisTick: {
                                                display: 'none'
                                            },


                                        }}
                                        tooltip={
                                            {
                                                trigger: 'none'
                                            }
                                        }

                                    />
                                </Box>

                            </div>
                            <div className='w-[46%] flex flex-row border border-[1px] rounded-xl border-lightGrey items-center justify-center bg-white p-2'>
                                <Box>
                                    <Typography variant='h5' className='text-primary font-bold'>Sensex</Typography>
                                    <Typography variant='h6' className='text-darkGrey '>21,862.82</Typography>
                                    <div className='flex gap-x-4'>
                                        <span className='text-red-600'>-36.21%</span>
                                        <span className='text-red-600'>-36.21%</span>
                                    </div>
                                </Box>
                                <Box>
                                    <LineChart
                                        xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                                        series={[
                                            {
                                                data: [2, 5.5, 2, 8.5, 1.5, 5],
                                            },
                                        ]}
                                        width={300}
                                        margin={{
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            top: 0
                                        }}
                                        height={150}
                                        axisHighlight={{
                                            y: 'none',
                                            x: 'none'
                                        }}
                                        slotProps={{
                                            mark: {
                                                display: "none"
                                            },
                                            axisLine: {
                                                display: "none"
                                            },
                                            axisTickLabel: {
                                                display: 'none'
                                            },
                                            axisTick: {
                                                display: 'none'
                                            },
                                        }}
                                        tooltip={
                                            {
                                                trigger: 'none'
                                            }
                                        }
                                    />
                                </Box>

                            </div>
                        </div>}
                </div>
            </div>
            <div className="md:py-[30px] md:px-[30px] max-w-[1440px] justify-center items-center mx-auto">

                <div className="flex justify-between gap-x-3">
                    <TextField
                        className="w-full"
                        sx={{ padding: "1re" }}
                        onChange={(e) => {
                            setInputData({ ...inputData, query: e.target.value });
                        }}
                        placeholder="Search mutual funds"
                        hiddenLabel
                        id="filled-hidden-label-small"
                        size="small"
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <React.Fragment>

                        <div className="flex justify-between">
                            <IconButton
                                sx={{ backgroundColor: colors.secondary, borderRadius: 2, }}
                                onClick={handleMenuClick}
                                style={{ color: '#fff' }}
                            >
                                <FilterAlt />
                            </IconButton>

                        </div>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={openMenu}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <div>
                                <div className="flex items-center justify-between bg-gray-700_01 px-5 py-3 mt-[-10px]">
                                    <Typography variant="h6" color="white">
                                        Filter
                                    </Typography>
                                    <IconButton onClick={handleClose} sx={{ color: colors.white }}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                </div>

                                <Box sx={{ padding: "1rem 2rem" }}>
                                    <div className="overflow-y-auto">
                                        {filterOptions.map((filter: any, index: number) => (
                                            <Accordion key={index} expanded={true}>
                                                <AccordionSummary
                                                    expandIcon={<Add />}
                                                    aria-controls="panel1-content"
                                                    id="panel1-header"
                                                    sx={{ backgroundColor: colors.lightBg }}
                                                >
                                                    <FormControlLabel
                                                        label={filter.name}
                                                        control={
                                                            <Checkbox
                                                                checked={filter.options.every(
                                                                    (option: any) => option.isChecked
                                                                )}
                                                                onChange={(event) => handleChange1(event, index)}
                                                            />
                                                        }
                                                    />
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="custom-scrollbar flex flex-col gap-4 overflow-y-auto" style={{ maxHeight: '200px' }}>
                                                        {filter.options.map((option: any, j: number) => (
                                                            <FormControlLabel
                                                                key={j}
                                                                label={option.name}
                                                                control={
                                                                    <Checkbox
                                                                        checked={option.isChecked}
                                                                        onChange={(event) => handleChange2(event, index, j)}
                                                                    />
                                                                }

                                                            />
                                                        ))}
                                                    </div>
                                                </AccordionDetails>

                                            </Accordion>

                                        ))}
                                    </div>
                                    <Button
                                        sx={{
                                            backgroundColor: colors.primary,
                                            color: colors.white,
                                            borderRadius: 2,
                                            textTransform: "none",
                                            padding: "0.5rem 2rem",
                                            marginTop: "1rem",
                                        }}
                                        onClick={handleFilter}
                                    >
                                        Apply
                                    </Button>
                                </Box>
                            </div>
                        </Menu>
                    </React.Fragment>
                </div>
                {(QuickFilterOption && quickFilterOptionFn && clearFilterOptionFn) &&
                    <div className="flex my-4 gap-x-4 items-center">
                        {QuickFilterOption.map((item, index) => (
                            <div
                                key={index}
                                className={`rounded-3xl p-1 border border-gray-700_01 ${inputData.type === item.value ? 'bg-mediumBg text-dark' : 'bg-white text-textGrey'}`}
                            >
                                <Button
                                    value={item.value}
                                    onClick={() => quickFilterOptionFn(item.value)}
                                    sx={{
                                        color: inputData.type === item.value ? colors.black : colors.textGrey,
                                        '&:hover': {
                                            backgroundColor: 'transparent'
                                        }
                                    }}
                                >
                                    {item.label}
                                </Button>
                                {inputData.type === item.value && (
                                    <span
                                        className=" cursor-pointer text-darkGrey p-4 w-[100px]"
                                        onClick={clearFilterOptionFn}
                                    // sx={{
                                    //   color: colors.darkGrey,
                                    //   '&:hover': {
                                    //     backgroundColor: 'transparent'
                                    //   },
                                    //   // width:'5px',
                                    //   backgroundColor:'#ff0000'
                                    // }}
                                    >
                                        <CloseIcon />
                                    </span>
                                )}
                            </div>
                        ))}
                    </div>}
                <div className='mt-8'>
                    {(dataEnteries && controlBarObj && setDataEnteries) && (
                        dataEnteries.length > 0 ? (
                            <div>
                                <TableComponent bodyData={dataEnteries} headData={controlBarObj} unSortdata={setDataEnteries} subcategory={subcategory} subCategoryTable={subCategoryTable} initials={initials} />
                            </div>
                        ) : (
                            <div className='mt-8'>
                                <Typography variant='subtitle2'>No data Found</Typography>
                            </div>
                        )
                    )}
                </div>
            </div>


        </div>
    )
}

export default ListingComponent;