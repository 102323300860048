import React, { useState ,useEffect} from 'react'
import DynamicBreadcrumbs from '../BreadCrumbs/BreadCrumbs'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Container, FormControlLabel, IconButton, InputAdornment, Menu, TextField, Typography } from '@mui/material';
import { Add, Close, FilterAlt, Search } from '@mui/icons-material';
import { colors } from '../../constants/colors';
import dropdown from '../../assets/icons/dropdown.svg';
import TableComponent from '../Tables/TableRow';
import { LineChart } from '@mui/x-charts';
import CloseIcon from '@mui/icons-material/Close';
import AIFTable from '../AIFListingTable/AIFTable';
interface Scheme {
    id: number;
    assetName: string;
    category: string;
    inception: string;
    fundSize: number;
    currentNAV: number;
    returnRate: number;
    promoter: string;
    raisedBar: number;
    isInWishlist: number;
    createdAt: string;
}

interface AIFEntry {
    promoter: string;
    schemes: Scheme[];
}

interface AifListingComponentProps {
    title: string;
    breadcrumb: any;
    totalEnteries: number;
    dataEnteries: AIFEntry[];
    controlBarObj: any[];
    summary: string;
    setDataEnteries: React.Dispatch<React.SetStateAction<AIFEntry[] | undefined>>;
    SmallChart?: boolean;
    loaderData?: boolean;
}

function AifListingComponent(
    { title, breadcrumb, totalEnteries, dataEnteries, controlBarObj, summary, setDataEnteries, SmallChart,loaderData }:

        { title: string, breadcrumb: any, filterOps: any, totalEnteries: any, dataEnteries: any, controlBarObj: any[], summary: string, setDataEnteries: any, SmallChart?: boolean, QuickFilterOption?: any[], quickFilterOptionFn?: any, clearFilterOptionFn?: any, inputData?: any, setInputData?: any, subcategory?: any[], subCategoryTable?: boolean, initials?: boolean,loaderData?:boolean }) {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);


    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredData, setFilteredData] = useState<any[]>([]);

    useEffect(() => {
        // Filter the dataEnteries array based on the searchTerm
        const filtered = dataEnteries?.filter((entry: AIFEntry) => {
            return entry.schemes.some((scheme: Scheme) =>
                scheme.assetName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                scheme.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
                scheme.promoter.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
        setFilteredData(filtered || []);
    }, [searchTerm, dataEnteries]);

    return (
        <div>
            <div className="bg-lightBg">
                <div className="md:py-[30px] md:px-[30px] max-w-[1440px] justify-center items-center mx-auto">
                    <div className="pb-4 border-b border-lightGrey mb-8">
                        <DynamicBreadcrumbs items={breadcrumb} />
                    </div>
                    <div className="w-full flex md:flex-row gap-x-6 ">
                        <div className="hidden sm:flex md:w-[400px] md:h-[150px] md:bg-white items-center justify-center align-middle relative rounded-full">
                            {/* Center image */}
                            <div className="flex items-center justify-center w-full h-full">
                                <img
                                    className="w-[80px]"
                                    src={dropdown}
                                    alt="center-image"
                                />
                            </div>
                        </div>

                        <div className="flex flex-col gap-y-3 ">
                            <div className="flex gap-x-8 items-center border-b-2">
                                <div className="flex flex-row w-full justify-between pb-4">
                                    <div>
                                        <h3 className="text-3xl font-medium text-primary">{title}</h3>
                                    </div>
                                    <div className="justify-center px-3 py-2 text-md border border-solid rounded-[35px] max-md:max-w-full bg-gray-700_01 text-white">
                                        Total found: {totalEnteries || '0'}
                                    </div>
                                </div>
                            </div>
                            <p className="mt-1 text-lg text-stone-500 max-md:max-w-full">
                                {summary}
                            </p>
                        </div>
                    </div>
                    {SmallChart &&
                        <div className='flex justify-between mt-8'>
                            <div className='w-[46%] flex flex-row border-[1px] rounded-xl border-lightGrey items-center justify-center bg-white p-2'>
                                <Box>
                                    <Typography variant='h5' className='text-primary font-extrabold '>NIFTY 50</Typography>
                                    <Typography variant='h6' className='text-darkGrey '>21,862.82</Typography>
                                    <div className='flex gap-x-4'>
                                        <span className='text-red-600'>-36.21%</span>
                                        <span className='text-red-600'>-36.21%</span>
                                    </div>
                                </Box>
                                <Box>
                                    <LineChart
                                        xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                                        series={[
                                            {
                                                data: [2, 5.5, 2, 8.5, 1.5, 5],
                                            },
                                        ]}
                                        width={300}
                                        margin={{
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            top: 0
                                        }}
                                        height={150}
                                        axisHighlight={{
                                            y: 'none',
                                            x: 'none'
                                        }}
                                        slotProps={{
                                            mark: {
                                                display: "none"
                                            },
                                            axisLine: {
                                                display: "none"
                                            },
                                            axisTickLabel: {
                                                display: 'none'
                                            },
                                            axisTick: {
                                                display: 'none'
                                            },


                                        }}
                                        tooltip={
                                            {
                                                trigger: 'none'
                                            }
                                        }

                                    />
                                </Box>

                            </div>
                            <div className='w-[46%] flex flex-row border-[1px] rounded-xl border-lightGrey items-center justify-center bg-white p-2'>
                                <Box>
                                    <Typography variant='h5' className='text-primary font-bold'>Sensex</Typography>
                                    <Typography variant='h6' className='text-darkGrey '>21,862.82</Typography>
                                    <div className='flex gap-x-4'>
                                        <span className='text-red-600'>-36.21%</span>
                                        <span className='text-red-600'>-36.21%</span>
                                    </div>
                                </Box>
                                <Box>
                                    <LineChart
                                        xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                                        series={[
                                            {
                                                data: [2, 5.5, 2, 8.5, 1.5, 5],
                                            },
                                        ]}
                                        width={300}
                                        margin={{
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            top: 0
                                        }}
                                        height={150}
                                        axisHighlight={{
                                            y: 'none',
                                            x: 'none'
                                        }}
                                        slotProps={{
                                            mark: {
                                                display: "none"
                                            },
                                            axisLine: {
                                                display: "none"
                                            },
                                            axisTickLabel: {
                                                display: 'none'
                                            },
                                            axisTick: {
                                                display: 'none'
                                            },
                                        }}
                                        tooltip={
                                            {
                                                trigger: 'none'
                                            }
                                        }
                                    />
                                </Box>

                            </div>
                        </div>}
                </div>
            </div>
            <div className="md:py-[30px] md:px-[30px] max-w-[1440px] justify-center items-center mx-auto">

                <div className="flex justify-between gap-x-3">
                    <TextField
                        className="w-full"
                        sx={{ padding: "1re" }}
                        placeholder="Search AIF"
                        hiddenLabel
                        id="filled-hidden-label-small"
                        size="small"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className='mt-8'>
                {(filteredData && controlBarObj && setDataEnteries) && (
                        filteredData.length > 0 ? (
                            <div>
                                <AIFTable data={filteredData} loader={loaderData} />
                            </div>
                        ) : (
                            <div className='mt-8'>
                                <Typography variant='subtitle2'>No data Found</Typography>
                            </div>
                        )
                    )}
                </div>
            </div>


        </div>
    )
}

export default AifListingComponent;