import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../auth/authService";
import { CreatePassword, OTP, SignUp } from "../../models/signup.model";
import { LoginModel } from "../../models/login.model";
import { SocialLogin } from "../../models/social-login.model";

export const signUp = createAsyncThunk(
  "auth/signup",
  async (data: SignUp, thunkAPI: any) => {
    try {
      return await authService.signUp(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (data: LoginModel, thunkAPI: any) => {
    try {
      return await authService.login(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const verifyOTP = createAsyncThunk(
  "auth/verify",
  async (data: OTP, thunkAPI: any) => {
    try {
      return await authService.verifyOTP(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addPassword = createAsyncThunk(
  "auth/addPassword",
  async (data: CreatePassword, thunkAPI: any) => {
    try {
      return await authService.addPassword(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const socialSignUp = createAsyncThunk(
  "auth/socialSignUp",
  async (data: SocialLogin, thunkAPI: any) => {
    try {
      return await authService.socialSignUp(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  otp: "",
  accessToken: "",
  refreshToken: "",
  signUpStatus: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  addedPassword: "",
  data: {},
  socialLoggedIn: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.signUpStatus = action.payload.statusCode;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.accessToken = action.payload.data.accessToken;
        state.refreshToken = action.payload.data.refreshToken;
      })
      .addCase(login.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(verifyOTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.accessToken = action.payload.data.accessToken;
        state.refreshToken = action.payload.data.refreshToken;
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(addPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.addedPassword = action.payload.message;
      })
      .addCase(addPassword.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(socialSignUp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(socialSignUp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.accessToken = action.payload.data.accessToken;
        state.refreshToken = action.payload.data.refreshToken;
        state.socialLoggedIn = true;
      })
      .addCase(socialSignUp.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      });
  },
});

export const { resetState } = authSlice.actions;
export default authSlice.reducer;
