import React, { useState } from 'react';
import { TableRow, IconButton, Collapse, Table, TableBody, TableCell, Container } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import TableCellComponent from './TableCellComponent';
import { BorderBottom, InfoOutlined, MoreVert, ReportProblem } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { colors } from '../../constants/colors';

const TableRowComponent = ({ data, getInitials, findHighestInterestRates, navigateDetails, handleMenuClick, modalMenuClick }: any) => {
    const [open, setOpen] = useState(false);
    // console.log('data On', data);


    return (
        <>
            <TableRow key={data.id} sx={{

            }} className="border-[1px] border-lightGrey hover:bg-lightBg cursor-pointer" onClick={() => setOpen(!open)}>
                {/* <TableCell >
         
        </TableCell> */}
                <TableCell component="th" scope="row" sx={{ border: 'none' }} className=" min-w-[100px]">
                    <div className="flex gap-x-2 items-center">
                        <div className="w-12 h-12 mb-3 md:mb-0 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-xl font-bold">
                            {getInitials(data.bankName)}
                            {/* {schemes.plans[0].id} */}
                        </div>
                        <div className="w-3/5">
                            <span className="cursor-pointer">{data.bankName}</span>
                        </div>
                    </div>
                </TableCell>

                <TableCell sx={{ border: 'none' }} align="center" className=" min-w-[340px]">{data?.schemes[0]?.creditRating || '-'}</TableCell>


                <TableCell sx={{ border: 'none' }} align="left" className=" min-w-[100px]">
                    <div className="flex gap-x-1 items-center justify-center">
                        {findHighestInterestRates(data.schemes).toFixed(2) || '0'}%
                    </div>
                </TableCell>

                <TableCell sx={{ border: 'none' }} align="center" className=" min-w-[100px]">{data?.schemes[0]?.minInvestmentAmount || '0'}</TableCell>
                <TableCell sx={{ border: 'none' }} align="center" className=" min-w-[150px]">{data?.schemes[0]?.maxInvestmentamount || '0'}</TableCell>
                <TableCell sx={{ border: 'none' }} align="center" className="min-w-[200px] ">{data.riskLevel || "-"}</TableCell>
                <TableCell align="center" sx={{ display: "flex", flexDirection: "row-reverse", border: 'none', }} className="min-w-[100px] ">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    {/* <IconButton onClick={modalMenuClick}>
            <MoreVert />
          </IconButton> */}
                </TableCell>
            </TableRow>
            <TableRow sx={{ paddingBottom: '40px' }}>
                <TableCell className="tableCells" colSpan={12} sx={{
                    padding: '0',
                }}>
                    <Collapse in={open} timeout="auto" unmountOnExit className="">


                        <Table aria-label="purchases">
                            <TableBody className="border-l-[1px] border-r-[1px] border-b-[1px] " sx={{
                                '&.css-1la361y-MuiTableCell-root': {
                                    padding: '0!important',
                                },
                            }}>
                                <TableRow sx={{ height: '5%', }}>
                                    <TableCell sx={{ border: 'none' }} colSpan={8} align="center">

                                    </TableCell>
                                </TableRow>
                                {data.schemes.map((schemes: any) => (

                                    <Container className="hover:bg-lightBg hover:border hover:border-primary hover:rounded-md my-4 min-w-full py-[2%] cursor-pointer" onClick={() => navigateDetails(schemes.id)}>
                                        <TableCell sx={{ border: 'none', padding: '0' }} className="cursor-pointer w-[260px]">
                                            <div className="flex gap-x-2 items-center subcategory">
                                                <div className="w-12 h-12 mb-3 md:mb-0 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-xl font-bold">
                                                    {getInitials(schemes.schemeName)}
                                                    {/* {schemes.plans[0].id} */}
                                                </div>
                                                <div className="w-3/5">
                                                    <span>{schemes.schemeName}</span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }} align="center" className="w-[340px]">{schemes?.creditRating}</TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }} align="center" className="w-[160px]">
                                            <div className="flex gap-x-1 items-center justify-center">
                                                {Number(schemes?.maxInterestRate).toFixed(2)}%
                                                <IconButton onClick={(event) => handleMenuClick(event, schemes?.seniorCitizenRate === '-' ? null : schemes?.seniorCitizenRate, schemes?.womenInterestRates === '-' ? null : schemes?.womenInterestRates)}>
                                                    <InfoOutlined fontSize="small" sx={{ color: '#ccc' }} />
                                                </IconButton>
                                            </div>

                                        </TableCell>

                                        <TableCell sx={{ borderBottom: 'none' }} align="center" className="w-[100px]">{schemes?.minInvestmentAmount || '-'}</TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }} align="center" className="w-[220px]">{schemes?.maxInvestmentamount || '-'}</TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }} align="center" className="w-[100px]">{schemes.frequency || '-'}</TableCell>
                                        <TableCell align="right" className="w-[150px]"
                                            sx={{
                                                '&.MuiTableCell-root': {
                                                    padding: 0,
                                                    textAlign: 'right',
                                                    borderBottom: 'none'
                                                },
                                            }}>
                                            <IconButton onClick={(e) => { modalMenuClick(e, schemes.isinWishilist, schemes.id) }}>
                                                <MoreVert />
                                            </IconButton>
                                        </TableCell>
                                    </Container>

                                ))}
                                <TableRow sx={{ height: '5%', }}>
                                    <TableCell sx={{ border: 'none' }} colSpan={8} align="center">

                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow >
            <TableRow sx={{ height: '5%', }}>
                <TableCell sx={{ border: 'none' }} colSpan={8} align="center">

                </TableCell>
            </TableRow>
        </>
    );
};

export default TableRowComponent;
