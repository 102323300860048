export const colors = {
  primary: "#255288",
  secondary: "#F17A21",
  tertiary: "#08991F",
  darkGrey: "#515151",
  lightBg: "#F3F8FF",
  mediumBg: "#EDEDED",
  darkBg: "#CDCDCD",
  orange: "#EF5C33",
  textGrey: "#696969",
  black: "#000000",
  // lightGrey: "#D9D9D9",
  lightGrey: "#a6a2a6", //CH11/5
  bgGrey: "#efefef",
  white: "#FFFFFF",
  inputLabel: "#9d9d9d",
  cyan: "#155e75",
  grey1: "#919191",
  bgGrey1: "#f1f1f1",
  bgColor: "#F5F5F5",
};
