import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';

// Define the type for each SIP item
interface SIPItem {
  sipRegId: string;
  sipAmount: string;
  noOfSIP: string;
  createdAt: string;
  schemeName:string;
}

// Define the props for the CancelSIPModal component
interface CancelSIPModalProps {
  open: boolean;
  onClose: () => void;
  mySIP: SIPItem[];
  schemeName:string;
}

const CancelSIPModal: React.FC<CancelSIPModalProps> = ({ open, onClose, mySIP, schemeName }) => {
  const [selectedRows, setSelectedRows] = useState<SIPItem[]>([]);

  const handleCheckboxChange = (sip: SIPItem) => {
    setSelectedRows((prev) =>
      prev.includes(sip)
        ? prev.filter((item) => item !== sip)
        : [...prev, sip]
    );
  };

  const handleSubmit = async () => {
    try {
      // Replace with your API endpoint
    //   const response = await axios.post('/api/cancelSIP', { data: selectedRows });
      console.log('Sip Cancel request', selectedRows);
      onClose(); // Close the modal on successful submission
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2" gutterBottom>
            <div className="text-2xl font-[600] text-primary mb-4">{schemeName}</div>
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell align="center">Start Date</TableCell>
                <TableCell align="center">No. of Installments</TableCell>
                <TableCell align="center">SIP Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mySIP.map((sip) => (
                <TableRow key={sip.sipRegId}>
                  <TableCell>
                    <Checkbox
                      checked={selectedRows.includes(sip)}
                      onChange={() => handleCheckboxChange(sip)}
                    />
                  </TableCell>
                  <TableCell align="center" >{sip.createdAt}</TableCell>
                  <TableCell align="center">{sip.noOfSIP}</TableCell>
                  <TableCell align="center">{sip.sipAmount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          disabled={selectedRows.length === 0}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default CancelSIPModal;
