import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Outlet } from "react-router-dom";
import ChatbotMain from "../../components/Chatbot/Chatbot";
import { useAuth } from "../../hooks/useAuth";
import { useUserData } from "../../hooks/useUserData";
const AppLayout = () => {
  const { isLoggedIn } = useAuth();
  const user = useUserData();
  return (
    <>
      <Header />
      <Outlet />
      {(isLoggedIn && user?.onboardStatus === 3) &&
        <ChatbotMain />
      }
      <Footer className="bg-lightBg" />
    </>
  );
};

export default AppLayout;
