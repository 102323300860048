import React, { useEffect, useRef, useState } from "react";
import { colors } from "../../../constants/colors";
import upload from "../../../assets/icons/UploadBtn.png";
import Buttons from "../../../components/Buttons/IndividualButtons/Buttons";
import { Img, Text } from "../../../components";
import * as yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { addDematDetails } from "../../../redux/demat/dematSlice";
import toast from "react-hot-toast";
import { checkFileSizeAndReset } from "../../../utils/index";
import ConfirmationModal from "../../../components/modals/PopupModal";
import FormSelect from "../../../components/FormSelect/FormSelect";
import { FormInput } from "../../../components/FormInput/FormInput";
import FileInput from "../../../components/FileInput/FileInput";

const dematValues = {
  dpName: "",
  dpId: "",
  clientId: "",
  depositoryName: "kfintech",
  panNumber: "",
};

const dematSchema = yup.object({
  dpName: yup.string().test("is-valid-dp-name", "Select DP", function (value) {
    const dpNameFieldValue = this.resolve(yup.ref("DP Name"));
    return value === dpNameFieldValue || value === "NSDL" || value === "CDSL";
  }),
  dpId: yup
    .string()
    .required("DP ID is required")
    .max(8, "DP ID should be eight digit")
    .min(8, "DP ID should be eight digit")
    .matches(
      /^([A-Za-z0-9_.])+$/,
      "Must Contain 8 Characters, No Special Case Character"
    ),
  clientId: yup
    .string()
    .required("Client ID is required")
    .max(8, "Clinet ID should be eight digit")
    .min(8, "Clinet ID should be eight digit")
    .matches(/^([0-9_.])+$/, "Must Contain only numeric 8 digit"),
  depositoryName: yup.string().required(),
  panNumber: yup
    .string()
    .required("PAN number is required")
    .matches(/^([A-Z]{5}[0-9]{4}[A-Z]{1})+$/, "Enter Valid PAN Number"),
});

interface InputFieldProps {
  label: string;
  type: string;
  id: string;
  altText?: string;
  name?: string;
  onChange: any;
  value?: any;
  disabled?: boolean;
}

interface DematDetailsFormProps {
  onDematToPreferences: () => void;
  allowToProceed: () => void; // Prop to handle transition to Demat Details
  userData: any;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  type,
  id,
  altText,
  name,
  onChange,
  value,
  disabled = false,
}) => {
  if (label === "DP Name") {
    return (
      <div
        className="flex flex-col text-lg mb-3"
        style={{ color: colors.darkGrey }}
      >
        <label htmlFor={id} className="" style={{ color: colors.darkGrey }}>
          {label}
        </label>
        <select
          id={id}
          className="shrink-0 mt-3 rounded-lgp-4  p-4"
          style={{
            color: colors.darkGrey,
            backgroundColor: colors.white,
            border: `1px solid ${colors.darkBg}`,
          }}
          aria-label={altText || label}
          name={name}
          onChange={onChange}
          value={value}
          disabled={disabled}
        >
          <option selected>Select DP Name</option>
          <option value="CDSL">CDSL</option>
          <option value="NSDL">NSDL</option>
        </select>
      </div>
    );
  } else {
    return (
      <div
        className="flex flex-col text-lg mb-3"
        style={{ color: colors.darkGrey }}
      >
        <label htmlFor={id} className="" style={{ color: colors.darkGrey }}>
          {label}
        </label>
        <input
          className="shrink-0 mt-3 rounded-lg p-4"
          style={{
            color: disabled ? colors.lightGrey : colors.darkGrey, //CH11/5
            backgroundColor: colors.white,
            border: `1px solid ${colors.darkBg}`,
          }}
          type={type}
          id={id}
          aria-label={altText || label}
          name={name}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
      </div>
    );
  }
};

const FileInputField: React.FC<InputFieldProps> = ({
  label,
  onChange,
  disabled,
}) => {
  const [fileName, setFileName] = React.useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const isValid = checkFileSizeAndReset(file, event); // Validate the file size
      if (isValid) {
        setFileName(file.name); // Update the displayed file name
      } else {
        setFileName(null); // Reset the displayed file name if invalid
      }
    }
  };

  return (
    <div className="flex flex-col mb-5 grow text-lg text-neutral-500">
      <div className="mb-2 text-base ">{label}</div>
      <label
        className="shrink-0 rounded-lg p-3 flex items-center justify-between "
        style={{
          color: colors.darkGrey,
          backgroundColor: colors.white,
          border: `1px solid ${colors.darkBg}`,
        }}
      >
        <input
          type="file"
          className="hidden sm:w-full"
          style={{ backgroundColor: colors.white }}
          onChange={(e) => {
            handleFileChange(e);
            onChange(e);
          }}
          disabled={disabled}
        />
        <span>{fileName ? fileName : ""}</span>
        <img
          src={upload} // Update with your image path
          alt="Upload Icon"
          className="h-10 w-10 ml-2 cursor-pointer"
        />
      </label>
    </div>
  );
};

const dpOptions = [
  { label: "CDSL", value: "CDSL" },
  { label: "NSDL", value: "NSDL" },
];

const DematForm: React.FC<DematDetailsFormProps> = ({
  onDematToPreferences,
  allowToProceed,
  userData,
}) => {
  const dispatch = useDispatch<any>();
  const formikRef = useRef<any>();
  const [cmlDoc, setCmlDoc] = useState();
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if(userData?.kyc?.pancardNumber) {
      formikRef.current.setFieldValue("panNumber", userData?.kyc?.pancardNumber);
    }
  }, [userData])

  useEffect(() => {
    if (Object.keys(userData?.demat).length > 0) {
      formikRef.current.setValues(userData?.demat);
      setFieldDisabled(true);
    }
  }, [userData?.demat]);

  const handleModalConfirm = () => {
    setIsModalVisible(false); // Hide the modal after confirmation
    if (formikRef.current) {
      formikRef.current.submitForm(); // Submit the form upon confirmation
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Hide the modal if user cancels
  };

  const handleSaveAndProceed = () => {
    setIsModalVisible(true); // Show the modal when the button is clicked
  };

  const checkAlreadySubmitted = () => {
    if (Object.keys(userData?.demat).length > 0) {
      onDematToPreferences();
    } else {
      handleSaveAndProceed();
    }
  };

  return (
    <>
      <div className="flex flex-col w-full max-w-screen-xl my-8">
        <Formik
          innerRef={formikRef}
          initialValues={dematValues}
          validationSchema={dematSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values, "user values are");
            if (cmlDoc) {
              const formData = new FormData();
              formData.append("dpName", values.dpName);
              formData.append("dpId", values.dpId);
              formData.append("clientId", values.clientId);
              formData.append("depositoryName", values.depositoryName);
              formData.append("panNumber", values.panNumber);
              formData.append("cmlFile", cmlDoc);
              dispatch(addDematDetails(formData));
              // onNomineeToDemat();
              onDematToPreferences();
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            isValid,
            /* and other goodies */
          }) => (
            <>
              <form
                onSubmit={handleSubmit}
                className="w-full flex flex-col gap-[31px]"
              >
                <div className="flex flex-row flex-wrap gap-8 justify-center">
                  <div className="w-full max-w-[500px]">
                    {/* <InputField
                        label="DP Name *"
                        type="text"
                        id="nomineeName"
                        name="dpName"
                        onChange={handleChange("dpName")}
                        value={values.dpName}
                        disabled={fieldDisabled}
                      /> */}
                    <FormSelect
                      label="DP Name *"
                      name="dpName"
                      options={dpOptions}
                      onChange={handleChange("dpName")}
                      value={values.dpName}
                      disabled={fieldDisabled}
                      error={errors.dpName}
                      touched={touched.dpName}
                    />
                  </div>
                  <div className="w-full max-w-[500px]">
                    <FormInput
                      label="DP ID *"
                      type="text"
                      id="dpId"
                      name="dpId"
                      onChange={handleChange("dpId")}
                      value={values.dpId}
                      disabled={fieldDisabled}
                      error={errors.dpId}
                      touched={touched.dpId}
                    />
                  </div>

                  {/* Row for Email and Relationship */}
                  <div className="w-full max-w-[500px]">
                    <FormInput
                      label="Client ID *"
                      type="text"
                      id="clientId"
                      name="clientId"
                      onChange={handleChange("clientId")}
                      value={values.clientId}
                      disabled={fieldDisabled}
                      error={errors.clientId}
                      touched={touched.clientId}
                    />
                  </div>
                  <div className="w-full max-w-[500px]">
                    <FormInput
                      label="Depository *"
                      type="text"
                      id="depositoryName"
                      name="depositoryName"
                      onChange={handleChange("depositoryName")}
                      value={values.depositoryName}
                      disabled={true}
                      error={errors.depositoryName}
                      touched={touched.depositoryName}
                    />
                  </div>
                  {!fieldDisabled && (
                    <div className="w-full max-w-[500px]">
                      <FileInput
                        label="Upload CML Copy *"
                        type="file"
                        id="cml"
                        name="cml"
                        onChange={(e: any) => setCmlDoc(e.target.files[0])}
                        disabled={fieldDisabled}
                        error={!cmlDoc ? "Please upload CML" : ""}
                        touched={touched.clientId}
                      />
                    </div>
                  )}
                  <div className="w-full max-w-[500px]">
                    <FormInput
                      label="Pan Number *"
                      type="text"
                      id="panNumber"
                      name="panNumber"
                      onChange={handleChange("panNumber")}
                      value={values.panNumber}
                      disabled={!!userData?.kyc?.pancardNumber || fieldDisabled}
                      error={errors.panNumber}
                      touched={touched.panNumber}
                    />
                  </div>
                  {fieldDisabled && (
                    <div className="w-full max-w-[500px]"></div>
                  )}
                </div>

                <div className="flex items-center md:pl-32">
                  <a href="" className="flex items-center">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/3ba9d573cbb000cc1e61ded9b73e398cc12d85c7f3f9f0c69419126880ffa1c6?apiKey=427d052184b84d30b7eba5c7e76648a1&"
                      className="shrink-0 w-8 aspect-square fill-red-500 mr-2 h-6"
                      alt="Icon Placeholder"
                    />
                    <a
                      href="https://aumline.in/"
                      target="_blank"
                      className="text-red-500 underline"
                    >
                      Don't have DEMAT account? Create one now!
                    </a>
                  </a>
                </div>

                <div className=" mb-16 ml-4 sm:ml-0 md:pl-32">
                  <section className="mt-10 mb-5 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                      <div className="flex flex-col max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                          <div className="flex gap-5 justify-between  mt-12 text-sm max-md:mt-10">
                            {/* Enable/disable buttons based on radioSelected */}
                            {!fieldDisabled && (
                              <button
                                // style={{
                                //  backgroundColor: colors.white,
                                //color: colors.darkGrey,
                                //border: `1px solid ${colors.darkGrey}`,
                                //}} ch-2/5 why added?
                                className={`grow justify-center px-8 py-4 whitespace-nowrap rounded-md border border-solid hover:bg-primary transition-all`}
                                tabIndex={0}
                                onClick={() => {
                                  toast(
                                    "You would not be able to transact in equity and move on.",
                                    {
                                      icon: "🚫",
                                    }
                                  );
                                  onDematToPreferences();
                                }} // Add onClick event handler // Disable button if radio is not selected
                              >
                                Skip
                              </button>
                            )}
                            <button
                              type="button"
                              className={`grow  justify-center px-8 py-4 rounded-md border-3 border-${colors.darkGrey} border-solid bg-darkGrey text-white hover:bg-primary transition-all`}
                              tabIndex={0}
                              onClick={checkAlreadySubmitted}
                              // onClick={() => updateUserRef.current.click()} // Add onClick event handler
                              // Disable button if radio is not selected
                              // disabled={
                              //   !isValid &&
                              //   !cmlDoc
                              // }
                            >
                              Save and Proceed
                            </button>
                            <ConfirmationModal
                              isVisible={isModalVisible} // Render conditionally based on state
                              message="Are you sure all fields are correct and verified? After you proceed to the next screen, fields cannot be edited again."
                              onConfirm={handleModalConfirm} // Confirm action and submit form
                              onCancel={handleModalCancel} // Cancel action and close modal
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default DematForm;
