import { CheckCircle } from "@mui/icons-material";

const VerifyButton = ({
  isVerified,
  onClick,
}: {
  isVerified: boolean;
  onClick: () => void;
}) => (
  <>
    {!isVerified ? (
      <button className="text-secondary cursor-pointer" onClick={onClick} type="button">
        Verify
      </button>
    ) : (
      <div className="flex items-center gap-3 text-green-600">
        <p>Verified</p>
        <CheckCircle />
      </div>
    )}
  </>
);

export default VerifyButton;
