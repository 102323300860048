import React, { useState } from 'react';
import { Button, Accordion, AccordionDetails, AccordionSummary, ToggleButtonGroup, ToggleButton, Skeleton, ClickAwayListener } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colors } from '../../constants/colors';
import { PieChart } from "@mui/x-charts";
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import formatKeyToTitle from '../../utils/formatKeytitle';

const AssetAllocation = ({ DataBYID, DataBYiSIN }: { DataBYID: any, DataBYiSIN: any }) => {
  // console.log('DataBYID Asset Allocation', DataBYiSIN);
  const [currentTab, setCurrentTab] = useState(1);

  const tabs = [
    { label: 'Asset Allocation', value: 1 }
  ];


  const AllAssetChartValue = () => {
    const data = DataBYID;
  
    return Object.entries(data).map(([key, value]) => ({
      id: key,
      value: value as number,
      label: formatKeyToTitle(key),
    }));
  };
  

  const AssetItem = ({ title, value }: { title: string; value: number }) => {
    return (
      <div className="flex flex-row justify-between items-center">
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between">
            <h6 className="font-semibold text-lg text-primary mb-4">{title}</h6>
            {/* <Typography variant="body2" color="text.secondary">{`${value.toFixed(2)}%`}</Typography> */}
          </div>
          <div className="w-full">
            <LinearProgressWithLabel value={value} />
          </div>
        </div>
      </div>
    );
  };

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${(props.value).toFixed(2)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const AssetList = ({ data }: { data: any }) => {
    const assetArray = Object.entries(data.assetAllocation).map(([key, value]) => ({ key, value: parseFloat(value as string) }));
    const filteredData = assetArray.filter((item) => item.value >= 0);
  
    return (
      <div className="flex flex-wrap">
        {filteredData.map(({ key, value }, index) => (
          <div key={index} className="my-4 w-1/2 px-8">
            <AssetItem title={formatKeyToTitle(key)} value={value} />
          </div>
        ))}
      </div>
    );
  };
  




  const AssetAllocationTab = () => {
    const pieChart = AllAssetChartValue();
    // console.log('pieChart', pieChart);

    return (
      <div className="md:w-full items-center justify-center">
        <div className="w-3/4">
          <PieChart
            series={[
              {
                data: pieChart,
                innerRadius: 120,
                outerRadius: 150,
                paddingAngle: 0,
                startAngle: -45,
                endAngle: 360,
              }
            ]}
            colors={['#8DAEE5', "#FF6B6B", "#FF69B4", "#FFFF66", '#B0E0E6', "#FFB347", "#7FFFD4", "#00FA9A"]}
            sx={{ width: "100%" }}
            height={300}
          />
        </div>
        <AssetList data={{ assetAllocation: DataBYID }} />
      </div>
    )
  };


  // const handleAlignmentss = (
  //   event: React.MouseEvent<HTMLElement>,
  //   newAlignment: string | null,
  // ) => {
  //   if (newAlignment !== null) {
  //     setAlignment(newAlignment);
  //   }
  // };

  return (
    <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          backgroundColor: colors.lightBg,
          borderRadius: '5px',
          borderColor: colors.primary,
          borderWidth: '1px',
          borderStyle: 'solid',
        }}
      >
        <h6 className="font-semibold text-lg text-primary">Asset Allocation</h6>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0', margin: '2rem 0' }}>
        <div className="border border-lightGrey rounded-md">
          <div className='border-b-2 border-primary'>
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={currentTab}
              onChange={(event, value) => setCurrentTab(value)}
              aria-label="Platform"
              sx={{
                width: '100%',
                justifyContent: 'center',
                '& .css-1xhpvac-MuiButtonBase-root-MuiToggleButton-root.Mui-selected': {
                  backgroundColor: colors.primary,
                },
                '& .css-233i2o-MuiButtonBase-root-MuiToggleButton-root.Mui-selected': {
                  color: '#fff',
                  backgroundColor: colors.primary
                },
                '& .css-1vwtnbs-MuiButtonBase-root-MuiToggleButton-root.Mui-selected': {
                  color: '#fff',
                  backgroundColor: colors.primary
                }
              }}
            >
              {tabs.map((tab) => (
                <ToggleButton
                  key={tab.value}
                  value={tab.value}
                  sx={{
                    fontWeight: 'bold',
                    color: tab.value ? colors.primary : '#fff',
                    width: tab.value === 1 ? '100%' : '25%', // Adjust width based on your requirements
                    '&:hover': {
                      backgroundColor: currentTab === tab.value ? 'primary' : 'transparent',
                    }
                  }}
                >
                  {tab.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
          <div className="my-5 flex flex-col md:flex-row gap-5 justify-between p-4 w-full mx-auto">
            <AssetAllocationTab />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AssetAllocation;
