import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FDService from "./fdService";
import FDTransactionsReport from "../../models/fdHoldings.model";

export const getFDInfo = createAsyncThunk(
  "fd/getHoldings",
  async (data: FDTransactionsReport, thunkAPI) => { // The expected argument type
    try {
      const response = await FDService.getFDInfo(data);
      return response.holdings;
   
      
    } catch (error:any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

const initialState = {
  holdings: [], // Default state for holdings
  isLoading: false,
  isError: false,
  errorMessage: "",
};

const fdSlice = createSlice({
  name: "fd",
  initialState,
  reducers: {
    resetState: (state) => {
      state.holdings = []; // Reset state to default
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFDInfo.pending, (state) => {
        state.isLoading = true; // Set loading state
      })
      .addCase(getFDInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.holdings = action.payload || []; // Store fetched data in holdings
      })
      .addCase(getFDInfo.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.errorMessage = action.error.message || "Error fetching data";
      });
  },
});



export const { resetState } = fdSlice.actions;
export default fdSlice.reducer;
