import React, { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const SortingComponent = ({ title, data, keyProp }: any) => {
    const [sortedData, setSortedData] = useState(data);

    useEffect(() => {
        setSortedData(data);
    }, [data]);

    function inferType(value: any) {
        if (typeof value === 'number') {
            return 'number';
        } else if (!isNaN(parseFloat(value))) {
            return 'float';
        } else if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(value)) {
            return 'date';
        } else {
            return 'string';
        }
    }

    const riskLevels: { [key: string]: number } = {
        'Low Risk': 1,
        'Low to Moderate Risk': 2,
        'Moderate Risk': 3,
        'Moderately High risk': 4,
        'High Risk': 5,
        'Very High Risk': 6
    };

    const interestRateFrequency: { [key: string]: number } = {
        'Days': 1,
        'MONTHLY': 2,
        'HALFYEARLY': 3,
        'YEARLY': 4,
        'CUMULATIVEDEPOSIT': 5,
    };

    function sortArray(sortConfig: any) {
        const { key, order = 'asc' } = sortConfig;
        const sampleValue = data[0][key];
        const type = key === 'riskLevel' ? 'riskLevel' : inferType(sampleValue);

        // console.log('Sorting by key:', key, 'Order:', order, 'Type:', type, 'data', data);

        const sortedArray = [...data].sort((a, b) => {
            let comparison = 0;
            let aValue, bValue;

            if (type === 'riskLevel') {
                aValue = riskLevels[a[key]] || 0;
                bValue = riskLevels[b[key]] || 0;
                comparison = aValue - bValue;
                // console.log('RiskLevel comparison:', aValue, bValue, comparison);
            } else if (key === 'investmentFrequency') {
                aValue = interestRateFrequency[a[key]] || 0;
                bValue = interestRateFrequency[b[key]] || 0;
                comparison = aValue - bValue;
                // console.log('InvestmentFrequency comparison:', aValue, bValue, comparison);
            } else if (key === 'createdAt') {
                aValue = new Date(a[key]).getTime();
                bValue = new Date(b[key]).getTime();
                comparison = aValue - bValue;
                // console.log('Date comparison:', aValue, bValue, comparison);
            } else if (type === 'number' || type === 'float') {
                aValue = parseFloat(a[key]) || 0;
                bValue = parseFloat(b[key]) || 0;
                comparison = aValue - bValue;
                // console.log('Number/Float comparison:', aValue, bValue, comparison);
            } else if (type === 'string') {
                aValue = a[key] ?? '';
                bValue = b[key] ?? '';
                comparison = aValue.localeCompare(bValue);
                // console.log('String comparison:', aValue, bValue, comparison);
            }

            return order === 'asc' ? comparison : -comparison;
        });

        // console.log('sortedArray', sortedArray);

        setSortedData(sortedArray);
    }

    return (
        <div className={`flex items-center ${keyProp === 'providerName' || keyProp === 'amcCode' ? 'w-10' : 'w-auto'}`}>
            <>{title}</>
            <div className='flex flex-col items-center justify-center w-10 h-2 ml-4'>
                <div className='' >
                    <KeyboardArrowUpIcon onClick={() => sortArray({ key: keyProp, order: 'asc' })} className='sm:w-2 w-[2px] mb-[-10px] cursor-pointer' />
                </div>
                <div className='' >
                    <KeyboardArrowDownIcon onClick={() => sortArray({ key: keyProp, order: 'desc' })} className='sm:w-2 w-[2px] mt-[-10px] cursor-pointer' />
                </div>
            </div>
        </div>
    );
}

export default SortingComponent;
