import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Elipse from "../../assets/images/Ellipse.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Fade,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  Pagination,
  Stack,
  TextField,
  Typography,
  makeStyles,
} from "@mui/material";
import {
  Add,
  Close,
  Logout,
  OutboxOutlined,
  PersonAdd,
  Search,
  Settings,
} from "@mui/icons-material";
import FilterAlt from "@mui/icons-material/FilterAltOutlined";
import { colors } from "../../constants/colors";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import MFTable from "../../components/MFTable/MFTable";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { getAllMF, getFundInfo, getMFundById } from "../../redux/MF/MFSlice";
import dropdown from "../../assets/icons/dropdown.svg";
import DynamicBreadcrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import InvestModal from "../../components/modals/investModal";
import toast from "react-hot-toast";
import { hideLoading, showLoading } from "../../redux/loader/loaderSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
const filterOps: any = [
  {
    name: "Fund Type",
    options: [
      {
        name: "Equity",
        value: 1,
        isChecked: false,
      },
      {
        name: "Debt",
        value: 3,
        isChecked: false,
      },
      {
        name: "Hybrid",
        value: 5,
        isChecked: false,
      },
      {
        name: "Solution Oriented",
        value: 7,
        isChecked: false,
      },
      {
        name: "GILT",
        value: 8,
        isChecked: false,
      },
      {
        name: "ELSS",
        value: 9,
        isChecked: false,
      },
      {
        name: "MIP",
        value: 10,
        isChecked: false,
      },
      {
        name: "Balanced",
        value: 11,
        isChecked: false,
      },
      {
        name: "STP",
        value: 12,
        isChecked: false,
      },
      {
        name: "FOF",
        value: 13,
        isChecked: false,
      },
      {
        name: "Liquid",
        value: 14,
        isChecked: false,
      },
      {
        name: "Bond",
        value: 15,
        isChecked: false,
      },
      {
        name: "Income",
        value: 16,
        isChecked: false,
      },
    ],
  },
];
interface FundData {
  fund: {
    id: number;
    isin: string;
    schemeType: string;
    schemePlan: string;
    schemeName: string;
    purchaseAllowed: string;
    purchaseTransactionMode: string;
    minimumPurchaseAmount: string;
    rtaAgentCode: string;
    sipFlag: string;
    stpFlag: string;
    swpFlag: string;
    switchFlag: string;
    startData: string;
    endDate: string;
    fundSize: string;
    mfRank: string;
    returnRate3Yrs: string;
    currentNAV: number;
    exitLoad: string;
    expenseRatio: string;
    lockInPeriod: string;
    riskLevel: string;
    isInWishlist: number;
  };
  fundData: { fundINFO: any; fundInfoByID: any };
}

const MFListing = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [totalFound, setTotalFound] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [isInvestModalOpen, setIsInvestModalOpen] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [chooseFund, setChooseFund] = useState();
  const [page, setPage] = useState(1);
  const [countOFPages, seCountOFPages] = useState(1);
  const [isinWishilist, setIsinWishilist] = useState<any>();
  const [filterOptions, setFilterOptions] = useState(filterOps);
  const [MFData, setMFData] = useState<any[]>([]);
  const [assets, setAssets] = useState<{ [key: number]: FundData | undefined }>(
    { 1: undefined, 2: undefined, 3: undefined }
  );
  const [inputData, setInputData] = useState({
    pageIndex: 1,
    pageSize: 100,
    query: "",
    type: "",
    tenure: "",
  });

  const [enableButton, setEnableButton] = useState<boolean>(false);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    filterOptions.forEach((filter: any) => {
      if (filter.name === "Fund Type") {
        const checkedOptions = filter.options.filter(
          (option: any) => option.isChecked
        ); // Get checked options

        if (checkedOptions.length > 0) {
          const checkedValues = checkedOptions
            .map((option: any) => option.name.toLowerCase())
            .join(","); // Join selected fund types

          setInputData((prevInputData) => ({
            ...prevInputData,
            type: checkedValues, // Update `inputData` with selected types
          }));
        }
      }
    });
  }, [filterOptions]);

  const handleChange1 = (
    event: React.ChangeEvent<HTMLInputElement>,
    filterIndex: number
  ) => {
    const { checked } = event.target;
    const updatedOptions = [...filterOptions];
    updatedOptions[filterIndex].options.map(
      (option: any) => (option.isChecked = checked)
    );
    setFilterOptions(updatedOptions);
  };

  const handleChange2 = (
    event: React.ChangeEvent<HTMLInputElement>,
    filterIndex: number,
    optionIndex: number
  ) => {
    const { checked } = event.target;
    const updatedOptions = [...filterOptions];
    updatedOptions[filterIndex].options[optionIndex].isChecked = checked;
    setFilterOptions(updatedOptions);
  };

  const handleFilter = () => {
    handleClose();
    fetchData();
  };

  //Fetch MF Data from Get All fund list API
  const fetchData = async () => {
    setLoader(true); // Start loading
    try {
      const response = await dispatch(getAllMF(inputData));

      if (response) {
        let mfList = response?.payload?.mfList || [];

        // Filter based on query for schemeName, schemeType, schemePlan, and riskLevel
        if (inputData.query) {
          const query = inputData.query.toLowerCase();
          mfList = mfList.filter((mf: any) =>
            mf.schemeName.toLowerCase().includes(query) ||
            mf.schemeType.toLowerCase()=== query ||
            mf.schemePlan.toLowerCase().includes(query) ||
            mf.riskLevel.toLowerCase()===query 
          );
        }
        setMFData(mfList);
        setTotalFound(mfList.length);
        seCountOFPages(response?.payload?.totalPages || 0);
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoader(false); // End loading
    }
  };

  useEffect(() => {
    setLoader(true);
    fetchData();
  }, []);

  useEffect(() => {
      fetchData();
  }, [inputData.query]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    setInputData({ ...inputData, pageIndex: value });
    fetchData();
  };

  useEffect(() => {
    if (inputData.query !== "") {
      fetchData();
    }
  }, [inputData.query]);

  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Investment", href: "/" }, // Breadcrumb item with link
    { label: "Mutual Funds" }, // Current page, no link
  ];
  const QuickFilterOption = [
    { label: "Equity", value: "Equity" },
    { label: "Debt", value: "Debt" },
    { label: "Solution oriented", value: "Solution oriented" },
    { label: "Hybrid", value: "Hybrid" },
    { label: "Others", value: "Others" },
  ];

  const quickFilterOptionFn = (e: string) => {
    setInputData({ ...inputData, type: e });
  };

  const clearFilterOptionFn = () => {
    setInputData({ ...inputData, type: "" });
  };

  //Fetch MF Data from Get by iD API
  const findMutualFund = async () => {
    try {
      if (isinWishilist.id) {
        const response = await dispatch(
          getMFundById({ fundId: isinWishilist?.id })
        );
        if (response?.payload) {
          setChooseFund(response.payload); // assuming `response.payload` contains the data
        } else {
          toast.error("Try again later");
        }
      }
    } catch (error) {
      toast.error("An error occurred while fetching data");
    }
  };

  const openInvestModal = () => {
    findMutualFund();
    setIsInvestModalOpen(true);
  };
  const closeInvestModal = () => {
    setIsInvestModalOpen(false);
    // fetchData();
  };

  const selectedFunds = async (fund: any) => {
    let removed = false;

    setAssets((prevSelected: any) => {
      const alreadySelected = Object.values(prevSelected).some(
        (f: any) => f?.fund?.id === fund.id
      );

      if (alreadySelected) {
        const newSelected = { ...prevSelected };
        for (let key in newSelected) {
          if (newSelected[key]?.fund?.id === fund.id) {
            newSelected[key] = undefined;
            removed = true;
            break;
          }
        }
        return newSelected;
      }

      return prevSelected;
    });

    if (removed) return;

    const availableIndex = Object.keys(assets).find(
      (key) => assets[Number(key)] === undefined
    );
    if (!availableIndex) {
      toast.error("You can select a maximum of three funds for comparison.");
    }
    if (!availableIndex) return;

    const dataByID = await findSelectedFund(fund.id, fund.isin);

    if (countDefinedFunds(assets) >= 3) {
      toast.error("You can select a maximum of three funds for comparison.");
      return;
    }

    if (dataByID) {
      setAssets((prevSelected: any) => ({
        ...prevSelected,
        [availableIndex]: { fund, fundData: dataByID },
      }));
    }
  };

  //Fetch all mF data from Api

  const findSelectedFund = async (id: number, isin: string) => {
    try {
      dispatch(showLoading());
      const promises: Promise<any>[] = [];

      if (isin) {
        promises.push(dispatch(getFundInfo({ isin: isin })));
      }

      if (id) {
        promises.push(dispatch(getMFundById({ fundId: id })));
      }

      const responses = await Promise.all(promises);
      let responseData: any = {};

      responses.forEach((response) => {
        try {
          const data = unwrapResult(response);
          if (data) {
            if (data.returns) {
              responseData.fundINFO = data;
            } else {
              responseData.fundInfoByID = data;
            }
          }
        } catch (error) {
          toast.error("Please Refresh page");
        }
      });

      if (responseData.fundINFO && responseData.fundInfoByID) {
        dispatch(hideLoading());
        return responseData;
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };
  const countDefinedFunds = (assets: {
    [key: number]: FundData | undefined;
  }) => {
    return Object.values(assets).filter((fund) => fund !== undefined).length;
  };

  const navigateDetails = () => {
    const numberOfDefinedFunds = countDefinedFunds(assets);

    if (numberOfDefinedFunds < 2) {
      toast.error("You must select at least 2 funds to compare.");
      return;
    }

    navigate("/asset-comparison", { state: { fundState: assets } });
  };

  useEffect(() => {
    if (assets && countDefinedFunds(assets) >= 2) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  }, [assets]);

  useEffect(() => {
    if (inputData.type) {
      fetchData();
    }
  }, [inputData.type]);

  return (
    <div>
      <div className="bg-lightBg">
        <div className="md:py-[30px] md:px-[30px] px-4 py-2 max-w-[1440px] justify-center items-center mx-auto">
          <div className="pb-4 border-b border-lightGrey md:mb-8 mb-2 sm:pt-0 pt-2">
            <DynamicBreadcrumbs items={breadcrumbItems} />
          </div>
          <div className="w-full flex md:flex-row gap-x-6 ">
            <div className="md:w-[300px] md:h-[150px] md:bg-white bg-red-950 items-center justify-center align-middle relative rounded-full md:flex hidden">
              {/* Center image */}
              <div className="flex items-center justify-center w-full h-full">
                <img className="w-[80px] " src={dropdown} alt="center-image" />
              </div>
            </div>
            <div className="flex flex-col md:gap-y-3">
              <div className="flex gap-x-8 items-center border-b-2">
                <div className="flex flex-row w-full md:justify-between justify-start md:pb-0 pb-2">
                  <div className="bg-white w-16 h-18 items-center justify-center flex align-middle relative rounded-full md:hidden mr-4 my-0 py-0">
                    {/* Center image */}
                    <div className="flex items-center justify-center w-full h-full">
                      <img
                        className="w-full p-3"
                        src={dropdown}
                        alt="center-image"
                      />
                    </div>
                  </div>
                  <div className="my-auto md:my-0">
                    <h3 className="text-3xl font-medium text-primary">
                      Mutual Funds
                    </h3>
                  </div>
                  {/* <div className="justify-center px-3 py-2 text-md border border-solid rounded-[35px] max-md:max-w-full bg-gray-700_01 text-white">
                  Total found: {totalFound}
                </div> */}
                </div>
              </div>
              <p className="mt-1 text-md sm:text-lg text-stone-500 max-md:max-w-full">
                Mutual Funds offer an accessible and efficient way for investors
                to diversify their investments, reduce risk, and achieve
                financial goals. AUM Sampann provides comprehensive details on
                various mutual funds, including performance metrics, risk
                analysis, and insights to help you make informed investment
                decisions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-4 px-2 sm:p-8 md:p-[60px] max-w-[1440px] w-full mx-auto">
        <div className="flex justify-between gap-x-3">
          <TextField
            className="w-full"
            sx={{ padding: "1re"}}
            onChange={(e) => {
              setInputData({ ...inputData, query: e.target.value });
            }}
            placeholder="Search mutual funds"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />

          <React.Fragment>
            {/* <div className="flex justify-between">
              <IconButton
                sx={{ backgroundColor: colors.secondary, borderRadius: 2 ,fontSize:{
                  sm:"16px",
                  xs:"11px"
                },
                font:{
                  xs:"bold"
                }, }}
                onClick={handleMenuClick}
                style={{ color: "#fff" }}
              >
                <FilterAlt />
              </IconButton>
            </div> */}
            <div className="flex justify-between">
              <Button
                sx={{
                  backgroundColor: enableButton
                    ? colors.primary
                    : colors.lightGrey,
                  borderRadius: 2,
                  fontSize:{
                    sm:"16px",
                    xs:"11px"
                  },
                  font:{
                    xs:"bold"
                  },
                  "&:hover": {
                    border: enableButton
                      ? `1px solid ${colors.primary}`
                      : undefined,
                    color: enableButton ? "#255288!important" : undefined,
                  },
                }}
                className="hover:bg-white hover:text-primary"
                onClick={navigateDetails}
                style={{ color: "#fff" }}
                disabled={!enableButton}
              >
                Compare
              </Button>
            </div>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              // onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <div>
                <div className="flex items-center justify-between bg-gray-700_01 px-5 py-3 mt-[-10px]">
                  <Typography variant="h6" color="white">
                    Filter
                  </Typography>
                  <IconButton
                    onClick={handleClose}
                    sx={{ color: colors.white }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </div>

                <Box sx={{ padding: "1rem 2rem" }}>
                  <div className="overflow-y-auto">
                    {filterOptions.map((filter: any, index: number) => (
                      <Accordion key={index} expanded={true}>
                        <AccordionSummary
                          expandIcon={<Add />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          sx={{ backgroundColor: colors.lightBg }}
                          className=""
                        >
                          <FormControlLabel
                            label={filter.name}
                            control={
                              <Checkbox
                                checked={filter.options.every(
                                  (option: any) => option.isChecked
                                )}
                                onChange={(event) =>
                                  handleChange1(event, index)
                                }
                                className=""
                              />
                            }
                          />
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            className="custom-scrollbar flex flex-col gap-4 overflow-y-auto"
                            style={{ maxHeight: "200px" }}
                          >
                            {filter.options.map((option: any, j: number) => (
                              <FormControlLabel
                                key={j}
                                label={option.name}
                                control={
                                  <Checkbox
                                    checked={option.isChecked}
                                    onChange={(event) =>
                                      handleChange2(event, index, j)
                                    }
                                  />
                                }
                              />
                            ))}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                  <Button
                    sx={{
                      backgroundColor: colors.primary,
                      color: colors.white,
                      borderRadius: 2,
                      textTransform: "none",
                      padding: "0.5rem 2rem",
                      marginTop: "1rem",
                    }}
                    onClick={handleFilter}
                  >
                    Apply
                  </Button>
                </Box>
              </div>
            </Menu>
          </React.Fragment>
        </div>
        <div className="flex flex-wrap my-4 gap-x-4 items-center">
          {QuickFilterOption.map((item, index) => (
            <div
              key={index}
              className={`rounded-3xl p-1 border border-gray-700_01 my-1 ${
                inputData.type === item.value
                  ? "bg-mediumBg text-dark"
                  : "bg-white text-textGrey"
              }`}
            >
              <Button
                value={item.value}
                onClick={() => quickFilterOptionFn(item.value)}
                sx={{
                  color:
                    inputData.type === item.value
                      ? colors.black
                      : colors.textGrey,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  fontSize:{
                    xs:"10px",
                    sm:"15px"
                  },
                }}
              >
                {item.label}
              </Button>
              {inputData.type === item.value && (
                <span
                  className=" cursor-pointer text-darkGrey p-4 w-[100px]"
                  onClick={clearFilterOptionFn}
                >
                  <CloseIcon />
                </span>
              )}
            </div>
          ))}
        </div>
        <div>
          <MFTable
            MFData={MFData}
            loader={loader}
            openInvestModal={openInvestModal}
            setIsinWishilist={setIsinWishilist}
            isinWishilist={isinWishilist}
            findMutualFund={findMutualFund}
            selectedFunds={selectedFunds}
            SelectedAssets={assets}
          />
        </div>
      </div>
      <div className="flex justify-center items-center mb-4">
        <Pagination
          shape="rounded"
          count={countOFPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </div>

      {chooseFund && (
        <InvestModal
          open={isInvestModalOpen}
          onClose={closeInvestModal}
          fundInfo={chooseFund}
        />
      )}
    </div>
  );
};

export default MFListing;
