import api from "../../config/apiConfig";

const getAadharOtp = async (aadharNumber: string) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post(
      "user/sendAadharOTP",
      { aadharNumber },
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );
    return response.data.data;
  } catch (error: any) {
    throw new Error(error.response?.data);
  }
};

const verifyAadharOTP = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log(
  //   "MF Service getindidualMutualFundInfo ::",
  //   "Req data: ",
  //   data,
  //   "Access Token :",
  //   accessToken
  // );

  const response = await api.post("user/verifyAadharOTP", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  // console.log("reduxx response :>> ", response);

  return response.data.data;
};

const panVerification = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");

  const response = await api.post("user/panVerify", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  // console.log("reduxx response :>> ", response);

  return response.data.data;
};

const verification_Service = { getAadharOtp, verifyAadharOTP, panVerification };
export default verification_Service;
