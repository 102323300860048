import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import nomineeService from "./nomineeService";
import { NomineeDetails } from "../../models/nominee.model";

export const addNominee = createAsyncThunk(
  "nominee/add",
  async (data: NomineeDetails, thunkAPI: any) => {
    try {
      return await nomineeService.addNominee(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addNomineeByV2 = createAsyncThunk(
  "nominee/addV2",
  async (data: any, thunkAPI: any) => {
    try {
      return await nomineeService.addNomineeV2(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const nomineeUpdate = createAsyncThunk(
  "nominee/update",
  async (data: any, thunkAPI: any) => {
    try {
      return await nomineeService.updateNominees(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  data: {},
  dataV2: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const nomineeSlice = createSlice({
  name: "nominee",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNominee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNominee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(addNominee.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(addNomineeByV2.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNomineeByV2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.dataV2 = action.payload.data;
      })
      .addCase(addNomineeByV2.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      ;
  },
});

export const { resetState } = nomineeSlice.actions;
export default nomineeSlice.reducer;
