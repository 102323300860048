import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import corporateService from "../nonInd_corporate/corporateService";

export const addCorporate = createAsyncThunk(
  "corporate/addCorporate",
  async (data: FormData, thunkAPI: any) => {
    try {
      return await corporateService.addCorporate(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  data: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const corporateSlice = createSlice({
  name: "corporate",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCorporate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCorporate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(addCorporate.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      });
  },
});

export const { resetState } = corporateSlice.actions;
export default corporateSlice.reducer;
