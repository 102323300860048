import React, { FC, useEffect, useRef, useState } from 'react';
import InfoSection from '../../../components/InfoSection/InfoSection';

import InfoSectionNon from '../../../components/InfoSection_nonIndividual/infoSectionNonIndividual';

import person from '../../../assets/icons/f7_person-2 blue.png'
import person24 from '../../../assets/icons/octicon_person-24-blue.png'
import bankDetails from '../../../assets/icons/Company_blue.png'
import demat from '../../../assets/icons/jam_document blue.png'
import preference from '../../../assets/icons/grommet-icons_checkbox-selectedblue.png'
import sign from '../../../assets/icons/la_file-signature blue.png'

import { colors } from '../../../constants/colors';
import KYC from '../../../components/KycProcedure/Kyc'; // Import KYC component
import Header from '../../../components/Header/Header';
import Buttons from '../../../components/Buttons/IndividualButtons/Buttons';

import upload from '../../../assets/icons/lucide_upload.svg';

import * as yup from "yup";
import { Formik } from "formik";
import { useSelector, useDispatch } from 'react-redux';
import { addLlp } from '../../../redux/nonInd_Llp/llpSlice';
import { checkFileSizeAndReset } from "../../../utils/index";
import ConfirmationModal from '../../../components/modals/PopupModal';
import { useUserData } from '../../../hooks/useUserData';



interface InfoSectionProps {
  icon: string;
  title: string;
  bgColor: string;
}


interface FormInputProps {
  label: string;
  onChange?: any;
  value?: any;
  name: string;
}




interface LlpDetailsProps {
  activeSection: string;
  allowToProceed: () => void;
  onLlpToSign: () => void
}





const llpValues = {
  Partner1PanNumber: "",
  Partner2PanNumber: "",

};

const llpSchema = yup.object({
  Partner1PanNumber: yup.string().required(),
  Partner2PanNumber: yup.string().required(),
});





interface InputFieldProps {
  label: string;
  type: string;
  id: string;
  altText?: string;
  onChange?: any;
  name: string
}


const FileInputField: React.FC<InputFieldProps> = ({ label, onChange, name }) => {
  const [fileName, setFileName] = React.useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const isValid = checkFileSizeAndReset(file, event); // Validate the file size
      if (isValid) {
        setFileName(file.name); // Update the displayed file name
      } else {
        setFileName(null); // Reset the displayed file name if invalid
      }
    }
  };

  return (
    <div className="flex flex-col mt-10 mb-10 grow text-lg text-neutral-500">
      <div className="mb-5 text-base sm:text-base  md:text-sm lg:text-lg">{label}</div>
      <label className="rounded-lg p-3 flex items-center justify-between " style={{ color: colors.darkGrey, backgroundColor: colors.white, border: `1px solid ${colors.darkBg}` }}>
        <input
          type="file"
          className="hidden sm:w-full"
          style={{ backgroundColor: colors.white }}
          onChange={(e) => {
            onChange(e);
            handleFileChange(e);
          }}
        />
        <span>{fileName ? fileName : ""}</span>
        <img
          src={upload} // Update with your image path
          alt="Upload Icon"
          className="h-8 w-8 ml-2 cursor-pointer"
        />
      </label>

    </div>
  );
};




const FormInput: FC<FormInputProps> = ({ label, onChange, value, name }) => (
  <div className="w-full sm:w-1/2 mb-10 mt-10 sm:mb-0"> {/* Adjusted width for small screens */}
    <div className="flex flex-col md:text-sm  lg:text-lg mb-10">
      <label htmlFor={label.replace(/ /g, '').toLowerCase()} className='mb-2' style={{ color: colors.darkGrey }}>{label}</label>
      <input
        className={`w-full mt-3 bg-white rounded-lg border border-solid p-4`}
        style={{ color: colors.darkGrey, marginBottom: 0 }}
        type="text"
        id={label.replace(/ /g, '').toLowerCase()}
        aria-label={label}
        onChange={onChange}
        value={value}
        name={name}
      />
    </div>
  </div>
);



const LlpDetails: FC<LlpDetailsProps> = ({ onLlpToSign, allowToProceed }) => {

  const [completedSections, setCompletedSections] = useState<boolean[]>(Array(6).fill(false));
  const dispatch = useDispatch<any>();
  // const organizationId = useSelector((state:any) => state?.general?.organizationId);
  const userData = useUserData();
  const [pancardNumber, setPancardNumber] = useState<string>("");
  const [organizationId, setOrganizationId] = useState<string>('');
  const organizationIdRef = useRef<string>(''); // Ref to store organizationId

  useEffect(() => {
    // Retrieve organizationId from local storage when component mounts
    const storedOrganizationId = localStorage.getItem('organizationId');
    if (storedOrganizationId) {
      setOrganizationId(storedOrganizationId);
      organizationIdRef.current = storedOrganizationId; // Update ref
    }
  }, []);


  useEffect(() => {
    if (userData?.kyc?.pancardNumber) {
      setPancardNumber(userData.kyc.pancardNumber); // Store pancardNumber in state
    }
  }, [userData]);


  const [pancard, setPancard] = useState<File>();
  const [address, setAddress] = useState<File>();
  const [bankStatement, setBankStatement] = useState<File>();
  const [cancelCheque, setCancelCheque] = useState<File>();
  const [BoardResolution, setBoardResolution] = useState<File>();
  const [AuthSignatories, setAuthSignatories] = useState<File>();
  const [PartnershipDeed, setPartnershipDeed] = useState<File>();
  const [RegCertificate, setRegCertificate] = useState<File>();
  const [Partner1Pancard, setPartner1Pancard] = useState<File>();
  const [Partner1AddressProof, setPartner1AddressProof] = useState<File>();
  const [ClientMasterList, setClientMasterList] = useState<File>();
  const [Partner2Pancard, setPartner2Pancard] = useState<File>();
  const [Partner2AddressProof, setPartner2AddressProof] = useState<File>();
  const [fatca, setFatca] = useState<File>();


  const [isModalVisible, setIsModalVisible] = useState(false); // State for popup visibility
  const formikRef = useRef<any>();

  const handleButtonClick = () => {
    // Open the modal when the "Save and Proceed" button is clicked
    setIsModalVisible(true);
  };

  const handleModalConfirm = () => {
    setIsModalVisible(false); // Hide the modal and proceed with form submission
    if (formikRef.current) {
      formikRef.current.submitForm(); // Submit the form upon confirmation
    }
  };
  const handleModalCancel = () => {
    // Logic for cancelling action (e.g., closing the popup)
    setIsModalVisible(false);
    console.log("Cancelled");
  };




  const formInputs = [
    'First Name',
    'Last Name',
    'Email ID',
    'Phone',
    'DOB',
    'Gender',
  ];


  const businessOptions = [
    { value: '', label: '' },
    { value: 'value1', label: 'value1' },
    { value: 'value2', label: 'value2' },
    { value: 'other', label: 'Other' }
  ];


  const onAllow = (): void => {
    // Mark the current section as completed
    const updatedSections = [...completedSections];
    updatedSections[5] = true;
    setCompletedSections(updatedSections);

    // Call the function to allow to proceed
    allowToProceed();
  };

  return (
    <>
      <div
        className="flex flex-col items-center justify-center w-full "
      >
        <div className="w-full max-w-[70%]">
          <div
            className="font-semibold text-base sm:text-3xl lg:mt-12"
            style={{ color: colors.primary }}
          >
            LLP
          </div>
          <div
            className="font-normal text-base mt-4 sm:text-xl lg:mt-12"
            style={{ color: colors.darkGrey }}
          >
            Note: All Documents should be self-attested.
          </div>

          <Formik
            innerRef={formikRef}
            initialValues={llpValues}
            validationSchema={llpSchema}
            onSubmit={(values, { setSubmitting }) => {
              console.log(values, "llp values");


              if (address && bankStatement && BoardResolution && AuthSignatories && PartnershipDeed && RegCertificate && Partner1Pancard && Partner1AddressProof && Partner2Pancard && Partner2AddressProof && ClientMasterList && fatca) {
                // Create a Company object from form values
                const formData = new FormData();
                formData.append("PanNumber", pancardNumber);
                formData.append("BankStatements", bankStatement);
                formData.append("Partner1PanNumber", values.Partner1PanNumber);
                formData.append("Partner2PanNumber", values.Partner2PanNumber);
                formData.append("AddressProof", address);
                formData.append("BoardResolution", BoardResolution);
                formData.append("AuthSignatories", AuthSignatories);
                formData.append("PartnershipDeed", PartnershipDeed);
                formData.append("RegCertificate", RegCertificate);
                formData.append("Partner1Pancard", Partner1Pancard);
                formData.append("Partner1AddressProof", Partner1AddressProof);
                formData.append("Partner2AddressProof", Partner2AddressProof);
                formData.append("Partner2Pancard", Partner2Pancard);
                formData.append("ClientMasterList", ClientMasterList);
                formData.append("Fatca", fatca);


                formData.append("organizationId", organizationIdRef.current);
                
                dispatch(addLlp(formData))
                // Call the function to proceed to the next step
                allowToProceed();
                // onLlpToSign();

              }
            }}
          >

            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) =>
            (<form onSubmit={handleSubmit}>
              <div className='flex justify-between'>
                {/* Inputs for screens above 768px */}
                <div className="hidden sm:flex flex-col w-full gap-5 ">
                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    {/* First row of file input fields */}
                      {/* <div className="w-full flex flex-row gap-5">
                        <FormInput label="PAN No. of LLP *" name="PanNumber" onChange={handleChange("PanNumber")}
                          value={values.PanNumber} />
                        <FileInputField label="Upload PAN Copy *" type="file" name='Pancard' id="" onChange={(e: any) =>
                          setPancard(e.target.files[0])
                        } />
                      </div> */}
                  </div>

                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                  <div className="w-full sm:w-1/2">
                      {/* Address Proof Of HUF */}
                      <FileInputField label="Address Proof of LLP *" type='file' id='' onChange={(e: any) => setAddress(e.target.files[0])} name={'AddressProof'} />
                    </div>
                    <div className="w-full sm:w-1/2">
                      {/* Bank Statement (Last 3 months) */}
                      <FileInputField label="Balance for Last  2 FInancial Years *" type="file" id="" onChange={(e: any) => setBankStatement(e.target.files[0])} name={'BankStatements'} />
                    </div>
                  </div>

                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    {/* Second row of file input fields */}
                    <div className="w-full sm:w-1/2">
                      {/* Bank Statement (Last 3 months) */}
                      <FileInputField label="Board Resolution Copy *" type="file" id="" onChange={(e: any) => setBoardResolution(e.target.files[0])} name={'BoardResolution'} />
                    </div>
                    <div className="w-full sm:w-1/2">
                      {/* Original Cancelled Cheque */}
                      <FileInputField label="Authorised Signatories  List With Specimen Signatures *" type="file" id="" onChange={(e: any) => setAuthSignatories(e.target.files[0])} name={'AuthSignatories'} />
                    </div>
                  </div>

                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    {/* Second row of file input fields */}
                    <div className="w-full sm:w-1/2">
                      {/* Bank Statement (Last 3 months) */}
                      <FileInputField label="Partnership Deed Copy *" type="file" id="" onChange={(e: any) => setPartnershipDeed(e.target.files[0])} name={'PartnershipDeed'} />
                    </div>
                    <div className="w-full sm:w-1/2">
                      {/* Original Cancelled Cheque */}
                      <FileInputField label="Certificate of Registration *" type="file" id="" onChange={(e: any) => setRegCertificate(e.target.files[0])} name={'RegCertificate'} />
                    </div>
                  </div>

                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    {/* First row of file input fields */}
                    <div className="w-full sm:w-1/2">
                      {/* Shared half screen space for Upload CML Copy and Form Input */}
                      <div className="w-full flex flex-row gap-5">
                        <FormInput label="PAN No. of Partner 1 *" name="Partner1PanNumber" onChange={handleChange("Partner1PanNumber")}
                          value={values.Partner1PanNumber} />
                        <FileInputField label="Upload  PAN Copy *" type="file" id="" onChange={(e: any) => setPartner1Pancard(e.target.files[0])} name={'Partner1Pancard'} />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2">
                      {/* Address Proof Of HUF */}
                      <FileInputField label="Address Proof of Partner 1 *" type='file' id='' onChange={(e: any) => setPartner1AddressProof(e.target.files[0])} name={'Partner1AddressProof'} />
                    </div>
                  </div>

                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    {/* First row of file input fields */}
                    <div className="w-full sm:w-1/2">
                      {/* Shared half screen space for Upload CML Copy and Form Input */}
                      <div className="w-full flex flex-row gap-5">
                        <FormInput label="PAN No. of Partner 2 *" name="Partner2PanNumber" onChange={handleChange("Partner2PanNumber")}
                          value={values.Partner2PanNumber} />
                        <FileInputField label="Upload PAN Copy *" type="file" id="" onChange={(e: any) => setPartner2Pancard(e.target.files[0])} name={'Partner2Pancard'} />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2">
                      {/* Address Proof Of HUF */}
                      <FileInputField label="Address Proof of Partner 2 *" type='file' id='' onChange={(e: any) => setPartner2AddressProof(e.target.files[0])} name={'Partner2AddressProof'} />
                    </div>
                  </div>

                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    {/* Second row of file input fields */}
                    <div className="w-full sm:w-1/2">
                      {/* Bank Statement (Last 3 months) */}
                      <FileInputField label="Client Master List *" type="file" id="" onChange={(e: any) => setClientMasterList(e.target.files[0])} name={'ClientMasterList'} />
                    </div>
                    <div className="w-full sm:w-1/2">
                      {/* Original Cancelled Cheque */}
                      <FileInputField label="FATCA Declaration *" type="file" id="" onChange={(e: any) => setFatca(e.target.files[0])} name={'Fatca'} />
                    </div>
                  </div>
             </div>

              </div>
              <section className="mt-10 mb-5 max-md:mt-5 max-md:mr-2.5 max-md:max-w-full">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col max-md:ml-0 max-md:w-full">
                      <div className="flex justify-end">
                        <div className="flex gap-4">
                          {/* Skip Button */}
                          <button
                            type="button"
                            style={{
                              backgroundColor: colors.white,
                              color: colors.darkGrey,
                              border: `1px solid ${colors.darkGrey}`,
                            }}
                            className="px-8 py-4 rounded-md border"
                            onClick={() => {
                              // Implement skip logic if needed
                            }}
                          >
                            Skip
                          </button>

                          {/* Save and Proceed Button */}
                          <button
                            type="button"
                            style={{ backgroundColor: colors.darkGrey, color: colors.white }}
                            className="px-8 py-4 rounded-md border border-darkGrey"
                            onClick={handleButtonClick} // Open the confirmation modal
                          >
                            Save and Proceed
                          </button>

                          {/* Confirmation Modal */}
                          <ConfirmationModal
                            isVisible={isModalVisible} // Render conditionally based on state
                            message="Are you sure you want to proceed? After submission, changes cannot be made."
                            onConfirm={handleModalConfirm} // Proceed with submission
                            onCancel={handleModalCancel} // Cancel without proceeding
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
            </form>)}
          </Formik>
        </div>
      </div>
    </>

  );
};

export default LlpDetails;
