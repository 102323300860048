import React, { useEffect, useState } from 'react'
import { hideLoading, showLoading } from '../../redux/loader/loaderSlice';
import { MFAssetHoldings } from '../../redux/Reports/ReportSlice';
import { useDispatch } from 'react-redux';
import api from '../../config/apiConfig';
import PmsListingComponent from '../../components/ListingTemplate/PmsListingTemplate';

function PMSListing() {
    const dispatch = useDispatch<any>();
    const [loader, setLoader] = useState<boolean>(false);

    const breadcrumbItems = [
        { label: 'Home', href: '/' },
        { label: 'Investment', href: '/' },
        { label: 'PMS', },
    ];
    const filterOps: any = [
        {
            name: "Fund Type",
            options: [
                {
                    name: "Equity",
                    value: 1,
                    isChecked: false,
                },
                {
                    name: "Debt",
                    value: 3,
                    isChecked: false,
                },
                {
                    name: "Hybrid",
                    value: 5,
                    isChecked: false,
                },
                {
                    name: "Solution Oriented",
                    value: 7,
                    isChecked: false,
                },
                {
                    name: "GILT",
                    value: 8,
                    isChecked: false,
                },
                {
                    name: "ELSS",
                    value: 9,
                    isChecked: false,
                },
                {
                    name: "MIP",
                    value: 10,
                    isChecked: false,
                },
                {
                    name: "Balanced",
                    value: 11,
                    isChecked: false,
                },
                {
                    name: "STP",
                    value: 12,
                    isChecked: false,
                },
                {
                    name: "FOF",
                    value: 13,
                    isChecked: false,
                },
                {
                    name: "Liquid",
                    value: 14,
                    isChecked: false,
                },
                {
                    name: "Bond",
                    value: 15,
                    isChecked: false,
                },
                {
                    name: "Income",
                    value: 16,
                    isChecked: false,
                },
            ],
        },
    ];

    const [totalEnteries, setTotalEnteries] = useState<number>();
    const [dataEnteries, setDataEnteries] = useState<any[]>();
    const [data, setData] = useState<any>();

    const FetchData = async () => {
        try {
          const accessToken = localStorage.getItem("accessToken");
          const response = await api.post("pms/getAll", {            
                pageIndex: 1,
                pageSize: 100
            
        } ,{
            headers: {
              Authorization: `${accessToken}`,
            },
          });
          setData(response?.data?.data?.pmsList);
          setTotalEnteries(response?.data?.data?.pmsList?.length);
        } catch (error) {
          console.error("Error uploading file:", error);
        }finally{
            setLoader(false);
        }
      };

      useEffect(() => {
        setLoader(true);
        FetchData();
      }, [])
      

    const MFTransations = [
        { key: 'assetName', value: 'Asset Name' },
        { key: 'category', value: 'Category' },
        { key: 'inception', value: 'Inception' },
        { key: 'fundSize', value: 'AUM (in Cr)' },
        { key: 'currentNAV', value: 'Current NAV' },
        { key: 'raisedBar', value: 'Expense Ratio' },
        { key: 'promoter', value: 'Promoter' },
        { key: 'returnRate', value: 'Return Rate' },
        { key: 'action', value: 'Action' },
    ];

    const summary = "PMS - Portfolio Management Services (PMS) is a tailored investment service where professional portfolio managers manage an individual's or institution's assets, offering personalized strategies based on the investor's financial goals, risk tolerance, and time horizon. PMS typically involves investing in equity, debt, or a combination of both."

    const QuickFilterOption = [
        { label: 'Equity', value: 'Equity' },
        { label: 'Debt', value: 'Debt' },
        { label: 'Hybrid', value: 'Hybrid' },
        { label: 'Solution oriented', value: 'Solution oriented' },
        { label: 'Others', value: 'Others' },
    ]


    return (
        <PmsListingComponent
            title='Portfolio Management Services (PMS)'
            breadcrumb={breadcrumbItems}
            filterOps={filterOps}
            totalEnteries={totalEnteries}
            dataEnteries={data}
            controlBarObj={MFTransations}
            summary={summary}
            setDataEnteries={setDataEnteries}
            loaderData={loader}
        />
        
    )
}

export default PMSListing