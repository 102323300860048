import { Toaster } from "react-hot-toast";
import AppLoader from "./components/AppLoader/AppLoader";
import AppRouter from "./routes/AppRoutes";
function App() {
  return (
    <>
      <AppLoader>
        <AppRouter />
      </AppLoader>
      <Toaster position="top-center" toastOptions={{duration: 3000}} />
    </>
  );
}

export default App;
