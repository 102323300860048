import React, { useEffect, useState } from 'react';
import { Box, Card, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useUserData } from '../../hooks/useUserData';
import { colors } from '../../constants/colors';
import { getUserById } from '../../redux/user/userSlice';
import { useDispatch } from 'react-redux';

const AccessControl: React.FC =() => {
    const { bank } = useUserData();
    const user =useUserData();


    const dispatch = useDispatch<any>();
    const [relationManager, setRelationManager] = useState<any>();
    const users = user?.relationManager;

    function handleAction({ action, user }: any) {
        console.log(`${action} clicked for user: `, user);
    }

    useEffect(() => {
        const fetchUserPreferences = async () => {
            try {
                const response = await dispatch(getUserById({}));
                const userData = response.payload.data[0];
                console.log(userData.relationManager, 'this is user data');
                setRelationManager(userData.relationManager);
            } catch (error) {
                console.error('Failed to fetch user preferences:', error);
            }
        };

        fetchUserPreferences();
    }, [dispatch]);

    return (
        <Box className='border border-lightGrey rounded-md'>
            <Card style={{ backgroundColor: colors.lightBg, minHeight: '60px', display: 'flex', alignItems: 'center', padding: '0 16px', borderRadius: '5px' }} className='h-8 w-full'>
            <Typography variant="h5" marginLeft={2} color={colors.darkGrey} fontWeight='bold'>Access Control</Typography>
                {/* <Button variant='contained' color='primary' style={{ marginLeft: 'auto' }}>Invite User</Button> */}
            </Card>
            <TableContainer className='mt-6 flex'>
                <Table>
                    <TableHead>
                        <TableRow className='border-b-2 border-textGrey'>
                            <TableCell style={{ borderBottom: 'none', fontWeight: 'bold' }}>Name</TableCell>
                            <TableCell style={{ borderBottom: 'none', fontWeight: 'bold' }}>Role</TableCell>
                            <TableCell style={{ borderBottom: 'none', fontWeight: 'bold' }}>Email ID</TableCell>
                            <TableCell style={{ borderBottom: 'none', fontWeight: 'bold' }}>Contact No.</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ height: '100px' }}>
                        <TableRow>
                            <TableCell style={{ borderBottom: 'none', padding: '20px 16px' }}>{users?.firstName}</TableCell>
                            <TableCell style={{ borderBottom: 'none', padding: '20px 16px' }}>Relation Manager</TableCell>
                            <TableCell style={{ borderBottom: 'none', padding: '20px 16px' }}>{users?.email}</TableCell>
                            <TableCell style={{ borderBottom: 'none', padding: '20px 16px' }}>{users?.mobile}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default AccessControl;
