import api from "../../config/apiConfig";

const getMutualFundInfo = async (data: any) => {
  // console.log('MF Service', accessToken);
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("/funds/getAll", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response.data.data;
};

const getindidualMutualFundInfo = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("/funds/getInfo", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  // console.log("reduxx response :>>", response);

  return response.data.data;
};

const getSTPFunds = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");

  const response = await api.post("funds/getStpFunds", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response.data;
};

const get_MF_DeatilsBy_id = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("/funds/getById", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  // console.log('reduxx response :>> ', response);

  return response.data.data;
};

const investMFService = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("/funds/invest", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const MFSummary = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("/funds/getSummary", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const investMFRequest = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("/funds/request", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const createPayment = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");

  const response = await api.post("/funds/paymentCreate", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const checkPaymentStatus = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("/funds/paymentStatus", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const sendOTP = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("/funds/sendOTP", {}, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response.data.data;
};

const verifyOTP = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("/funds/verifyOTP", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const importFund = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("/funds/import", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const switchMutualFund = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("funds/switch", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response.data;
};

const redeemMutualFund = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("funds/redeem", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response.data;
};

const importFundV2 = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("/funds/getMy", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const MFService = {
  getMutualFundInfo,
  getindidualMutualFundInfo,
  get_MF_DeatilsBy_id,
  investMFService,
  createPayment,
  checkPaymentStatus, sendOTP, verifyOTP, importFund,
  getSTPFunds,
  switchMutualFund,
  redeemMutualFund,
  importFundV2,
  investMFRequest,
  MFSummary
};
export default MFService;
