import React from 'react';
import { Breadcrumbs, Link, Typography, Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext'; // Default separator
import { colors } from '../../constants/colors';

// Props for the DynamicBreadcrumbs component
interface DynamicBreadcrumbsProps {
  items: { label: string; href?: string }[]; // Breadcrumb items, some with optional href
  separator?: React.ReactNode; // Custom separator
}

// DynamicBreadcrumbs with customizable separator and dynamic breadcrumb items
const DynamicBreadcrumbs: React.FC<DynamicBreadcrumbsProps> = ({ items, separator }) => {
  const effectiveSeparator = separator || <NavigateNextIcon sx={{
    marginTop:{
      xs:"5px",
      sm:"2px"
    },
    fontSize: {
    xs: '12px', 
    sm: '16px', 
  },}} />; // Default separator is '>'

  return (
    <Box>
      <Breadcrumbs
        separator={effectiveSeparator} // Use the provided or default separator
        aria-label="breadcrumb"
      >
        {items.map((item, index) => {
          const isLastItem = index === items.length - 1; // Last item logic

          if (isLastItem) {
            return (
              <Typography key={index} sx={{ color: colors.primary , fontWeight:'bold',marginTop:{
                xs:"5px",
                sm:"2px"
              },fontSize: {
                xs: '12px', 
                sm: '16px',
              },}}>
                {item.label}
              </Typography>
            );
          }

          return (
            <Link
              key={index}
              color="inherit"
              href={item.href}
              underline="hover" // Add underline on hover
              sx={{fontSize: {
                xs: '12px', 
                sm: '16px',
              },}}
            >
              {item.label}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default DynamicBreadcrumbs;
