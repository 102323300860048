import React from 'react';
import { colors } from "../../../constants/colors";

interface ButtonsProps {
  denyText?: string;
  allowText?: string;
  denyTextColor?: string;
  denyBgColor?: string;
  allowTextColor?: string;
  allowBgColor?: string;
  onDenyClick?: () => void; // Define onDenyClick prop
  onAllowClick?: () => void; // Define onAllowClick prop
  radioSelected?: boolean; // Define radioSelected prop
}

const Buttons: React.FC<ButtonsProps> = ({
  denyText = 'Deny',
  allowText = 'Allow',
  denyTextColor = colors.darkGrey,
  denyBgColor = colors.white,
  allowTextColor = colors.white,
  allowBgColor = colors.darkGrey,
  onDenyClick, // Destructure onDenyClick prop
  onAllowClick, // Destructure onAllowClick prop
  radioSelected // Destructure radioSelected prop
}) => {
  return (
    <section className="mt-10 mb-5 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
            <div className="flex gap-5 justify-between text-sm max-md:mt-10">
              {/* Enable/disable buttons based on radioSelected */}
              <button 
                style={{ backgroundColor: denyBgColor, color: denyTextColor, border:`1px solid ${colors.darkGrey}` }} 
                className={`grow justify-center px-8 py-4 whitespace-nowrap rounded-md border border-solid `} 
                tabIndex={0}
                onClick={onDenyClick} // Add onClick event handler
                disabled={!radioSelected} // Disable button if radio is not selected
              >
                {denyText}
              </button>
              
              <button 
                style={{ backgroundColor: allowBgColor, color: allowTextColor }} 
                className={`grow  justify-center px-8 py-4 rounded-md border-3 border-${allowBgColor} border-solid `} 
                tabIndex={0}
                onClick={onAllowClick} // Add onClick event handler
                 // Disable button if radio is not selected
              >
                {allowText}
              </button>
            </div>
          </div>
        </div>
       
      </div>
    </section>
  );
}

export default Buttons;
