import { Button, Skeleton, Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { ArrowUpward, ArrowDownward, MoreVert } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../constants/colors';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import PrintIcon from '@mui/icons-material/Print';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import getInitials from '../../utils/getInitailasIMGUtils';
import TimeStamptoExp from '../../utils/timestampConverter';
import TableComponent from '../Tables/TableRow';
type FDPlan = {
    fdId: string;
    name: string;
    startDate: string;
    providerName: string;
    scheme: string;
    option: string;
    investmentTenure: string;
    endDate: string;
    amount: string;
    interesetRate: string;
    investmentFrequency: string;
    grossInterest: string;
    interestPaymentDates: string;
    createdAt: string;
};
type AIFPlan = {
    holdingName: string;      // Mapped from `assetName`
    category: string;
    isin: string;             // Added field
    investedAmount: number;   // Added field
    currentAmount: number;    // Added field
    currentNAV: number;
    units: number;            // Added field
    transactionDate: string;  // Added field
    createdAt: string;
    cuurentPnL: number;       // Mapped from `raisedBar`
    currentPnLinPercentage: number;  // Added field
};


type PMSPlan = {
    holdingName: string;      // Mapped from `assetName`
    category: string;
    isin: string;             // Added field
    investedAmount: number;   // Added field
    currentAmount: number;    // Added field
    currentNAV: number;
    units: number;            // Added field
    transactionDate: string;  // Added field
    createdAt: string;
    cuurentPnL: number;       // Mapped from `raisedBar`
    currentPnLinPercentage: number;  // Added field
};

type BondPlan = {
    holdingName: string;      // Mapped from `assetName`
    category: string;
    isin: string;             // Added field
    investedAmount: number;   // Added field
    currentAmount: number;    // Added field
    currentNAV: number;
    units: number;            // Added field
    transactionDate: string;  // Added field
    createdAt: string;
    cuurentPnL: number;       // Mapped from `raisedBar`
    currentPnLinPercentage: number;  // Added field
};

type EQUITYPlan = {
    holdingName: string;      // Mapped from `assetName`
    category: string;
    isin: string;             // Added field
    investedAmount: number;   // Added field
    currentAmount: number;    // Added field
    currentNAV: number;
    units: number;            // Added field
    transactionDate: string;  // Added field
    createdAt: string;
    cuurentPnL: number;       // Mapped from `raisedBar`
    currentPnLinPercentage: number;  // Added field
};


type MFPlan = {
    transactionDate: string;
    folioNumber: string;
    currentValue: string;
    closingBalance: string;
    costValue: string;
    name: string;
    schemeName: string;
    category: string;
    units: string;
    purchaseNAV: string;
    purchaseAmount: string;
    currentNAV: string;
    amount: number;
    holdingPeriod: string;
    xirr: string;
    action: string;
    amcCode: string;
    unrealisedPNL: number;
    unrealisedPNLPercentage: number;
    totalTimeInDays: number;
};
type MFTransaction = {
    transactionNo: string;
    amcCode: string;
    createdAt: string;
    bseSchemeCode: string;
    folioNumber: string;
    schemeName: string;
    category: string;
    options: string;
    orderType: string;
    subTransactionType: string;
    grossPurchase: string;
    stampDuty: string;
    netPurchase: string;
    units: string;
};
interface Props {
    controlBarObj: Array<{ key: string; value: string }>;
    sortConfig: any;
    handleSort: any;
    loading: boolean;
    rowData: FDPlan[] | MFPlan[] | MFTransaction[] | AIFPlan[] | PMSPlan[] | BondPlan[] | EQUITYPlan[];  // Include PMSPlan
    segment: string;
    reportType: string;
}

const AllAssetPF: React.FC<Props> = ({ controlBarObj, loading, rowData, segment, sortConfig, handleSort, reportType }) => {
    // console.log('Row data', rowData);
    const navigate = useNavigate();
    const [sortedPlans, setSortedPlans] = useState<(FDPlan | MFPlan | MFTransaction | AIFPlan | PMSPlan)[]>([]);
    const controlBarRef = useRef<HTMLDivElement>(null);
    const controlBarContentRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setSortedPlans(rowData);
    }, [rowData]);
    useEffect(() => {
        const handleScroll = () => {
            if (controlBarContentRef.current && controlBarRef.current) {
                controlBarContentRef.current.scrollLeft = controlBarRef.current.scrollLeft;
            }
        };
        const controlBarCurrent = controlBarRef.current;
        if (controlBarCurrent) {
            controlBarCurrent.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (controlBarCurrent) {
                controlBarCurrent.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    
    
    const generatePDF = (data: (FDPlan | MFPlan | MFTransaction | AIFPlan | PMSPlan)[], segment: string) => {
        const doc = new jsPDF();
        const columns = segment ===  'AIF' ? ['Holding Name', 'Category', 'Invested Amount', 'Current Amount', 'Transaction Date', 'Created At', 'Current NAV', 'Return Rate', 'In Wishlist'] : (segment === 'Mutual Funds' && reportType === 'Unrealised gain/loss') ? [
            'Transaction Date', 'Folio No', 'AMC Name', 'Scheme Name', 'Category', 'Units', 'Purchase Price', 'Purchase Amount', 'Current NAV', 'Unrealised P&L', 'Holding Period', 'xirr'
        ] : (segment === 'Mutual Funds') ? [
            'Transaction Date', 'Folio No', 'AMC Name', 'Scheme Name', 'Category', 'Units', 'Purchase Price', 'Purchase Amount', 'Current NAV', 'Unrealised P&L', 'Holding Period', 'xirr'
        ] : (segment === 'PMS') ? [
           'Holding Name', 'Category', 'Invested Amount', 'Current Amount', 'Transaction Date', 'Created At', 'Current NAV', 'Return Rate', 'In Wishlist'
        ] : [
            'Deposit No.', 'Deposit Date', 'Company Name', 'Scheme', 'Options', 'Tenure', 'Maturity Date', 'Invested Amount', 'Interest P.A (%)', 'Interest Payment Frequency', 'Gross Interest', 'Interest Paid', 'Interest Payment Dates'
        ];
    
        const rows = data.map((plan) => {
            const row = segment === 'AIF' ? [
                (plan as AIFPlan).holdingName,
                (plan as AIFPlan).category,
                (plan as AIFPlan).transactionDate,
                (plan as AIFPlan).investedAmount,
                (plan as AIFPlan).currentAmount,
                (plan as AIFPlan).currentNAV,
                (plan as AIFPlan).cuurentPnL,
                (plan as AIFPlan).createdAt
            ] : segment === 'PMS' ? [
                (plan as AIFPlan).holdingName,
                (plan as AIFPlan).category,
                (plan as AIFPlan).transactionDate,
                (plan as AIFPlan).investedAmount,
                (plan as AIFPlan).currentAmount,
                (plan as AIFPlan).currentNAV,
                (plan as AIFPlan).cuurentPnL,
                (plan as AIFPlan).createdAt
            ] : segment === 'Mutual Funds' ? [
                (plan as MFPlan).transactionDate,
                (plan as MFPlan).folioNumber,
                (plan as MFPlan).name,
                (plan as MFPlan).schemeName,
                (plan as MFPlan).category,
                (plan as MFPlan).units,
                (plan as MFPlan).purchaseNAV,
                (plan as MFPlan).purchaseAmount,
                (plan as MFPlan).currentNAV,
                (plan as MFPlan).amount,
                (plan as MFPlan).holdingPeriod,
                (plan as MFPlan).xirr,
            ] : [
                (plan as FDPlan).fdId,
                { content: (plan as FDPlan).name, styles: { cellWidth: 'wrap' } },
                (plan as FDPlan).startDate,
                (plan as FDPlan).providerName,
                (plan as FDPlan).scheme,
                (plan as FDPlan).option,
                (plan as FDPlan).investmentTenure,
                (plan as FDPlan).endDate,
                (plan as FDPlan).amount,
                (plan as FDPlan).interesetRate,
                (plan as FDPlan).investmentFrequency,
                (plan as FDPlan).grossInterest,
                (plan as FDPlan).interestPaymentDates,
            ];
    
            // Convert the name field object to a string
            row[1] = typeof row[1] === 'object' ? (row[1] as { content: string }).content : row[1];
            return row;
        });
    
        doc.autoTable({
            head: [columns],
            body: rows,
        });
    
        const filenameSegment = segment.replace(/[^a-zA-Z0-9]/g, '');
        doc.save(`${filenameSegment}_data.pdf`);
    };
    
    
    const generateExcel = (data: (FDPlan | MFPlan | MFTransaction | AIFPlan | PMSPlan)[], segment: string, controlBarObj: any) => {
        let filtered = data;
        if(segment === "AIF") {
            filtered = data.map((plan: any) => {
                const filteredItem: any = {};
                controlBarObj.forEach((item: any) => {
                    filteredItem[item.key] = plan[item.key]; // Access the required fields
                });
                return filteredItem;
            })
        }
        const worksheet = XLSX.utils.json_to_sheet(filtered);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, segment);
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(dataBlob, `${segment}_data.xlsx`);
    };
    
    

    // console.log('setSortedPlans', sortedPlans);


    return (
        <div>
            {loading || !rowData.length ? (
                <Skeleton
                    variant="rectangular"
                    height={200}
                    animation="wave"
                    sx={{ bgcolor: colors.lightBg }}
                />
            ) : (
                <div className="overflow-x-auto">
                    {/* <div className="bg-lightBg py-1 flex mb-8 overflow-x-scroll" ref={controlBarRef}>
                        {controlBarObj.map(({ key, value }) => (
                            <div key={key} className="grid grid-cols-[repeat(1,300px)] items-end" >
                                <Button
                                    onClick={() => handleSort(key)}
                                    sx={{ fontWeight: 'bold', color: colors.primary, textAlign: 'right' }}
                                    fullWidth
                                    className='flex justify-end items-end'
                                >
                                    {value}{' '}
                                    {sortConfig?.key === key ? (
                                        sortConfig.direction === 'ascending' ? (
                                            <ArrowUpward />
                                        ) : (
                                            <ArrowDownward />
                                        )
                                    ) : null}
                                </Button>
                            </div>
                        ))}
                    </div> */}
                    <TableComponent
                        headData={controlBarObj}
                        bodyData={sortedPlans}
                        // findHighestInterestRates={findHighestInterestRates}
                        segment={segment}
                        reportType={reportType}
                        unSortdata={setSortedPlans}
                    />
                    <div className="flex my-4 items-end justify-end ">
                        {/* <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => generatePDF(sortedPlans, segment)}
                            sx={{
                                margin: '0 1rem',
                                justifyContent: 'space-between'
                            }}
                        >
                            <PrintIcon /> Generate PDF
                        </Button> */}
                        <Button
                            variant="outlined"
                            onClick={() => generateExcel(sortedPlans, segment, controlBarObj)}
                        >
                            <TextSnippetIcon /> Download Excel
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
export default AllAssetPF;