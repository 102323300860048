import CloseIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import add from '../../assets/icons/AddButton.svg';
import { Button } from '../../components';
import { colors } from '../../constants/colors';
import { useUserData } from '../../hooks/useUserData';
import { addNomineeByV2 } from '../../redux/nominee/nomineeSlice';
import { getUserById, updateProfile } from '../../redux/user/userSlice';
import convertFileToBase64 from '../../utils/fileConverter';
import { CustomFileinput } from '../FileInput/CustomFileinput';
import FormDatePicker from '../FormDatePicker/FormDatePicker';
import { FormInput } from '../FormInput/FormInput';
import FormSelect from '../FormSelect/FormSelect';
import ConfirmationModal from '../modals/PopupModal';

const relationshipOptions = [{label: 'AUNT', value: 1}, {label: 'BROTHER-IN-LAW', value: 2}, {label: 'BROTHER', value: 3}, {label: 'DAUGHTER', value: 4}, {label: 'DAUGHTER-IN-LAW', value: 5}, {label: 'FATHER', value: 6}, {label: 'FATHER-IN-LAW', value: 7}, {label: 'GRAND DAUGHTER', value: 8}, {label: 'GRAND FATHER', value: 9}, {label: 'GRAND MOTHER', value: 10}, {label: 'GRAND SON', value: 11}, {label: 'MOTHER-IN-LAW', value: 12}, {label: 'MOTHER', value: 13}, {label: 'NEPHEW', value: 14}, {label: 'NIECE', value: 15}, {label: 'SISTER', value: 16}, {label: 'SISTER-IN-LAW', value: 17}, {label: 'SON', value: 18}, {label: 'SON-IN-LAW', value: 19}, {label: 'SPOUSE', value: 20}, {label: 'UNCLE', value: 21}, {label: 'OTHERS', value: 22}, {label: 'COURT APPOINTED LEGAL GUARDIAN', value: 23}];

interface Guardian {

}

interface Nominee {
    name?: string;
    mobileNumber?: string;
    email?: string;
    relation?: number;
    dateOfBirth?: string;
    percentage?: string;
    pancardNumber?: string;
    // nomineeAddress?: string;
    // pincode?: string;
    aadharNumber?: string;
    aadharMobileNumber?: string;
    isMinor?: boolean;
    // addressProof?:any;
    guardian?:{
        name?:string;
        // mobileNumber?:string;
        pancardNumber?:string;
        relation?:string
    }
}

interface AddNomineesProps {
    onSubmit?: any;
    data?: any;
    Border?: boolean;
    submitButton?: boolean;
    removeParent?: boolean;
    xs: number;
    initialNomineeCount: number;
    nominee?: any;

}

const calculateAge = (dateOfBirth: any): number => {
    const [day, month, year] = dateOfBirth.split('/').map(Number);
    const birthDate = new Date(year, month - 1, day);
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const AddNominees: React.FC<AddNomineesProps> = ({ onSubmit, data, Border, xs, initialNomineeCount, submitButton, removeParent, nominee }) => {
    const userdata = useUserData();
    const registeredNominees = userdata?.nominee;
    const kyc = userdata?.kyc;
    const [newNominees, setNewNominees] = useState<Nominee[]>([]);
    const dispatch = useDispatch<any>();
    const [errors, setErrors] = useState<{ [key: number]: { [field: string]: string } }>({});
    const [showGuardianDetails, setShowGuardianDetails] = useState<any>(false);
    // const [addressChange, setAddressChange] = useState<{ [key: number]: boolean }>({});
    const [nameDisabled, setNameDisabled] = useState(true);
    // const [addresspincode, setAddresspincode] = useState(true);
    const [existingNomineeDetails, setExistingNomineeDetails] = useState<boolean>();
    console.log(registeredNominees, 'nominee');
    
    useEffect(() => {
        const existingNomineeData = nominee || [];
        if ((newNominees.length === 0 && initialNomineeCount > 0) || existingNomineeData?.length > 0) {
            let initialNominees: Nominee[];

            if (existingNomineeData?.length > 0) {
                initialNominees = existingNomineeData.map((data: any) => ({
                    name: data.name,
                    mobileNumber: data.mobileNumber,
                    email: data.email,
                    relation: data.relation,
                    dateOfBirth: new Date(data.dateOfBirth).toISOString().split('T')[0], // Convert timestamp to YYYY-MM-DD
                    percentage: data.percentage,
                    // nomineeAddress: data.nomineeAddress,
                    // pincode: data.pincode,
                    aadharNumber: data.aadharNumber,
                    aadharMobileNumber: data.aadharMobileNumber,
                    isMinor: data.isMinor === 1,
                    guardian: JSON.parse(data.guardianDetails || '{}'),
                    pancardNumber: data.pancardNumber
                }));
                setExistingNomineeDetails(true)
            } else {
                initialNominees = Array.from({ length: initialNomineeCount }, () => ({}));
            }
            setNewNominees(initialNominees);
        }
    }, [initialNomineeCount]);

    

    // const address1 = userdata?.address1 || "";
    // const address2 = userdata?.address2 || "";
    // const address3 = userdata?.address3 || "";
    // const defaultAddress = `${address1} ${address2} ${address3}`.trim();
    // const defaultPincode = userdata?.pincode;

   const handleInputChange = async (
    index: number,
    e: { name: string; value: any; files?: FileList }
) => {
    const { name, value, files } = e;
    let inputValue = value;
    if (files && files[0]) {
        try {
            inputValue = await convertFileToBase64(files[0]);  // Ensure convertFileToBase64 returns a base64 string
        } catch (error) {
            console.error("File conversion error:", error);
            return;
        }
    }

    setNewNominees((prevNominees: any) => {
        const updatedNominees = prevNominees.map(
            (nominee: any, i: number) => {
                if (i === index) {
                    if (name === "guardianName") {
                        return {
                            ...nominee,
                            guardian: {
                                ...nominee.guardian,
                                name: inputValue,
                            },
                        };
                    } else if (name === "guardianMobileNumber") {
                        return {
                            ...nominee,
                            guardian: {
                                ...nominee.guardian,
                                mobileNumber: inputValue,
                            },
                        };
                    } else if (name === "guardianPanCard") {
                        return {
                            ...nominee,
                            guardian: {
                                ...nominee.guardian,
                                pancardNumber: inputValue,
                            },
                        };
                    } else if (name === "guardianRelationship") { // Handle the new relationship field
                        return {
                            ...nominee,
                            guardian: {
                                ...nominee.guardian,
                                relation: inputValue, // Update relationship value
                            },
                        };
                    } else {
                        return {
                            ...nominee,
                            [name]: inputValue,
                        };
                    }
                }
                return nominee;
            }
        );

        if (name === "dateOfBirth") {
            const age = calculateAge(updatedNominees[index].dateOfBirth);
            updatedNominees[index].isMinor = age < 18;
            setShowGuardianDetails((prevDetails: any) => ({
                ...prevDetails,
                [index]: age < 18,
            }));
        }

        return updatedNominees;
    });
};

    
    
    const handleRemoveNominee = (index: number) => {
        const updatedNominees = newNominees.filter((_, i) => i !== index);
        setNewNominees(updatedNominees);
        const newErrors = { ...errors };
        delete newErrors[index];
        setErrors(newErrors);

        setShowGuardianDetails((prevDetails: any) => {
            const { [index]: _, ...remainingDetails } = prevDetails;
            return remainingDetails;
        });
    };
      

    const validate = () => {
        const newErrors: { [key: number]: { [field: string]: string } } = {};
        const fullName = `${userdata.firstName}${userdata.lastName}`;

        const kycPancardNumbers: string[] = [];
        const kycAadhaarNumbers: string[] = [];

        if (userdata.kyc && typeof userdata.kyc === 'object') {
            const { pancardNumber, aadharNumber } = userdata.kyc;
            if (pancardNumber) kycPancardNumbers.push(pancardNumber);
            if (aadharNumber) kycAadhaarNumbers.push(aadharNumber);
        }

        const calculateTotalPercentage = (nominees: Nominee[]) => {
            return nominees.reduce((total, nominee) => {
                if (nominee?.percentage) {
                    const percentage = parseFloat(nominee.percentage.replace('%', ''));
                    if (!isNaN(percentage)) {
                        return total + percentage;
                    }
                }
                return total;
            }, 0);
        };
        if (!existingNomineeDetails) {
            const totalPercentageDistribution = calculateTotalPercentage(registeredNominees) + calculateTotalPercentage(newNominees);

            newNominees.forEach((nominee, index) => {
                const nomineeErrors: { [field: string]: string } = {};

                if (!nominee.name) {
                    nomineeErrors.name = 'Full Name is required';
                } else if (nominee.name === fullName) {
                    nomineeErrors.name = `Nominee Name should be different from User Name, ${fullName}`;
                }
                if (!nominee.dateOfBirth) {
                    nomineeErrors.dateOfBirth = 'Date Of Birth is required';
                }
                if (nominee.isMinor) {
                    if (!nominee.guardian?.name) {
                        nomineeErrors.guardianName = 'Guardian Name is required';
                    }
                }                

                if (nominee.percentage && totalPercentageDistribution !== 100) {
                    nomineeErrors.percentage = 'Total percentage should be 100%';
                }

                if (Object.keys(nomineeErrors).length > 0) {
                    newErrors[index] = nomineeErrors;
                }
            });

        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const handleSaveAndProceed = () => {
        if (existingNomineeDetails) {
            if (onSubmit) {
                onSubmit()
            }
        } else {
            if (validate()) {
                setIsModalVisible(true);
            }
        }
    };

    const handleModalConfirm = async () => {
        setIsModalVisible(false);

        try {
            const NewNomineeData = {
                nominee: newNominees.map(nominee => ({
                    ...nominee,
                    guardian: {
                        name: nominee.guardian?.name || '',
                        // mobileNumber: nominee?.guardian?.mobileNumber || '',
                        pancardNumber:nominee?.guardian?.pancardNumber || '',
                        relation:nominee?.guardian?.relation || ''
                    },
                }))
            };
            console.log(NewNomineeData,"NewNomineeData");
            const response = await dispatch(updateProfile(NewNomineeData));
            if (response.payload?.success === 200) {
                toast.success('Nominee added successfully');
                dispatch(addNomineeByV2(NewNomineeData))
                const fetchUserPreferences = async () => {
                    try {
                        const response = await dispatch(getUserById({}));
                        const userData = response.payload.data[0];
                        setExistingNomineeDetails(userData?.nominee);
                        console.log(userData?.nominee,"...1...");
                    } catch (error) {
                        console.error('Failed to fetch user preferences:', error);
                    }
                };
        
                fetchUserPreferences();
                onSubmit();
                setNewNominees([]);
                return ({ success: true })
            } else if (response.payload?.success === 400) {
                toast.error('Nominee already exists.');
                setNewNominees([]);
            } else {
                toast.error('Failed to add nominee. Please try again later.');
            }
        } catch (error) {
            console.error('Error adding nominee:', error);
            toast.error('Failed to add nominee. Please try again later.');
        }
    };

    const handleAddNominee = () => {
        if ((registeredNominees?.length + newNominees?.length) === 3) {
            toast.error(`You can add maximum 3 Nominee's`)
            return;
        }
        setNewNominees([
            ...newNominees,
            { 
                name: '', 
                relation: 0, 
                dateOfBirth: '', 
                percentage: '', 
                guardian: { name: '', pancardNumber: '', relation: '' } 
            }
        ]);
        
        // setNewNominees([...newNominees, { pincode: defaultPincode }]);
    };

    const handleNameDisabled = () => {
        setNameDisabled(!nameDisabled);
    }

    // const handlePincodeDisabled = () => {
    //     setAddresspincode(!addresspincode);
    // }

    const calAge = (timestamp: any) => {
        const birthDate = new Date(timestamp);
        const ageDifMs = Date.now() - birthDate.getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };


    return (
        <div className='w-full'>
            <div className=''>
                {newNominees.map((nominee, index) => (
                    <Box key={index} className={`${Border ? 'border border-lightGrey rounded-lg p-4 mb-4 ' : ''} mt-4`}>
                        <div className='flex justify-between items-center mb-4'>
                            <Typography variant='h6' className='my-6 pb-4' style={{color: colors.primary, fontWeight: 'bold'}}>{existingNomineeDetails ? `Nominee ${index + 1}` : ''}</Typography>
                            {((newNominees.length > 1 || removeParent) && !existingNomineeDetails) &&
                                <span onClick={() => handleRemoveNominee(index)} className='cursor-pointer'>
                                    <CloseIcon sx={{ color: '#ff0000', backgroundColor: colors.white, borderRadius: '40px', width: '2rem', height: '2rem', }} />
                                </span>}

                        </div>
                        <Grid container spacing={2} className='flex justify-between items-center'>
                            <Grid item xs={xs}>
                                <FormInput
                                    label="Full Name *"
                                    name="name"
                                    value={nominee.name}
                                    onChange={(e: any) => handleInputChange(index, e.target)}
                                    error={errors[index]?.name}
                                    touched={!!errors[index]?.name}
                                    placeholder='Full Name'
                                    className='mb-4'
                                    required
                                    disabled={existingNomineeDetails}
                                />
                            </Grid>
                
                            <Grid item xs={xs}>
                                <FormSelect
                                    label="Relationship *"
                                    name="relation"
                                    value={nominee.relation}
                                    onChange={(e: any) => handleInputChange(index, e.target)}
                                    options={relationshipOptions}
                                    className='mb-4'
                                    disabled={existingNomineeDetails}
                                />
                            </Grid>
                            <Grid item xs={xs}>
                                <FormDatePicker
                                    label="Date of Birth *"
                                    name="dateOfBirth"
                                    onChange={(date) => {
                                        handleInputChange(index, { name: 'dateOfBirth', value: date ? date.format("DD/MM/YYYY") : '' });
                                    }}
                                    value={
                                        nominee.dateOfBirth
                                            ? dayjs(nominee.dateOfBirth) // Convert the timestamp to a dayjs object
                                            : null
                                    }
                                    inputFormat="DD/MM/YYYY"
                                    defaultValue={dayjs(new Date())}
                                    className="mb-4"
                                    error={errors[index]?.dateOfBirth}
                                    touched={!!errors[index]?.dateOfBirth}
                                    disabled={existingNomineeDetails}
                                />

                            </Grid>
                            <Grid item xs={xs}>
                                <FormInput
                                    label="Share (in percentage) *"
                                    name="percentage"
                                    value={nominee.percentage}
                                    onChange={(e: any) => handleInputChange(index, e.target)}
                                    error={errors[index]?.percentage}
                                    touched={!!errors[index]?.percentage}
                                    type='number'
                                    placeholder='Enter percentage'
                                    className='mb-4'
                                    disabled={existingNomineeDetails}
                                />
                            </Grid>
                               {calAge(nominee.dateOfBirth) < 18 && ( // Calculate age and check if less than 18
                                <>
                                    <Grid item xs={xs}>
                                        <FormInput
                                            label="Guardian Name *"
                                            name={`nominees[${index}].guardian.name`} // Adjust the name prop
                                            value={nominee.guardian?.name || ""}
                                            onChange={(e: any) => handleInputChange(index, { name: 'guardianName', value: e.target.value })}
                                            error={errors[index]?.guardianName}
                                            touched={!!errors[index]?.guardianName}
                                            className='mb-4'
                                            disabled={existingNomineeDetails}
                                        />
                                    </Grid>
                                    <Grid item xs={xs}>
                                    <FormInput
                                        label="Guardian PAN Card Number *"
                                        name={`nominees[${index}].guardian.panCard`} // Adjust the name prop
                                        value={nominee.guardian?.pancardNumber || ""}
                                        onChange={(e: any) => handleInputChange(index, { name: 'guardianPanCard', value: e.target.value.toUpperCase() })}
                                        error={errors[index]?.guardianPanCard}
                                        touched={!!errors[index]?.guardianPanCard}
                                        className='mb-4'
                                        disabled={existingNomineeDetails}
                                    />
                                </Grid>
                                <Grid item xs={xs}>
                                    <FormSelect
                                        label="Guardian Relationship *"
                                        name={`nominees[${index}].guardian.relation`} // Adjust the name prop
                                        value={nominee.guardian?.relation || ""} // Ensure value is handled correctly
                                        onChange={(e: any) => handleInputChange(index, { name: 'guardianRelationship', value: e.target.value })}
                                        options={relationshipOptions}
                                        className='mb-4'
                                        disabled={existingNomineeDetails}
                                    />
                                </Grid>
                            </>
                        )}
                            <Grid item xs={xs} className='flex items-center gap-x-4 justify-end'>
                                {(newNominees && newNominees.length > 0 && submitButton) &&
                                    <div className='flex justify-center items-center gap-x-2 bg-white p-1 rounded-2xl cursor-pointer' onClick={handleSaveAndProceed}>
                                        <DoneIcon sx={{ color: colors.white, backgroundColor: colors.tertiary, borderRadius: '40px', width: '2rem', height: '2rem', }} />
                                        <span className='text-secondary' >Submit</span>
                                    </div>}
                            </Grid>
                        </Grid>
                        <div className='flex justify-between items-center mt-4'>
                        </div>
                    </Box>
                ))}
            </div>
            {((registeredNominees?.length + newNominees?.length) < 3) && 
                (registeredNominees?.length + newNominees?.length > 0 && !existingNomineeDetails) &&
                <div>
                    <span
                        className='flex items-center gap-x-2 font-bold underline underline-offset-4 text-secondary cursor-pointer'
                        onClick={handleAddNominee}
                    >
                        <img
                            src={add}
                            alt='add nominee'
                        />
                        Add another Nominee
                    </span>
                </div>
            }
            {!submitButton && (newNominees && newNominees.length > 0) &&
                <Button
                    type="button"
                    className={`mt-8 grow justify-center px-8 py-4 rounded-md border-3 border-darkGrey border-solid bg-darkGrey text-white hover:bg-primary transition-all`}
                    tabIndex={0}
                    onClick={handleSaveAndProceed}
                >
                    Submit
                </Button>}

            <ConfirmationModal
                isVisible={isModalVisible} // Render conditionally based on state
                message="Are you sure all fields are correct and verified?"
                onConfirm={handleModalConfirm} // Confirm action and submit form
                onCancel={handleModalCancel} // Cancel action and close modal
            />
        </div>
    );
};

export default AddNominees;
