import React, { useState } from "react";
import { colors } from "../../constants/colors";
import { useUserData } from "../../hooks/useUserData";
import InvestmentPage from "./InvestmentPage";
import { RxCross2 } from "react-icons/rx";

interface MainProps {
  fundId?: number;
  fundInfo?: any;
  onClose?: () => void;
}

const Main: React.FC<MainProps> = ({ fundId = 0, fundInfo, onClose }) => {
  const userData = useUserData();
  console.log("fundInfo", fundInfo);

  return (
    <div className="bg-lightBg pt-10 pb-10">
      <div className="w-full relative">
      <div className="absolute right-0 top-0 text-3xl pl-10 font-bolder mr-4 "
              style={{ color: colors.primary }}>
          <RxCross2 className="cursor-pointer" onClick={onClose} />
          </div>
        <div className="flex justify-start w-auto">
          <div className="relative">
            <h6
              className="text-3xl pl-10 font-medium"
              style={{ color: colors.primary }}
            >
              Transact Now
            </h6>
            <h6
              className="text-lg pl-10 mt-5 font-medium"
              style={{ color: colors.textGrey }}
            >
              Client Code: {userData?.kyc?.pancardNumber}
            </h6>
          </div>
        </div>
        <div>          
            <InvestmentPage
              fundId={fundId}
              fundInfo={fundInfo}
              onClose={onClose}
            />
        </div>
      </div>
    </div>
  );
};

export default Main;
