import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { colors } from '../../constants/colors';

interface EditModalProps {
    open: boolean;
    type: 'phone' | 'email' | 'text' | 'address'; // Added 'address' type
    value: string;
    onConfirm: (newValue: string) => void;
    onClose: () => void;
}

const EditModal: React.FC<EditModalProps> = ({ open, type, value, onConfirm, onClose }) => {
    const [newValue, setNewValue] = useState('');

    useEffect(() => {
        if (open) {
            setNewValue(value); // Reset the input value when the modal opens with current value
        }
    }, [open, value]);

    const handleConfirm = () => {
        if (type === 'phone') {
            if (newValue.trim() && /^\d{10}$/.test(newValue)) {
                onConfirm(newValue);
            } else {
                alert('Please enter a valid 10-digit phone number.');
            }
        } else if (type === 'email') {
            if (newValue.trim() && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newValue)) {
                onConfirm(newValue);
            } else {
                alert('Please enter a valid email address.');
            }
        } else if ( type === 'address') { // Handle address input similarly to text
            if (newValue.trim()) {
                onConfirm(newValue);
            } else {
                alert('Please enter a valid text.');
            }
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
                // Reset value when closing modal
                setNewValue('');
            }}
            aria-labelledby="edit-modal-title"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} // Center Modal
        >
            <Box
                sx={{
                    bgcolor: colors.white,
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    width: 600,
                    maxWidth: '90%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography id="edit-modal-title" variant="h6" component="h2" sx={{ color: colors.primary, fontWeight: 'bold' }}>
                    Edit {type === 'phone' ? 'Phone Number' : type === 'email' ? 'Email' : type === 'address' ? 'Address' : 'Text'}
                </Typography>
                <Typography sx={{ mt: 2, color: colors.textGrey, textAlign: 'center' }}>
                    Are you sure you want to edit the below {type === 'phone' ? 'phone number' : type === 'email' ? 'email' : type === 'address' ? 'address' : 'text'}?
                </Typography>
                <Typography sx={{ mt: 1, color: colors.primary, fontWeight: 'bold', textAlign: 'center' }}>
                    {type === 'phone' ? `+91 ${value.replace(/.(?=.{4,}$)/g, '*')}` : value}
                </Typography>
                <Typography sx={{ mt: 3, color: colors.textGrey, textAlign: 'center' }}>
                    Enter new {type === 'phone' ? 'phone number' : type === 'email' ? 'email' : type === 'address' ? 'address' : 'text'}
                </Typography>

                <input
                    type={type === 'phone' ? 'number' : type === 'email' ? 'email' : 'text'} // Use 'text' for address
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                    placeholder={`Enter new ${type === 'phone' ? 'phone number' : type === 'email' ? 'email' : type === 'address' ? 'Address' : 'text'}`}
                    style={{
                        width: '100%',
                        padding: '10px',
                        margin: '10px 0',
                        borderColor: colors.grey1,
                        borderRadius: '4px',
                        borderWidth: '1px',
                        outline: 'none',
                        fontSize: '16px',
                        boxSizing: 'border-box',
                    }}
                    maxLength={type === 'phone' ? 10 : undefined} // Limit length for phone numbers
                />

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
                    <Button
                        variant="contained"
                        sx={{
                            bgcolor: colors.secondary,
                            '&:hover': {
                                bgcolor: colors.orange,
                            },
                        }}
                        onClick={() => {
                            onClose();
                            // Reset value when closing modal
                            setNewValue('');
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            bgcolor: colors.primary,
                            '&:hover': {
                                bgcolor: colors.primary,
                            },
                        }}
                        onClick={handleConfirm}
                    >
                        Confirm
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditModal;
