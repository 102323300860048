import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import eSign from "./eSignService";

export const eSign_kyc = createAsyncThunk(
  "esign/requestESign",
  async (_, thunkAPI) => {
    try {
      console.log("ESign API called by Slice");
      const response = await eSign.EsignKyc();
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const uploadVideo = createAsyncThunk(
  "esign/uploadVideo",
  async (data: FormData, thunkAPI) => {
    try {
      const response = await eSign.uploadVideo(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const uploadAufForm = createAsyncThunk(
  "esign/uploadAufForm",
  async (data: FormData, thunkAPI) => {
    try {
      const response = await eSign.uploadAufForm(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const initialState = {
  esign_status: {},
  data: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  errorMessage: {},
};

export const EsignSlice = createSlice({
  name: "Esign",
  initialState: initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(eSign_kyc.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(eSign_kyc.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.esign_status = action.payload;
        state.message = ""; // Reset message
      })
      .addCase(eSign_kyc.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.esign_status = {};
        state.errorMessage = action.payload as JSON;
      })
      .addCase(uploadVideo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadVideo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload;
        state.message = ""; // Reset message
      })
      .addCase(uploadVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload as JSON;
      })
      .addCase(uploadAufForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadAufForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload;
        state.message = ""; // Reset message
      })
      .addCase(uploadAufForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload as JSON;
      });
  },
});

export const { resetState } = EsignSlice.actions;
export default EsignSlice.reducer;
