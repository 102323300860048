import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FDService from './FdServics';
import FDmodel from '../../models/FD.model';

export const getFDinfo = createAsyncThunk(
    "fd/getAllV2",
    async (data: any, thunkAPI: any) => {
        try {
            if (!data) {
                throw new Error("invalid Parameter provided");
            }
            const response = await FDService.getFDsInfo(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const getFDById = createAsyncThunk(
    "fd/getById",
    async (data: any, thunkAPI) => {
        try {
            if (!data) {
                throw new Error("Invalid parameter provided");
            }
            const response = await FDService.getFDById(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const getFDSchemeInfo = createAsyncThunk(
    "fd/getSchemeInfo",
    async (data: any, thunkAPI) => {
        try {
            if (!data) {
                throw new Error("Invalid parameter provided");
            }
            const response = await FDService.getFDSchemeInfo(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const getFDTransactions = createAsyncThunk(
    "fd/getFDTransactions",
    async (data: any, thunkAPI) => {
        try {
            if (!data) {
                throw new Error("Invalid parameter provided");
            }
            const response = await FDService.getFDTransactions(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const getFDWithdraw = createAsyncThunk(
    "fd/withdraw",
    async (data: any, thunkAPI) => {
        try {
            if (!data) {
                throw new Error("Invalid parameter provided");
            }
            const response = await FDService.FDWithdraw(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const getFDPrinciple = createAsyncThunk(
    "fd/getPrincipal",
    async (data: any, thunkAPI) => {
        try {
            if (!data) {
                throw new Error("Invalid parameter provided");
            }
            const response = await FDService.FDPrincipleAmount(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const getFDTenure = createAsyncThunk(
    "fd/getTenures",
    async (data: any, thunkAPI) => {
        try {
            if (!data) {
                throw new Error("Invalid parameter provided");
            }
            const response = await FDService.FDTenure(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const getFDInterestRate = createAsyncThunk(
    "fd/getInterestRate",
    async (data: any, thunkAPI) => {
        try {
            if (!data) {
                throw new Error("Invalid parameter provided");
            }
            const response = await FDService.FDInterestRate(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const RequestQuote = createAsyncThunk(
    "fd/invest",
    async (data: any, thunkAPI) => {
        try {
            if (!data) {
                throw new Error("Invalid parameter provided");
            }
            const response = await FDService.ReqFDQuote(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);


const initialState = {
    FDfunds: [],
    fundinfo: [{ 'name': 'fundInfo' }],
    FDDetails: [],
    ReqQuote: [],
    Tenure: [],
    Principle: [],
    FDInterest: [],
    FDSchemeInfo: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
};

export const FDSlice = createSlice({
    name: "FDFund",
    initialState: initialState,
    reducers: {
        resetState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFDinfo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFDinfo.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.FDfunds = action.payload;
            })
            .addCase(getFDinfo.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(getFDById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFDById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.FDDetails = action.payload;
            })
            .addCase(getFDById.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(RequestQuote.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(RequestQuote.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.ReqQuote = action.payload.data;
            })
            .addCase(RequestQuote.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(getFDPrinciple.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFDPrinciple.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.Principle = action.payload.data;
            })
            .addCase(getFDPrinciple.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(getFDTenure.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFDTenure.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.Tenure = action.payload.data;
            })
            .addCase(getFDTenure.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(getFDInterestRate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFDInterestRate.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.FDInterest = action.payload.data;
            })
            .addCase(getFDInterestRate.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(getFDSchemeInfo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFDSchemeInfo.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.FDSchemeInfo = action.payload;
            })
            .addCase(getFDSchemeInfo.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(getFDTransactions.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFDTransactions.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.FDSchemeInfo = action.payload;
            })
            .addCase(getFDTransactions.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(getFDWithdraw.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFDWithdraw.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.FDSchemeInfo = action.payload.data;
            })
            .addCase(getFDWithdraw.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
    },
});

export const { resetState } = FDSlice.actions;
export default FDSlice.reducer;
