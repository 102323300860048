import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoginModel } from "../../models/login.model";
import userService from "./userService";
import { User } from "../../models/user.model";

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (data: User, thunkAPI: any) => {
    try {
      return await userService.updateUser(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addPreferences = createAsyncThunk(
  "user/addPreferences",
  async (data: any, thunkAPI: any) => {
    try {
      return await userService.addPreferences(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addSignature = createAsyncThunk(
  "user/addSignature",
  async (data: any, thunkAPI: any) => {
    try {
      return await userService.addSignature(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getUserById = createAsyncThunk(
  "user/getUserById",
  async (data: any, thunkAPI: any) => {
    try {
      return await userService.getUserById();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const showInterest = createAsyncThunk(
  "user/interest",
  async (data: any, thunkAPI: any) => {
    try {
      return await userService.showInterest(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getRMUser = createAsyncThunk(
  "admin/getRMUser",
  async (data: any, thunkAPI: any) => {
    try {
      return await userService.getRMUser(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getImpersonateToken = createAsyncThunk(
  "admin/getImpersonateToken",
  async (data: any, thunkAPI: any) => {
    try {
      return await userService.getImpersonateToken(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const loginAsUser = createAsyncThunk(
  "admin/loginAsUser",
  async (data: any, thunkAPI: any) => {
    try {
      return await userService.loginAsUser(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const sendResetPasswordLink = createAsyncThunk(
  "user/sendResetPasswordLink",
  async (data: any, thunkAPI: any) => {
    try {
      return await userService.sendResetPasswordLink(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (data: any, thunkAPI: any) => {
    try {
      const { token } = data;
      return await userService.resetPassword(
        { password: data.password },
        token
      );
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAUFForm = createAsyncThunk(
  "user/getAUFForm",
  async (_, thunkAPI: any) => {
    try {
      return await userService.getAUFForm();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const downloadAUFForm = createAsyncThunk(
  "user/downloadAUFForm",
  async (_, thunkAPI: any) => {
    try {
      return await userService.downloadAUFForm();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const createBSEUser = createAsyncThunk(
  "user/createBSEUser",
  async (_, thunkAPI: any) => {
    try {
      return await userService.createBSEUser();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const verifyBseOTP = createAsyncThunk(
  "user/verifyBseOTP",
  async (data: any, thunkAPI: any) => {
    try {
      return await userService.verifyBseOTP(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const bseNomineeAuth = createAsyncThunk(
  "user/bseNomineeAuth",
  async (data: any, thunkAPI: any) => {
    try {
      return await userService.bseNomineeAuth(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const chatBot = createAsyncThunk(
  "user/chat",
  async (data: any, thunkAPI: any) => {
    try {
      console.log('message on Chatbot api', data);
      return await userService.chatBotServices(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "/user/changePassword",
  async (data: any, thunkAPI: any) => {
    try {
      return await userService.updatePassword(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "/user/requestForUpdate",
  async (data: any, thunkAPI: any) => {
    try {
      return await userService.updateProfile(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  user: {},
  data: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const authSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.user = { ...state.user, ...action.payload.data };
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getUserById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.user = action.payload.data[0];
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getRMUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRMUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.user = action.payload.data[0];
      })
      .addCase(getRMUser.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(showInterest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(showInterest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.user = action.payload.data[0];
      })
      .addCase(showInterest.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(loginAsUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginAsUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.user = action.payload.data[0];
      })
      .addCase(loginAsUser.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getImpersonateToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getImpersonateToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.user = action.payload.data[0];
      })
      .addCase(getImpersonateToken.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(sendResetPasswordLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendResetPasswordLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.user = action.payload.data;
      })
      .addCase(sendResetPasswordLink.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.user = action.payload.data;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(addPreferences.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPreferences.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(addPreferences.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(addSignature.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addSignature.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(addSignature.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getAUFForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAUFForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(getAUFForm.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(downloadAUFForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(downloadAUFForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(downloadAUFForm.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(createBSEUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createBSEUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(createBSEUser.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      }).addCase(verifyBseOTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyBseOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(verifyBseOTP.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(bseNomineeAuth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(bseNomineeAuth.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(bseNomineeAuth.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
  },
});

export const { resetState } = authSlice.actions;
export default authSlice.reducer;
