import PropTypes from 'prop-types';
import styles from './StepIndicator.module.css';

const StepIndicator = ({ stepNumber, description, isActive }: { stepNumber: string, description: string, isActive: boolean }) => (
  <div className='stepind md:flex md:flex-col '>
    <div className={`grid sm:grid-cols-4 md:grid-cols-2`}>
      <div
        className={`flex  px-6 text-3xl font-semibold rounded-full aspect-square ${isActive ? styles.activeStep : styles.inactiveStep} ${styles.stepNumber} md:w-24 md:h-24 md:text-4xl`}
      >
        {stepNumber}
      </div>
      <div className={`absolute ml-16 top-0 left-0 right-0 bottom-0 z-10 flex items-center px-6 ${styles.description} ${isActive ? styles.activeDescription : styles.inactiveDescription} text-center md:text-left md:ml-0 sm:ml-5`}>
        {description}
      </div>
    </div>
  </div>
);

StepIndicator.propTypes = {
  stepNumber: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export { StepIndicator };
