// Main.tsx

import React, { useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Img, Text } from "../../components/index";
import Header from "../../components/Header/Header";
import Footer from "../Footer/Footer";

import Lumpsum from "../MFTransaction/Lumpsum";
import Redeem from "../MFTransaction/Redeem";
import Switch from "../MFTransaction/Switch";
import SIP from "../MFTransaction/SIP";
import STP from "../MFTransaction/STP";
import SWP from "../MFTransaction/SWP";
import { colors } from "../../constants/colors";
import { useUserData } from "../../hooks/useUserData";
import MfInfo from "../../components/MFInfoSection/MFInfo";
import { RxCross2 } from "react-icons/rx";

interface MainProps {
  fundId?: number;
  fundInfo?: any;
  onClose?: () => void;
}

const Main: React.FC<MainProps> = ({ fundId = 0, fundInfo, onClose }) => {
  const [activeSection, setActiveSection] = useState("lumpsum");
  const userData = useUserData();

  const isDisabled = !fundInfo?.totalUnits && !fundInfo?.currentValue;

  const handleLumpsum = () => {
    setActiveSection("lumpsum");
  };

  const handleRedeem = () => {
    if (!isDisabled) {
    setActiveSection("redeem");
    }
  };

  const handleSwitch = () => {
    if (!isDisabled) {
      setActiveSection("switch");
    }
  };

  const handleSIP = () => {
    setActiveSection("sip");
  };

  const handleSTP = () => {
    if (!isDisabled) {
      setActiveSection("stp");
    }
  };

  const handleSWP = () => {
    if (!isDisabled) {
      setActiveSection("swp");
    }
  };

  console.log("fundInfo", fundInfo);

  return (
    <div className="bg-lightBg pt-10 pb-10  ">
      <div className="w-full relative">
      <div className="absolute right-0 top-0 text-3xl pl-10 font-bolder mr-4 "
              style={{ color: colors.primary }}>
          <RxCross2 className="cursor-pointer" onClick={onClose} />
          </div>
        <div className="flex justify-start">
          <div className="relative">
            <h6
              className="text-3xl pl-10 font-medium"
              style={{ color: colors.primary }}
            >
              Transact Now
            </h6>
            <h6
              className="text-lg pl-10 mt-5 font-medium"
              style={{ color: colors.textGrey }}
            >
              Client Code: {userData?.kyc?.pancardNumber}
            </h6>
          </div>
        </div>
        <div className="flex flex-wrap justify-center gap-5 md:gap-6 pt-10">
          <MfInfo onClick={handleLumpsum} title="Lumpsum" isActive={activeSection === "lumpsum"} />
          <MfInfo onClick={handleRedeem} title="Redeem" isActive={activeSection === "redeem"} style={{
              cursor: isDisabled ? "not-allowed" : "pointer",
              opacity: isDisabled ? 0.5 : 1,
            }} />
          <MfInfo onClick={handleSwitch} title="Switch" isActive={activeSection === "switch"} style={{
              cursor: isDisabled ? "not-allowed" : "pointer",
              opacity: isDisabled ? 0.5 : 1,
            }} />
          <MfInfo onClick={handleSIP} title="SIP" isActive={activeSection === "sip"} />
          <MfInfo onClick={handleSTP} title="STP" isActive={activeSection === "stp"} style={{
              cursor: isDisabled ? "not-allowed" : "pointer",
              opacity: isDisabled ? 0.5 : 1,
            }} />
          <MfInfo onClick={handleSWP} title="SWP" isActive={activeSection === "swp"} style={{
              cursor: isDisabled ? "not-allowed" : "pointer",
              opacity: isDisabled ? 0.5 : 1,
            }} />
        </div>
        <div>
          {activeSection === "lumpsum" && (
            <Lumpsum
              fundId={fundId}
              activeSection={activeSection}
              fundInfo={fundInfo}
              onClose={onClose}
            />
          )}

          {activeSection === "redeem" && (
            <Redeem
              activeSection={activeSection}
              fundInfo={fundInfo}
              onClose={onClose}
            />
          )}

          {fundInfo?.switchFlag === "Y" && activeSection === "switch" && (
            <Switch
              activeSection={activeSection}
              fundInfo={fundInfo}
              onClose={onClose}
            />
          )}

          {fundInfo?.sipFlag === "Y" && activeSection === "sip" && (
            <SIP
              fundId={fundId}
              activeSection={activeSection}
              fundInfo={fundInfo}
              onClose={onClose}
            />
          )}
          {fundInfo?.stpFlag === "Y" && activeSection === "stp" && (
            <STP
              fundId={fundId}
              activeSection={activeSection}
              fundInfo={fundInfo}
              onClose={onClose}
            />
          )}

          {fundInfo?.swpFlag === "Y" && activeSection === "swp" && (
            <SWP
              fundId={fundId}
              onClose={onClose}
              activeSection={activeSection}
              fundInfo={fundInfo}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Main;
