import React from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import Main from '../PMSTransaction/main';
import { colors } from '../../constants/colors';

// const scrollbarStyle = `
//   /* width */
//   ::-webkit-scrollbar {
//     width: 3px;
//   }

//   /* Track */
//   ::-webkit-scrollbar-track {
//     background: #f1f1f1; 
//   }
 
//   /* Handle */
//   ::-webkit-scrollbar-thumb {
//     background: #888; 
//   }

//   /* Handle on hover */
//   ::-webkit-scrollbar-thumb:hover {
//     background: #555; 
//   }
// `;

interface InvestModalPMSProps {
  open: boolean;
  onClose: () => void;
  fundInfo: any;
}

const InvestModalPMS: React.FC<InvestModalPMSProps> = ({ open, onClose, fundInfo }) => {
  console.log('fundinfo MF');
  
  const fundId = fundInfo.id;
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: '8px',
          width: '80%', // Adjusted width to fill most of the screen
          maxHeight: '90%', // Adjusted maxHeight to allow some space at the bottom for the close button
          overflow: 'auto', // Use overflow instead of overflowY
          display: 'flex',
          flexDirection: 'column',
          '&::-webkit-scrollbar': {
            width: '12px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '&::-webkit-scrollbar-thumb': {
            background: colors.primary,
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }}
      >
        {/* <style>{scrollbarStyle}</style> */}
        <Main fundId={fundId} fundInfo={fundInfo} onClose={onClose}/>
      </Box>
    </Modal>
  );
};

export default InvestModalPMS;
