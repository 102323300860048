import React, { useEffect, useState } from "react";
import { Text, Button, Heading, Img } from "../../components";
import Header from "../../components/Header/Header";
import { CorporateFare, PersonOutline } from "@mui/icons-material";
import { colors } from "../../constants/colors";
import Footer from "../../components/Footer/Footer";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const entities = [
  {
    icon: PersonOutline,
    name: "Individual or Sole Proprietorship",
    description:
      "For Independent Financial Advisors, Registered Product Distributors, Insurance Agents who are running the business in individual capacity",
  },
  {
    icon: CorporateFare,
    name: "Non Individual",
    description: "For Banks, Wealth Firms, Advisor Firms.",
  },
];

export default function EntityPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [focusedDiv, setFocusedDiv] = useState<number>(0);

  const handleFocus = (index: number) => {
    setFocusedDiv(index);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center w-full bg-white-A700">
        <div className="flex flex-col items-center justify-start w-full px-5 py-8 md:py-20  bg-primary shadow-xs min-h-screen">
          <div className="flex flex-col items-center justify-center gap-6">
            <Heading as="h1" className="text-white text-lg md:text-3xl">
              Entity Type
            </Heading>
            <Text size="md" as="p" className=" text-white text-sm md:text-lg">
              Select entity type to help us better understand
            </Text>
            <div className="flex flex-col md:flex-row items-center justify-center md:items-start md:justify-start gap-3 md:gap-[42px]">
              {entities.map((entity: any, index: number) => (
                <div
                  className={`flex flex-row w-full min-h-[150px] md:min-h-[250px] max-w-[500px] items-center justify-start gap-16  px-6 border border-solid cursor-pointer bg-white rounded-lg ${
                    focusedDiv === index
                      ? "border-deep_orange-500 border-4"
                      : ""
                  }`}
                  onClick={() => handleFocus(index)}
                  tabIndex={index}
                >
                  <div className="flex flex-col items-center justify-center h-[50px] w-[50px] md:h-[100px] md:w-[100px]">
                    <div className="flex flex-col items-center justify-center h-[50px] w-[50px] md:h-[100px] md:w-[100px] p-6 bg-orange rounded-[50%]">
                      <entity.icon
                        sx={{
                          fontSize: isLargeScreen ? 64 : 32,
                          color: colors.white,
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <Heading
                      size="s"
                      as="h2"
                      className="md:w-[77%] md:mt-[22px] text-center text-sm md:text-xl"
                    >
                      {entity.name}
                    </Heading>
                    <Text
                      as="p"
                      className="md:mt-[9px] mb-[17px] !text-gray-800 text-center text-xs md:text-base"
                    >
                      {entity.description}
                    </Text>
                  </div>
                </div>
              ))}
              {/* <div
                className={`flex flex-col items-center justify-start p-2 gap-2 border border-solid w-full md:w-[350px] md:h-[350px] cursor-pointer bg-white rounded-lg ${
                  focusedDiv === 1 ? "border-deep_orange-500 border-4" : ""
                }`}
                onClick={() => handleFocus(1)}
                tabIndex={0}
              >
                <div className="flex flex-col items-center justify-start h-[50px] w-[50px] md:h-[100px] md:w-[100px] mt-9">
                  <div className="flex flex-col items-center justify-center h-[50px] w-[50px] md:h-[100px] md:w-[100px] p-6 bg-orange rounded-[50%]">
                    <CorporateFare
                      sx={{
                        fontSize: isLargeScreen ? 64 : 32,
                        color: colors.white,
                      }}
                    />
                  </div>
                </div>
                <Heading
                  size="s"
                  as="h2"
                  className="md:w-[77%] md:mt-[22px] text-center text-sm md:text-xl"
                >
                  Non Individual
                </Heading>
                <Text
                  as="p"
                  className="md:mt-[9px] mb-[17px] !text-gray-800 text-center text-xs md:text-base"
                >
                  For Banks, Wealth Firms, Advisor Firms.
                </Text>
              </div> */}
            </div>
            <div className="flex flex-row justify-start gap-6">
              {/* <Button
                color="white"
                className="mt-px text-white font-semibold border-white border border-solid"
                onClick={() => navigate("/onboarding")}
              >
                <span className="text-sm md:text-lg">Skip</span>
              </Button> */}
              <Button
                className="font-semibold text-white bg-darkGrey border-white border border-solid mt-8"
                color={colors.darkGrey}
                disabled={focusedDiv !== 0 && focusedDiv !== 1}
                onClick={() =>
                  focusedDiv === 0
                    ? navigate("/onboarding")
                    : navigate("/nonIndividual")
                }
              >
                <span className="text-sm md:text-lg">Save & Proceed</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
