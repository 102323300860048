import React, { useState } from "react";
import InfoSection from "../../../components/InfoSection/InfoSection";



import person from "../../../assets/icons/f7_person-2-normal.png";
import person24 from "../../../assets/icons/octicon_person-24 grey (1).png";
import bankDetails from "../../../assets/icons/ph_bank_normal.png";
import demat from "../../../assets/icons/jam_document_normal.png";
import preference from "../../../assets/icons/grommet-icons_checkbox-selectedblue.png";
import sign from "../../../assets/icons/la_file-signature blue.png";

import { colors } from "../../../constants/colors";

import { useDispatch } from "react-redux";
import { addPreferences } from "../../../redux/user/userSlice";
import ConfirmationModal from "../../../components/modals/PopupModal";

interface PreferenceDetailsProps {
  onPreferenceToPreferences2: () => void; // Prop to handle transition to Demat Details
  allowToProceed: () => void;
  activeSection:any

}

const Preferences: React.FC<PreferenceDetailsProps> = ({
  onPreferenceToPreferences2,
  allowToProceed,
}) => {
  const dispatch = useDispatch<any>();
  const [fileName, setFileName] = useState<string | null>(null);
  const [completedSections, setCompletedSections] = useState<boolean[]>(
    Array(6).fill(false)
  );

  const infoSections = [
    { icon: person, title: "General Details" },
    { icon: bankDetails, title: "Bank Details" },
    { icon: person, title: "Nominee Details" },
    { icon: demat, title: "Demat Details" },
    { icon: preference, title: "Preferences" },
    { icon: sign, title: "Signatures" },
  ];

  // const renderInfoSections = () => {
  //   return infoSections.map((section, index) => (
  //     <InfoSection
  //       key={index}
  //       icon={require(`../../assets/icons/${section.icon}.svg`)}
  //       title={section.title}
  //       isActive={index === 3} // Adjust the condition as needed
  //     />
  //   ));
  // };

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [investmentPlan, setInvestmentPlan] = useState<string>("Direct");

  const [isModalVisible, setIsModalVisible] = useState(false); // State for popup visibility



  const handleCheckboxChange = (option: string) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const renderCheckbox = (option: string) => (
    <label key={option} className="flex items-center ml-10">
      <input
        type="checkbox"
        checked={selectedOptions.includes(option)}
        onChange={() => handleCheckboxChange(option)}
        className={`mr-2 `}
      />
      {option}
    </label>
  );

  const renderRadio = (option: string) => (
    <label key={option} className="flex items-center ml-10">
      <input
        type="radio"
        checked={investmentPlan === option}
        onChange={() => setInvestmentPlan(option)}
        className={`mr-2 `}
      />
      {option}
    </label>
  );

  const onAllow = (): void => {
    // Mark the current section as completed
    const updatedSections = [...completedSections];
    updatedSections[5] = true;
    setCompletedSections(updatedSections);

    // Call the function to allow to proceed
    allowToProceed();
  };

  const onAddPreferences = () => {
    // Call the function to allow to proceed
    const payload = {
      mutualFund: selectedOptions.includes("Mutual Funds") ? 1 : 0,
      bond: selectedOptions.includes("Bonds") ? 1 : 0,
      alf: selectedOptions.includes("ALF") ? 1 : 0,
      pms: selectedOptions.includes("PMS") ? 1 : 0,
      isRegular: investmentPlan === "Regular" ? 1 : 0,
    };
    dispatch(addPreferences(payload));
    onPreferenceToPreferences2()
    setIsModalVisible(false);
  };


  const handleButtonClick = () => {
    setIsModalVisible(true); // Show the confirmation modal
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Close the modal without action
  };


  return (
    <>
      <main
        className="flex flex-col items-center pl-2 pr-2 sm:pl-5 mb-5"
        style={{ backgroundColor: colors.lightBg }}
      >
        <div className="mx-auto sm:w-full md:px-8 lg:px-14 mt-5">
          <div className="flex flex-col gap-1.5 items-center justify-center w-full md:w-auto md:items-center lg:items-start lg:ml-32">
            <div className="mb-10 md:pl-5 md:pr-5">
              <h1
                className="text-base pl-5 pr-5 md:text-xl"
                style={{ color: colors.darkGrey }}
              >
                What types of assets are you interested in investing in?
              </h1>
              <div className="flex flex-col md:flex-row text-start justify-start gap-2 text-sm md:text-lg md:mt-5 mt-5">
                {["Mutual Funds", "Bonds", "AIF", "PMS"].map(renderCheckbox)}
              </div>
            </div>
            <div className="mb-10 md:pl-5 md:pr-5">
              <h1
                className="text-base  mt-4 pl-5 pr-5 md:text-xl"
                style={{ color: colors.darkGrey }}
              >
                Which investment plan do you prefer for mutual funds?
              </h1>
              <div className="flex flex-col md:flex-row text-start justify-start text-sm md:text-lg gap-2 mt-5">
                {["Direct", "Regular"].map(renderRadio)}
              </div>
            </div>
          </div>
          <div className="ml-4 lg:ml-44 md:ml-32 mt-1 mb-10">
            <section className="mt-10 mb-5 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                    <div className="flex gap-5 justify-between  mt-12 text-sm max-md:mt-10">
                      {/* Enable/disable buttons based on radioSelected */}
                      <button
                        style={{
                          backgroundColor: colors.white,
                          color: colors.darkGrey,
                          border: `1px solid ${colors.darkGrey}`,
                        }}
                        className={`grow justify-center px-8 py-4 whitespace-nowrap rounded-md border border-solid `}
                        tabIndex={0}
                        onClick={() => {}} // Add onClick event handler // Disable button if radio is not selected
                      >
                        Skip
                      </button>
                      <button
                        style={{
                          backgroundColor: colors.darkGrey,
                          color: colors.white,
                        }}
                        type="button"
                        className={`grow  justify-center px-8 py-4 rounded-md border-3 border-${colors.darkGrey} border-solid `}
                        tabIndex={0}
                        onClick={() =>handleButtonClick()}// Add onClick event handler
                        // Disable button if radio is not selected
                      >
                        Save and Proceed
                      </button>
                      <ConfirmationModal
                      isVisible={isModalVisible} // Display the modal based on this state
                      message="Are you sure all fields are correct and verified? After you proceed to the next screen, fields cannot be edited again."
                      onConfirm={onAddPreferences} // Confirm action, which adds preferences and proceeds
                      onCancel={handleModalCancel} // Cancel action, just closes the modal
                    />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
};

export default Preferences;
