import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import AddNominees from '../../../components/Profile/AddNominees';
import { useUserData } from '../../../hooks/useUserData';

interface NomineeDetailsProps {
  label: string;
  name: string;
  onNomineeToDemat: () => void;
  allowToProceed: () => void;
  userData: any;
}


const NomineeDetailsForm: React.FC<NomineeDetailsProps> = ({
  label,
  name,
  onNomineeToDemat,
  userData,
}) => {
  const { nominee } = useUserData();
  const handleSaveAndProceed = () => {
    onNomineeToDemat();
  };

  // console.log('user details', nominee);

  return (
    <>
      <div className="flex flex-col w-full max-w-screen-xl my-8">
        <div className="w-full max-w-[1440px] mx-auto ">
          <div className="">
            <AddNominees onSubmit={handleSaveAndProceed} Border={false} xs={5} initialNomineeCount={1} removeParent={false} nominee={nominee} />
          </div>
        </div>
      </div>
    </>
  );
};

export default NomineeDetailsForm;
