import React from 'react';
import { useDispatch } from 'react-redux';
import { createChatBotMessage } from 'react-chatbot-kit';

interface MessageParserProps {
  children: React.ReactNode;
  actions: {
    handleSomeAction: (message: string) => Promise<Response>;
    updateMessages: (message: any) => void; // Adjust type as per your implementation
  };
  createChatBotMessage: any;
}

const MessageParser: React.FC<MessageParserProps> = ({ children, actions, createChatBotMessage }) => {
  const dispatch = useDispatch();

  const handleSomeAction = async (message: string) => {
    console.log('message on Action provider', message);
    try {
      const response = await actions.handleSomeAction(message);
      const data = await response.json();

      const botMessage = createChatBotMessage(data, { loading: false, message: data, type: 'text', id: Date.now() });
      actions.updateMessages(botMessage); // Assuming updateMessages is defined in actions
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const parse = (message: string) => {
    console.log('Message received:', message);
    handleSomeAction(message);
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child as React.ReactElement<any>, {
          parse: parse,
          actions: {
            ...actions,
            handleSomeAction: handleSomeAction,
          },
        });
      })}
    </div>
  );
};

export default MessageParser;
