import api from "../../config/apiConfig";

const EsignKyc = async () => {
  console.log("ESign API called Services");
  const accessToken = localStorage.getItem("accessToken");
  try {
    const response = await api.post("user/requestESign", null, {
      headers: {
        Authorization: accessToken,
      },
    });
    console.log("ESign API called Response", response);

    return response.data;
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
};

const uploadVideo = async (data: FormData) => {
  const accessToken = localStorage.getItem("accessToken");
  try {
    const response = await api.post("user/uploadVideo", data, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
};

const uploadAufForm = async (data: FormData) => {
  const accessToken = localStorage.getItem("accessToken");
  try {
    const response = await api.post("user/uploadAof", data, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
};

const eSign = { EsignKyc, uploadVideo, uploadAufForm };
export default eSign;
