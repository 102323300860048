import axios from "axios";
import api from "../../config/apiConfig";
import { CreatePassword, OTP, SignUp } from "../../models/signup.model";
import { LoginModel } from "../../models/login.model";
import { SocialLogin } from "../../models/social-login.model";
import {jwtDecode} from "jwt-decode";
import toast from "react-hot-toast";

const accessTokenKey = "accessToken";
const refreshTokenKey = "refreshToken";

const getToken = () => {
  return {
    accessToken: localStorage.getItem(accessTokenKey),
    refreshToken: localStorage.getItem(refreshTokenKey),
  };
};

const setToken = (token: string) => {
  localStorage.setItem(accessTokenKey, token);
};

const isTokenExpired = () => {
  const { accessToken } = getToken();
  if (!accessToken) {
    return true;
  }
  const decoded = jwtDecode(accessToken);
  return decoded.exp && Date.now() >= decoded.exp * 1000;
};

const getAccessToken = () => {
  const refreshToken = localStorage.getItem(refreshTokenKey);
  return api.post("/user/refreshToken", { refreshToken });
};

const refreshToken = async () => {
  const newToken = await getAccessToken();
  const { accessToken } = newToken.data?.data;
  setToken(accessToken);
  return accessToken;
};

const signUp = async (data: SignUp) => {
  const response = await api.post("/user/signUp", data);
  return response.data;
};

const login = async (data: LoginModel) => {
  const response = await api.post("/user/logIn", data);
  setToken(response.data.accessToken);

 // Schedule logout with a delay of 30 minutes
 setTimeout(() => {
  // Show a toast message 5 seconds before logging out
  // toast.error("Session Id will be expire in next 5 sec.");

  // Schedule the actual logout after the toast duration (5 seconds)
  setTimeout(() => {
    localStorage.removeItem(accessTokenKey);
    localStorage.removeItem(refreshTokenKey);
    logout();
  }, 5000); // 5 seconds in milliseconds
// }, 15000 - 5000);//autologout in 15 seconds
}, 1800000 - 5000); // 30 minutes minus 5 seconds for the toast duration

  return response.data;
};

const verifyOTP = async (data: OTP) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("/user/verifyOTP", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response.data;
};

const addPassword = async (data: CreatePassword) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("/user/addPassword", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response.data;
};

const removeTokens = () => {
  localStorage.removeItem(accessTokenKey);
  localStorage.removeItem(refreshTokenKey);
}

const socialSignUp = async (data: SocialLogin) => {
  const response = await api.post("/user/socialSignUp", data);
  return response.data;
};

const logout = () => {
  removeTokens();
  localStorage.removeItem("user");
  window.location.href = "/login";
};

const authService = {
  getToken,
  isTokenExpired,
  refreshToken,
  signUp,
  login,
  verifyOTP,
  addPassword,
  socialSignUp,
  logout,
  removeTokens,
};
export default authService;
