import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import generalService from "./generalService";
import { Company } from "../../models/nonGeneral.model";

// Define async thunk to add organization
export const addOrganization = createAsyncThunk(
  "general/addOrganization",
  async (data: Company, thunkAPI: any) => {
    try {
      return await generalService.addOrganization(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const getInitialOrganizationId = () => {
  const storedId = localStorage.getItem("organizationId");
  return storedId ? storedId : null;
};

const initialState = {
  data: {},
  organizationId: getInitialOrganizationId(), // Initialize organizationId with value from local storage
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create generalSlice
export const generalSlice = createSlice({
  name: "general",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.organizationId = action.payload.data.organizationId;

        // Store organization ID in local storage
        localStorage.setItem("organizationId", state.organizationId !== null ? state.organizationId : "");
        console.log("Received organization ID:", state.organizationId);
      })
      .addCase(addOrganization.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      });
  },
});

// Export actions and reducer
export const { resetState } = generalSlice.actions;
export default generalSlice.reducer;
