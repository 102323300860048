import React, { useState } from 'react';
import { Box, Card, Typography, Divider, IconButton, Chip } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useDispatch } from 'react-redux';
import { downloadAUFForm } from '../../redux/user/userSlice'; // Adjust import according to your structure
import { colors } from '../../constants/colors';
import { useUserData } from '../../hooks/useUserData';

interface UserData {
    registeredByAdmin: number;
}

const Compliance = ({ users }: any) => {
    const [pdfData, setPdfData] = useState<Blob | null>(null);
    const dispatch = useDispatch<any>();
    const user: UserData = useUserData();  // Assuming useUserData fetches the user data

    const handleDownload = async () => {
        try {
            const resultAction = await dispatch(downloadAUFForm());

            if (downloadAUFForm.fulfilled.match(resultAction)) {
                setPdfData(resultAction.payload.data);

                if (resultAction.payload) {
                    const url = window.URL.createObjectURL(new Blob([resultAction.payload]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'AUF_FORM.pdf');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                } else {
                    console.error('No PDF data available');
                }
            } else {
                console.error('Failed to download PDF:', resultAction.payload);
            }
        } catch (error) {
            console.error('Error downloading the PDF:', error);
        }
    };

    return (
        <Box className='border border-lightGrey rounded-md'>
            {/* Card Section */}
            <Card
                style={{
                    backgroundColor: colors.lightBg,
                    minHeight: '60px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 16px',
                    borderRadius: '5px',
                }}
                className='h-8 w-full'
            >
                <Typography variant="h5" marginLeft={2} color={colors.darkGrey} fontWeight='bold'>
                    Compliance
                </Typography>
            </Card>

            {/* New Container Section */}
            <Box className='p-4'>
                {/* Label and Divider */}
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Box display='flex' flexDirection='row'>
                        <Typography variant='h6' color={colors.darkGrey} fontWeight={'bold'} className='mb-2 pr-5'>
                            KYC
                        </Typography>
                    </Box>

                    <Box
                        display='flex'
                        alignItems='center'
                        className='rounded-full'
                        style={{
                            padding: '8px',
                            border: `1px solid ${colors.darkGrey}`,
                            backgroundColor: colors.white,
                        }}
                    >
                        <Box
                            style={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: user.registeredByAdmin === 0 ? 'greenyellow' : '#f39a45',
                                borderRadius: '50%',
                                marginRight: '8px',
                            }}
                        />
                        <Typography variant='body2' color={colors.darkGrey}>
                            {user.registeredByAdmin === 0 ? '100% Compliant' : 'Not Compliant'}
                        </Typography>
                    </Box>
                </Box>
                <Divider style={{ marginBottom: '16px', marginTop: '16px' }} />

                {/* E-signed document or KYC Not Done */}
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Box display='flex' flexDirection='row' alignItems='center'>
                        {user.registeredByAdmin === 0 ? (
                            <>
                                <Typography variant='body1' color={colors.darkGrey} className='flex items-center'>
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '2em',
                                            marginRight: '8px',
                                            color: colors.darkGrey,
                                        }}
                                    >
                                        •
                                    </span>
                                    <span style={{ fontWeight: 'normal' }}>E-signed document</span>
                                </Typography>

                                {/* Download button */}
                                <IconButton
                                    onClick={handleDownload}
                                    aria-label='download document'
                                    style={{
                                        marginLeft: '8px',
                                        color: colors.darkGrey,
                                        cursor: 'pointer',
                                    }}
                                >
                                    <FileDownloadOutlinedIcon />
                                </IconButton>
                            </>
                        ) : (
                            <Chip 
                                label="User KYC Not Done"  
                                style={{ 
                                    marginRight: '8px', 
                                    backgroundColor: colors.secondary, 
                                    color: colors.white 
                                }} 
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Compliance;
