// utils/PopupWindow.ts
export const BrowserPopUpModal = (url: string, title: string, width = 600, height = 400) => {
    // Calculate the position to center the popup
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
  
    const popupWindow = window.open(
      url,
      title,
      `width=${width},height=${height},top=${top},left=${left},resizable,scrollbars=yes,status=1`
    );
  
    // Bring the popup to the front if it's already open
    if (popupWindow) {
      popupWindow.focus();
    }
  
    return popupWindow;
  };

  