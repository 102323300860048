import React from 'react';
import { useDispatch } from 'react-redux';
import { chatBot } from '../../redux/user/userSlice';
import { createChatBotMessage } from 'react-chatbot-kit';

const ActionProvider = ({ createChatBotMessage, setState, children }: any) => {
  const dispatch = useDispatch<any>();

  const handleSomeAction = async (message: any) => {
    // console.log('Handling action for message:', message);
    try {
      const response = await dispatch(chatBot({ query: message }));
      let tempData = response.payload.data.response
      const data = await tempData;
      const botMessage = createChatBotMessage(data);
      // Assuming updateMessages is a function passed via props or context
      // Replace this with your actual function to update messages
      setState((prevState: any) => ({
        ...prevState,
        messages: [...prevState.messages, botMessage],
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            ...child.props.actions,
            handleSomeAction: handleSomeAction, // Pass handleSomeAction to children's actions
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
