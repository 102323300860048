import api from "../../config/apiConfig";

const getUnrealisedReport = async (data: any) => {
    // console.log('MF Service', accessToken);
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/funds/getUnrealisedReport", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data.data;
};

const getAllMFHoldings = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    console.log('AssetsHolding Service at AssetsHolding APi access token ', accessToken);

    const response = await api.post("/funds/getHoldings", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    // console.log('FD Service Response : ',response);

    return response.data.data;
};

const getAif = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");

    const response = await api.post("/aif/getAll", data, {
        headers: {
            Authorization: accessToken,
        },
    });

    return response.data.data;
};

const getPms = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");

    const response = await api.post("/pms/getAll", data, {
        headers: {
            Authorization: accessToken,
        },
    });

    return response.data.data;
};

const getBonds = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");

    const response = await api.post("/bond/getAll", data, {
        headers: {
            Authorization: accessToken,
        },
    });

    return response.data.data;
};

const ReportService = { getUnrealisedReport, getAllMFHoldings, getAif, getPms, getBonds };
export default ReportService;
