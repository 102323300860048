import React, { useState, useEffect } from 'react';
import { DateRange, DateRangePicker } from "materialui-daterange-picker";
import { Typography } from '@mui/material';
import './DatePickerStyles.css';
import { colors } from '../../constants/colors';

type DropdownValues = {
    segment: string;
    reportType: string;
    fundType: string;
    startDate: number | undefined;
    endDate: number | undefined;
    transactionType: number;
};

type DatePickerProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setTimestamps: React.Dispatch<React.SetStateAction<DropdownValues>>;
    timestamps: DropdownValues;
};

const DatePicker: React.FC<DatePickerProps> = ({ open, setOpen, setTimestamps, timestamps }) => {
    const [dateRange, setDateRange] = useState<DateRange>({});
    const [error, setError] = useState<string | null>(null);

    // Define the max date as the current date
    const maxDate = new Date();

    // Get the current date and the first day of the previous month
    const currentDate = new Date();
    const previousMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const currentMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    // Initial date range (previous month on the left, current month on the right)
    const initialDateRange: DateRange = {
        startDate: previousMonthStartDate, 
        endDate: currentDate
    };

    // Function to convert date to timestamp
    const formatToTimestamp = (date: Date | undefined) => {
        return date ? date.getTime() : undefined;
    };

    // Function to handle date changes
    const handleDateChange = (range: DateRange) => {
        const { startDate, endDate } = range;

        // Check if endDate is greater than maxDate
        if (endDate && endDate > maxDate) {
            setError('End date cannot be beyond today.');
            return;
        }

        setError(null);
        setDateRange(range);
    };

    useEffect(() => {
        const startDateTimestamp = formatToTimestamp(dateRange.startDate);
        const endDateTimestamp = formatToTimestamp(dateRange.endDate);

        setTimestamps({ ...timestamps, startDate: startDateTimestamp, endDate: endDateTimestamp });
    }, [dateRange, setTimestamps]);

    const toggle = () => setOpen(!open);

    useEffect(() => {
        // Only close the date picker if the end date is defined and has not been manually set
        if (dateRange.endDate && !error) {
            setOpen(false);
        }
    }, [dateRange, error, setOpen]);

    return (
        <div className=''>
            {!open && (
                <span onClick={toggle} className='w-52 h-14 border border-lightGrey flex justify-center items-center rounded-md'>
                    {dateRange.startDate && dateRange.endDate ? (
                        <Typography>
                            {dateRange.startDate.toLocaleDateString()} - {dateRange.endDate.toLocaleDateString()}
                        </Typography>
                    ) : (
                        <Typography variant='subtitle2' sx={{ color: colors.darkGrey }}>Select Date Range</Typography>
                    )}
                </span>
            )}
            {open && (
                <div className="dateRangePickerContainer">
                    <DateRangePicker
                        open={open}
                        toggle={toggle}
                        onChange={handleDateChange}
                        initialDateRange={initialDateRange} // Pass the initial date range
                        maxDate={maxDate} 
                    />
                    {error && (
                        <Typography variant='body2' sx={{ color: colors.secondary }}>
                            {error}
                        </Typography>
                    )}
                </div>
            )}
        </div>
    );
};

export default DatePicker;
