import React, { useState } from "react";
import InfoSection from "../../../components/InfoSection/InfoSection";

import person from '../../../assets/icons/f7_person-2-normal.png'
import person24 from '../../../assets/icons/octicon_person-24 grey (1).png'
import bankDetails from '../../../assets/icons/Company.png'
import demat from '../../../assets/icons/jam_document.svg'
import preference from '../../../assets/icons/grommet-icons_checkbox-selectedblue.png'
import sign from '../../../assets/icons/la_file-signature blue.png'

import { colors } from '../../../constants/colors';
import upload from '../../../assets/icons/lucide_upload.svg';
import Buttons from '../../../components/Buttons/IndividualButtons/Buttons'
import { Img, Text } from "../../../components";


interface InputFieldProps {
  label: string;
  type: string;
  id: string;
  altText?: string;
}


interface PreferenceFormProps {
  onPreferenceToDemat: () => void;
  allowToProceed:() => void // Prop to handle transition to Demat Details
  activeSection: string;
}


  

const InputField: React.FC<InputFieldProps> = ({ label, type, id, altText }) => {
    if (label === "Depository Names") {
      return (
        <div className="flex flex-col text-lg mb-10" style={{color:colors.darkGrey}}>
          <label htmlFor={id} className="" style={{color:colors.darkGrey}}>{label}</label>
          <select
            id={id}
            className="shrink-0 mt-3 rounded-lgp-4  p-4"
            style={{color:colors.darkGrey, backgroundColor:colors.white, border:`1px solid ${colors.darkBg}`}}
            aria-label={altText || label}
          >
            <option value="Select Account Type"></option>
            <option value="savings">Option 1</option>
            <option value="checking">Option 2</option>
            <option value="investment">OPtion 3</option>
          </select>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col text-lg mb-10" style={{color:colors.darkGrey}}>
          <label htmlFor={id} className="" style={{color:colors.darkGrey}}>{label}</label>
          <input
            className="shrink-0 mt-3 rounded-lg p-4"
            style={{color:colors.darkGrey, backgroundColor:colors.white, border:`1px solid ${colors.darkBg}`}}
            type={type}
            id={id}
            aria-label={altText || label}
          />
        </div>
      );
    }
  };
  

  const FileInputField: React.FC<InputFieldProps> = ({ label }) => {
    const [fileName, setFileName] = React.useState<string | null>(null);
  
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const maxFileSize = 1 * 1024 * 1024; // 1 MB
        if (file.size > maxFileSize) {
          alert("File size exceeds the 1 MB limit.");
          event.target.value = ""; // Clear the file input
          return;
        }
        setFileName(file.name);
      } else {
        setFileName(null);
      }
    };
  
    return (
      <div className="flex flex-col mt-10 mb-10 grow text-lg text-neutral-500">
        <div className="mb-5 text-base sm:text-base md:text-xl">{label}</div>
        <label className="shrink-0 rounded-lg p-3 flex items-center justify-between " style={{color:colors.darkGrey, backgroundColor:colors.white, border:`1px solid ${colors.darkBg}`}}>
          <input
            type="file"
            className="hidden sm:w-full"
            style={{ backgroundColor:colors.white}}
            onChange={handleFileChange}
          />
          <span>{fileName ? fileName : ""}</span>
          <img
            src={upload} // Update with your image path
            alt="Upload Icon"
            className="h-10 w-10 ml-2 cursor-pointer"
          />
        </label>
      </div>
    );
  };

const Preference2: React.FC<PreferenceFormProps> = ({ onPreferenceToDemat, allowToProceed, activeSection }) => {
  const [completedSections, setCompletedSections] = useState<boolean[]>(Array(6).fill(false));
  
  const infoSections = [
    { icon: person24, title: 'General Details'},
    { icon: bankDetails , title: 'Bank Details'},
    { icon: person, title: 'Nominee Details'},
    { icon: demat, title: 'Demat Details'},
    { icon: preference , title: 'Preferences'},
    { icon: sign , title: 'Signatures'},
  ];



  const onAllow = (): void => {
    // Mark the current section as completed
    const updatedSections = [...completedSections];
    updatedSections[5] = true;
    setCompletedSections(updatedSections);

    // Call the function to allow to proceed
    allowToProceed();
  };



  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleCheckboxChange = (option: string) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const renderCheckbox = (option: string) => (
    <label key={option} className="flex items-center ml-10">
      <input
        type="checkbox"
        checked={selectedOptions.includes(option)}
        onChange={() => handleCheckboxChange(option)}
        className={`mr-2`}
      />
      {option}
    </label>
  );

  const renderRadio = (option: string) => (
    <label key={option} className="flex items-center ml-10">
      <input
        type="radio"
        checked={selectedOptions.includes(option)}
        onChange={() => handleCheckboxChange(option)}
        className={`mr-2`}
      />
      {option}
    </label>
  );





  return (
    <section className="flex w-full">

    <main className={`flex flex-col w-full sm:w-full justify-center`} style={{ backgroundColor: colors.lightBg }}>
              
                    <div className='flex justify-between'>
      {/* Inputs for screens above 768px */}
      <div className="hidden sm:flex flex-col w-full gap-5 pl-10 pr-10 lg:pr-36 lg:pl-36 pb-5">
                {/* Row for Name of Nominee and Phone */}
               
<div className="mx-auto sm:w-full  mt-5 ">
<div className="flex flex-col gap-1.5  justify-center w-full md:w-auto lg:justify-center">
          <div className={`font-normal max-md:max-w-full text-center lg:text-center sm:text-xl text-base mt-12`} style={{ color: colors.darkGrey }}>
          We haven't received the following documents yet. Kindly upload them, please.
          </div>
          <div className={`font-semibold max-md:max-w-full text-center lg:text-center sm:text-3xl text-base mt-12`} style={{ color: colors.primary }}>
          Corporate
          </div>
          <div className={`font-normal max-md:max-w-full text-center lg:text-center sm:text-xl text-base mt-5`} style={{ color: colors.darkGrey }}>
          Balance  Sheet for Last 2 Financial Years
          </div>
          <div className={`font-normal max-md:max-w-full text-center lg:text-center sm:text-xl text-base mt-2`} style={{ color: colors.darkGrey }}>
          Memorandam & Articles of Association
          </div>
          <div className={`font-normal max-md:max-w-full text-center lg:text-center sm:text-xl text-base mt-2`} style={{ color: colors.darkGrey }}>
          Latest Shareholding Pattern Copy
          </div>
</div>
</div>
                
              </div>
          </div>
          <div className='flex justify-center items-center h-full'>
          <div className='flex flex-col mb-16'>
            {/* Your form content */}
            <Buttons
              denyText="Upload Pending Documents Now"
              allowText="Skip For Now & Sign"
              onAllowClick={onPreferenceToDemat}
              onDenyClick={() => {}}
            />
          </div>
        </div>
                  </main>
                 
                </section>
  );
};

export default Preference2;




