import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MFService from "../InvestMF/investService";
import {Invest} from "../../models/invest.model";

export const addMFund = createAsyncThunk(
  "invest/addMF",
  async (data: Invest, thunkAPI: any) => {
    try {
      return await MFService.addMFund(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  data: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const InvestMFSlice = createSlice({
  name: "invest",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMFund.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addMFund.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(addMFund.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      });
  },
});

export const { resetState } = InvestMFSlice.actions;
export default InvestMFSlice.reducer;
