import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WLService from "./wishlistService";
// import WLService from "./wishlistService";


export const getAllWatchlist = createAsyncThunk(
    "wishlist/get",
    async (_, thunkAPI) => { // Remove unnecessary type annotation
        try {
            const response = await WLService.getWatchListInfo();
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const addToWatchlist = createAsyncThunk(
    'wishlist/add',
    async (data: any, thunkAPI) => {
        try {
            const response = await WLService.addWatchList(data);
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const removeToWatchlist = createAsyncThunk(
    "wishlist/delete",
    async (data: any, thunkAPI) => {
        try {
            const response = await WLService.removeWatchList(data);
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

const initialState = {
    watchlist: [],
    addItemWatchlist: [],
    removeItemWatchlist: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
};

export const wishlist = createSlice({
    name: "Watchlist",
    initialState: initialState,
    reducers: {
        resetState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllWatchlist.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllWatchlist.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.watchlist = action.payload.data;
            })
            .addCase(getAllWatchlist.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(addToWatchlist.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addToWatchlist.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.addItemWatchlist = action.payload.data;
            })
            .addCase(addToWatchlist.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(removeToWatchlist.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(removeToWatchlist.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.removeItemWatchlist = action.payload.data;
            })
            .addCase(removeToWatchlist.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            });
    },
});

export const { resetState } = wishlist.actions;
export const { reducer: WLSliceReducer } = wishlist;
