import * as React from "react";
import { Card, CardContent } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { styled } from "@mui/material/styles";
import { useDrawingArea } from "@mui/x-charts";

// Define the props for the PieSummary component
interface SimplePieChartProps {
  data: any; // The shape of data can be defined more specifically if needed
}

const PieSummary: React.FC<SimplePieChartProps> = ({ data }) => {
  const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 20,
  }));

  console.log("Received data:", data); // Debugging log

  // Extract investedFunds from the data prop, providing a default value of an empty array
  const investedFunds = data.investedFunds || []; // Safe fallback to empty array

  // Transform the investedFunds data into pie chart data format
  const chartData = investedFunds.map((fund: any) => ({
    label: fund.schemeName,
    value: fund.currentMktValue,
  }));

  function PieCenterLabel() {
    const { width, height, left, top } = useDrawingArea();
    const total = chartData.reduce(
      (acc: number, item: { value: number }) => acc + item.value,
      0
    ); // Calculate total
    return (
      <g transform={`translate(${left + width / 2}, ${top + height / 2})`}>
        <StyledText x={0} y={-10} fontWeight={"bold"}>
          Total
        </StyledText>
        <StyledText x={0} y={10} color="#A1A1A1">
          {total.toFixed(2)}
        </StyledText>
      </g>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <Card className="flex-grow flex flex-col">
        <CardContent className="flex-grow flex flex-col">
          <div className="flex-grow flex items-center justify-center">
            {/* Render PieChart only if chartData has items */}
            {chartData.length > 0 ? (
              <PieChart
                series={[
                  {
                    data: chartData, // Use the transformed chartData
                    cx: "50%", // Centered horizontally in the SVG
                    cy: "50%", // Centered vertically in the SVG
                    innerRadius: 100,
                    outerRadius: 140,
                  },
                ]}
                width={400}
                height={400}
                sx={{
                  padding: 0,
                  margin: 0,
                  border: "none",
                }}
                margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
                slotProps={{
                  legend: { hidden: true },
                }}
              >
                <PieCenterLabel />
              </PieChart>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <h6 className="font-bold text-xs text-gray-500">No Data Available</h6>
              </div>
            )}
          </div>

          {/* Render asset names and values */}
          {chartData.length > 0 && (
            <div
              className="flex flex-col gap-4 justify-between mt-5"
              style={{
                maxHeight: "200px", // Set a maximum height
                overflowY: chartData.length > 5 ? "auto" : "hidden", // Enable scrolling if there are more than 10 items
              }}
            >
              {chartData.map((item: any, index: any) => (
                <div
                  key={index}
                  className="flex justify-between items-center"
                  style={{ width: "100%", color: "#A1A1A1" }}
                >
                  <div className="flex w-full items-center gap-x-3">
                    <span
                      className="w-3 h-3 rounded-3xl"
                      style={{ backgroundColor: item.color }} // Use the assigned color
                    ></span>
                    <h6 className="font-bold text-xs pr-2">{item.label}:</h6>
                  </div>
                  <span className="font-inter text-xs">
                    {item.value.toFixed(2)} {/* Value displayed here */}
                  </span>
                </div>
              ))}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default PieSummary;
