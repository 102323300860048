import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  Fade,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useEffect, useState } from "react";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { useDispatch, useSelector } from "react-redux";
import { getFDSchemeInfo, getFDinfo } from "../../redux/FD/FDSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BorderBottom, InfoOutlined, MoreVert, ReportProblem } from "@mui/icons-material";
import getInitials from "../../utils/getInitailasIMGUtils";
import { colors } from "../../constants/colors";
import { addToWatchlist, removeToWatchlist } from "../../redux/wishlists/wishlistSlice";
import toast from "react-hot-toast";
import InvestModal from "../modals/FDRequestModal";
import MainTableComponent from "./MainTableComponent";
interface Data {
  schemes: Scheme[];
}

interface Scheme {
  schemeName: string;
  plans: Plan[];
  ratings: string;
  minInvestAmountinINR: {
    monthly: number;
    quaterly: number;
    halfYearly: number;
    annual: number;
    cumulativeDeposit: number;
    annualisedYield: number;
  };
  maxInvestAmountinINR: number;
}

interface Plan {
  id: number;
  interestRates: {
    annual: number;
  };
  name: string;
  plans: {};
  ratings: string;
  minInvestAmountInINR: number;
  maxInvestAmountinINR: number;
}

interface Result {
  highestRateOverall: number;
  planWithHighestRateOverall: Plan | null;
  highestRatePerScheme: { [schemeName: string]: { highestRate: number; highestPlanId: number } };
}

const Rows = ({ data, setLoading, setDataById, setTenureALL, openInvestModal }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const isSelected = (id: number) => selected.indexOf(id) !== -1;
  const isItemSelected = isSelected(data.id);
  const labelId = `enhanced-table-checkbox-${data.id}`;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorMD, setAnchorMD] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const openMenuMD = Boolean(anchorMD);
  const [seniorCitizenRate, setSeniorCitizenRate] = useState<number | null>(null);
  const [womanSpecialRate, setWomanSpecialRate] = useState<number | null>(null);
  const [isinWishilist, setIsinWishilist] = useState<any>();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const modalMenuClick = (event: React.MouseEvent<HTMLElement>, isinWishilist: number, id: number) => {
    setAnchorMD(event.currentTarget);
    let data = { status: isinWishilist, id: id }
    setIsinWishilist(data)
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, seniorcitizenRate: number | null, womanSpecialRate: number | null) => {
    setAnchorEl(event.currentTarget);
    setSeniorCitizenRate(seniorcitizenRate);
    setWomanSpecialRate(womanSpecialRate)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMD = () => {
    setAnchorMD(null);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };


  const findHighestInterestRates = (data: any[]) => {
    let highestRateOverall = 0;

    data.forEach((item) => {
      if (highestRateOverall < item.maxInterestRate) {
        highestRateOverall = item.maxInterestRate;
      }
    });

    return highestRateOverall;
  };



  // const result = findHighestInterestRates(data);
  // console.log(result); 

  const navigateDetails = (id: number) => {
    // console.log('on click', id);
    navigate(`/fd-details/${id}`);
  };

  const Watchlist = async () => {
    if (!isinWishilist) return;

    if (isinWishilist.status === 1) {
      const data = { itemId: isinWishilist.id, itemType: 3 };
      const response = await dispatch(removeToWatchlist(data));
      if (response.payload) {
        setIsinWishilist({ ...isinWishilist, status: 0 });
        toast.success('Removed from watchlist');
      } else {
        toast.error('Failed to remove from watchlist');
      }
    } else if (isinWishilist.status === 0) {
      const data = { itemId: isinWishilist.id, itemType: 3 };
      const response = await dispatch(addToWatchlist(data));
      if (response.payload) {
        setIsinWishilist({ ...isinWishilist, status: 1 });
        toast.success('Added to watchlist');
      } else {
        toast.error('Failed to add to watchlist');
      }
    }
  };


  const fetchData = async (id: number) => {
    setLoading(true);
    try {
      const response = await dispatch(getFDSchemeInfo({ schemeId: id }));

      if (response?.payload) {
        setDataById(response.payload.scheme);
        setTenureALL(response?.payload?.scheme?.tenures);
        openInvestModal();
      } else {
        toast.error("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  }
  // console.log('data on fd', data);

  return (
    <>
      <MainTableComponent
        data={data}
        getInitials={getInitials}
        findHighestInterestRates={findHighestInterestRates}
        navigateDetails={navigateDetails}
        handleMenuClick={handleMenuClick}
        modalMenuClick={modalMenuClick}
      />
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem>
          <Box>
            <div className="flex  gap-x-2">
              <span className="font-medium text-sm text-gray-700_01">Senior Citizen:</span>
              <span className="font-semibold text-sm">
                {seniorCitizenRate !== null ? `+${seniorCitizenRate}% p.a.` : '-'}
              </span>
            </div>
            <div className="flex gap-x-2">
              <span className="font-medium text-sm text-gray-700_01">Woman Special Rate:</span>
              <span className="font-semibold text-sm">
                {womanSpecialRate !== null ? `+${womanSpecialRate}% p.a.` : '-'}
              </span>
            </div>
          </Box>
        </MenuItem>
        {/* Add other MenuItems here if needed */}
      </Menu >
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorMD}
        open={openMenuMD}
        onClose={handleCloseMD}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            backgroundColor: '#255288',
            color: '#fff',
            overflow: 'hidden',
            borderRadius: '10px',
            position: 'relative',
            width: isSmallScreen ? '80%' : '12%',
          },
        }}
        className="relative"
      >
        <div className="mx-6">
          <ListItem button onClick={handleClose} sx={{ marginLeft: '-25px' }}>
            <MenuItem className="text-xs sm:text-sm" onClick={Watchlist}>{isinWishilist?.status === 1 ? "Remove from watchlist" : 'Add to Watchlist'}</MenuItem>
          </ListItem>
          <Divider sx={{ backgroundColor: '#fff' }} />
          <ListItem button onClick={handleClose} sx={{ marginLeft: '-25px' }}>
            <MenuItem className="text-xs sm:text-sm" onClick={(e: any) => { fetchData(isinWishilist.id) }}>Invest</MenuItem>
          </ListItem>
        </div>
        <div
          className="absolute bg-white"
          style={{
            width: '20px',
            height: '20px',
            transform: 'rotate(315deg)',
            bottom: 'calc(100% - 10px)',
            left: 'calc(50% - 10px)',
          }}
        />
      </Menu>
    </>
  );
};

const FDTable = ({ fdData, loader }: any) => {
  // const [loader, setLoader] = useState<boolean>(false);
  // const [FDdata, setFDdata] = useState<any>();
  // console.log('fdData', fdData);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataById, setDataById] = useState<any>()
  const [tenureALL, setTenureALL] = useState<any>()
  const [isInvestModalOpen, setIsInvestModalOpen] = useState(false);

  const openInvestModal = () => {
    setIsInvestModalOpen(true);
  };

  const closeInvestModal = () => {
    setIsInvestModalOpen(false);
  };
  let FDInfo: any;
  if (dataById) {
    FDInfo = {
      schemeID: dataById.id,
      schemeName: dataById.schemeName,
      maxInvestmentAmount: dataById.maxInvestmentamount,
      minInvestmentAmount: dataById.minInvestmentAmount,
      isHDFC: dataById.isHDFC,
    };
  }
  return (
    <>
      <TableContainer component={Paper} sx={{ boxShadow: 'none', border: 'none' }}>
        <Table aria-label="collapsible table" >
          <TableHead sx={{ backgroundColor: colors.lightBg, border: 'none' ,paddingTop:'2%',paddingBottom:'2%'}}>
            <TableRow sx={{ border: 'none' }}>
              {/* <TableCell padding="checkbox" /> */}
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} >Asset Name</TableCell>
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">
                Rating
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">
                Interest Rate
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">
                Min. investment
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="right">
                Max. investment
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="right">
                Payment Frequency
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="right">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {loader ? (
            <div className="flex justify-center items-center h-full">
              <div className="flex flex-col justify-center items-center">
                <Dots />
              </div>
            </div>
          ) : (
            <TableBody className="" sx={{
              border: 'none',
              '& .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.css-1yhpg23-MuiTableCell-root': {
                borderBottom: 'none',
              },
            }} >
              <TableRow sx={{ height: '5%', }}>
                <TableCell colSpan={8} align="center">

                </TableCell>
              </TableRow>

              {fdData && fdData.length > 0 ?
                (<Rows
                  // key={data.id}
                  data={fdData}
                  setLoading={setLoading}
                  setDataById={setDataById}
                  setTenureALL={setTenureALL}
                  openInvestModal={openInvestModal}
                />
                )


                : <TableRow>
                  <TableCell colSpan={8} align="center">
                    No similar plans available.
                  </TableCell>
                </TableRow>}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {dataById && (<InvestModal open={isInvestModalOpen} onClose={closeInvestModal} data={{ FDInfo: FDInfo, tenure: tenureALL, data: dataById }} />)}
    </>
  );
};

export default FDTable;