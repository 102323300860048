import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import kycService from "../../redux/kyc/kycService";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { zeroKyc } from "../../redux/kyc/kycSlice";
import { Formik } from "formik";
import dayjs from "dayjs";
import * as yup from "yup";
import { FormInput } from "../../components/FormInput/FormInput";
import VerifyButton from "../../components/VerifyButton/VerifyButton";
import { IndianStates } from "../../enums/states";
import { updateUser } from "../../redux/user/userSlice";
import toast from "react-hot-toast";

const aadharValues = {
  aadharNumber: "",
  aadharMobileNumber: "",
  fullName: "",
};

const aadharSchema = yup.object({
  aadharNumber: yup
    .string()
    .required("Aadhar Number is required")
    .matches(/^([0-9_.])+$/, "Must contain only number"),
  aadharMobileNumber: yup
    .string()
    .required("Mobile Number registered with Pan Card is required")
    .max(13, "Mobile Number should be 10 digit")
    .min(13, "Mobile Number should be 10 digit")
    .matches(/^([0-9_.+])+$/, "Must contain only number"),
  fullName: yup.string().required(),
});

const DigilockerDetails = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { search } = useLocation();
  const aadharFormRef = useRef<any>(null);
  const [aadharCard, setAadharCard] = useState<string>();
  useEffect(() => {
    fetchDigilockerData();
  }, []);

  const fetchDigilockerData = async () => {
    const digilocker_uid = new URLSearchParams(search).get("digilocker_uid");
    const access_token = new URLSearchParams(search).get("access_token");
    console.log(access_token, "digilocker_uid");
    if (access_token !== null) {
      const response = await dispatch(zeroKyc(access_token));
      console.log(response, "response");
      const data = response.payload.data;
      const payload = {
        dateOfBirth: data?.aadhaar_dob?.replaceAll("-", "/"),
        address1: data?.aadhaar_house,
        address2: `${data?.aadhaar_street}, ${data?.aadhaar_location}`,
        city: data?.aadhaar_district,
        state: IndianStates[data?.aadhaar_state],
        pincode: data?.aadhaar_post_code,
        gender: data?.aadhaar_gender === 'M' ? "male" : "female",
        isZeroDoc: 1
      }
      const res = await dispatch(updateUser(payload));
      if(res?.error?.message) {
        toast.error("Something went wrong!");
        return;
      }
      navigate('/onboarding', {
        state: {
          activeSection: 'general',
          sectionIndex: 2
        }
      });
    }
  };

  return (
    <div className="flex flex-col w-full max-w-screen-xl min-h-screen mx-auto my-8">
      <h3 className="text-center font-semibold text-xl mb-8">
        Your Aadhar Details
      </h3>
      <Formik
        innerRef={aadharFormRef}
        initialValues={aadharValues}
        validationSchema={aadharSchema}
        onSubmit={async (values, { setSubmitting }) => {
          console.log(values, "user values");
          // const response = await dispatch(updateUser(values));
          // if (response?.error?.message) {
          //   toast.error("Failed to update details");
          //   return;
          // }
          // onProceed();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => {
          return (
            <>
              <form
                onSubmit={handleSubmit}
                className="w-full flex flex-col gap-[31px]"
              >
                <div className="flex justify-center items-center">
                  <div className="flex flex-1 flex-col items-center gap-8 justify-center">
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Aadhaar Number *"
                        type="text"
                        name="aadharNumber"
                        onChange={(e: any) =>
                          setFieldValue("aadharNumber", e.target.value)
                        }
                        value={values.aadharNumber}
                        error={errors.aadharNumber}
                        touched={touched.aadharNumber}
                      />
                    </div>
                    {/* {!verifiedDetails.aadharCard && ( */}
                    {/* )} */}
                    {/* {verifiedDetails.aadharCard && (
                        <div className="w-full max-w-[500px]"></div>
                      )} */}
                    {/* <div className="w-full max-w-[500px]">
                    <FormInput
                      label="Mobile Number Registered with Aadhaar *"
                      type="phone"
                      name="aadharMobileNumber"
                      onChange={(e: any) => {
                        setFieldValue("aadharMobileNumber", e);
                      }}
                      value={values.aadharMobileNumber}
                      // disabled={
                      //   !!verifiedDetails?.aadharMobileNumber ||
                      //   isAadharVerified
                      // }
                      error={errors.aadharMobileNumber}
                      touched={touched.aadharMobileNumber}
                    />
                  </div> */}
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Full Name as per Aadhar *"
                        type="text"
                        name="fullName"
                        onChange={handleChange("fullName")}
                        value={values.fullName}
                        error={errors.fullName}
                        disabled={true}
                        touched={touched.fullName}
                      />
                    </div>
                  </div>
                  {aadharCard && (
                    <div className="flex-1 flex justify-center">
                      <img src={aadharCard} alt="aadharCopy" />
                    </div>
                  )}
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default DigilockerDetails;
