import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Skeleton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { colors } from '../../constants/colors';
import { useSelector } from 'react-redux';
import formatKeyToTitle from '../../utils/formatKeytitle';

const RiskRatios = () => {
  const [loading, setLoading] = useState(true);
  const ratios = useSelector((state: any) => state.MFund?.MFundbyid?.ratios);
  // console.log('ratios', ratios);
  

  useEffect(() => {
    if (ratios && Array.isArray(ratios)) {
      setLoading(false); // Set loading to false once data is available
    }
  }, [ratios]);

  const RickType = () => {
    const numberOfEntriesToShow = 100;

    return (
      <>
        {loading ? ( // Check if loading is true, show skeleton
          <Skeleton variant="rectangular" height={200} animation="wave" sx={{ bgcolor: colors.lightBg }} />
        ) : (
          <div className="flex flex-wrap gap-4 justify-between">
            {ratios && ratios.length > 0 && Object.entries(ratios[0]).slice(0, numberOfEntriesToShow).map(([name, value]: [string, any], index: number) => (
              <div
                key={index}
                className="flex flex-col border border-lightGrey w-fit md:w-1/5 md:h-20 p-4 rounded-lg"
              >
                <span className="text-gray-700_01 text-md">{formatKeyToTitle(name)}</span>
                <span className="font-bold text-md text-darkGrey">{value}</span>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <Accordion defaultExpanded sx={{ boxShadow: 'none' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          backgroundColor: colors.lightBg,
          borderRadius: '5px',
          borderColor: colors.primary,
          borderWidth: '1px',
          borderStyle: 'solid',
        }}
      >
        <h6 className="font-semibold text-lg text-primary">Risk Ratios</h6>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0', margin: '2rem 0' }}>
        <RickType />
      </AccordionDetails>
    </Accordion>
  );
};

export default RiskRatios;
