import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AIFService from "./AIFService";

export const InvestRequest = createAsyncThunk(
  "aif/invest",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      // console.log('Getting data for InvestMF ', data);

      const response = await AIFService.investAIFRequest(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const getTransactionRequest = createAsyncThunk(
  "aif/getTransactions",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      // console.log('Getting data for InvestMF ', data);

      const response = await AIFService.getTransactionInfo(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const WithdrawRequest = createAsyncThunk(
  "aif/withdraw",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      // console.log('Getting data for InvestMF ', data);

      const response = await AIFService.withdrawAIFRequest(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const AIFSummary = createAsyncThunk(
  "aif/getSummary",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      // console.log('Getting data for InvestMF ', data);

      const response = await AIFService.AIFSummary({});
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);


const initialState = {
  funds: [],
  fundinfo: [{ 'name': 'fundInfo' }],
  MFundbyid: [],
  paymentData: {},
  InvestMF: [],
  sendOTP: [],
  verifyOTP: [],
  ImportFUnd: [],
  paymentStatus: "",
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

export const AIFSlice = createSlice({
  name: "AIF",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(InvestRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(InvestRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(InvestRequest.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getTransactionRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTransactionRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(getTransactionRequest.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(WithdrawRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(WithdrawRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(WithdrawRequest.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(AIFSummary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AIFSummary.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(AIFSummary.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
  },
});

export const { resetState } = AIFSlice.actions;
export const { reducer: AIFSliceReducer } = AIFSlice;
