import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PMSService from "./PMSService";

export const InvestRequest = createAsyncThunk(
  "pms/invest",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      // console.log('Getting data for InvestMF ', data);

      const response = await PMSService.investPMSRequest(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);
export const getTransactionRequest = createAsyncThunk(
  "pms/getTransactions",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      // console.log('Getting data for InvestMF ', data);

      const response = await PMSService.getTransactionInfo(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const WithdrawRequest = createAsyncThunk(
  "pms/withdraw",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      // console.log('Getting data for InvestMF ', data);

      const response = await PMSService.withdrawPMSRequest(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const PMSSummary = createAsyncThunk(
  "pms/getSummary",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      // console.log('Getting data for InvestMF ', data);

      const response = await PMSService.PMSSummary({});
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);



const initialState = {
  funds: [],
  fundinfo: [{ 'name': 'fundInfo' }],
  MFundbyid: [],
  paymentData: {},
  InvestMF: [],
  sendOTP: [],
  verifyOTP: [],
  ImportFUnd: [],
  paymentStatus: "",
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

export const PMSSlice = createSlice({
  name: "PMS",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(InvestRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(InvestRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(InvestRequest.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getTransactionRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTransactionRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(getTransactionRequest.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(WithdrawRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(WithdrawRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(WithdrawRequest.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(PMSSummary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(PMSSummary.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(PMSSummary.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
  },
});

export const { resetState } = PMSSlice.actions;
export const { reducer: PMSSliceReducer } = PMSSlice;
