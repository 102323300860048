
import { colors } from "../../constants/colors";

interface ButtonsProps {
    skipText?: string;
    saveAndProceedText?: string;
  }


const Buttons = () => {
    const textColorSkip = colors.black;
    const bgColorSkip = colors.white;
    const textColorSave = colors.white;
    const bgColorSave = colors.black
    const inputFieldColor = colors.inputLabel;
 
    
    
    return (
        <section className="mt-10 mr-8 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                    <div className="flex gap-5 justify-between self-start mt-12 text-sm max-md:mt-10">
                    <button style={{ backgroundColor: bgColorSkip, color: textColorSkip }} className={`grow justify-center px-8 py-4 whitespace-nowrap rounded-md border border-black border-solid text-neutral-700 max-md:px-5`} tabIndex={0}>Skip</button>
        
                    <button style={{ backgroundColor: bgColorSave, color: textColorSave }} className={`grow  justify-center px-8 py-4 rounded-md border border-black border-solid max-md:px-5`} tabIndex={0}>Save & Proceed</button>
                    </div>
                </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            </div>
        </div>
        </section>
        );
}

export { Buttons }