const formatDateFromTimestamp = (timestamp: number): string => {
  const correctedTimestamp = timestamp.toString().length === 10 ? timestamp * 1000 : timestamp; // Convert seconds to milliseconds if necessary
  const date = new Date(correctedTimestamp);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
    // hour: '2-digit',
    // minute: '2-digit',
    // second: '2-digit',
  };
  return date.toLocaleDateString('en-GB', options);
};

const calculateYearsOfExperience = (startDate: number, currentDate: number): string => {
  const start = new Date(startDate);
  const current = new Date(currentDate);
  
  let years = current.getFullYear() - start.getFullYear();
  let months = current.getMonth() - start.getMonth();
  const days = current.getDate() - start.getDate();

  if (days < 0) {
    months--;
  }
  if (months < 0) {
    years--;
    months += 12;
  }

  if (years === 0) {
    return `${months} months`;
  }

  const totalExperience = years + (months / 12);
  return `${totalExperience.toFixed(0)} years`;
};

const TimeStamptoExp = { formatDateFromTimestamp, calculateYearsOfExperience };
export default TimeStamptoExp;
