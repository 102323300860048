import React from "react";
import { FormInput } from "../FormInput/FormInput";
import { colors } from "../../constants/colors";
import toast from "react-hot-toast";

const amountOptions = [
  { value: "5000", label: '5000' },
  { value: "10000", label: '10,000' },
  { value: "25000", label: '25,000' },
  { value: "50000", label: '50,000' },
  { value: "100000", label: '1,00,000' },
];

interface AmountButtonsProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  selectedAmount?: string;
  label?: string;
  fieldName: string;
  setSelectedAmount: (amount: string) => void;
  values: any;
  errors: any;
  touched: any;
  ingnoreAmount?: any[];
  fieldDisabled?: boolean;
  currentValue?: string; // This will be used for conditional validation
}

const AmountButtons: React.FC<AmountButtonsProps> = ({
  setFieldValue,
  selectedAmount,
  setSelectedAmount,
  fieldName,
  values,
  errors,
  touched,
  fieldDisabled,
  label,
  ingnoreAmount = [],
  currentValue, // Optional
}) => {
  // Validation function
  const validateAmount = (amount: any, currentValue: any) => {
    if (currentValue && parseFloat(amount) > parseFloat(currentValue)) {
      toast.error("Investment amount cannot exceed the current value.");
      return false;
    }
    return true;
  };

  // Handle amount button click
  const handleClick = (amount: string) => {
    if (validateAmount(amount, currentValue)) {
      const newValue = amount === selectedAmount ? "" : amount;
      setFieldValue(fieldName, newValue);
      setSelectedAmount(newValue);
    }
  };

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    // if (validateAmount(newValue, currentValue)) {
      setFieldValue(fieldName, newValue);
      setSelectedAmount(newValue);
    // }
  };

// Ensure currentValue is a valid number, otherwise default to 0
const currentAmount = currentValue ? parseFloat(currentValue) : 0;

// Filter out ignored amounts based on currentAmount
const filteredAmountOptions =
    currentAmount > 0
      ? amountOptions.filter((option) => parseFloat(option.value) <= currentAmount)
      : amountOptions;

console.log(filteredAmountOptions, "filtered Amount Options");

  return (
    <>
      <div className="flex flex-col">
        <FormInput
          label={label}
          type="number"
          id={fieldName}
          name={fieldName}
          onChange={handleInputChange}
          value={selectedAmount}
          disabled={fieldDisabled}
          error={errors[fieldName]}
          touched={touched[fieldName]}
        />
      </div>
      <div className="flex justify-between items-center">
        <h6
          className="font-medium text-xl mb-5"
          style={{ color: colors.darkGrey }}
        >
          OR Select recommended amount
        </h6>
      </div>
      <div className="flex flex-wrap gap-4">
        {filteredAmountOptions.map((amt, index) => (
          <span key={index} onClick={() => handleClick(amt.value)} className="my-2">
            <div
              className={`items-center py-4 flex flex-col gap-y-2 rounded-md border border-solid bg-opacity-80 min-w-[150px] md:min-w-[200px] ${selectedAmount === amt.value
                ? "bg-lightBg text-primary border-primary"
                : "bg-white text-black"
                }`}
            >
              <div className="text-xl font-medium">Rs. {amt.label}</div>
            </div>
          </span>
        ))}
      </div>
    </>
  );
};

export default AmountButtons;