import api from "../../config/apiConfig";


const addLlp = async (data: FormData) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/org/addLLP", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
};

const llpService = { addLlp };
export default llpService;