import React, { FC, useEffect, useRef, useState } from "react";
import FormDatePicker from "../../../components/FormDatePicker/FormDatePicker";
import {
  Checkbox,
  FormControlLabel,
  Switch,
  TextFieldProps,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import person from "../../../assets/icons/f7_person-2 blue.png";
import { colors } from "../../../constants/colors";

import { Formik } from "formik";
import * as yup from "yup";

import dayjs from "dayjs";
import styles from "../Individual.module.css";
import ZeroDocKyc from "../ZeroDocKyc/ZeroDocKyc";
import ManualKyc from "../ManualKyc/ManualKyc";
import file from "../../../assets/icons/file.png";
import Buttons from "../../../components/Buttons/IndividualButtons/Buttons";
import { useDispatch } from "react-redux";
import { getUserById, updateUser } from "../../../redux/user/userSlice";
import PhoneInput from "react-phone-number-input";
import { E164Number } from "libphonenumber-js/core";
import { useUserData } from "../../../hooks/useUserData";
import toast from "react-hot-toast";
import { FormInput } from "../../../components/FormInput/FormInput";
import FormSelect from "../../../components/FormSelect/FormSelect";
import { City, State } from "country-state-city";
import { IndianStates, getKeyByValue } from "../../../enums/states";
import { Button } from "../../../components";
import ConfirmationModal from "../../../components/modals/PopupModal";
import { getStatesOfCountry } from "country-state-city/lib/state";

interface InfoSectionProps {
  icon: string;
  title: string;
  bgColor: string;
}

interface FormInputProps {
  label: string;
  onChange?: any;
  value?: any;
  name?: string;
  type?: string;
  disabled?: boolean;
}

interface GeneralDetailsFormProps {
  activeSection: string; // Prop to indicate the active section
  onProceed: () => void;
  passingData: any;
  userData: any;
  isVerified: any;
  setIsVerified: any;
}

const userValues = {
  // firstName: "",
  // lastName: "",
  // email: "",
  mobile: "",
  // dateOfBirth: dayjs("2000-01-01").format("DD-MM-YYYY"),
  // gender: "",
  // dateOfBirth: dayjs("2000-01-01").format("DD/MM/YYYY"),
  gender: "male",
  addressType: 1,
  address1: "",
  address2: "",
  //address3: "",
  city: "",
  state: "",
  pincode: "",
  wealthSource: "",
  incomeSlab: "",
  occupation: "",
  politicalExposed: "0",
  isNRI: "1",
  fullName: "",
};

const userSchema = yup.object({
  // firstName: yup.string().required(),
  // lastName: yup.string().required(),
  // email: yup.string().email().required(),
  mobile: yup.string().required(),
  // dateOfBirth: yup.string().required(),
  gender: yup.string().required(),
  addressType: yup.string().required(),
  address1: yup.string().required("Address1 is required"),
  address2: yup.string().required("Address2 is required"),
  //address3: yup.string().required(),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  pincode: yup.string().required("Pincode is required"),
  wealthSource: yup.string().required(),
  incomeSlab: yup.string().required(),
  occupation: yup.string().required(),
  politicalExposed: yup.number().required(),
  isNRI: yup.number().required(),
  // fullName: yup.string(),
});

//CH11/5 - remove below code if it is  no longer required

// const FormInput: FC<FormInputProps> = ({
//   label,
//   onChange,
//   value,
//   name,
//   type,
//   disabled,
// }) => (
//   <div className="w-full sm:w-3/4 mx-auto mb-4 sm:mb-0">
//     {" "}
//     {/* Adjusted width for small screens */}
//     <div className="flex flex-col text-lg md:mb-10">
//       <label
//         htmlFor={label.replace(/ /g, "").toLowerCase()}
//         style={{ color: colors.darkGrey }}
//       >
//         {label}
//       </label>
//       {type === "phone" ? (
//         <PhoneInput
//           type={type}
//           name={name}
//           defaultCountry="IN"
//           onChange={onChange}
//           value={value}
//           disabled={disabled}
//           style={{
//             color: colors.darkGrey,
//             border: `1px solid ${colors.darkBg}`,
//           }}
//           className={`w-full mt-3 bg-white rounded-lg border border-solid p-4 input-phone-number`}
//         />
//       ) : (
//         <input
//           className={`w-full mt-3 bg-white rounded-lg border border-solid p-4`}
//           style={{
//             color: colors.darkGrey,
//             border: `1px solid ${colors.darkBg}`,
//           }}
//           type="text"
//           id={label.replace(/ /g, "").toLowerCase()}
//           aria-label={label}
//           onChange={onChange}
//           value={value}
//           name={name}
//           disabled={disabled}
//         />
//       )}
//     </div>
//   </div>
// );

const GeneralDetailsForm: FC<GeneralDetailsFormProps> = ({
  activeSection,
  onProceed,
  userData,
  isVerified,
  setIsVerified,
}) => {
  const updateUserRef = useRef<any>();
  const formikRef = useRef<any>();
  const dispatch = useDispatch<any>();
  const user = useUserData();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [states, setStates] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const[availableData,setAvailableData]=useState(false);

  const [alignment, setAlignment] = React.useState("web");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [generalData, setGeneralData] = useState<any>({
    // fullName: "",
    // email: "",
    mobile: "",
    // dateOfBirth: "",
    gender: "",
    addressType: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    pincode: "",
    wealthSource: "",
    incomeSlab: "",
    occupation: "",
    politicalExposed: "",
    isNRI: "",
  });

  useEffect(() => {
    setAvailableData(userData?.mobile!=="" && userData?.gender !== "" && userData?.addressType>=0 && userData?.address1!=="" &&  userData?.address2!=="" && userData?.pincode!=="" && userData?.state!=="" && userData?.city!=="" && userData?.wealthSource!=="" && userData?.incomeSlab!=="" && userData?.occupation!=="" && userData?.politicalExposed?.toString()!=="" && userData?.isNRI?.toString()!=="");
  }, [userData]);

  const isGeneralDataComplete = () => {
    console.log(generalData, 'genearalData');
    // Iterate over the values and check if any are empty
    return Object.values(generalData).every((value) => value !== "");
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  // useEffect(() => {
  //   const getToastMessage = () => {
  //     if (isVerified.isAadharVerified && isVerified.isPanVerified) {
  //       toast.success("Aadhar and Pan are verified");
  //     } else if (isVerified.isAadharVerified && !isVerified.isPanVerified) {
  //       toast.error("Please verify Pan to proceed");
  //     } else if (!isVerified.isAadharVerified && isVerified.isPanVerified) {
  //       toast.error("Please verify Aadhar to proceed");
  //     }
  //   };

  //   getToastMessage();
  // }, [isVerified]);

  const [selectedOption, setSelectedOption] = useState<string>("manual");
  useEffect(() => {
    dispatch(getUserById({}));
    getStates();
  }, []);

  useEffect(() => {
    console.log(user, "user");
    // if (userData?.firstName) {
    //   formikRef?.current?.setFieldValue("firstName", userData?.firstName);
    //   addGeneralData("fullName", userData?.firstName);
    // }
    // if (userData?.email) {
    //   formikRef?.current?.setFieldValue("email", userData?.email);
    //   addGeneralData("email", userData?.email);
    // }
    if (userData?.mobile) {
      formikRef?.current?.setFieldValue("mobile", userData?.mobile);
      addGeneralData("mobile", userData?.mobile);
    }
    // if (userData?.dateOfBirth) {
    //   formikRef?.current?.setFieldValue(
    //     "dateOfBirth",
    //     dayjs(userData?.dateOfBirth).format("DD/MM/YYYY")
    //   );
    //   addGeneralData("dateOfBirth", userData?.dateOfBirth);
    // }
    if (userData?.gender) {
      formikRef?.current?.setFieldValue(
        "gender",
        userData?.gender === 1 ? "male" : userData?.gender === 2 ? "female" : ""
      );
      addGeneralData(
        "gender",
        userData?.gender === 1 ? "male" : userData?.gender === 2 ? "female" : ""
      );
    }
    if (userData?.addressType && userData?.addressType !== -1) {
      formikRef?.current?.setFieldValue("addressType", userData?.addressType);
      addGeneralData("addressType", userData?.addressType);
    }
    if (userData?.address1) {
      formikRef?.current?.setFieldValue("address1", userData?.address1);
      addGeneralData("address1", userData?.address1);
    }
    if (userData?.address2) {
      formikRef?.current?.setFieldValue("address2", userData?.address2);
      addGeneralData("address2", userData?.address2);
    }
    /* 
    if (userData?.address3) {
      formikRef?.current?.setFieldValue("address3", userData?.address3);
    }*/
    if (userData?.pincode) {
      formikRef?.current?.setFieldValue("pincode", userData?.pincode);
      addGeneralData("pincode", userData?.pincode);
    }
    if (userData?.state) {
      const state = getKeyByValue(userData?.state);
      formikRef?.current?.setFieldValue("state", state);
      addGeneralData("state", state);
      getCities(state);
    }
    if (userData?.city) {
      formikRef?.current?.setFieldValue("city", userData?.city);
      addGeneralData("city", userData?.city);
    }

    if (userData?.wealthSource) {
      formikRef?.current?.setFieldValue("wealthSource", userData?.wealthSource);
      addGeneralData("wealthSource", userData?.wealthSource);
    }
    if (userData?.incomeSlab) {
      formikRef?.current?.setFieldValue("incomeSlab", userData?.incomeSlab);
      addGeneralData("incomeSlab", userData?.incomeSlab);
    }
    if (userData?.occupation) {
      formikRef?.current?.setFieldValue("occupation", userData?.occupation);
      addGeneralData("occupation", userData?.occupation);
    }
    if (userData?.occupation) {
      formikRef?.current?.setFieldValue(
        "politicalExposed",
        userData?.politicalExposed?.toString()
      );
      addGeneralData(
        "politicalExposed",
        userData?.politicalExposed?.toString()
      );
    }
    if (userData?.occupation) {
      formikRef?.current?.setFieldValue("isNRI", userData?.isNRI?.toString());
      addGeneralData("isNRI", userData?.isNRI?.toString());
    }
  }, [userData]);

  const addGeneralData = (key: string, value: string) => {
    setGeneralData((prevData: any) => ({ ...prevData, [key]: value }));
  };

  const handleRadioChange = (option: string) => {
    setSelectedOption(option);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleModalConfirm = () => {
    setIsModalVisible(false); // Hide the modal after confirmation
    if (formikRef.current) {
      console.log("formikRef", formikRef);
      formikRef.current?.handleSubmit(); // Submit the form upon confirmation
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Hide the modal if user cancels
  };

  const handleSaveAndProceed = () => {
    // generalDetailsRef.current?.handleSubmit();
    setIsModalVisible(true); // Show the modal when the button is clicked
  };

  const checkAlreadySubmitted = () => {
    if (isGeneralDataComplete()) {
      onProceed();
    } else {
      handleSaveAndProceed();
    }
  };

  const getStates = () => {
    setStates(
      State.getStatesOfCountry("IN").map((state) => {
        return {
          label: state.name,
          value: state.name,
          isoCode: state.isoCode,
          code: IndianStates[state.name],
        };
      })
    );
  }

  const getCities = (state: any) => {
    const currentState = getStatesOfCountry('IN').filter((item: any) => {
      return item.name === state;
    })[0];
    setCities(
      City.getCitiesOfState("IN", currentState.isoCode).map((x: any) => {
        return { label: x.name, value: x.name };
      })
    );
  };

  const fetchCityAndState = (pincode: string) => {
    if (!pincode || pincode.length !== 6) {
      return;
    }
    fetch(`https://api.postalpincode.in/pincode/${pincode}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "data");
        if (data[0].Status === "Success") {
          const { PostOffice } = data[0];
          if (PostOffice.length > 0) {
            formikRef.current.setFieldValue("state", PostOffice[0].State);
            getCities(PostOffice[0].State);
            if (PostOffice[0].District === "Bangalore") {
              formikRef.current.setFieldValue("city", "Bengaluru");
            }
            formikRef.current.setFieldValue("city", PostOffice[0].District);
          } else {
            toast("No data found for this pincode");
          }
        } else {
          toast("Invalid pincode");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    // { value: "other", label: "Other" },
  ];
  const [passingData, setPassingData] = useState({});
  // console.log("passingData", passingData);

  const addressTypes = [
    { value: 1, label: "Residential or Business" },
    { value: 2, label: "Residential" },
    { value: 3, label: "Business" },
    { value: 4, label: "Registered Office" },
    { value: 5, label: "Prefer not to say" },
  ];

  const wealthSource = [
    { value: "01", label: "Salary" },
    { value: "02", label: "Business Incode" },
    { value: "03", label: "Gift" },
    { value: "04", label: "Ancestral Property" },
    { value: "05", label: "Rental Income" },
    { value: "06", label: "Prize Money" },
    { value: "07", label: "Royalty" },
    { value: "08", label: "Others" },
  ];

  const incomeSlab = [
    { value: "31", label: "Below 1 Lakh" },
    { value: "32", label: "1 to 5 Lacs" },
    { value: "33", label: "5 to 10 Lacs" },
    { value: "34", label: "10 to 25 Lacs" },
    { value: "35", label: "25 Lacs to 1 Crore" },
    { value: "36", label: "Above 1 Crore" },
  ];

  const occupation = [
    { value: "01", label: "Business" },
    { value: "02", label: "Service" },
    { value: "03", label: "Professional" },
    { value: "04", label: "Agriculturist" },
    { value: "05", label: "Retired" },
    { value: "06", label: "Housewife" },
    { value: "07", label: "Student" },
    { value: "08", label: "Others" },
    { value: "09", label: "Doctor" },
    { value: "41", label: "Private Sector Service" },
    { value: "42", label: "Public Sector Service" },
    { value: "43", label: "Forex Dealer" },
  ];

  return (
    <>
      <div className="flex flex-col w-full max-w-screen-xl my-8">
        <Formik
          innerRef={formikRef}
          initialValues={userValues}
          validationSchema={userSchema}
          onSubmit={async (values, { setSubmitting }) => {
            console.log(values, "user values");
            const payload = {
              ...values,
              state: IndianStates[values.state],
            };
            const response: any = await dispatch(updateUser(payload));
            if (response?.error?.message) {
              toast.error("Failed to update details");
              return;
            }
            onProceed();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => {
            const detailsPassing = ({
              field,
              value,
            }: {
              field: string;
              value: any;
            }) => {
              setPassingData((prevData) => ({ ...prevData, [field]: value }));
            };
            return (
              <>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    console.log(values, "values");
                    console.log(errors, "errors");
                    handleSubmit();
                  }}
                  className="w-full flex flex-col gap-[31px]"
                >
                  <div className="flex flex-row flex-wrap gap-8 justify-center">
                    {/* Inputs for screens above 768px */}
                    {/*<div className="w-full max-w-[500px]">
                      <FormInput
                        label="Full Name as per PAN *"
                        name="firstName"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChange("firstName")(e.target.value);
                          detailsPassing({
                            field: "firstName",
                            value: e.target.value,
                          });
                        }}
                        value={values.firstName}
                        disabled={true}
                        error={errors.firstName}
                        touched={touched.firstName}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Email ID *"
                        name="email"
                        onChange={handleChange("email")}
                        value={values.email}
                        disabled={true}
                        error={errors.email}
                        touched={touched.email}
                      />
                    </div> */ }
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Phone *"
                        type="phone"
                        name="mobile"
                        onChange={handleChange("mobile")}
                        value={values.mobile}
                        disabled={userData?.mobile!=""}
                        error={errors.mobile}
                        touched={touched.mobile}
                      />
                    </div>
                    {/* <div className="w-full max-w-[500px]">
                      <FormDatePicker
                        label="DOB *"
                        onChange={(date) => {
                          const formattedDate = date
                            ? date.format("DD/MM/YYYY")
                            : "";
                          handleChange("dateOfBirth")(formattedDate);
                          detailsPassing({
                            field: "dateOfBirth",
                            value: formattedDate,
                          });
                          setFieldValue("dateOfBirth", formattedDate);
                        }}
                        value={
                          values.dateOfBirth
                            ? dayjs(values.dateOfBirth, "DD-MM-YYYY")
                            : null
                        }
                        defaultValue={dayjs("2000-01-01", "DD-MM-YYYY")}
                        disabled={!!generalData?.dateOfBirth}
                        error={errors.dateOfBirth}
                        touched={touched.dateOfBirth}
                      />
                    </div> */}
                    <div className="w-full max-w-[500px]">
                      {" "}
                      {/* Adjusted width for Gender */}
                      <FormSelect
                        label="Gender *"
                        options={genderOptions}
                        onChange={handleChange("gender")}
                        value={values.gender}
                        disabled={userData?.gender!==-1}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormSelect
                        label="Address Type *"
                        options={addressTypes}
                        onChange={handleChange("addressType")}
                        value={values.addressType}
                        disabled={userData?.addressType!==-1}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Pincode *"
                        name="pincode"
                        onChange={(e: any) => {
                          setFieldValue("pincode", e.target.value);
                          fetchCityAndState(e.target.value);
                        }}
                        value={values.pincode}
                        disabled={userData?.pincode!==null}
                        error={errors.pincode}
                        touched={touched.pincode}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormSelect
                        label="State *"
                        name="state"
                        onChange={(e: any) => {
                          console.log(e.target.value, "e");
                          setFieldValue("state", e.target.value);
                          getCities(e.target.value);
                        }}
                        value={values.state}
                        disabled={userData?.state!==null}
                        error={errors.state}
                        touched={touched.state}
                        options={states}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormSelect
                        label="City *"
                        name="city"
                        onChange={handleChange("city")}
                        value={values.city}
                        disabled={userData?.city!==null}
                        error={errors.city}
                        touched={touched.city}
                        options={cities}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Address 1 *"
                        name="address1"
                        onChange={handleChange("address1")}
                        value={values.address1}
                        disabled={userData?.address1!==""}
                        error={errors.address1}
                        touched={touched.address1}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Address 2 *"
                        name="address2"
                        onChange={handleChange("address2")}
                        value={values.address2}
                        disabled={userData?.address2!==""}
                        error={errors.address2}
                        touched={touched.address2}
                      />
                    </div>
                    <div className="w-full max-w-[500px]"></div>
                  </div>
                  <div className="pl-32">
                    <h4 className="font-inter font-semibold text-xl">
                      FATCA Details
                    </h4>
                  </div>
                  <div className="flex flex-row flex-wrap gap-8 justify-center">
                    <div className="w-full max-w-[500px]">
                      <FormSelect
                        label="Wealth Source *"
                        options={wealthSource}
                        onChange={handleChange("wealthSource")}
                        value={values.wealthSource}
                        disabled={userData?.wealthSource!==""}
                        error={errors.wealthSource}
                        touched={touched.wealthSource}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormSelect
                        label="Occupation *"
                        options={occupation}
                        onChange={handleChange("occupation")}
                        value={values.occupation}
                        disabled={userData?.occupation!==null}
                        error={errors.occupation}
                        touched={touched.occupation}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormSelect
                        label="Income Slab *"
                        options={incomeSlab}
                        onChange={handleChange("incomeSlab")}
                        value={values.incomeSlab}
                        disabled={userData?.incomeSlab!==""}
                        error={errors.incomeSlab}
                        touched={touched.incomeSlab}
                      />
                    </div>
                    <div className="w-full max-w-[500px]"></div>
                    <div
                      style={{ width: "100%" }}
                      className="md:px-32 flex flex-col gap-8"
                    >
                      <div className="flex flex-col md:flex-row md:ml-20 justify-between items-center">
                        <label htmlFor="">
                          Politically exposed person /Related to Politically
                          exposed person *
                        </label>
                        <ToggleButtonGroup
                          color="primary"
                          value={values.politicalExposed}
                          exclusive
                          onChange={handleChange("politicalExposed")}
                          // disabled={!!generalData?.politicalExposed}
                          aria-label="Platform"
                          sx={{
                            "& .css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                              {
                                backgroundColor: colors.primary,
                                color: colors.white,
                                fontWeight: "bold",
                              },
                          }}
                        >
                          <ToggleButton value="1">Yes</ToggleButton>
                          <ToggleButton value="0">No</ToggleButton>
                        </ToggleButtonGroup>
                        {/* <FormControlLabel
                        required
                        control={
                          <Switch
                            sx={{ color: colors.orange }}
                            onChange={(e) => {
                              setFieldValue(
                                "politicalExposed",
                                e.target.checked ? 1 : 0
                              );
                            }}
                            checked={values.politicalExposed === 1}
                          />
                        }
                        label="Politically exposed person /Related to Politically exposed person *"
                        sx={{ color: colors.darkGrey }}
                        className="text-sm md:text-lg"
                        disabled={isFieldDisabled}
                      /> */}
                      </div>
                      <div className="flex flex-col md:flex-row md:ml-20 justify-between items-center">
                        <label htmlFor="">
                          I am an Indian Citizen and a Tax Resident of India and
                          no other country citizenship *
                        </label>
                        <ToggleButtonGroup
                          color="primary"
                          value={values.isNRI}
                          exclusive
                          onChange={handleChange("isNRI")}
                          // disabled={!!generalData?.isNRI}
                          aria-label="Platform"
                          sx={{
                            "& .css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                              {
                                backgroundColor: colors.primary,
                                color: colors.white,
                                fontWeight: "bold",
                              },
                          }}
                        >
                          <ToggleButton value="1">Yes</ToggleButton>
                          <ToggleButton value="0">No</ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                      {/* <div className="flex flex-col md:flex-row md:ml-20">
                        <FormControlLabel
                          required
                          control={
                            <Switch
                              sx={{ color: colors.orange }}
                              onChange={(e) => {
                                setFieldValue(
                                  "fatca",
                                  e.target.checked ? 1 : 0
                                );
                              }}
                              checked={values.fatca === 1}
                            />
                          }
                          label="I am an Indian Citizen and a Tax Resident of India and no other country citigenship *"
                          sx={{ color: colors.darkGrey }}
                          className="text-sm md:text-lg"
                          disabled={isFieldDisabled}
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="pl-32">
                    <Button
                      type="button"
                      className={`px-8 py-4 rounded-md bg-darkGrey text-white hover:bg-primary transition-all max-w-[250px] w-full mt-12`}
                      tabIndex={0}
                      onClick={checkAlreadySubmitted}
                      //onClick={() => updateUserRef.current.click()} // Add onClick event handler
                      // Disable button if radio is not selected
                    >
                      {availableData?"Proceed": "Save and Proceed"}
                    </Button>
                  </div>
                  <ConfirmationModal
                    isVisible={isModalVisible} // Render conditionally based on state
                    message="Are you sure all fields are correct and verified? After you proceed to the next screen, fields cannot be edited again."
                    onConfirm={handleModalConfirm} // Confirm action and submit form
                    onCancel={handleModalCancel} // Cancel action and close modal
                  />
                </form>
              </>
            );
          }}
        </Formik>
        {/* <div
        className="flex flex-col text-lg w-full max-w-[1440px] mx-auto"
        style={{ color: colors.darkGrey }}
      >
        <div className="w-full sm:w-5/6 mx-auto sm:mb-0 ">
          <div className="sm:text-3xl text-xl font-medium text-center text-black-800 max-md:max-w-full sm:text-left sm:mb-20 sm:ml-0 ">
            Aadhar and PAN Details
          </div>
        </div>
      </div>

      {activeSection === "general" && (
        <div className="w-full max-w-[1440px] mx-auto ">
          <main
            className={`flex flex-col lg:flex-row justify-center sm:gap-7 md:ml-10 md:mr-20 mb-24 max-md:mt-10 max-w-full ${styles.zeroManual}`}
          >
            <div className=" flex justify-center ">
              <input
                type="radio"
                id="zeroDocKYC"
                name="kycOption"
                value="zeroDoc"
                checked={selectedOption === "zeroDoc"}
                onChange={() => handleRadioChange("zeroDoc")}
                className="mt-9 sm:w-full mb-4"
                disabled={isFieldDisabled}
              />
            </div>
            <label className="sm:w-full " htmlFor="zeroDocKYC">
              <div
                className={`px-6 py-3.5 bg-white rounded-lg border border-solid border-stone-300 max-md:max-w-full flex flex-col md:flex-row gap-5 items-center`}
                style={{ backgroundColor: colors.white }}
              >
                <img
                  src={`${file}`}
                  alt="KYC Verification Icon"
                  className="w-16 h-16 sm:w-auto sm:h-auto flex-shrink-0"
                  style={{ maxWidth: "4rem", maxHeight: "4rem", margin: 0 }}
                />
                <div className="flex flex-col flex-grow self-stretch">
                  <h2
                    className="text-base font-bold text-cyan-800 whitespace-normal"
                    style={{ color: colors.darkGrey }}
                  >
                    Zero Doc KYC
                  </h2>
                  <p
                    className={`mt-2 leading-5  sm:text-base text-xs`}
                    style={{ color: colors.darkGrey }}
                  >
                    You must have a valid Aadhaar number linked with your mobile
                    number.
                  </p>
                </div>
              </div>
            </label>
            <div className="pl-24"></div>
            <div className=" flex justify-center">
              <input
                type="radio"
                id="manualKYC"
                name="kycOption"
                value="manual"
                checked={selectedOption === "manual"}
                onChange={() => handleRadioChange("manual")}
                className="mt-9 sm:w-full mb-4"
                disabled={isFieldDisabled}
              />
            </div>
            <label className="sm:w-full" htmlFor="manualKYC">
              <div
                className={`px-6 py-3.5 bg-white rounded-lg border border-solid border-stone-300 max-md:max-w-full flex flex-col md:flex-row gap-5 items-center`}
                style={{ backgroundColor: colors.white }}
              >
                <img
                  src={`${person}`}
                  alt="KYC Verification Icon"
                  className="w-16 h-16 sm:w-auto sm:h-auto flex-shrink-0"
                  style={{ maxWidth: "4rem", maxHeight: "4rem", margin: 0 }}
                />
                <div className="flex flex-col flex-grow self-stretch ">
                  <h2
                    className="text-base font-bold whitespace-nowrap"
                    style={{ color: colors.darkGrey }}
                  >
                    Manual KYC
                  </h2>
                  <p
                    className="mt-2 leading-5 sm:text-base text-xs"
                    style={{ color: colors.darkGrey }}
                  >
                    Valid ID proof options include Aadhaar and PAN card.
                  </p>
                </div>
              </div>
            </label>
          </main>
          {selectedOption === "manual" && (
            <ManualKyc
              updateUserRef={updateUserRef}
              selectedOption={selectedOption}
              onProceed={onKYCAllow}
              passingData={passingData}
              generalDetailsRef={formikRef}
              kycData={userData?.kyc}
              isVerified={isVerified}
            />
          )}

          {selectedOption === "zeroDoc" && (
            <div className="items-center mb-10 ml-2 sm:ml-36 md:mt-10">
              <Buttons
                onDenyClick={() => {}}
                onAllowClick={onKYCAllow} // Pass the function to handle "Allow" click
                radioSelected={selectedOption === "zeroDoc"} // Pass true if zeroDoc is selected
              />
            </div>
          )}
        </div>
      )} */}
      </div>
    </>
  );
};

export default GeneralDetailsForm;
