import React from 'react';
import { colors } from "../../constants/colors";
import { Dialog, DialogActions, DialogContent, Typography, Button, SxProps } from '@mui/material';

type CustomDialogProps = {
  open: boolean;
  errormessage: string;
  button: boolean;
  handleDialogClose: () => void;
  handleClose: () => void;
  openAuthLink?: () => void;
  openPaymentTab?:()=>void;
};

const CustomDialog: React.FC<CustomDialogProps> = ({
  open,
  errormessage,
  button,
  handleDialogClose,
  handleClose,
  openAuthLink,
  openPaymentTab,
}) => {

  const btnStyle: SxProps = {
    backgroundColor: colors.primary,
    borderRadius: "40px",
    color: colors.lightBg,
    padding: "10px",
    "&:hover": {
      backgroundColor: colors.primary,
      color: colors.lightBg,
    },
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      disableEscapeKeyDown
    >
      <DialogContent>
        <Typography variant="h6" className="font-bold mt-3 text-center">
          {errormessage}
        </Typography>
      </DialogContent>
      <DialogActions>
        {button ? (
          <Button onClick={openPaymentTab} sx={btnStyle}>
            Ok
          </Button>
        ) : (
          <>
            <Button onClick={openAuthLink} sx={btnStyle}>
              Open Authorization Link
            </Button>
            <Button onClick={handleClose} sx={{ ...btnStyle, ml: "10px" }}>
              Close
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
