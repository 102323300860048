import React, { FC, useEffect, useRef, useState } from "react";
import { colors } from "../../constants/colors";
import { Button } from "../Button";
import * as yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import {
  InvestRequest
} from "../../redux/Bond/BONDSlice";
import MFDetailCard from "../MFTransaction/MFDetailCard";
import { hideLoading, showLoading } from "../../redux/loader/loaderSlice";
import { useNavigate } from "react-router-dom";
import { FormInput } from "../FormInput/FormInput";
import AmountButtons from "../MFTransaction/AmountButtons";
import APIloader from "../AppLoader/APIloader";
import Dialog from "@mui/material/Dialog";
import { Button as Btn } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { fetchDashboardData } from "../../redux/Dashboard/DashboardSlice";
import BondDetailCard from "./BondDetailCard";
import { showInterest } from "../../redux/user/userSlice";

interface MFProps {
  fundId: number;
  fundInfo: any;
  onClose?: any;
}

const InvestmentPage: FC<MFProps> = ({ fundId, fundInfo, onClose }) => {
  const ref = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  // const lumpsumValues = {
  //   orderType: 1,
  //   fundId: fundId,
  //   amount: "",
  //   folioNumber: "",
  //   dividend: "",
  //   bankAccount: "",
  // };

  const [investmentPlan, setInvestmentPlan] =
    useState<string>("New investment");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<any>(null);
  const [investmentPeriod, setInvestmentPeriod] = useState<number | null>(null);
  const [lumpsumValues, setLumpsumValues] = useState({
    bondId: 0,
    amount: 0
  });

  const handleDialogClose = () => setShowSummary(false);

  const handleConfirm = async () => {
      if(summaryData){
        try {
          const { amount, fundId} =
            summaryData;
          
          const updatedBodyData = {
             bondId: fundInfo?.id,
             amount: parseInt(amount),
             investmentPeriod: investmentPeriod,
          };
    
          //console.log(fundInfo, "bodyData");
          setLoading(true);
          const res = await dispatch(InvestRequest(updatedBodyData));
          if (res?.payload?.data?.success === 200) {
            setLoading(false);
            toast.success("Investment Successful");
            navigate("/bond-listing");
          } else {
            setLoading(false);
            toast.error("Error in executing your order.");
          }
          onClose();
        } catch (error) {
          toast.error("Investment Failed");
        } finally {
          dispatch(hideLoading());
          handleDialogClose();
        }
      }

  };
  

  // const calculateUnits = (amount: number, nav: number) => {
  //   return (amount / nav).toFixed(2); // Returns units to two decimal places
  // };

  // const proceedToPayment = (values: any) => {
  //   const nav = fundInfo?.currentNAV; // Get current NAV from fundInfo
  //   const amount = parseFloat(values.amount); // Parse amount from form values
  //   const units = calculateUnits(amount, nav); // Calculate number of units
  //   console.log("Current NAV:", fundInfo.currentNAV);

  //   const data = {
  //     ...values,
  //     investmentPlan, // Make sure to include investmentPlan in the data object
  //     units, // Add calculated units to the data object
  //   };
  //   setSummaryData(data);
  //   setShowSummary(true);
  // };

  // useEffect(() => {
  //   if (fundInfo) {
  //     ref.current?.setFieldValue("folioNumber", fundInfo?.folioNo ?? "112233");
  //   }
  // }, [fundInfo]);

  // useEffect(() => {
  //   fetchData(); // Fetch data when component mounts or dependencies change
  // }, []);

  // const fetchData = async () => {
  //   let data = { fetchFor: "aumcapital" };
  //   try {
  //     const response = await dispatch(fetchDashboardData(data));
  //     if (response.payload.data) {
  //       setTransactionData(response.payload.data);
  //       evaluateInvestmentStatus(response.payload.data);
  //     }
  //   } catch (error) {
  //     console.error("Fetch dashboard data failed:", error);
  //     toast.error("Please Refresh");
  //     // setLoading(false);
  //   }
  // };

  // const evaluateInvestmentStatus = (data: any) => {
  //   let newInvestmentDisabled = false;

  //   // Log the entire recentTransactions array for debugging
  //   console.log("Recent Transactions Data:", data.recentTransactions);

  //   // Check if any of the MF orders are of type `isBuy` 1
  //   data.recentTransactions.forEach(
  //     (transactionGroup: any[], groupIndex: number) => {
  //       transactionGroup.forEach((order: any, orderIndex: number) => {
  //         console.log(`Processing order ${orderIndex}:`, order);

  //         if (order.orderType === "MF" && order.isBuy === 1) {
  //           console.log("Found MF order with isBuy 1:", order);
  //           newInvestmentDisabled = true;
  //         }
  //       });
  //     }
  //   );

  //   // Update the state based on the evaluation
  //   setIsNewInvestmentEnabled(!newInvestmentDisabled);

  //   // Log the final state of newInvestmentDisabled
  //   console.log("New Investment Disabled:", newInvestmentDisabled);
  // };

  const showingInterest = async () => {
    try {
      const response = await dispatch(showInterest({ id:fundId, holdingType:1 }));

      if (response?.payload?.statusCode === 200) {
        toast.success("Your Interest is submitted successfully");
      } else {
        toast.error("Something wents wrong.Please try again later!");
        console.warn('Unexpected response structure:', response);
      }
    } catch (error) {
      toast.error("Something wents wrong.Please try again later!");
      console.error("Error fetching users:", error);
    }
  };

  return (
    <>
      <div className="bg-lightBg pb-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="">
            <div className="bg-white">
              <div className="md:px-8 py-10">
                <BondDetailCard fundInfo={fundInfo} />
                <div className="my-5">
                  <Formik
                    innerRef={ref}
                    initialValues={lumpsumValues}
                    onSubmit={async () => {                  
                      // Show summary dialog
                      setSummaryData({ 
                        amount: selectedAmount, 
                        fundId: fundInfo?.id 
                      });
                      setShowSummary(true);
                    }}
                    // validationSchema={validationSchema}
                    // onSubmit={async (values: any) => {
                    //   try {
                    //     // const isValid = await validationSchema.isValid(values);
                    //     if (isValid) {
                    //       // proceedToPayment(values);
                    //     } else {
                    //       // Form validation failed, do something (optional)
                    //     }
                    //   } catch (error: any) {
                    //     toast.error("Investment Failed");
                    //   } finally {
                    //     setSubmitting(false);
                    //   }
                    // }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit} className="mt-5">
                        <AmountButtons
                          label="Amount"
                          setFieldValue={setFieldValue}
                          selectedAmount={selectedAmount}
                          setSelectedAmount={setSelectedAmount}
                          fieldName="amount"
                          values={values}
                          errors={errors}
                          touched={touched}
                        />
                        {/* Investment Period Dropdown */}
                        <div className="mt-4">
                          <label
                            htmlFor="investmentPeriod"
                            className="block text-xl font-medium text-gray-700"
                          >
                            Investment Period
                          </label>
                          <select
                            id="investmentPeriod"
                            name="investmentPeriod"
                            value={investmentPeriod || ""}
                            onChange={(e) =>
                              setInvestmentPeriod(parseInt(e.target.value))
                            }
                            className="mt-2 block w-full pl-3 pr-10 py-4 text-base border-2 border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-md rounded-md text-gray-700"
                          >
                            <option value="" disabled>
                              Select investment period
                            </option>
                            {Array.from({ length: 15 }, (_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1} year{i + 1 > 1 ? "s" : ""}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Display chosen investment period */}
                        {investmentPeriod && (
                          <p className="mt-2 text-md  text-gray-700">
                            You choose investment period for the next{" "}
                            {investmentPeriod} year
                            {investmentPeriod > 1 ? "s" : ""}.
                          </p>
                        )}
                        <section className="mt-10 mb-5 max-md:mt-5 max-md:mr-2.5 max-md:max-w-full">
                          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col max-md:ml-0 max-md:w-full">
                              <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                                <div className="flex gap-5 justify-between lg:mt-2 text-sm max-md:mt-2 lg:mb-5">
                                  <Button
                                    type="submit"
                                    className={`grow justify-center px-8 py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary`}
                                    tabIndex={0}
                                  >
                                    Invest
                                    {/* Proceed to payment */}
                                  </Button>
                                  <Button
                                  type="button"
                                    className={`grow justify-center px-8 py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary`}
                                    onClick={showingInterest}
                                  >
                                    Show Interest
                                    {/* Proceed to payment */}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <APIloader loadingModal={loading} message={""}/>

      {/* Summary Dialog */}
      <Dialog
        open={showSummary}
        onClose={handleDialogClose}
        className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
      >
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 max-w-2xl relative flex flex-col">
            <Typography variant="h4" className="font-bold mb-4">
              Summary
            </Typography>
            <DialogContent className="flex flex-col">
              {summaryData ? (
                <>
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">
                      Investment Plan:
                    </span>
                    <span>{fundInfo?.Bond}</span>
                  </div>
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">Amount:</span>
                    <span>Rs. {summaryData?.amount}</span>
                  </div>
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">Investment Period:</span>
                    <span>{investmentPeriod && (
                          <span>
                            {investmentPeriod} year
                            {investmentPeriod > 1 ? "s" : ""}
                          </span>
                        )}</span>
                  </div>
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">
                      Yield:
                    </span>
                    <span>{fundInfo?.Yield}%</span>
                  </div>
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">
                      Price:
                    </span>
                    <span>Rs.{fundInfo?.Price}</span>
                  </div>
                </>
              ) : (
                <div className="flex justify-center items-center">
                  <span>Loading...</span>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Btn
                onClick={handleConfirm}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Confirm
              </Btn>
              <Btn
                onClick={handleDialogClose}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Cancel
              </Btn>
            </DialogActions>
            {/* <Typography variant="body2" className="mb-4" sx={{ marginTop: 4 }}>
              Once confirmed, you will be redirected to the banking payment page
              for transaction
            </Typography> */}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default InvestmentPage;
