const formatDate = (dateString: string | undefined) => {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
};

const revertDateFormat = (dateString: string | undefined) => {
    if (!dateString) return '';
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
};

const durationCounter = (dateString: string | undefined): string => {
    if (!dateString) return '';

    const [year, month, day] = dateString.split('-').map(Number);
    const startDate = new Date(year, month - 1, day); 
    const currentDate = new Date();

    let age = currentDate.getFullYear() - startDate.getFullYear();
    const monthDiff = currentDate.getMonth() - startDate.getMonth();
    const dayDiff = currentDate.getDate() - startDate.getDate();


    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
    }

    return `${age} years`;
};


const DateFormat = { formatDate, revertDateFormat , durationCounter };
export default DateFormat;