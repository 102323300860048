import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFDinfo } from '../../redux/FD/FDSlice';
import { hideLoading, showLoading } from '../../redux/loader/loaderSlice';
import { MFAssetHoldings } from '../../redux/Reports/ReportSlice';
import api from '../../config/apiConfig';
import BondListingComponent from '../../components/ListingTemplate/BondListingTemplate';


function BondsListing() {
    const dispatch = useDispatch<any>();
    const [totalFound, setTotalFound] = useState(0);
    const [loader, setLoader] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [countOFPages, setCountOFPages] = useState(1);
    const [inputData, setInputData] = useState({
        pageIndex: 1,
        pageSize: 100,
    });

    const breadcrumbItems = [
        { label: 'Home', href: '/' },
        { label: 'Investment', href: '/' },
        { label: 'Bonds', },
    ];
    const filterOps: any = [
        {
            name: "Fund Type",
            options: [
                {
                    name: "Equity",
                    value: 1,
                    isChecked: false,
                },
                {
                    name: "Debt",
                    value: 3,
                    isChecked: false,
                },
                {
                    name: "Hybrid",
                    value: 5,
                    isChecked: false,
                },
                {
                    name: "Solution Oriented",
                    value: 7,
                    isChecked: false,
                },
                {
                    name: "GILT",
                    value: 8,
                    isChecked: false,
                },
                {
                    name: "ELSS",
                    value: 9,
                    isChecked: false,
                },
                {
                    name: "MIP",
                    value: 10,
                    isChecked: false,
                },
                {
                    name: "Balanced",
                    value: 11,
                    isChecked: false,
                },
                {
                    name: "STP",
                    value: 12,
                    isChecked: false,
                },
                {
                    name: "FOF",
                    value: 13,
                    isChecked: false,
                },
                {
                    name: "Liquid",
                    value: 14,
                    isChecked: false,
                },
                {
                    name: "Bond",
                    value: 15,
                    isChecked: false,
                },
                {
                    name: "Income",
                    value: 16,
                    isChecked: false,
                },
            ],
        },
    ];

    const [totalEnteries, setTotalEnteries] = useState<number>();
    const [dataEnteries, setDataEnteries] = useState<any[]>();
    const [data, setData] = useState<any>();
    const FetchData = async () => {
        try {
          const accessToken = localStorage.getItem("accessToken");
          const response = await api.post("bond/getAll", {            
            inputData            
        } ,{
            headers: {
              Authorization: `${accessToken}`,
            },
          });
          setData(response?.data?.data?.bondList);
          setTotalEnteries(response?.data?.data?.bondList?.length);
          setTotalFound(response?.data?.data?.bondList?.length|| 0);
          setCountOFPages(response?.data?.data?.totalPages || 0);
        } catch (error) {
          console.error("Error uploading file:", error);
        }finally{
            setLoader(false);
        }
      };

      useEffect(() => {
        setLoader(true);
        FetchData();
      }, [])
    // const fetchData = async () => {
    //     let data = {
    //         startDate: 0,
    //         endDate: 0,
    //         fundType: 'EQUITY',
    //         transactionType: 1,
    //     };
    //     try {
    //         dispatch(showLoading());
    //         const response = await dispatch(MFAssetHoldings(data));
    //         if (response) {
    //             setDataEnteries(response?.payload || []);
    //             setTotalEnteries(response.payload?.fdList?.length || 0);
    //             dispatch()
    //         }
    //     } catch (error) {
    //         console.error("API Error:", error);
    //     } finally {
    //         dispatch(hideLoading());
    //     }
    // };

    // useEffect(() => {
    //     fetchData()
    // }, [])

    const MFTransations = [
        { key: 'schemeName', value: 'Bond Name' },
        { key: 'category', value: 'Category' },
        { key: 'minInvestment', value: 'Min. Investment' },
        { key: 'yield', value: 'Yield' },
        { key: 'price', value: 'Price' },
        { key: 'payment', value: 'Payment' },
        { key: 'coupon', value: 'Coupon' },
        { key: 'maturityDate', value: 'Maturity Date' },
        { key: 'action', value: 'Action' },
    ];

    const summary = ' Fixed Deposits (FDs) are secure and reliable investment options that offer guaranteed returns, making them an ideal choice for investors seeking capital protection and steady income. AUM Sampann provides detailed information on various Fixed Deposit options, including interest rates, tenure options, and maturity benefits, empowering you to choose the best FD scheme to suit your financial needs.'



    return (
        <BondListingComponent
            title='Bonds'
            breadcrumb={breadcrumbItems}
            filterOps={filterOps}
            totalEnteries={totalEnteries}
            dataEnteries={data}
            controlBarObj={MFTransations}
            summary={summary}
            setDataEnteries={setDataEnteries}
            // SmallChart={true}
            // QuickFilterOption={QuickFilterOption}
            // quickFilterOptionFn={quickFilterOptionFn}
            // clearFilterOptionFn={clearFilterOptionFn}
            inputData={inputData}
            setInputData={setInputData}
            // subcategory={subcategoryLabel}
            // subCategoryTable={true}
            initials={true}
            loaderData={loader}
        />
    )
}


export default BondsListing