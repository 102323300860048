import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bankService from "./bankService";
import { BankData } from "../../models/bank.model";

export const addBank = createAsyncThunk(
  "bank/add",
  async (data: FormData, thunkAPI: any) => {
    try {
      return await bankService.addBankDetails(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const verifyBankDetails = createAsyncThunk(
  "bank/verify",
  async (data: BankData, thunkAPI: any) => {
    try {
      return await bankService.verifyBankDetails(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
)

const initialState = {
  data: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const bankSlice = createSlice({
  name: "bank",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBank.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBank.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(addBank.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      }).addCase(verifyBankDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyBankDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(verifyBankDetails.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      });
  },
});

export const { resetState } = bankSlice.actions;
export default bankSlice.reducer;
