import api from "../../config/apiConfig";

const investPMSRequest = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("pms/invest", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const getTransactionInfo = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("pms/getTransactions", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};


const withdrawPMSRequest = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("pms/withdraw", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const PMSSummary = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("pms/getSummary", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const PMSService = {
  investPMSRequest,
  withdrawPMSRequest,
  getTransactionInfo,
  PMSSummary
};
export default PMSService;
