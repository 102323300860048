import React, { useEffect, useState } from 'react'
import ListingComponent from '../../components/ListingTemplate/listingTemplate';
import { useDispatch } from 'react-redux';
import { getFDinfo } from '../../redux/FD/FDSlice';
import { hideLoading, showLoading } from '../../redux/loader/loaderSlice';
import { MFAssetHoldings } from '../../redux/Reports/ReportSlice';


function EquityListing() {
    const dispatch = useDispatch<any>();
    const [inputData, setInputData] = useState({
        pageIndex: 1,
        pageSize: 100,
        query: "",
        type: "",
        tenure: "",
    });

    const breadcrumbItems = [
        { label: 'Home', href: '/' },
        { label: 'Investment', href: '/' },
        { label: 'Equity', },
    ];
    const filterOps: any = [
        {
            name: "Fund Type",
            options: [
                {
                    name: "Equity",
                    value: 1,
                    isChecked: false,
                },
                {
                    name: "Debt",
                    value: 3,
                    isChecked: false,
                },
                {
                    name: "Hybrid",
                    value: 5,
                    isChecked: false,
                },
                {
                    name: "Solution Oriented",
                    value: 7,
                    isChecked: false,
                },
                {
                    name: "GILT",
                    value: 8,
                    isChecked: false,
                },
                {
                    name: "ELSS",
                    value: 9,
                    isChecked: false,
                },
                {
                    name: "MIP",
                    value: 10,
                    isChecked: false,
                },
                {
                    name: "Balanced",
                    value: 11,
                    isChecked: false,
                },
                {
                    name: "STP",
                    value: 12,
                    isChecked: false,
                },
                {
                    name: "FOF",
                    value: 13,
                    isChecked: false,
                },
                {
                    name: "Liquid",
                    value: 14,
                    isChecked: false,
                },
                {
                    name: "Bond",
                    value: 15,
                    isChecked: false,
                },
                {
                    name: "Income",
                    value: 16,
                    isChecked: false,
                },
            ],
        },
    ];

    const [totalEnteries, setTotalEnteries] = useState<number>(20);
    const [dataEnteries, setDataEnteries] = useState<any[]>();

    // const fetchData = async () => {
    //     let data = {
    //         startDate: 0,
    //         endDate: 0,
    //         fundType: 'EQUITY',
    //         transactionType: 1,
    //     };
    //     try {
    //         dispatch(showLoading());
    //         const response = await dispatch(MFAssetHoldings(data));
    //         if (response) {
    //             setDataEnteries(response?.payload || []);
    //             setTotalEnteries(response.payload?.fdList?.length || 0);
    //             dispatch()
    //         }
    //     } catch (error) {
    //         console.error("API Error:", error);
    //     } finally {
    //         dispatch(hideLoading());
    //     }
    // };

    // useEffect(() => {
    //     fetchData()
    // }, [])

    const MFTransations = [
        { key: 'schemeName', value: 'Stock Name' },
        { key: 'folioNumber', value: 'LTP' },
        { key: 'transactionNo', value: 'Change Amount' },
        { key: 'createdAt', value: 'Change(in %)' },
        { key: 'action', value: 'Action' },
        // { key: 'orderType', value: 'Transaction Type' },
        // { key: 'subTransactionType', value: 'Sub-transaction Type' },
        // { key: 'grossPurchase', value: 'Gross Purchase /Redeem' },
        // { key: 'stampDuty', value: 'Stamp Duty' },
        // { key: 'netPurchase', value: 'Net Purchase/Redeem' },
        // { key: 'units', value: 'Units' },
        // { key: 'createdAt', value: 'NAV (Nav Date)' },
    ];
    // const subcategoryLabel = [
    //     { key: 'bseSchemeCode', value: 'bse SchemeCode' },
    //     { key: 'amcCode', value: 'amcCode' },
    //     { key: 'category', value: 'category' },
    //     { key: 'options', value: 'options' },
    // ]

    const data = [
        {
            "schemeName": "INFY",
            "folioNumber": "1000",
            "id": 12,
            "transactionNo": "10831500",
            "createdAt": "2024-04-19T13:01:04.000Z",
        },
        {
            "schemeName": "TCS",
            "folioNumber": "2000",
            "id": 12,
            "transactionNo": "10831500",
            "createdAt": "2024-04-19T13:01:04.000Z",

        },
        {
            "schemeName": "Tata Motors",
            "folioNumber": "200",
            "id": 12,
            "transactionNo": "10831500",
            "createdAt": "2024-04-19T13:01:04.000Z",

        },
        {
            "schemeName": "HDFC Bank",
            "folioNumber": "300",
            "id": 12,
            "transactionNo": "10831500",
            "createdAt": "2024-04-19T13:01:04.000Z",

        },
        {
            "schemeName": "ITC",
            "folioNumber": "5000",
            "id": 12,
            "transactionNo": "10831500",
            "createdAt": "2024-04-19T13:01:04.000Z",
        },
    ]
    const summary = ' Fixed Deposits (FDs) are secure and reliable investment options that offer guaranteed returns, making them an ideal choice for investors seeking capital protection and steady income. AUM Sampann provides detailed information on various Fixed Deposit options, including interest rates, tenure options, and maturity benefits, empowering you to choose the best FD scheme to suit your financial needs.'

    const QuickFilterOption = [
        { label: 'Gainers', value: 'Equity' },
        { label: 'Losers', value: 'Debt' },
        { label: 'Top traded (by volume)', value: 'Hybrid' },
        { label: 'Top traded (by value)', value: 'Solution oriented' },
    ]

    const quickFilterOptionFn = (e: any) => {
        console.log('Quick filter Options', e);
        setInputData({ ...inputData, type: e })
        // fetchData()
    }
    const clearFilterOptionFn = () => {
        setInputData({
            pageIndex: 1,
            pageSize: 100,
            query: "",
            type: "",
            tenure: "",
        });
        // fetchData();
    };


    return (
        <ListingComponent
            title='Equity'
            breadcrumb={breadcrumbItems}
            filterOps={filterOps}
            totalEnteries={totalEnteries}
            dataEnteries={data}
            controlBarObj={MFTransations}
            summary={summary}
            setDataEnteries={setDataEnteries}
            SmallChart={true}
            QuickFilterOption={QuickFilterOption}
            quickFilterOptionFn={quickFilterOptionFn}
            clearFilterOptionFn={clearFilterOptionFn}
            inputData={inputData}
            setInputData={setInputData}
            initials={true}
        />
    )
}

export default EquityListing;
