import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";

import DynamicBreadcrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useDispatch } from "react-redux";
import OrderHistoryTable from "../../components/OrderHistoryTable/OrderHistoryTable";

const headings = ["Date", "Type", "SchemeName", "Price", "Status"];

// Dummy data for rows
const MFrows:any = [
  {
    Date: "2024-11-01",
    Type: "Equity",
    SchemeName: "Growth Fund",
    Price: 125.5,
    Status: "Active",
  },
  {
    Date: "2024-11-02",
    Type: "Debt",
    SchemeName: "Income Fund",
    Price: 110.0,
    Status: "Inactive",
  },
  {
    Date: "2024-11-03",
    Type: "Hybrid",
    SchemeName: "Balanced Fund",
    Price: 135.75,
    Status: "Active",
  },
  {
    Date: "2024-11-04",
    Type: "Equity",
    SchemeName: "Wealth Fund",
    Price: 150.0,
    Status: "Pending",
  },
  {
    Date: "2024-11-01",
    Type: "Equity",
    SchemeName: "Growth Fund",
    Price: 125.5,
    Status: "Active",
  },
  {
    Date: "2024-11-02",
    Type: "Debt",
    SchemeName: "Income Fund",
    Price: 110.0,
    Status: "Inactive",
  },
  {
    Date: "2024-11-03",
    Type: "Hybrid",
    SchemeName: "Balanced Fund",
    Price: 135.75,
    Status: "Active",
  },
  {
    Date: "2024-11-04",
    Type: "Equity",
    SchemeName: "Wealth Fund",
    Price: 150.0,
    Status: "Pending",
  },
];
const FDrows:any = [
  {
    Date: "2024-11-01",
    Type: "Equity",
    SchemeName: "Growth Fund",
    Price: 125.5,
    Status: "Active",
  },
  {
    Date: "2024-11-02",
    Type: "Debt",
    SchemeName: "Income Fund",
    Price: 110.0,
    Status: "Inactive",
  },
  {
    Date: "2024-11-03",
    Type: "Hybrid",
    SchemeName: "Balanced Fund",
    Price: 135.75,
    Status: "Active",
  },
  {
    Date: "2024-11-04",
    Type: "Equity",
    SchemeName: "Wealth Fund",
    Price: 150.0,
    Status: "Pending",
  },
  {
    Date: "2024-11-01",
    Type: "Equity",
    SchemeName: "Growth Fund",
    Price: 125.5,
    Status: "Active",
  },
  {
    Date: "2024-11-02",
    Type: "Debt",
    SchemeName: "Income Fund",
    Price: 110.0,
    Status: "Inactive",
  },
  {
    Date: "2024-11-03",
    Type: "Hybrid",
    SchemeName: "Balanced Fund",
    Price: 135.75,
    Status: "Active",
  },
  {
    Date: "2024-11-04",
    Type: "Equity",
    SchemeName: "Wealth Fund",
    Price: 150.0,
    Status: "Pending",
  },
];

const AIFrows:any = [
  {
    Date: "2024-11-01",
    Type: "Equity",
    SchemeName: "Growth Fund",
    Price: 125.5,
    Status: "Active",
  },
  {
    Date: "2024-11-02",
    Type: "Debt",
    SchemeName: "Income Fund",
    Price: 110.0,
    Status: "Inactive",
  },
  {
    Date: "2024-11-03",
    Type: "Hybrid",
    SchemeName: "Balanced Fund",
    Price: 135.75,
    Status: "Active",
  },
  {
    Date: "2024-11-04",
    Type: "Equity",
    SchemeName: "Wealth Fund",
    Price: 150.0,
    Status: "Pending",
  },
  {
    Date: "2024-11-01",
    Type: "Equity",
    SchemeName: "Growth Fund",
    Price: 125.5,
    Status: "Active",
  },
  {
    Date: "2024-11-02",
    Type: "Debt",
    SchemeName: "Income Fund",
    Price: 110.0,
    Status: "Inactive",
  },
  {
    Date: "2024-11-03",
    Type: "Hybrid",
    SchemeName: "Balanced Fund",
    Price: 135.75,
    Status: "Active",
  },
  {
    Date: "2024-11-04",
    Type: "Equity",
    SchemeName: "Wealth Fund",
    Price: 150.0,
    Status: "Pending",
  },
];
const PMSrows:any = [
    {
      Date: "2024-11-01",
      Type: "Equity",
      SchemeName: "Growth Fund",
      Price: 125.5,
      Status: "Active",
    },
    {
      Date: "2024-11-02",
      Type: "Debt",
      SchemeName: "Income Fund",
      Price: 110.0,
      Status: "Inactive",
    },
    {
      Date: "2024-11-03",
      Type: "Hybrid",
      SchemeName: "Balanced Fund",
      Price: 135.75,
      Status: "Active",
    },
    {
      Date: "2024-11-04",
      Type: "Equity",
      SchemeName: "Wealth Fund",
      Price: 150.0,
      Status: "Pending",
    },
    {
      Date: "2024-11-01",
      Type: "Equity",
      SchemeName: "Growth Fund",
      Price: 125.5,
      Status: "Active",
    },
    {
      Date: "2024-11-02",
      Type: "Debt",
      SchemeName: "Income Fund",
      Price: 110.0,
      Status: "Inactive",
    },
    {
      Date: "2024-11-03",
      Type: "Hybrid",
      SchemeName: "Balanced Fund",
      Price: 135.75,
      Status: "Active",
    },
    {
      Date: "2024-11-04",
      Type: "Equity",
      SchemeName: "Wealth Fund",
      Price: 150.0,
      Status: "Pending",
    },
  ];

const OrderHistory = () => {
  const dispatch = useDispatch<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showScrollbar, setShowScrollbar] = useState(false);

  const handleMouseEnter = () => {
    setShowScrollbar(true);
  };

  const handleMouseLeave = () => {
    setShowScrollbar(false);
  };

  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Orders" }, // Breadcrumb item with link
  ];

  return (
    <div>
      <div className="px-8 pt-8 md:p-[40px] max-w-[1440px] w-full mx-auto flex flex-col gap-y-2">
        <DynamicBreadcrumbs items={breadcrumbItems} />
        <Divider sx={{ my: 2 }} />{" "}
        {/* Adds a division between the breadcrumbs and the content below */}
        <div className="flex flex-row gap-x-2 items-center w-full">
          <div>
            <h1 className="text-3xl font-medium text-gray-900">
              Orders History
            </h1>
          </div>
        </div>
      </div>
      {MFrows && MFrows?.length > 0 && (
        <div className="px-10 max-w-[1440px] w-full mx-auto mb-10">
          <div className="">
            <div className="flex flex-col">
              <div className="flex gap-x-8 items-center">
                <div className="flex flex-row w-full justify-start pb-4">
                  <div>
                    <h1 className="text-2xl font-semibold text-primary">
                      Mutual Funds
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <OrderHistoryTable headings={headings} rows={MFrows} />
            </div>
          </div>
        </div>
      )}
      {FDrows && FDrows?.length > 0 && (
        <div className="px-10 max-w-[1440px] w-full mx-auto mb-10">
          <div className="">
            <div className="flex flex-col">
              <div className="flex gap-x-8 items-center">
                <div className="flex flex-row w-full justify-start pb-4">
                  <div>
                    <h1 className="text-2xl font-semibold text-primary">
                      Fixed deposites
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <OrderHistoryTable headings={headings} rows={FDrows} />
            </div>
          </div>
        </div>
      )}
      {AIFrows && AIFrows?.length > 0 && (
        <div className="px-10 max-w-[1440px] w-full mx-auto mb-10">
          <div className="">
            <div className="flex flex-col">
              <div className="flex gap-x-8 items-center">
                <div className="flex flex-row w-full justify-start pb-4">
                  <div>
                    <h1 className="text-2xl font-semibold text-primary">AIF</h1>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <OrderHistoryTable headings={headings} rows={AIFrows} />
            </div>
          </div>
        </div>
      )}
      {PMSrows && PMSrows?.length > 0 && (
        <div className="px-10 max-w-[1440px] w-full mx-auto mb-10">
          <div className="">
            <div className="flex flex-col">
              <div className="flex gap-x-8 items-center">
                <div className="flex flex-row w-full justify-start pb-4">
                  <div>
                    <h1 className="text-2xl font-semibold text-primary">PMS</h1>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <OrderHistoryTable headings={headings} rows={PMSrows} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
