import React, { useEffect, useState } from 'react'
import ListingComponent from '../../../components/ListingTemplate/listingTemplate';
import { useDispatch } from 'react-redux';
import { getFDinfo } from '../../../redux/FD/FDSlice';
import { hideLoading, showLoading } from '../../../redux/loader/loaderSlice';
import { MFAssetHoldings } from '../../../redux/Reports/ReportSlice';
import api from '../../../config/apiConfig';
import AifListingComponent from '../../../components/ListingTemplate/AifListingTemplate';

function AIFListing() {
    const dispatch = useDispatch<any>();
    const [loader, setLoader] = useState<boolean>(false);
    const [isinWishilist, setIsinWishilist] = useState<any>();
    const breadcrumbItems = [
        { label: 'Home', href: '/' },
        { label: 'Investment', href: '/' },
        { label: 'AIF', },
    ];
    const filterOps: any = [
        {
            name: "Fund Type",
            options: [
                {
                    name: "Equity",
                    value: 1,
                    isChecked: false,
                },
                {
                    name: "Debt",
                    value: 3,
                    isChecked: false,
                },
                {
                    name: "Hybrid",
                    value: 5,
                    isChecked: false,
                },
                {
                    name: "Solution Oriented",
                    value: 7,
                    isChecked: false,
                },
                {
                    name: "GILT",
                    value: 8,
                    isChecked: false,
                },
                {
                    name: "ELSS",
                    value: 9,
                    isChecked: false,
                },
                {
                    name: "MIP",
                    value: 10,
                    isChecked: false,
                },
                {
                    name: "Balanced",
                    value: 11,
                    isChecked: false,
                },
                {
                    name: "STP",
                    value: 12,
                    isChecked: false,
                },
                {
                    name: "FOF",
                    value: 13,
                    isChecked: false,
                },
                {
                    name: "Liquid",
                    value: 14,
                    isChecked: false,
                },
                {
                    name: "Bond",
                    value: 15,
                    isChecked: false,
                },
                {
                    name: "Income",
                    value: 16,
                    isChecked: false,
                },
            ],
        },
    ];

    const [totalEnteries, setTotalEnteries] = useState<number>();
    const [dataEnteries, setDataEnteries] = useState<any[]>();
    const [data, setData] = useState<any>();
    const FetchData = async () => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const response = await api.post("aif/getAll", { pageIndex: 1, pageSize: 1000 }, {
                headers: { Authorization: `${accessToken}` },
            });

            const aifList = response?.data?.data?.aifList || [];

            // Filter out entries where assetName is missing or equal to "-"
            const filteredEntries = aifList.flatMap((item: any) => 
                item.schemes.filter((scheme: any) => scheme.assetName && scheme.assetName !== "-")
            );

            // Set the filtered length
            setTotalEnteries(filteredEntries.length);
            setData(response?.data?.data?.aifList);
        } catch (error) {
            console.error("Error uploading file:", error);
        } finally {
            setLoader(false);
        }
    };

      useEffect(() => {
        setLoader(true);
        FetchData();
      }, [])


    const MFTransations = [
        { key: 'schemeName', value: 'Asset Name' },
        { key: 'category', value: 'Category' },
        { key: 'inception', value: 'Inception' },
        { key: 'aum', value: 'AUM (in Cr)' },
        { key: 'currentNAV', value: 'Current NAV' },
        { key: 'returnRate', value: 'Return Rate' },
        { key: 'promoter', value: 'Promoter' },
        { key: 'raisedBar', value: 'Raised Bar' },
        { key: 'action', value: 'Action' },
    ];
    
    const subcategoryLabel = [
        { key: 'AssetName', value: 'AssetName' },
        { key: 'Category', value: 'Category' },
        { key: 'Inception', value: 'Inception' },
        { key: 'AUM(in Cr)', value: 'options' },
        { key: 'CurrentNAV', value: 'CurrentNAV' },
        { key: 'ReturnRate', value: 'ReturnRate' },
        { key: 'Promoter', value: 'Promoter' },
        { key: 'RaisedBar', value: 'RaisedBar' },
    ]
    const summary = 'An Alternative Investment Fund (AIF) is a pooled investment vehicle that collects funds from investors to invest in assets beyond traditional investments like stocks and bonds. AIFs include private equity, venture capital, hedge funds, real estate, and commodities. They are of 3 categories - category 1, category 2 and category 3.'

    const QuickFilterOption = [
        { label: 'Gainers', value: 'Equity' },
        { label: 'Losers', value: 'Debt' },
        { label: 'Top traded (by volume)', value: 'Hybrid' },
        { label: 'Top traded (by value)', value: 'Solution oriented' },
    ]


    return (
        <AifListingComponent
            title='Alternative Investment Fund (AIF)'
            breadcrumb={breadcrumbItems}
            filterOps={filterOps}
            totalEnteries={totalEnteries}
            dataEnteries={data}
            controlBarObj={MFTransations}
            summary={summary}
            setDataEnteries={setDataEnteries} 
            subcategory={subcategoryLabel}
            subCategoryTable={true}
            loaderData={loader}
        />
    )
}

export default AIFListing