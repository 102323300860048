import React, { FC, useEffect, useState } from "react";
import { colors as color } from "../../constants/colors";
import { useDispatch } from "react-redux";
import { getUserById } from "../../redux/user/userSlice";

type InfoSectionProps = {
  icon: string;
  title: string;
  isActive: boolean; // Prop for indicating active state
  isCompleted: boolean; // Prop for indicating completed state
  isPrevious: boolean; // Prop for indicating if it's the previous step
  setActiveSection?: any;
  sectionKey?: string;
};

const InfoSection: FC<InfoSectionProps> = ({
  icon,
  title,
  isActive,
  isCompleted,
  isPrevious,
  setActiveSection,
  sectionKey,
}: InfoSectionProps) => {
  const dispatch = useDispatch<any>();
  const [bgColor, setBgColor] = useState<string>(color.white);
  const [textColor, setTextColor] = useState<string>(color.primary);

  useEffect(() => {
    console.log(sectionKey, "sectionKey");
    // Set background color and text color based on isActive, isCompleted, and isPrevious states
    if (isActive) {
      setBgColor(color.primary); // Active state background color is primary
      setTextColor(color.white); // Active state text color is white
    } else if (isCompleted) {
      setBgColor(color.darkGrey); // Completed state background color is darkGrey
      setTextColor(color.white); // Completed state text color is white
    } else if (isPrevious) {
      setBgColor(color.darkGrey); // Previous step background color is darkGrey
      setTextColor(color.white); // Previous step text color is white
    } else {
      setBgColor(color.white); // Default inactive state background color is white
      setTextColor(color.primary); // Default inactive state text color is primary
    }
  }, [isActive, isCompleted, isPrevious]);

  return (
    <div
      className="flex flex-col items-center justify-center pb-2 cursor-pointer"
      onClick={() => {
        dispatch(getUserById({}));
        if (setActiveSection && isCompleted) setActiveSection(sectionKey);
      }}
    >
      <img
        loading="lazy"
        src={icon}
        className="w-6 sm:w-11 aspect-square"
        alt=""
      />
      <div
        className={`text-center text-xs sm:text-base px-2 py-2 sm:px-4 sm:py-1 mt-2 rounded  md:w-40 lg:w-40`}
        style={{
          backgroundColor: bgColor,
          color: textColor,
          border: `1px solid ${color.primary}`,
        }}
      >
        {title}
      </div>
    </div>
  );
};

export default InfoSection;
