import api from "../../config/apiConfig";
import { User } from "../../models/user.model";

const updateUser = async (data: User) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("/user/update", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response.data;
};

const addPreferences = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("/user/addPreferences", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response.data;
};

const addSignature = async (data: FormData) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("/user/addSignature", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response.data;
};

const sendResetPasswordLink = async (data: FormData) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("/user/sendPasswordLink", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response.data;
};

const resetPassword = async (data: any, token: string) => {
  const response = await api.post("/user/resetPassword", data, {
    headers: {
      Authorization: token,
    },
  });
  return response.data;
};

const getUserById = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post(
    "/user/getById",
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response.data;
};

const getRMUser = async (data:any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post(
    "/admin/rm/getAllUsers",
    data,
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response.data;
};

const showInterest = async (data:any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post(
    "user/interest",
    data,
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response.data;
};

const getImpersonateToken = async (data:any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post(
    "/admin/impersonateRM",
    data,
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response.data;
};

const loginAsUser = async (data:any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post(
    "/admin/loginUser",
    data,
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response.data;
};

const getAUFForm = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post(
    "/user/getAOF",
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response.data;
};

const downloadAUFForm = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post(
    "/user/downloadAOF",
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response.data;
};


const createBSEUser = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post(
    "/user/createbse",
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response.data;
};

const verifyBseOTP = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post(
    "/user/verifyBseOTP",
    data,
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response.data;
};

const chatBotServices = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post(
    "/user/chat",
    data,
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response.data;
};


const updatePassword = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post(
    "/user/changePassword",
    data,
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response.data;
};

const updateProfile = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post(
    "/user/requestForUpdate",
    data,
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response.data;
};

const bseNomineeAuth = async (data: any) => {
  const response = await api.get(
    `/user/bse/auth/${data.id}?STATUS=${data.status}`,
  );
  return response.data;
};

const userService = {
  updateUser,
  addPreferences,
  addSignature,
  sendResetPasswordLink,
  resetPassword,
  getUserById,
  getRMUser,
  getAUFForm,
  downloadAUFForm,
  createBSEUser,
  verifyBseOTP,
  chatBotServices,
  updatePassword,
  updateProfile,
  getImpersonateToken,
  loginAsUser,
  showInterest,
  bseNomineeAuth
};
export default userService;
