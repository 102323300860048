import api from "../../config/apiConfig";

const getFDsInfo = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/fd/getAllV2", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data.data;
};

const getFDById = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/fd/getById", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data.data;
};

const getFDSchemeInfo = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/fd/getSchemeInfo", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data.data;
};

const getFDTransactions = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/fd/getFdTransactions", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data.data;
};

const FDPrincipleAmount = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/fd/getPrincipal", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data.data;
};
const FDTenure = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/fd/getTenures", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data.data;
};

const FDInterestRate = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/fd/getInterestRate", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data.data;
};

const ReqFDQuote = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("fd/invest", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response;
};

const FDWithdraw = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("fd/withdraw", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response;
};

const FDService = { getFDsInfo, getFDById, getFDSchemeInfo, ReqFDQuote, FDPrincipleAmount, FDTenure, FDInterestRate, getFDTransactions, FDWithdraw };
export default FDService;