import api from "../../config/apiConfig";
import { Invest } from "../../models/invest.model";


const addMFund = async (data: Invest) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/funds/invest", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
};

const MFService = { addMFund };
export default MFService;