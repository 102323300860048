import React from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Collapse, IconButton, Typography, ListItem, MenuItem, Divider, Menu, useMediaQuery, Fade } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import getInitials from '../../utils/getInitailasIMGUtils';
import { useNavigate } from 'react-router-dom';
import TimeStamptoExp from '../../utils/timestampConverter';
import { colors } from '../../constants/colors';
import SortingComponent from '../ShortingFN/ShortingComponent';
import { BorderBottom, InfoOutlined, MoreVert, ReportProblem } from "@mui/icons-material";
import MainTable from './MainTable';
import SubCategoryTableLayout from './SubCategoryTable';
import MainTableLayout from './MainTable';
interface TableComponentProps {
    headData: Array<{ key: string, value: string }>;
    subcategory?: any;
    bodyData: Array<any>;
    unSortdata?: any;
    findHighestInterestRates?: (schemes: any) => number;
    navigateDetails?: (id: string) => void;
    handleMenuClick?: (event: React.MouseEvent<HTMLElement>, rate1: any, rate2: any) => void;
    segment?: string;
    reportType?: string;
    subCategoryTable?: boolean;
    initials?: boolean;
}

const TableComponent: React.FC<TableComponentProps> = ({ headData, bodyData, findHighestInterestRates, handleMenuClick, segment, reportType, unSortdata, subcategory, subCategoryTable, initials }) => {
    const [open, setOpen] = React.useState<{ [key: string]: boolean }>({});
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorMD, setAnchorMD] = React.useState<null | HTMLElement>(null);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const openMenuMD = Boolean(anchorMD);


    console.log('data.subcategoryData', bodyData[0]?.subcategoryData, 'subcategory', subcategory);

    let subcategoryKeys: any;

    if (subcategory) {
        subcategoryKeys = new Set(subcategory.map((label: any) => label.key));
    }
    const modalMenuClick = (event: React.MouseEvent<HTMLElement>, id: any) => {
        setAnchorMD(event.currentTarget);
        // let data = { status: isinWishilist, id: id }
        // setIsinWishilist(data)
    };
    const handleCloseMD = () => {
        setAnchorMD(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <MainTableLayout bodyData={bodyData} headData={headData} unSortdata={unSortdata} modalMenuClick={modalMenuClick} initials={initials} />
            
{/* 
            <Menu
                id="fade-menu"
                MenuListProps={{
                    "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorMD}
                open={openMenuMD}
                onClose={handleCloseMD}
                TransitionComponent={Fade}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        backgroundColor: '#255288',
                        color: '#fff',
                        overflow: 'hidden',
                        borderRadius: '10px',
                        position: 'relative',
                        width: isSmallScreen ? '80%' : '12%',
                    },
                }}
                className="relative"
            >
                <div className="mx-6">
                    <ListItem button onClick={handleClose} sx={{ marginLeft: '-25px' }}>
                        <MenuItem className="text-xs sm:text-sm" >{'Add to Watchlist'}</MenuItem>
                    </ListItem>
                    <Divider sx={{ backgroundColor: '#fff' }} />
                    <ListItem button onClick={handleClose} sx={{ marginLeft: '-25px' }}>
                        <MenuItem className="text-xs sm:text-sm" >Invest</MenuItem>
                    </ListItem>
                </div>
                <div
                    className="absolute bg-white"
                    style={{
                        width: '20px',
                        height: '20px',
                        transform: 'rotate(315deg)',
                        bottom: 'calc(100% - 10px)',
                        left: 'calc(50% - 10px)',
                    }}
                />
            </Menu> */}
        </>

    );
};

export default TableComponent;
