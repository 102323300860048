import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import userService from "../../redux/user/userService";
// Tailwind CSS classes for styling buttons
const buttonClass = "px-4 py-2 rounded-lg text-white";
const buttonActiveClass = `${buttonClass} bg-primary hover:bg-orange`;
const buttonDisabledClass = `${buttonClass} bg-gray-300 cursor-not-allowed`;

interface VideoRecorderProps {
  recordedVideo: Blob | null;
  setRecordedVideo: React.Dispatch<React.SetStateAction<Blob | null>>;
  onLocationChange: (latitude: number, longitude: number) => void;
}

const VideoRecorder = ({
  recordedVideo,
  setRecordedVideo,
  onLocationChange,
}: VideoRecorderProps) => {
  const webcamRef = useRef<Webcam | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isWebcamReady, setIsWebcamReady] = useState(false);
  const [videoChunks, setVideoChunks] = useState<Blob[]>([]);
  const [countdown, setCountdown] = useState<number>(0);
  const [videoUrl, setVideoUrl] = useState<string>("");

  const [latitude, setLocalLatitude] = useState<number>(0);
  const [longitude, setLocalLongitude] = useState<number>(0);

  // Generate a random code once when the component mounts

  useEffect(() => {
    const kycCheck = async () => {
      try {
        const response = await userService.getUserById();
        console.log(response);
          if (response?.data?.kycVideo===""||response?.data?.isSignatureAdd===0) {
            if (webcamRef) {
              navigator.permissions
                .query({ name: "geolocation" })
                .then((permissionStatus) => {
                  if (permissionStatus.state === "denied") {
                    alert(
                      "Please allow geolocation access from your browser to proceed further."
                    );
                  }
                });
              navigator.mediaDevices.enumerateDevices().then((devices) => {
                if (devices.some((device) => device.kind === "videoinput")) {
                  navigator.mediaDevices
                    .getUserMedia({ video: true })
                    .then()
                    .catch(() => {
                      alert(
                        "Please allow webcam access from your browser to proceed further."
                      );
                    });
                } else {
                  alert("Please make sure you have a webcam connected to your device.");
                }
              });
            }
          }
      } catch (err) {
       console.log(err);
      }
    };
    kycCheck();
  }, [webcamRef]);

  const handleUserMedia = () => {
    setIsWebcamReady(true);
  };

  const startRecording = () => {
    if (webcamRef.current && isWebcamReady) {
      const stream = webcamRef.current.stream;

      navigator.geolocation.getCurrentPosition(function (position) {
        // console.log("Latitude: " + position.coords.latitude + " Longitude: " + position.coords.longitude);
        // console.log(position);
        const { latitude, longitude } = position.coords;
        setLocalLatitude(latitude);
        setLocalLongitude(longitude);
        onLocationChange(latitude, longitude);

        if (stream) {
          // Reset video data if re-recording
          if (recordedVideo) {
            setRecordedVideo(null);
            setVideoUrl("");
            setVideoChunks([]);
          }

          const mediaRecorder = new MediaRecorder(stream);
          mediaRecorderRef.current = mediaRecorder;

          mediaRecorder.ondataavailable = (event) => {
            if (event.data && event.data.size > 0) {
              setVideoChunks((prev: any) => [...prev, event.data]);
            }
          };

          mediaRecorder.start(); // Start recording
          setIsRecording(true); // Set recording status to true
          setCountdown(10); // Initialize countdown

          const countdownInterval = setInterval(() => {
            setCountdown((prev) => {
              if (prev <= 1) {
                clearInterval(countdownInterval); // Clear interval once countdown reaches zero
                stopRecording(); // Set recording status to false
                mediaRecorder.stop(); // Stop recording
                return 0;
              }
              return prev - 1;
            });
          }, 1000); // Decrease countdown every second
        }
      });
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop(); // Stop recording
      setIsRecording(false); // Set recording status to false
    }
  };

  useEffect(() => {
    if (videoChunks.length > 0) {
      const videoBlob = new Blob(videoChunks, { type: "video/webm" });
      const videoUrl = URL.createObjectURL(videoBlob);
      setRecordedVideo(videoBlob);
      setVideoUrl(videoUrl);
      setVideoChunks([]); // Reset chunks
    }
  }, [videoChunks]);

  return (
    <div className="flex flex-col items-center gap-4 relative">
      {/* Display the generated random code */}
      {/* <div className="text-xl">
        Generated Code: <span className="font-bold">{randomCode}</span>
      </div> */}

      {recordedVideo ? (
        <div className="flex flex-col items-center">
          <video
            src={videoUrl}
            controls
            autoPlay
            className="w-full h-70 border border-gray-300"
          />
          <button
            onClick={() => {
              setRecordedVideo(null);
              setVideoUrl("");
              setVideoChunks([]);
              setIsRecording(false);
            }}
            className={`${buttonActiveClass} mt-5`}
          >
            Re-record
          </button>
        </div>
      ) : (
        <div className="relative">
          <Webcam
            ref={webcamRef}
            audio={false}
            videoConstraints={{ frameRate: { ideal: 4, exact: 2, max: 8 } }}
            onUserMedia={handleUserMedia}
            screenshotFormat="image/jpeg"
            className="h-70 w-[800px]"
            // style={{ width: '100%' }}
          />
          {isRecording && (
            <div className="absolute top-2 left-2 flex items-center">
              {/* Red blinking dot */}
              <div className="h-4 w-4 bg-red-500 rounded-full animate-pulse mr-2" />
              <div className="text-red-500 text-lg font-bold">Recording...</div>
            </div>
          )}
          {isRecording && (
            <div className="absolute bottom-2 right-2 text-base font-semibold bg-gray-800 text-white p-1 rounded">
              {` ${countdown}s`}
            </div>
          )}
        </div>
      )}

      {!recordedVideo && (
        <button
          onClick={startRecording}
          disabled={!isWebcamReady || isRecording}
          className={isRecording ? buttonDisabledClass : buttonActiveClass}
        >
          Start Video Recording
        </button>
      )}
    </div>
  );
};

// Tailwind CSS class for the blinking effect
const styles = `
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.animate-blink {
  animation: blink 1s infinite;
}
`;

export default VideoRecorder;
