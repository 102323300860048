import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BONDService from "./BONDService";

export const InvestRequest = createAsyncThunk(
  "bond/invest",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      // console.log('Getting data for InvestMF ', data);

      const response = await BONDService.investBONDRequest(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const WithdrawRequest = createAsyncThunk(
  "bond/withdraw",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      // console.log('Getting data for InvestMF ', data);

      const response = await BONDService.withdrawBONDRequest(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const BondSummary = createAsyncThunk(
  "bond/getSummary",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      // console.log('Getting data for InvestMF ', data);

      const response = await BONDService.BondSummary({});
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

const initialState = {
  funds: [],
  fundinfo: [{ 'name': 'fundInfo' }],
  MFundbyid: [],
  paymentData: {},
  InvestMF: [],
  sendOTP: [],
  verifyOTP: [],
  ImportFUnd: [],
  paymentStatus: "",
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

export const BONDSlice = createSlice({
  name: "BOND",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(InvestRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(InvestRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(InvestRequest.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(WithdrawRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(WithdrawRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(WithdrawRequest.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(BondSummary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(BondSummary.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(BondSummary.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
  },
});

export const { resetState } = BONDSlice.actions;
export const { reducer: BONDSliceReducer } = BONDSlice;
