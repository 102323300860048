import { colors } from "../../constants/colors";
import styles from './HeaderText.module.css';
const AumSampannHeader = () => {
  const textBlack = colors.black
  const textWhite = colors.white
  const background = colors.white
  const LargeSectionHeader = ({ title }: { title: string }) => (
    <header style={{ color: textBlack }} className={`${styles.largeHeader} mt-6 mr-8 mb-8 text-4xl max-md:mr-2.5 max-md:max-w-full`}>
      {title}
    </header>
  );
  const SmallSectionHeader = ({ title }: { title: string }) => (
    <header style={{ color: textBlack }} className={`${styles.smallHeader} mt-6 mr-8 mb-10 text-3xl max-md:mr-2.5 max-md:max-w-full`}>
      {title}
    </header>
  );
  return (
    <>
      <SmallSectionHeader title="AUM" />
      <LargeSectionHeader title="Sampann" />
    </>

  );
};

export { AumSampannHeader };