import React, { useState } from 'react';
import GeneralDetailsForm from '../GeneralDetails/GeneralDetails';
import Demat from '../Demat/Demat';
import Preferences from '../Preferences/Preferences';
import Sign from '../Signature/Sign';
import Preferences2 from '../Preferences/Preferences2';
import NonIndividualFormLayout from '../../../layout/NonIndividualFormHeader/NonIndividualFormHeader';
import PanVerification from '../../IndividualForms/PanVerification/PanVerification';
import { useUserData } from '../../../hooks/useUserData';
import BankDetailsForm from '../../IndividualForms/BankDetailsForm/BankDetailsForm';
import DematForm from '../../IndividualForms/DematForm/DematForm';
import NonIndividualCompanyLayout from '../../../layout/NonIndividualCompanyHeader/NonIndividualCompanyHeader';


const MainNonIndividual: React.FC = () => {
  const [activeSection, setActiveSection] = useState('PAN');
  const [completedSections, setCompletedSections] = useState<boolean[]>(Array(9).fill(false));

  type SectionKey = 'HUF' | 'Corporate' | 'LLP' | 'Trust';
  const [businessType, setBusinessType] = useState<SectionKey>('HUF'); // Default to one of the valid SectionKeys

  const handleSaveAndProcess = (sectionName: string, index: number) => {
    const updatedSections = [...completedSections];
    updatedSections[index] = true;
    setCompletedSections(updatedSections);
    setActiveSection(sectionName);
  };

  const handleSectionChange = (newSection: string) => {
    setActiveSection(newSection);
  };

  const user = useUserData();
  
  return (
    <div>
      <NonIndividualFormLayout completedSections={completedSections} activeSection={activeSection}>
        {activeSection === 'PAN' && (
          <PanVerification
            onProceed={() => handleSaveAndProcess('general', 0)}
            activeSection={activeSection}
            userData={user}
          />
        )}
        {activeSection === 'general' && (
          <GeneralDetailsForm
            onGeneralToCompany={() => handleSaveAndProcess('company', 1)}
            activeSection={activeSection}
            allowToProceed={() => handleSaveAndProcess('company', 1)}
            onBusinessTypeChange={setBusinessType}
            userData={user}
          />
        )}
        {activeSection === 'company' && (
          <NonIndividualCompanyLayout
            setBusinessType={setBusinessType}
            activeSection={activeSection}
            onSectionChange={handleSectionChange}
            allowToProceed={() => handleSaveAndProcess('company', 2)}
            completedSections={completedSections}
            businessType={businessType}
          />
        )}
        {activeSection === "bank" && (
          <BankDetailsForm
            onBankToNominee={() => handleSaveAndProcess("demat", 3)}
            allowToProceed={() => {}}
            userData={user}
          />
        )}
        {activeSection === "demat" && (
          <DematForm
            onDematToPreferences={() => handleSaveAndProcess("preference", 4)}
            allowToProceed={() => {}}
            userData={user}
          />
        )}
        {activeSection === 'preference' && (
          <Preferences
            onPreferenceToPreferences2={() => handleSaveAndProcess('preference2', 5)}
            activeSection={activeSection}
            allowToProceed={() => {}}
          />
        )}
        {activeSection === 'preference2' && (
          <Preferences2
            onPreferenceToDemat={() => handleSaveAndProcess('demat', 2)}
            activeSection={activeSection}
            allowToProceed={() => {}}
          />
        )}
        {activeSection === 'demat' && (
          <Demat
            onDematToGeneral={() => handleSaveAndProcess('general', 3)}
            activeSection={activeSection}
            allowToProceed={() => {}}
          />
        )}
        {activeSection === 'sign' && (
          <Sign allowToProceed={() => { } } userData={user} />
        )}
      </NonIndividualFormLayout>
    </div>
  );
};

export default MainNonIndividual;
