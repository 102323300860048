import React, { FC, useEffect, useRef, useState } from 'react';
import InfoSection from '../../../components/InfoSection/InfoSection';

import InfoSectionNon from '../../../components/InfoSection_nonIndividual/infoSectionNonIndividual';

import person from '../../../assets/icons/f7_person-2 blue.png'
import person24 from '../../../assets/icons/octicon_person-24-blue.png'
import bankDetails from '../../../assets/icons/Company_blue.png'
import demat from '../../../assets/icons/jam_document blue.png'
import preference from '../../../assets/icons/grommet-icons_checkbox-selectedblue.png'
import sign from '../../../assets/icons/la_file-signature blue.png'

import { colors } from '../../../constants/colors';
import KYC from '../../../components/KycProcedure/Kyc'; // Import KYC component
import Header from '../../../components/Header/Header';
import Buttons from '../../../components/Buttons/IndividualButtons/Buttons';

import upload from '../../../assets/icons/lucide_upload.svg';

import * as yup from "yup";
import { Formik } from "formik";
import { useSelector, useDispatch } from 'react-redux';
import { addTrust } from '../../../redux/nonInd_Trust/trustSlice';
import { checkFileSizeAndReset } from "../../../utils/index";
import ConfirmationModal from '../../../components/modals/PopupModal';
import { useUserData } from '../../../hooks/useUserData';




interface InfoSectionProps {
  icon: string;
  title: string;
  bgColor: string;
}


interface FormInputProps {
  label: string;
  onChange?: any;
  value?: any;
  name: string;
}




interface TrustDetailsProps {
  onTrustToSign: () => void;
  activeSection: string;
  allowToProceed: () => void;
}



const trustValues = {
  Trustee1PanNumber: "",
  Trustee2PanNumber: "",
  Company1PanNumber:""

};

const trustSchema = yup.object({
  Trustee1PanNumber: yup.string().required(),
  Trustee2PanNumber: yup.string().required(),
  Company1PanNumber: yup.string().required()
});







interface InputFieldProps {
  label: string;
  type: string;
  id: string;
  altText?: string;
  onChange?: any;
  name: string;
  value?: any
}


const FileInputField: React.FC<InputFieldProps> = ({ label, name, onChange, value }) => {
  const [fileName, setFileName] = React.useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const isValid = checkFileSizeAndReset(file, event); // Validate the file size
      if (isValid) {
        setFileName(file.name); // Update the displayed file name
      } else {
        setFileName(null); // Reset the displayed file name if invalid
      }
    }
  };

  return (
    <div className="flex flex-col mt-10 mb-10 grow text-lg text-neutral-500">
      <div className="mb-5 text-base sm:text-base  md:text-sm lg:text-lg">{label}</div>
      <label className="rounded-lg p-3 flex items-center justify-between " style={{ color: colors.darkGrey, backgroundColor: colors.white, border: `1px solid ${colors.darkBg}` }}>
        <input
          type="file"
          className="hidden sm:w-full"
          style={{ backgroundColor: colors.white }}
          onChange={(e) => {
            onChange(e);
            handleFileChange(e);
          }}
        />
        <span>{fileName ? fileName : ""}</span>
        <img
          src={upload} // Update with your image path
          alt="Upload Icon"
          className="h-8 w-8 ml-2 cursor-pointer"
        />
      </label>

    </div>
  );
};




const FormInput: FC<FormInputProps> = ({ label, onChange, value, name }) => (
  <div className="w-full sm:w-1/2 mb-10 mt-10 sm:mb-0"> {/* Adjusted width for small screens */}
    <div className="flex flex-col md:text-sm  lg:text-lg mb-10">
      <label htmlFor={label.replace(/ /g, '').toLowerCase()} className='mb-2' style={{ color: colors.darkGrey }}>{label}</label>
      <input
        className={`w-full mt-3 bg-white rounded-lg border border-solid p-4`}
        style={{ color: colors.darkGrey, marginBottom: 0 }}
        type="text"
        id={label.replace(/ /g, '').toLowerCase()}
        aria-label={label}
        onChange={onChange}
        value={value}
        name={name}
      />
    </div>
  </div>
);



const TrustDetails: FC<TrustDetailsProps> = ({ onTrustToSign, allowToProceed }) => {

  const [completedSections, setCompletedSections] = useState<boolean[]>(Array(6).fill(false));
  const dispatch = useDispatch<any>();
  // const organizationId = useSelector((state:any) => state?.general?.organizationId);

  const [organizationId, setOrganizationId] = useState<string>('');
  const organizationIdRef = useRef<string>(''); // Ref to store organizationId

  useEffect(() => {
    // Retrieve organizationId from local storage when component mounts
    const storedOrganizationId = localStorage.getItem('organizationId');
    if (storedOrganizationId) {
      setOrganizationId(storedOrganizationId);
      organizationIdRef.current = storedOrganizationId; // Update ref
    }
  }, []);




  const formInputs = [
    'First Name',
    'Last Name',
    'Email ID',
    'Phone',
    'DOB',
    'Gender',
  ];


  const businessOptions = [
    { value: '', label: '' },
    { value: 'value1', label: 'value1' },
    { value: 'value2', label: 'value2' },
    { value: 'other', label: 'Other' }
  ];


  const onAllow = (): void => {
    // Mark the current section as completed
    const updatedSections = [...completedSections];
    updatedSections[5] = true;
    setCompletedSections(updatedSections);

    // Call the function to allow to proceed
    allowToProceed();
  };

  const [pancard, setPancard] = useState<File>();
  const [address, setAddress] = useState<File>();
  const [bankStatement, setBankStatement] = useState<File>();
  const [cancelCheque, setCancelCheque] = useState<File>();

  const [trustee1Pancard, setTrustee1Pancard] = useState<File>();
  const [trustee1AddressProof, setTrustee1AddressProof] = useState<File>();
  const [trustee2Pancard, setTrustee2Pancard] = useState<File>();
  const [trustee2AddressProof, setTrustee2AddressProof] = useState<File>();
  const [trustDeed, setTrustDeed] = useState<File>();
  const [balanceSheet, setBalanceSheet] = useState<File>();
  const [certifiedTrustees, setCertifiedTrustees] = useState<File>();
  const [certificateOfRegistration, setCertificateOfRegistration] = useState<File>();
  const [contactList, setContactList] = useState<File>();
  const [clientMasterList, setClientMasterList] = useState<File>();
  const [companyPancard, setCompanyPancard] = useState<File>();
  const [companyAddressProof, setCompanyAddressProof] = useState<File>();
  const [incomeProof, setIncomeProof] = useState<File>();
  const [ITReturn, setITReturn] = useState<File>();
  const [sixMonthbankStatement, setSixMonthbankStatement] = useState<File>();
  const [boardResolution, setBoardResolution] = useState<File>();
  const [fatca, setFatca] = useState<File>();
  const userData = useUserData();
  const [pancardNumber, setPancardNumber] = useState<string>("");

  const [isModalVisible, setIsModalVisible] = useState(false); // State for popup visibility
  const formikRef = useRef<any>();

  const handleButtonClick = () => {
    // Open the modal when the "Save and Proceed" button is clicked
    setIsModalVisible(true);
  };

  const handleModalConfirm = () => {
    setIsModalVisible(false); // Hide the modal and proceed with form submission
    if (formikRef.current) {
      formikRef.current.submitForm(); // Submit the form upon confirmation
    }
  };
  const handleModalCancel = () => {
    // Logic for cancelling action (e.g., closing the popup)
    setIsModalVisible(false);
    console.log("Cancelled");
  };

  useEffect(() => {
    if (userData?.kyc?.pancardNumber) {
      setPancardNumber(userData.kyc.pancardNumber); // Store pancardNumber in state
    }
  }, [userData]);


  return (
    <>
      <div
        className="flex flex-col items-center justify-center w-full "
      >
        <div className="w-full max-w-[70%]">
          <div
            className="font-semibold text-base sm:text-3xl lg:mt-12"
            style={{ color: colors.primary }}
          >
            Trust
          </div>
          <div
            className="font-normal text-base mt-4 sm:text-xl lg:mt-12"
            style={{ color: colors.darkGrey }}
          >
            Note: All Documents should be self-attested.
          </div>

          <Formik
            innerRef={formikRef}
            initialValues={trustValues}
            validationSchema={trustSchema}
            onSubmit={(values, { setSubmitting }) => {
              console.log(values, "trust values");


              if ( address && bankStatement && trustee1Pancard && trustee1AddressProof && trustee2Pancard && trustee2AddressProof && trustDeed && balanceSheet && certifiedTrustees && certificateOfRegistration && contactList && clientMasterList && companyPancard && companyAddressProof && boardResolution && fatca && sixMonthbankStatement && incomeProof && ITReturn ) {
                // Create a Company object from form values
                const formData = new FormData();
                formData.append("PanNumber", pancardNumber);
                formData.append("Trustee1PanNumber", values.Trustee1PanNumber);
                formData.append("Trustee2PanNumber", values.Trustee2PanNumber);
                formData.append("AddressProof", address);
                formData.append("BankStatements", bankStatement);
                formData.append("Trustee1Pancard", trustee1Pancard);
                formData.append("Trustee1AddressProof", trustee1AddressProof);
                formData.append("Trustee2Pancard", trustee2Pancard);
                formData.append("Trustee2AddressProof", trustee2AddressProof);
                formData.append("TrustDeed", trustDeed);
                formData.append("BalanceSheet", balanceSheet);
                formData.append("CertifiedTrustees", certifiedTrustees);
                formData.append("CertificateOfRegistration", certificateOfRegistration);
                formData.append("ContactList", contactList);
                formData.append("ClientMasterList", clientMasterList);
                formData.append("CompanyPancard", companyPancard);
                formData.append("CompanyAddressProof", companyAddressProof);
                formData.append("BoardResolution", boardResolution);
                formData.append("Company1PanNumber", values.Company1PanNumber);
                formData.append("SixMonthBankStatement", sixMonthbankStatement);
                formData.append("incomeProof", incomeProof);
                formData.append("ITReturn", ITReturn);
                formData.append("Fatca", fatca);

                formData.append("organizationId", organizationIdRef.current);

                dispatch(addTrust(formData))
                // Call the function to proceed to the next step
                allowToProceed();
                // onTrustToSign();
              }
            }}
          >

            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) =>
            (

              <form onSubmit={handleSubmit}>
                <div className='flex justify-between'>
                  {/* Inputs for screens above 768px */}
                  <div className="hidden sm:flex flex-col w-full gap-5">
                    {/* <div className="flex flex-row w-full gap-5 lg:gap-20">
                      <div className="w-full sm:w-1/2">
                        <div className="w-full flex flex-row gap-5">
                          <FormInput label="PAN No. of Trust *" name={'PanNumber'} onChange={handleChange("PanNumber")}
                            value={values.PanNumber} />
                          <FileInputField label="Upload PAN Copy *" type="file" id="" name={''} onChange={(e: any) =>
                            setPancard(e.target.files[0])
                          } />
                        </div>
                      </div>
                      <div className="w-full sm:w-1/2">
                        <FileInputField label="Address Proof of Trust *" type='file' id='' name={''} onChange={(e: any) =>
                          setAddress(e.target.files[0])
                        } />
                      </div>
                    </div> */}

                    <div className="flex flex-row w-full gap-5 lg:gap-20">
                      {/* Second row of file input fields */}
                      <div className="w-full sm:w-1/2">
                        {/* Address Proof Of HUF */}
                        <FileInputField label="Address Proof of Trust *" type='file' id='' name={''} onChange={(e: any) =>
                          setAddress(e.target.files[0])
                        } />
                      </div>
                      <div className="w-full sm:w-1/2">
                        {/* Bank Statement (Last 3 months) */}
                        <FileInputField label="Bank Statement ( Last 3 months *)" type="file" id="" name={''} onChange={(e: any) =>
                          setBankStatement(e.target.files[0])
                        } />
                      </div>
                      {/* <div className="w-full sm:w-1/2">
                        <FileInputField label="Original Cancelled Cheque *" type="file" id="" name={''} onChange={(e: any) =>
                          setCancelCheque(e.target.files[0])
                        } />
                      </div> */}
                    </div>


                    <div className="flex flex-row w-full gap-5 lg:gap-20">
                      {/* First row of file input fields */}
                      <div className="w-full sm:w-1/2">
                        {/* Shared half screen space for Upload CML Copy and Form Input */}
                        <div className="w-full flex flex-row gap-5">
                          <FormInput label="PAN No. of Trustee 1 *" name={'Trustee1PanNumber'} onChange={handleChange("Trustee1PanNumber")}
                            value={values.Trustee1PanNumber} />
                          <FileInputField label="Upload  PAN Copy *" type="file" id="" name={''} onChange={(e: any) =>
                            setTrustee1Pancard(e.target.files[0])
                          } />
                        </div>
                      </div>
                      <div className="w-full sm:w-1/2">
                        {/* Address Proof Of HUF */}
                        <FileInputField label="Address Proof of Trustee 1 *" type='file' id='' name={''} onChange={(e: any) =>
                          setTrustee1AddressProof(e.target.files[0])
                        } />
                      </div>
                    </div>

                    <div className="flex flex-row w-full gap-5 lg:gap-20">
                      {/* First row of file input fields */}
                      <div className="w-full sm:w-1/2">
                        {/* Shared half screen space for Upload CML Copy and Form Input */}
                        <div className="w-full flex flex-row gap-5">
                          <FormInput label="PAN No. of Trustee 2 *" name={'Trustee2PanNumber'} onChange={handleChange("Trustee2PanNumber")}
                            value={values.Trustee2PanNumber} />
                          <FileInputField label="Upload PAN Copy *" type="file" id="" name={''} onChange={(e: any) =>
                            setTrustee2Pancard(e.target.files[0])
                          } />
                        </div>
                      </div>
                      <div className="w-full sm:w-1/2">
                        {/* Address Proof Of HUF */}
                        <FileInputField label="Address Proof of Trustee 2 *" type='file' id='' name={''} onChange={(e: any) =>
                          setTrustee2AddressProof(e.target.files[0])
                        } />
                      </div>
                    </div>

                    <div className="flex flex-row w-full gap-5 lg:gap-20">
                      {/* Second row of file input fields */}
                      <div className="w-full sm:w-1/2">
                        {/* Bank Statement (Last 3 months) */}
                        <FileInputField label="Trust Deed *" type="file" id="" name={''} onChange={(e: any) =>
                          setTrustDeed(e.target.files[0])
                        } />
                      </div>
                      <div className="w-full sm:w-1/2">
                        {/* Original Cancelled Cheque */}
                        <FileInputField label="Balance Sheet for Last 2 Financial Years *" type="file" id="" name={''} onChange={(e: any) =>
                          setBalanceSheet(e.target.files[0])
                        } />
                      </div>
                    </div>

                    <div className="flex flex-row w-full gap-5 lg:gap-20">
                      {/* Second row of file input fields */}
                      <div className="w-full sm:w-1/2">
                        {/* Bank Statement (Last 3 months) */}
                        <FileInputField label="List of Certified Trustees *" type="file" id="" name={''} onChange={(e: any) =>
                          setCertifiedTrustees(e.target.files[0])
                        } />
                      </div>
                      <div className="w-full sm:w-1/2">
                        {/* Original Cancelled Cheque */}
                        <FileInputField label="Certificate of Registration *" type="file" id="" name={''} onChange={(e: any) =>
                          setCertificateOfRegistration(e.target.files[0])
                        } />
                      </div>
                    </div>

                    <div className="flex flex-row w-full gap-5 lg:gap-20">
                      {/* Second row of file input fields */}
                      <div className="w-full sm:w-1/2">
                        {/* Bank Statement (Last 3 months) */}
                        <FileInputField label="List of Mobile Numbers & Email ID’s *" type="file" id="" name={''} onChange={(e: any) =>
                          setContactList(e.target.files[0])
                        } />
                      </div>
                      <div className="w-full sm:w-1/2">
                        {/* Original Cancelled Cheque */}
                        <FileInputField label="Client Master List (Demat) *" type="file" id="" name={''} onChange={(e: any) =>
                          setClientMasterList(e.target.files[0])
                        } />
                      </div>
                    </div>
                    <div className={`font-semibold max-md:max-w-full text-center md:text-left  lg:text-left sm:text-3xl text-base lg:mt-12`} style={{ color: colors.darkGrey }}>
                      Financial Details
                    </div>
                   <div className="flex flex-row w-full gap-5 lg:gap-20">
                      {/* First row of file input fields */}
                      <div className="w-full sm:w-1/2">
                        {/* Shared half screen space for Upload CML Copy and Form Input */}
                        <div className="w-full flex flex-row gap-5">
                          <FileInputField label="Income Proof : (For trade in F&O or Currency) *" type="file" id="" name={''} onChange={(e: any) =>
                            setIncomeProof(e.target.files[0])
                          } />
                        </div>
                        <div className="w-full flex flex-row gap-5">
                          <FileInputField label="Last 6 months bank statement *" type="file" id="" name={''} onChange={(e: any) =>
                            setSixMonthbankStatement(e.target.files[0])
                          } />
                        </div>
                      </div>
                      <div className="w-full sm:w-1/2">
                        {/* Address Proof Of HUF */}
                        <FileInputField label="Latest IT return copy *" type='file' id='' name={''} onChange={(e: any) =>
                          setITReturn(e.target.files[0])
                        } />
                      </div>
                    </div>
                    <div className={`font-semibold max-md:max-w-full text-center md:text-left  lg:text-left sm:text-3xl text-base lg:mt-12`} style={{ color: colors.darkGrey }}>
                      Trustee Details
                    </div>
                   <div className="flex flex-row w-full gap-5 lg:gap-20">
                      {/* First row of file input fields */}
                      <div className="w-full sm:w-1/2">
                        {/* Shared half screen space for Upload CML Copy and Form Input */}
                        <div className="w-full flex flex-row gap-5">
                          <FormInput label="PAN No. of Company *" name={'Company1PanNumber'} onChange={handleChange("Company1PanNumber")} value={values.Company1PanNumber}
                          />
                          <FileInputField label="Upload  PAN Copy *" type="file" id="" name={''} onChange={(e: any) =>
                            setCompanyPancard(e.target.files[0])
                          } />
                        </div>
                      </div>
                      <div className="w-full sm:w-1/2">
                        {/* Address Proof Of HUF */}
                        <FileInputField label="Address Proof of Company *" type='file' id='' name={''} onChange={(e: any) =>
                          setCompanyAddressProof(e.target.files[0])
                        } />
                      </div>
                    </div>

                    {/* <div className="flex flex-row w-full gap-5 lg:gap-20">

      <div className="w-full sm:w-1/2">

      <div className="w-full flex flex-row gap-5">
        <FormInput label="PAN No. of Company" name={''} />
        <FileInputField label="Upload PAN Copy" type="file" id="" name={''} />
      </div>
    </div>
      <div className="w-full sm:w-1/2">

        <FileInputField label="Address Proof of Company" type='file' id='' name={''} />
      </div>
    </div>
  */}

                    <div className="flex flex-row w-full gap-5 lg:gap-20">
                      {/* Second row of file input fields */}
                      <div className="w-full sm:w-1/2">
                        {/* Bank Statement (Last 3 months) */}
                        <FileInputField label="Board Resolution *" type="file" id="" name={''} onChange={(e: any) =>
                          setBoardResolution(e.target.files[0])
                        } />
                      </div>
                      <div className="w-full sm:w-1/2">
                        {/* Original Cancelled Cheque */}
                        <FileInputField label="FATCA Declaration *" type="file" id="" name={''} onChange={(e: any) =>
                          setFatca(e.target.files[0])
                        } />
                      </div>
                    </div>


                  </div>
                </div>
                <section className="mt-10 mb-5 max-md:mt-5 max-md:mr-2.5 max-md:max-w-full">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col max-md:ml-0 max-md:w-full">
                      <div className="flex justify-end">
                        <div className="flex gap-4">
                          {/* Skip Button */}
                          <button
                            type="button"
                            style={{
                              backgroundColor: colors.white,
                              color: colors.darkGrey,
                              border: `1px solid ${colors.darkGrey}`,
                            }}
                            className="px-8 py-4 rounded-md border"
                            onClick={() => {
                              // Implement skip logic if needed
                            }}
                          >
                            Skip
                          </button>

                          {/* Save and Proceed Button */}
                          <button
                            type="button"
                            style={{ backgroundColor: colors.darkGrey, color: colors.white }}
                            className="px-8 py-4 rounded-md border border-darkGrey"
                            onClick={handleButtonClick} // Open the confirmation modal
                          >
                            Save and Proceed
                          </button>

                          {/* Confirmation Modal */}
                          <ConfirmationModal
                            isVisible={isModalVisible} // Render conditionally based on state
                            message="Are you sure you want to proceed? After submission, changes cannot be made."
                            onConfirm={handleModalConfirm} // Proceed with submission
                            onCancel={handleModalCancel} // Cancel without proceeding
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </form>)}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default TrustDetails;
