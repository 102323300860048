import api from "../../config/apiConfig";

const investAIFRequest = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("aif/invest", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const getTransactionInfo = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("aif/getTransactions", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const AIFSummary = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("aif/getSummary", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const withdrawAIFRequest = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("aif/withdraw", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const AIFService = {
  investAIFRequest,
  withdrawAIFRequest,
  getTransactionInfo,
  AIFSummary
};
export default AIFService;
