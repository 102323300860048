import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { hideLoading, showLoading } from "../../redux/loader/loaderSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import api from '../../config/apiConfig';
import {
  createBSEUser,
  verifyBseOTP,
} from "../../redux/user/userSlice";
import VerifyOTPModal from '../../components/modals/VerifyOTPModal';
import userService from '../../redux/user/userService';

const EsignSuccess = () => {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const navigate = useNavigate();
  const [ip, setIp] = useState("");
  const [otp, setOtp] = useState<string>("");
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [BSEDone, setBSEDone] = useState([]);
  const [Card,setCard]=useState(false);
  const [verificationError,setVerificationError]=useState(false);
  const [countdown, setCountdown] = useState(10);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const esignIdFromUrl = params.get('esign_id');
  
    const uploadAufForm = async () => {
      const accessToken = localStorage.getItem("accessToken");
  
      if (!esignIdFromUrl) {
        toast.error("Esign ID not found");
        return;
      }
  
      if (!accessToken) {
        toast.error("Access token not found");
        return;
      }
  
      try {
        dispatch(showLoading());
        const response = await api.post(
          "user/uploadAof",
          { esignId: esignIdFromUrl },
          {
            headers: {
              Authorization: accessToken,
              'Content-Type': 'application/json'
            },
          }
        );
        console.log(response,"Response");
        if(response?.data?.statusCode===200){
          toast.success("AUF form uploaded successfully");
        }
        else{
          setVerificationError(true);
          setErrorMessage(response?.data?.message);
        }
      } catch (error: any) {
        dispatch(hideLoading());
        console.error("Error in API call:", error.response || error.message || error);
        toast.error("Failed to upload AUF form");
      }
    };
    
    const BseCheck = async () => {
      try {
        // dispatch(showLoading());
        const response = await userService.getUserById();
        if (response?.data[0]?.isUccDone === 0) {
          const handleCreateBSEUser = async () => {
            try {
              await fetchIp();
               const bseRes = await dispatch(createBSEUser());
                    console.log(bseRes);
                      if (bseRes?.error?.message) {
                        dispatch(hideLoading());
                        setVerificationError(true);
                        setErrorMessage(bseRes?.payload?.message);
                        toast.error("Error in creating BSE user");
                        return;
                      }
              setShowVerifyModal(true);
              dispatch(hideLoading());
            } catch (error) {
              console.log(error);
              dispatch(hideLoading());
              toast.error("Error in creating BSE user. Please try after sometimes!");
            } finally {
              dispatch(hideLoading());
            }
          };
          await handleCreateBSEUser();
        } else{
          const ResendOTP = async () => {
            const accessToken = localStorage.getItem("accessToken");
            try {
              await fetchIp();
              const response = await api.post(
                "user/sendOTP", {},
                {
                  headers: {
                    Authorization: accessToken,
                  },
                }
              );
              dispatch(hideLoading());
              toast.success("Verification OTP sent to your provided Email Id.");
              setShowVerifyModal(true);
            } catch (error: any) {
              dispatch(hideLoading());
              console.error("Error in API call:", error.response || error.message || error);
              toast.error("Failed to send OTP for verification");
            }
          };
          await ResendOTP();
        }
        
        // Using useEffect to react to BSEDone state changes
      } catch (err) {
        console.log(err);
        dispatch(hideLoading());
      } finally {
        dispatch(hideLoading());
      }
    };
  
    BseCheck();
    uploadAufForm();
  }, []);
  
  

  const fetchIp = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      setIp(response.data.ip);
    } catch (error) {
      console.error("Error fetching the IP address", error);
    }
  };

  const submitOTP = async () => {
    try {
      if (otp && ip) {
        dispatch(showLoading());
        const otpResponse = await dispatch(
          verifyBseOTP({ otp, ipAddress: ip })
        );
        if (otpResponse?.error?.message) {
          toast.error("Error in verifying OTP");
          setVerificationError(true);
          const timer = setInterval(() => {
            setCountdown(prevCountdown => {
              if (prevCountdown === 1) {
                clearInterval(timer);
                window.location.href='/';
                window.location.reload();
                window.localStorage.clear();
              }
              return prevCountdown - 1;
            });
          }, 1000);
          return;
        }
        console.log("otpResponse", otpResponse?.payload?.data);
        toast.success("OTP verified successfully.");
        setShowVerifyModal(false);
        setCard(true);
        setTimeout(() => {
          // window.location.href = otpResponse?.payload?.data;
          toast.success("Please check your email for nominee authentication.");
        }, 3000);

         // Start countdown and redirect after 10 seconds
         const timer = setInterval(() => {
          setCountdown(prevCountdown => {
            if (prevCountdown === 1) {
              clearInterval(timer);
              window.localStorage.clear();
              window.location.href='/';
              window.location.reload();
            }
            return prevCountdown - 1;
          });
        }, 1000);

        // setTimeout(() => {
        //   window.location.href = "/";
        // }, 5000);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      const timer = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown === 1) {
            clearInterval(timer);
            navigate('/');
            window.localStorage.clear();
          }
          return prevCountdown - 1;
        });
      }, 1000);
    } finally {
      const timer = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown === 1) {
            clearInterval(timer);
            navigate('/');
            window.localStorage.clear();
          }
          return prevCountdown - 1;
        });
      }, 1000);
      dispatch(hideLoading());
    }
  };

  return (
    <>
    <div className='w-auto h-dvh p-10 bg-blue-800 flex justify-center items-center'>
      {/* <h5>Esign has been submitted successfully!</h5> */}
      {Card?(<div className="border rounded-2xl bg-white p-10 text-center text-xl w-[50%] mx-auto my-auto mt-20">Onboarding is completed successfully.Please check your registered email and authenticate your elog and nominee email.Now you will be redirected to the login page. Please wait for <span className='text-blue-900'>{countdown}</span> sec.</div>):(
        !verificationError?(
        <div className="border rounded-2xl bg-white p-10 text-center text-xl w-[50%] mx-auto my-auto mt-20">Onboarding is completed. Please enter the OTP sent to your registered mail.</div>
        ):(
        <div className="border rounded-2xl bg-white p-10 text-center text-xl w-[50%] mx-auto my-auto mt-20">{errorMessage}</div>
        ))}
    </div>
    <VerifyOTPModal
        title="Verify OTP Sent to your mobile and email"
        showModal={showVerifyModal}
        setShowModal={setShowVerifyModal}
        otp={otp}
        setOtp={setOtp}
        onClick={submitOTP}
      />
    </>
  );
};

export default EsignSuccess;
