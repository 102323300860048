import api from "../../config/apiConfig";

const getWatchListInfo = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("wishlist/get", null, {
      headers: {
        Authorization: accessToken,
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

const addWatchList = async (data: any) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await api.post('wishlist/add', data, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

const removeWatchList = async (data: any) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("wishlist/delete", data, {
      headers: {
        Authorization: accessToken,
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};


const WLService = { getWatchListInfo, addWatchList, removeWatchList };
export default WLService;
