import {StepIndicator as StepInd} from '../../components';
import {Button as Buttons} from "../../components";
import {AumSampannHeader as HeaderText} from "../../components";
import styles from "../../pages/CompanyDetails/CompanyDetails.module.css"
import { colors } from "../../constants/colors";
import { ChangeEvent } from 'react';

const SectionTitle = ({ title, className }:{title:string, className:string}) => <h2 className="mr-8 text-2xl text-black max-md:mt-10 mt-20 max-md:mr-2.5 max-md:max-w-full">{title}</h2>;

const textBlack = colors.black
const textWhite = colors.white
const background = colors.white

const textColorSkip = colors.black;
const bgColorSkip = colors.white;
const textColorSave = colors.white;
const bgColorSave = colors.black
const inputFieldColor = colors.inputLabel;


const InfoSection = ({
    label,
    imageSrc,
    altText,
    value,
    onChange
  }: {
    label: string;
    imageSrc: string;
    altText: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  }) => (
    <div className="flex mt-20 gap-5 justify-between max-md:flex-wrap">
      <div className="flex flex-col grow shrink-0 text-lg basis-0 text-neutral-400 w-fit">
        <div>{label}</div>
        <input
          type="text"
          id={label.toLowerCase().replace(/\s+/g, '-')}
          value={value}
          onChange={onChange}
          style={{ backgroundColor: background }}
          className={`shrink-0 mt-2 rounded-md border border-solid h-[50px] max-md:max-w-full`}
          aria-label={label}
        />
      </div>
      <div className="flex flex-col grow shrink-0 basis-0 w-fit">
        <div className="text-lg text-neutral-400">Upload PAN Copy</div>
        <div className="flex flex-col justify-center items-end px-16 py-3.5 mt-2 bg-white rounded-md border border-solid border-zinc-300 max-md:px-5">
          <img loading="lazy" src={imageSrc} alt={altText} className="w-6 aspect-square" />
        </div>
      </div>
    </div>
  );
  

  
  
  const ActionButton = ({ children, className }: { children: React.ReactNode; className?: string; }) => (
    <button className={`grow justify-center px-8 py-4 rounded-md border border-solid max-md:px-5 ${className}`}>
      {children}
    </button>
  );
  

function CompanyDetails() {
  return (
    <>
       <main className="flex flex-col px-16 py-12 bg-white max-md:px-5">
            <div className=""><HeaderText/></div>
            <nav id='stepInd' className="flex -ml-14 gap-5 justify-evenly items-start mt-24 mr-8 whitespace-nowrap max-md:flex-wrap max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
            <StepInd stepNumber="1" description="General Details" isActive={false} />
            <StepInd stepNumber="2" description="Company Details" isActive={true} />
            <StepInd stepNumber="3" description="Demat Details" isActive={false} />
            <StepInd stepNumber="4" description="Preferences" isActive={false} />
            <StepInd stepNumber="5" description="Signatures" isActive={false} />
            </nav>

                <p className="flex flex-col bg-white max-md:px-5">
                  <SectionTitle title="Choose your category" className={"-ml-16"}  />
                  <div className="flex gap-5 justify-between mt-10 pr-20 mr-8 text-xl text-black whitespace-nowrap max-md:flex-wrap max-md:pr-5 max-md:mr-2.5 max-md:max-w-full">
                    <ActionButton className="text-white bg-black border-zinc-300">HUF</ActionButton>
                    <ActionButton className="bg-white border-zinc-300">Corporate</ActionButton>
                    <ActionButton className="bg-white border-zinc-300">LLP</ActionButton>
                    <ActionButton className="bg-white border-zinc-300">Trust</ActionButton>
                    <ActionButton className="bg-white border-zinc-300">Others</ActionButton>
                  </div>
                  <SectionTitle title="HUF" className=""/>
                  <p className="mt-11 mr-8 text-base underline text-stone-500 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
                    Note: All Documents should be self attested.
                  </p>
                </p>
           <div>
              <div className="mt-9 max-md:mr-2.5 max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                  <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow  max-md:max-w-5">
                      <InfoSection label="Pan Number of HUF" imageSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/713734590c630105ef97885a461d67b24266ffa57ff01d65549b5d5b7c2e2a76?apiKey=427d052184b84d30b7eba5c7e76648a1&" altText="PAN copy" value={''} onChange={function (e: ChangeEvent<HTMLInputElement>): void {
                                      throw new Error('Function not implemented.');
                                  } } />
                      
                      <SectionTitle title="Bank Statement (Last 3 months)" className={""} />
                      <div className="flex flex-col justify-center items-end px-16 py-3.5 mt-2 bg-white rounded-md border border-solid border-zinc-300 max-md:px-5 max-md:max-w-full">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/713734590c630105ef97885a461d67b24266ffa57ff01d65549b5d5b7c2e2a76?apiKey=427d052184b84d30b7eba5c7e76648a1&" alt="Bank statement" className="mr-6 w-6 aspect-square max-md:mr-2.5" />
                      </div>
                      
                      <InfoSection label="Pan Number of Karta" imageSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/713734590c630105ef97885a461d67b24266ffa57ff01d65549b5d5b7c2e2a76?apiKey=427d052184b84d30b7eba5c7e76648a1&" altText="PAN copy of Karta" value={''} onChange={function (e: ChangeEvent<HTMLInputElement>): void {
                                      throw new Error('Function not implemented.');
                                  } } />
                     
                      <SectionTitle title="Deed Declaration of HUF / List of Co-Parceners" className={""} />
                      <div className="flex flex-col justify-center items-end px-16 py-3.5 mt-2.5 bg-white rounded-md border border-solid border-zinc-300 max-md:px-5 max-md:max-w-full">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/713734590c630105ef97885a461d67b24266ffa57ff01d65549b5d5b7c2e2a76?apiKey=427d052184b84d30b7eba5c7e76648a1&" alt="Deed declaration" className="mr-6 w-6 aspect-square max-md:mr-2.5" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col max-md:max-w-full">
                      <SectionTitle title="Address Proof of HUF" className={""} />
                      <div className="flex flex-col justify-center items-end px-16 py-3.5 mt-2  bg-white rounded-md border border-solid border-zinc-300 max-md:px-5 max-md:max-w-full">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/713734590c630105ef97885a461d67b24266ffa57ff01d65549b5d5b7c2e2a76?apiKey=427d052184b84d30b7eba5c7e76648a1&" alt="Address proof of HUF" className="mr-6 w-6 aspect-square max-md:mr-2.5" />
                      </div>
                      
                      <SectionTitle title="Original Cancelled Cheque" className={""} />
                      <div className="flex flex-col justify-center items-end px-16 py-3.5 mt-2  bg-white rounded-md border border-solid border-zinc-300 max-md:px-5 max-md:max-w-full">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/713734590c630105ef97885a461d67b24266ffa57ff01d65549b5d5b7c2e2a76?apiKey=427d052184b84d30b7eba5c7e76648a1&" alt="Cancelled cheque" className="mr-6 w-6 aspect-square max-md:mr-2.5" />
                      </div>
                      
                      <SectionTitle title="Address Proof of Karta" className={""} />
                      <div className="flex flex-col justify-center items-end px-16 py-3.5  mt-2 bg-white rounded-md border border-solid border-zinc-300 max-md:px-5 max-md:max-w-full">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/713734590c630105ef97885a461d67b24266ffa57ff01d65549b5d5b7c2e2a76?apiKey=427d052184b84d30b7eba5c7e76648a1&" alt="Address proof of Karta" className="mr-6 w-6 aspect-square max-md:mr-2.5" />
                      </div>
                      
                      <SectionTitle title="FATCA Declaration" className={""} />
                      <div className="flex flex-col justify-center items-end px-16 py-3.5  mt-2 bg-white rounded-md border border-solid border-zinc-300 max-md:px-5 max-md:max-w-full">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/713734590c630105ef97885a461d67b24266ffa57ff01d65549b5d5b7c2e2a76?apiKey=427d052184b84d30b7eba5c7e76648a1&" alt="FATCA declaration" className="mr-6 w-6 aspect-square max-md:mr-2.5" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=""><Buttons/></div>
       </main>
    </>
  );
}

export default CompanyDetails;
