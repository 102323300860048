import axios from "axios";
import api from "../../config/apiConfig";

const manualKYC = async (data: FormData) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/user/addKYC", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
};

const zeroKyc = async (access_token: string) => {
    const token = localStorage.getItem("accessToken");
    const response = await api.post("/user/zeroDocKyc", { accessToken: access_token }, {
        headers: {
            Authorization: token,
        },
    });
    return response.data;
}

const skipAdharKyc = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("user/skipAadhaar", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
};

const kycService = { manualKYC, zeroKyc, skipAdharKyc };
export default kycService;