import { Box, CircularProgress, Modal, Typography } from '@mui/material';
import React from 'react';

interface APIloaderProps {
  loadingModal: boolean;
  message:string;
}

function APIloader({ loadingModal,message }: APIloaderProps) {
  return (
    <Modal
      open={loadingModal}
      aria-labelledby="loading-modal-title"
      aria-describedby="loading-modal-description"
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <div className="w-[30%] h-[30%] flex flex-col items-center justify-center bg-lightBg rounded-lg">
          <Typography id="loading-modal-title" variant="subtitle1" component="h2" marginBottom={5}>
            {message !==""?message:`Processing your request, please wait...`}
          </Typography>
          <CircularProgress />
        </div>
      </Box>
    </Modal>
  );
}

export default APIloader;
