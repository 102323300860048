import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dematService from "./dematService";

export const addDematDetails = createAsyncThunk(
  "demat/add",
  async (data: FormData, thunkAPI: any) => {
    try {
      return await dematService.addDematDetails(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  data: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const dematSlice = createSlice({
  name: "demat",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addDematDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addDematDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(addDematDetails.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      });
  },
});

export const { resetState } = dematSlice.actions;
export default dematSlice.reducer;
