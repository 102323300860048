import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FormInput } from "../../../components/FormInput/FormInput";
import { colors } from "../../../constants/colors";
import FileInput from "../../../components/FileInput/FileInput";
import * as yup from "yup";
import FormDatePicker from "../../../components/FormDatePicker/FormDatePicker";
import dayjs from "dayjs";
import { Button } from "../../../components";
import ConfirmationModal from "../../../components/modals/PopupModal";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { addKYC } from "../../../redux/kyc/kycSlice";
import { hideLoading, showLoading } from "../../../redux/loader/loaderSlice";
import { panVerify } from "../../../redux/verification_aadhar&pan/verificationSlice";
import VerifyButton from "../../../components/VerifyButton/VerifyButton";
import { getUserById, updateUser } from "../../../redux/user/userSlice";
import { setNestedObjectValues } from "formik";
import { CheckCircle } from "@mui/icons-material";
import { useLocation } from "react-router-dom"; // Import useLocation
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button as Btn,
} from "@mui/material";

const panValues = {
  pancardNumber: "",
  dateOfBirth: "",
  email: "",
  fullName: "",
};

const panSchema = yup.object({
  pancardNumber: yup
    .string()
    .required("PAN Card Number is required")
    .matches(/^([A-Z]{5}[0-9]{4}[A-Z]{1})+$/, "Enter Valid PAN Number"),
  fullName: yup
    .string()
    .required("Entered name should be same as PAN Card Name")
    .matches(
      /^[a-zA-Z\s-]+$/,
      "The name can only contain letters, spaces, and hyphens"
    ),
  dateOfBirth: yup.string().required("Enter DOB associated with PAN Number"),
});

const PanVerification = ({ onProceed, userData, activeSection }: any) => {
  const dispatch = useDispatch<any>();
  const location = useLocation(); // Get current location
  const currentPath = location.pathname; // Extract pathname
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [verifiedDetails, setVerifiedDetails] = useState({
    pancardNumber: "",
    pancard: "",
    isPanVerified: false,
  });
  const [isAadharVerified, setIsAadharVerified] = useState(false);
  const [isPanVerified, setIsPanVerified] = useState(false);
  const [panCard, setPanCard] = useState<File>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);
  const panFormRef = useRef<any>(null);
  const [nameDisabled, setNameDisabled] = useState(true);

  // New state to track PAN type validity
  const [isPanTypeValid, setIsPanTypeValid] = useState(false);

  useEffect(() => {
    dispatch(getUserById({}));
  }, [dispatch]);

  useEffect(() => {
    if (userData) {
      if (userData?.firstName) {
        panFormRef.current?.setFieldValue("fullName", userData?.firstName);
      }
      if (userData?.dateOfBirth) {
        panFormRef.current?.setFieldValue(
          "dateOfBirth",
          dayjs(userData?.dateOfBirth).format("DD/MM/YYYY")
        );
        setIsFieldDisabled(true);
      }

      if (userData?.kyc && Object.keys(userData?.kyc).length > 0) {
        setVerifiedDetails({
          pancard: userData?.kyc?.pancard,
          pancardNumber: userData?.kyc?.pancardNumber?.slice(-4),
          isPanVerified: !!userData?.kyc?.isPanVerified,
        });
      }
    }
  }, [userData]);

  
  const validatePanType = (panNumber: string) => {
    if (panNumber.length !== 10) {
      // Don't validate until the PAN number is fully entered
      return true;
    }
  
    const fourthChar = panNumber.charAt(3).toUpperCase();
    const individualTypes = ["P", "J"];
    const nonIndividualTypes = ["C", "H", "L", "T"];
  
    if (currentPath === "/onboarding") {
      if (!individualTypes.includes(fourthChar)) {
        setDialogMessage("Invalid PAN type. Only individual PAN types are allowed (P, J).");
        setIsDialogOpen(true);
        return false;
      }
      return true;
    } else if (currentPath === "/nonIndividual") {
      if (!nonIndividualTypes.includes(fourthChar)) {
        setDialogMessage("Invalid PAN type. Only non-individual PAN types are allowed (C, H, L, T).");
        setIsDialogOpen(true);
        return false;
      }
      return true;
    }
  
    setDialogMessage("Invalid PAN number.");
    setIsDialogOpen(true);
    return false;
  };
  


  // Handler for PAN number change to validate PAN type live
  const handlePanNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    handleChange: any,
    setFieldValue: any
  ) => {
    const newValue = e.target.value.toUpperCase();
    handleChange(e);
    setFieldValue("pancardNumber", newValue);

    // Validate PAN type based on 4th character
    const isValid = validatePanType(newValue);
    setIsPanTypeValid(isValid);
  };

  const checkAlreadySubmitted = () => {
    if (Object.keys(userData?.kyc).length > 0) {
      onProceed();
    } else {
      handleSaveAndProceed();
    }
  };

  const handleModalConfirm = () => {
    setIsModalVisible(false); // Hide the modal after confirmation
    if (panFormRef.current) {
      panFormRef.current?.handleSubmit(); // Submit the form upon confirmation
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Hide the modal if user cancels
  };

  const handleSaveAndProceed = async () => {
    // generalDetailsRef.current?.handleSubmit();
    //setIsModalVisible(true); // Show the modal when the button is clicked
    //added so that modal window should only comes once all validations are done
    if (panFormRef.current) {
      // console.log("panFormikRef");
      const validationErrors = await panFormRef.current.validateForm();
      if (Object.keys(validationErrors).length > 0) {
        panFormRef.current.setTouched(
          setNestedObjectValues(validationErrors, true)
        );
        return;
      }
      if (panFormRef.current.isValid && isPanTypeValid) {
        // Ensure PAN type is valid
        setIsModalVisible(true); // Show the modal when the button is clicked
      } else {
        toast.error(
          "PAN type does not match the required type for this section."
        );
      }
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleVerifyPAN = async () => {
    try {
      const payload = {
        pancardNumber: panFormRef.current?.values?.pancardNumber,
        dateOfBirth: panFormRef.current?.values?.dateOfBirth,
        fullName: panFormRef.current?.values?.fullName,
      };
      if (!payload.pancardNumber || !payload.dateOfBirth || !payload.fullName) {
        setDialogMessage("Please enter all the details");
        setIsDialogOpen(true);
        return;
      }

      dispatch(showLoading());
      const response = await dispatch(panVerify(payload));

      if (response?.error?.message) {
        setDialogMessage(response?.payload?.message);
        setIsDialogOpen(true);
        return;
      }

      setIsPanVerified(true);
      toast.success("PAN verified successfully"); // You can keep success toast if needed
    } catch (error) {
      console.error("Error:", error);
      setDialogMessage("Failed to verify PAN");
      setIsDialogOpen(true);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleNameDisabled = () => {
    setNameDisabled(!nameDisabled);
    // console.log("value", nameDisabled);
  };

  // Determine if the "Save and Proceed" button should be disabled
  const isSaveDisabled = !isPanVerified || !isPanTypeValid;

  return (
    <>
      <div className="flex flex-col w-full max-w-screen-xl my-8">
        {/* Adjusted text size */}
        {/* Inputs for screens above 900px */}
        {!!userData?.kyc?.isPanVerified ? (
          <div className="flex flex-col gap-5 items-center">
            <CheckCircle className="text-green-600 text-3xl" fontSize="large" />
            <p className="text-lg font-semibold font-inter">
              Your PAN is already verified. Please proceed further.
            </p>
            <Button
              type="button"
              className={`px-8 py-4 rounded-md bg-darkGrey text-white hover:bg-primary transition-all max-w-[250px] w-full mt-12`}
              tabIndex={0}
              onClick={onProceed}
            >
              Proceed
            </Button>
          </div>
        ) : (
          <>
            <Formik
              innerRef={panFormRef}
              initialValues={panValues}
              validationSchema={panSchema}
              onSubmit={async (values, { setSubmitting }) => {
                if (!isPanVerified) {
                  toast.error(
                    "Please verify your PAN details by clicking 'Verify'"
                  );
                  return;
                }
                dispatch(
                  updateUser({
                    dateOfBirth: values.dateOfBirth,
                    firstName: values.fullName,
                  })
                );

                const formData = new FormData();
                formData.append("pancardNumber", values.pancardNumber);
                formData.append("isNominee", "0");
                formData.append("forPan", "1");
                const response = await dispatch(addKYC(formData));
                if (response?.error?.message) {
                  toast.error("Failed to add PAN Details");
                  return;
                }
                onProceed();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  className="w-full flex flex-col gap-4"
                >
                  <div className="flex flex-row flex-wrap gap-8 justify-center">
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Full Name as per PAN Card *"
                        type="text"
                        name="fullName"
                        id="fullName"
                        onChange={(e: any) =>
                          setFieldValue("fullName", e.target.value)
                        }
                        value={values.fullName}
                        error={errors.fullName}
                        disabled={nameDisabled}
                        touched={touched.fullName}
                        suffix={
                          <span
                            className="text-sm text-secondary cursor-pointer font-semibold"
                            onClick={handleNameDisabled}
                          >
                            {nameDisabled ? "Edit" : "Save"}
                          </span>
                        }
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormDatePicker
                        label="DOB *"
                        onChange={(date) => {
                          const formattedDate = date
                            ? date.format("DD/MM/YYYY")
                            : "";
                          handleChange("dateOfBirth")(formattedDate);
                          setFieldValue("dateOfBirth", formattedDate);
                        }}
                        disabled={isPanVerified || isFieldDisabled}
                        value={
                          values.dateOfBirth
                            ? dayjs(values.dateOfBirth, "DD/MM/YYYY")
                            : null
                        }
                        name="dateOfBirth"
                        error={errors.dateOfBirth}
                        touched={touched.dateOfBirth}
                        inputFormat="DD/MM/YYYY"
                      />
                    </div>

                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="PAN Number *"
                        type="text"
                        name="pancardNumber"
                        id="pancardNumber"
                        maxLength={10}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePanNumberChange(e, handleChange, setFieldValue)
                        }
                        placeholder={
                          verifiedDetails?.pancardNumber
                            ? `**** **** ${verifiedDetails?.pancardNumber}`
                            : ""
                        }
                        disabled={
                          !!verifiedDetails?.pancardNumber || isPanVerified
                        }
                        value={values.pancardNumber}
                        error={errors.pancardNumber}
                        touched={touched.pancardNumber}
                        suffix={
                          <VerifyButton
                            isVerified={
                              verifiedDetails.isPanVerified || isPanVerified
                            }
                            onClick={handleVerifyPAN}
                          />
                        }
                      />
                      {/* Optional: Show validation message for PAN type */}
                      {!isPanTypeValid && values.pancardNumber.length >= 4 && (
                        <p className="text-red-500 text-xs mt-1">
                          PAN type does not match the required type for this
                          section.
                        </p>
                      )}
                    </div>

                    <div className="w-full max-w-[500px]"></div>
                  </div>
                  <div className="pl-32">
                    <Button
                      type="button"
                      className={`px-8 py-4 rounded-md bg-darkGrey text-white hover:bg-primary transition-all max-w-[250px] w-full mt-12 ${
                        isSaveDisabled ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      tabIndex={0}
                      onClick={checkAlreadySubmitted}
                      disabled={isSaveDisabled}
                    >
                      Save and Proceed
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
            <Dialog
              open={isDialogOpen}
              onClose={handleCloseDialog}
              sx={{
                "& .MuiDialog-paper": {
                  width: "400px", // Set the width
                  height: "200px", // Set the height
                  maxWidth: "100%", // Ensure it doesn't exceed the viewport width
                },
              }}
            >
              <DialogTitle sx={{ fontWeight: "bold", color: colors.primary }}>
                Error
              </DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ color: "text.secondary" }}>
                  {dialogMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Btn
                  onClick={handleCloseDialog}
                  variant="contained"
                  sx={{
                    backgroundColor: colors.primary,
                    color: "white",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                    },
                  }}
                >
                  OK
                </Btn>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>

      <ConfirmationModal
        isVisible={isModalVisible} // Render conditionally based on state
        message="Are you sure all fields are correct and verified? After you proceed to the next screen, fields cannot be edited again."
        onConfirm={handleModalConfirm} // Confirm action and submit form
        onCancel={handleModalCancel} // Cancel action and close modal
      />
    </>
  );
};

export default PanVerification;
