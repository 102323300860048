import React, { useState } from "react";
import {
  TableRow,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableCell,
  Container,
  ListItem,
  MenuItem,
  Divider,
  Menu,
  Fade
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import InvestModalBond from "../modals/investModalBond";

const TableRowComponent = ({ data, getInitials }: any) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);  
  const [, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorMD, setAnchorMD] = React.useState<null | HTMLElement>(null);
  const [isInvestModalOpen, setIsInvestModalOpen] = useState<boolean>(false);
  const [Fund,setFund]=useState({
    id: 0,
    Promotor:"",
    Bond:"",
    Category:"",
    Investment:0,
    Yield:0,
    Price:0
  });

  const handleExpandClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const isArray = Array.isArray(data);

  const openMenuMD = Boolean(anchorMD);

  const modalMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    id: number,
    Promotor:string,
    Bond:string,
    Category:string,
    Investment:number,
    Yield:number,
    Price:number
  ) => {
    setAnchorMD(event.currentTarget);
    setFund({id:id,Promotor:Promotor,Bond:Bond,Category:Category,Investment:Investment,Yield:Yield,Price:Price})
  };

  console.log(Fund,"Values");

  const handleCloseMD = () => {
    setAnchorMD(null);
  };

  const openInvestModal = () => {
    setIsInvestModalOpen(true);
  };
  const closeInvestModal = () => {
    setIsInvestModalOpen(false);
    // fetchData();
  };

  return (
    <>
      {isArray &&
        data?.map((item: any, index: number) => {
          const bondName = item?.bondName || "N/A";

          return (
            <React.Fragment key={item.id}>
              <TableRow className="border-[1px] border-lightGrey hover:bg-lightBg">
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ border: "none" }}
                  className="min-w-[150px]"
                >
                  <div className="flex gap-x-2 items-center">
                    <div className="w-12 h-12 mb-3 md:mb-0 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-xl font-bold">
                      {getInitials(bondName)}
                    </div>
                    <div className="w-3/5">
                      <span
                        className="cursor-pointer"
                        onClick={() => handleExpandClick(index)}
                      >
                        {bondName}
                      </span>
                    </div>
                  </div>
                </TableCell>

                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                  className="min-w-[150px]"
                >
                  {item?.category || "-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                  className="min-w-[150px]"
                >
                  {"Rs." + item?.minInvestment || "-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                  className="min-w-[150px]"
                >
                  {item?.yield + "%" || "-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                  className="min-w-[150px]"
                >
                  {"Rs." + item?.price || "-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                  className="min-w-[150px]"
                >
                  {"Rs." + item?.payment || "-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                  className="min-w-[150px]"
                >
                  {item?.coupon + "%" || "-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                  className="min-w-[150px]"
                >
                  {item?.maturityDate || "-"}
                </TableCell>
                <TableCell align="center" sx={{ border: "none" }}  className="min-w-[100px]">
                  <IconButton
                    onClick={(e) => {
                      modalMenuClick(e,item?.id,item?.promoter,item?.bondName,item?.category,item?.minInvestment,item?.yield,item?.price);
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow sx={{ height: "5%" }}>
                <TableCell
                  sx={{ border: "none" }}
                  colSpan={8}
                  align="center"
                ></TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
               <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorMD}
        open={openMenuMD}
        onClose={handleCloseMD}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            backgroundColor: "#255288",
            color: "#fff",
            overflow: "hidden",
            borderRadius: "10px",
            position: "relative",
            width: "12%",
          },
        }}
        className="relative"
      >
        <div className="mx-6">
          <ListItem button sx={{ marginLeft: "-25px",opacity: 0.5,
            cursor:"not-allowed" }}>
            <MenuItem sx={{cursor:"not-allowed"}} className="text-xs sm:text-sm">
              {/* {isinWishilist?.status === 1
                ? "Remove from watchlist"
                : "Add to Watchlist"} */}
                Add to Watchlist
            </MenuItem>
          </ListItem>
          <Divider
            sx={{
              backgroundColor: "#fff",
            }}
          />
          <ListItem
            button
            sx={{ marginLeft: "-25px"}}
            //   onClick={checkMF === 1 ? openInvestModal : () => {}}
          >
            <MenuItem className="cursor-pointer hover:bg-slate-500" onClick={openInvestModal}>Invest</MenuItem>
      
          </ListItem>
        </div>
        <div
          className="absolute bg-white"
          style={{
            width: "20px",
            height: "20px",
            transform: "rotate(315deg)",
            bottom: "calc(100% - 10px)",
            left: "calc(50% - 10px)",
          }}
        />
      </Menu>
      {isInvestModalOpen && <InvestModalBond open={isInvestModalOpen} onClose={closeInvestModal} fundInfo={Fund}/>}
    </>
  );
};

export default TableRowComponent;
