import { initializeApp } from "firebase/app";
import {GoogleAuthProvider, getAuth} from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBkCofL_eBUWDCVbneZlT6-DhkwPa_lshA",
  authDomain: "aumsampann-2e7d1.firebaseapp.com",
  projectId: "aumsampann-2e7d1",
  storageBucket: "aumsampann-2e7d1.appspot.com",
  messagingSenderId: "858321095328",
  appId: "1:858321095328:web:29d5a3a36812a83a0457c5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const googleAuthProvider = new GoogleAuthProvider();

export default app;