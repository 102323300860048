// src/SignaturePad.tsx
import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';



interface SignaturePadProps {
    onSignatureSave: (signatureFile: File) => void; // Callback function to handle saving signature file
  }


const SignaturePad: React.FC<SignaturePadProps> = ({ onSignatureSave }) => {
  const sigCanvas = useRef<SignatureCanvas>(null);


  const clear = () => {
    sigCanvas.current?.clear();
  };

  const save = () => {
    const canvas = sigCanvas.current?.getTrimmedCanvas();
    if (!canvas) return;
  
    // Convert the canvas to a Blob
    canvas.toBlob((blob) => {
      if (!blob) return;
  
      // Create a File object from the Blob
      const signatureFile = new File([blob], 'signature.png', { type: 'image/png' });
      console.log(signatureFile);
  
      onSignatureSave(signatureFile);
      // Do something with the signature file (e.g., send it to a server)
      // For example, you can pass it to a function in your main code to upload through the API
      // uploadSignature(signatureFile);
    }, 'image/png');
  };
  
  return (
    <div className="flex flex-col items-center mt-10 mb-5">
      <SignatureCanvas
        ref={sigCanvas}
        penColor="black"
        canvasProps={{
          className: 'border border-black w-96 h-48 bg-white'
        }}
      />
      <div className="mt-4">
        <button onClick={clear} className="bg-primary text-white px-4 py-2 rounded mr-2">Clear</button>
        <button onClick={save} className="bg-orange text-white px-4 py-2 rounded">Upload Sign</button>
      </div>
    </div>
  );
};

export default SignaturePad;
