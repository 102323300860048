import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fundInfoModel from "../../models/mutualFund.model";
import fundInfoById_Model from "../../models/mutualFundbyId.model";
import verification_Service from "./verificationService";

export const aadharVerificationOTP = createAsyncThunk(
  "user/sendAadharOTP",
  async (aadharNumber: any, thunkAPI) => {
    // console.log("verification Slice");
    try {
      const response = await verification_Service.getAadharOtp(aadharNumber);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const aadharVerification = createAsyncThunk(
  "user/verifyAadharOTP",
  async (requestData: any, thunkAPI) => {
    // console.log(requestData, "requestData");
    // const { accessToken, data } = requestData;
    try {
      const response = await verification_Service.verifyAadharOTP(requestData);
      return response;
    } catch (error: any) {
      // console.log(error, "aadhar verification error");
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const panVerify = createAsyncThunk(
  "user/panVerify",
  async (data: any, thunkAPI: any) => {
    try {
      if (!data) {
        // console.log("no data", data);
        throw new Error("No refresh token provided");
      }
      // console.log("data :>> ", data);

      const response = await verification_Service.panVerification(data);

      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

const initialState = {
  aadhar: [],
  pan: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

export const verification = createSlice({
  name: "verification",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(panVerify.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(panVerify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.pan = action.payload;
      })
      .addCase(panVerify.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(aadharVerification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(aadharVerification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.aadhar = action.payload;
      })
      .addCase(aadharVerification.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      });
  },
});

export const { resetState } = verification.actions;
export const { reducer: verificationReducer } = verification;
