import React, { useState } from "react";
import {
  TableRow,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableCell,
  Container,
  ListItem,
  MenuItem,
  Divider,
  Menu,
  Fade,
} from "@mui/material";
import {

  MoreVert,

} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InvestModalAIF from "../modals/investModalAIF";
import { Navigate ,useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addToWatchlist,
  removeToWatchlist,
} from "../../redux/wishlists/wishlistSlice";
import toast from "react-hot-toast";
import { useUserData } from "../../hooks/useUserData";

const TableRowComponent = ({ data }: any) => {
  const [open, setOpen] = useState(false);
  const user = useUserData();
  const checkAIF = user?.preferences?.aif;
  const dispatch = useDispatch<any>();
  // console.log('data On', data);
  const navigate= useNavigate();
  const [isinWishilist, setIsinWishilist] = useState<any>();
  const [openRows, setOpenRows] = useState<boolean[]>(
    new Array(data.length).fill(false)
  );
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorMD, setAnchorMD] = React.useState<null | HTMLElement>(null);
  const [isInvestModalOpen, setIsInvestModalOpen] = useState<boolean>(false);
  const [Fund, setFund] = useState({
    id: 0,
    FundManager: "",
    AssetName: "",
    Category: "",
    AumInCr: "",
    NAV:0,
    ReturnSinceInception: "",
    RaisedBar:"" ,
    IsInWishlist:0
  });

  const Watchlist = async () => {
    if (isinWishilist.status === 1) {
      const data = { itemId: isinWishilist.id, itemType: 5 };
      const response = await dispatch(removeToWatchlist(data));
      if (response.payload) {
        setIsinWishilist({ ...isinWishilist, status: 0 });
        toast.success("Removed from watchlist");
      } else {
        toast.error("Failed to remove from watchlist");
      }
    } else if (isinWishilist.status === 0) {
      const data = { itemId: isinWishilist.id, itemType: 5 };
      const response = await dispatch(addToWatchlist(data));
      if (response.payload) {
        setIsinWishilist({ ...isinWishilist, status: 1 });
        toast.success("Added to watchlist");
      } else {
        toast.error("Failed to add to watchlist");
      }
    }
  };

  const openMenuMD = Boolean(anchorMD);

  const modalMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    id: number,
    FundManager: string,
    AssetName: string,
    Category: string,
    AumInCr: string,
    NAV:number,
    ReturnSinceInception: string,
    RaisedBar:string ,
    IsInWishlist:number
  ) => {
    setAnchorMD(event.currentTarget);
    setFund({
      id: id,
      FundManager: FundManager,
      AssetName: AssetName,
      Category: Category,
      AumInCr: AumInCr,
      NAV:NAV,
      ReturnSinceInception: ReturnSinceInception,
      RaisedBar:RaisedBar ,
      IsInWishlist:IsInWishlist
    });
  };

  const handleCloseMD = () => {
    setAnchorMD(null);
  };

  const openInvestModal = () => {
    setIsInvestModalOpen(true);
  };
  const closeInvestModal = () => {
    setIsInvestModalOpen(false);
    // fetchData();
  };

    // Helper function to determine if assetName is valid
    const isValidAssetName = (assetName: string) => {
      return assetName && assetName !== "-" && assetName.trim() !== "";
    };


  return (
    <>
     {data?.schemes[0]?.assetName && isValidAssetName(data?.schemes[0]?.assetName) && (
      <TableRow
        key={data.id}
        className="border-[1px] border-lightGrey hover:bg-lightBg cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        {/* <TableCell >
         
        </TableCell> */}
        <TableCell
          component="th"
          scope="row"
          // sx={{ border: "none" }}
          className=" min-w-[210px]"
        >
          <div className="flex gap-x-2 items-center">
            <div className="w-5/5">
              <span className="cursor-pointer">
                {data?.promoter}
              </span>
            </div>
          </div>
        </TableCell>
        <TableCell
          
          align="center"
          className=" min-w-[150px]"
        >
        </TableCell>
        <TableCell
          
          align="center"
          className=" min-w-[100px]"
        >
        </TableCell>
        <TableCell
          
          align="center"
          className=" min-w-[100px]"
        >
        </TableCell>
        <TableCell
          
          align="center"
          className=" min-w-[150px]"
        >
        </TableCell>
        <TableCell
         
          align="center"
          className=" min-w-[150px]"
        >
        </TableCell>
        <TableCell
         
          align="center"
          className=" min-w-[150px]"
        >
        </TableCell>
        {/* <TableCell
          sx={{ border: "none" }}
          align="center"
          className=" min-w-[150px]"
        >
          {data?.schemes[0]?.raisedBar || "-"}
        </TableCell> */}
        <TableCell align="center" sx={{ display: "flex", flexDirection: "row-reverse", border: 'none', }} className="min-w-[100px] flex justify-center">
                    {data?.schemes?.length>0 && (<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>)}
                    {/* <IconButton
                    onClick={(e) => {
                      modalMenuClick(
                        e,
                        data?.schemes[0]?.id,
                        data?.schemes[0]?.fundManager,
                        data?.schemes[0]?.assetName,
                        data?.schemes[0]?.category,
                        data?.schemes[0]?.aumInCr,
                        data?.schemes[0]?.currentNAV,
                        data?.schemes[0]?.returnSinceInception,
                        data?.schemes[0]?.raisedBar,
                        data?.schemes[0]?.isInWishlist
                      );setIsinWishilist({status:data?.schemes[0]?.isInWishlist,id:data?.schemes[0]?.id});
                    }}
                  >
                    <MoreVert />
                  </IconButton> */}
                </TableCell>
      </TableRow>
     )}
      {data?.schemes?.length>0?(<TableRow sx={{ paddingBottom: "40px" }}>
        <TableCell
          className="tableCells"
          colSpan={12}
          sx={{
            padding: "0",
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit className="">
            <Table aria-label="purchases">
              <TableBody
                className="border-l-[1px] border-r-[1px] border-b-[1px] "
                sx={{
                  "&.css-1la361y-MuiTableCell-root": {
                    padding: "0!important",
                  },
                }}
              >
                <TableRow sx={{ height: "5%" }}>
                  <TableCell
                    sx={{ border: "none" }}
                    colSpan={8}
                    align="center"
                  ></TableCell>
                </TableRow>
                {data?.schemes?.map((schemes: any,index:any) => (
                  <Container className="hover:bg-lightBg hover:border hover:border-primary hover:rounded-md my-4 min-w-full py-[2%] cursor-pointer" onClick={() => navigate(`/aif-details/${data?.schemes[index]?.id}`, { state: { schemes: data?.schemes[index] }})}>
                    <TableCell
                      sx={{ border: "none", padding: "0" }}
                      className="cursor-pointer  w-[180px]"
                    >
                      <div className="flex gap-x-2 items-center  subcategory">
                        <div className="w-4/5">
                          <span>{schemes?.assetName}</span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "none" }}
                      align="center"
                      className=" w-[150px]"
                    >
                      {schemes?.fundManager || "-"}
                    </TableCell>

                    <TableCell
                      sx={{ borderBottom: "none" }}
                      align="center"
                      className="w-[140px]"
                    >
                      {schemes?.inceptionDate || "-"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "none" }}
                      align="center"
                      className="w-[120px]"
                    >
                      {schemes?.aumInCr || "-"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "none" }}
                      align="center"
                      className="w-[220px]"
                    >
                      {schemes?.abs1Month==="-"?"-":schemes?.abs1Month+"%"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "none" }}
                      align="center"
                      className="w-[220px]"
                    >
                       {schemes?.ann1Year==="-"?"-":schemes?.ann1Year+"%"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "none" }}
                      align="center"
                      className="w-[230px]"
                    >
                      {schemes?.returnSinceInception==="-"?"-":schemes?.returnSinceInception+"%"}
                    </TableCell>
                    {/* <TableCell
                      sx={{ borderBottom: "none" }}
                      align="center"
                      className="min-w-[150px]"
                    >
                      {schemes?.raisedBar || "-"}
                    </TableCell> */}
                    <TableCell
                      align="right"
                      className="w-[60px]"
                      sx={{
                        "&.MuiTableCell-root": {
                          padding: 0,
                          textAlign: "right",
                          borderBottom: "none",
                        },
                      }}
                    >
                      <IconButton
                    onClick={(e) => {
                      modalMenuClick(
                        e,
                        schemes?.id,
                        schemes?.fundManager,
                        schemes?.assetName,
                        schemes?.category,
                        schemes?.aumInCr,
                        schemes?.currentNAV,
                        schemes?.returnSinceInception,
                        schemes?.raisedBar,
                        schemes?.isInWishlist
                      );setIsinWishilist({status:schemes?.isInWishlist,id:schemes?.id});
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                    </TableCell>
                  </Container>
                ))}
                 <TableRow sx={{ height: "5%" }}>
                  <TableCell
                    sx={{ border: "none" }}
                    colSpan={8}
                    align="center"
                  ></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>):(<></>)}
      {data?.schemes[0]?.assetName && isValidAssetName(data?.schemes[0]?.assetName) && (<TableRow sx={{ height: "5%" }}>
        <TableCell
          sx={{ border: "none" }}
          colSpan={8}
          align="center"
        ></TableCell>
      </TableRow>)}
      {isInvestModalOpen && (
        <InvestModalAIF
          open={isInvestModalOpen}
          onClose={closeInvestModal}
          fundInfo={Fund}
        />
      )}
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorMD}
        open={openMenuMD}
        onClose={handleCloseMD}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            backgroundColor: "#255288",
            color: "#fff",
            overflow: "hidden",
            borderRadius: "10px",
            position: "relative",
            width: "12%",
          },
        }}
        className="relative"
      >
        <div className="mx-6">
          <ListItem
            button
            sx={{ marginLeft: "-25px"}}
          >
            <MenuItem
              
              className="text-xs sm:text-sm"
            
              onClick={Watchlist}>{isinWishilist?.status === 1 ? "Remove from watchlist" : 'Add to Watchlist'}
            </MenuItem>
          </ListItem>
          <Divider
            sx={{
              backgroundColor: "#fff",
            }}
          />
          <ListItem
            button
            sx={{ marginLeft: "-25px",opacity: checkAIF === 1 ? 1 : 0.5,
              cursor: checkAIF === 1 ? "pointer" : "not-allowed"}}
              onClick={checkAIF === 1 ? openInvestModal : () => {}}
          >
            <MenuItem
              className="cursor-pointer hover:bg-slate-500"
              onClick={openInvestModal}
            >
              Invest
            </MenuItem>
          </ListItem>
        </div>
        <div
          className="absolute bg-white"
          style={{
            width: "20px",
            height: "20px",
            transform: "rotate(315deg)",
            bottom: "calc(100% - 10px)",
            left: "calc(50% - 10px)",
          }}
        />
      </Menu>
    </>
  );
};

export default TableRowComponent;
