import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/auth/authSlice";
import userReducer from "../redux/user/userSlice";
import kycReducer from "../redux/kyc/kycSlice";
import bankReducer from "../redux/bank/bankSlice";

import { MFSliceReducer } from "./MF/MFSlice";
import orgReducer from "./NonInd_GeneralDetails/generalSlice";
import nomineeReducer from "../redux/nominee/nomineeSlice";
import dematReducer from "../redux/demat/dematSlice";
import hufReducer from "./nonInd_huf/hufSlice";
import corporateReducer from "./nonInd_corporate/corporateSlice";
import llpReducer from "./nonInd_Llp/llpSlice";

import assetReducer from "./asset_Holdings/assetSlice";

import investReducer from "./InvestMF/investSlice";

import FdReducer from "./FDHoldings/fdSlice"

import FDSlice from "./FD/FDSlice";
import eSignSlice from "./eSign/eSignSlice";
import { verificationReducer } from "./verification_aadhar&pan/verificationSlice";
import { loaderReducer } from "./loader/loaderSlice";
import { ReportSliceReducer } from "./Reports/ReportSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    kyc: kycReducer,
    bank: bankReducer,
    MFund: MFSliceReducer,
    FDund: FDSlice,
    org: orgReducer,
    nominee: nomineeReducer,
    demat: dematReducer,
    huf: hufReducer,
    corporate: corporateReducer,
    llp: llpReducer,
    asset: assetReducer,
    invest: investReducer,
    esignKyc: eSignSlice,
    verification: verificationReducer,
    FDHolding:FdReducer,
    loaderReducer: loaderReducer,
    Reports: ReportSliceReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
