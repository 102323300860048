import React from "react";
import { colors } from "../../constants/colors";

interface FormSelectProps {
    label: string;
    onChange?: any;
    value?: any;
    name?: string;
    id?: string;
    type?: string;
    disabled?: boolean;
    error?: string;
    touched?: boolean;
    className?: string;
    style?: any;
    options: any;
  }

export const FormSelect = React.forwardRef<HTMLSelectElement, FormSelectProps> (({
    label,
    onChange,
    value,
    name,
    id,
    type,
    disabled,
    error,
    touched,
    options,
    className,
    style,
    ...restProps
  }) => (
  
    <div className={`flex flex-col text-lg md:mb-10 ${className}`} style={{...style}}>
      <label
        htmlFor={label}
        className="text-lg text-left"
        style={{ color: colors.darkGrey }}
      >
        {label}
      </label>
      <select
        id={label}
        className="w-full mt-3 mx-auto bg-white rounded-lg border border-solid border-lightGrey p-4  "
        style={{
          color: colors.darkGrey,
        }}
        name={name}
        aria-label={label}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        <option selected value="">Select {label}</option>
        {options.map((option: any, index: number) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {!!error && touched ? <p className="text-secondary h-1.5">{error}</p> : <p className="text-secondary h-1.5"></p>}
    </div>
  ));

export default FormSelect;
