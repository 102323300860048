import React, { useState } from 'react';
import { TableRow, IconButton, Collapse, Table, TableBody, TableCell, Container } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { colors } from '../../constants/colors';


const MFTableRowComponent = ({ data, getInitials, modalMenuClick }: any) => {
    const [openIndex, setOpenIndex] = useState<number | null>(null);



    const handleExpandClick = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    const isArray=Array.isArray(data);  

    return (
        <>
            {isArray && data?.map((item: any, index: number) => {
                const Name = item?.schemeName || 'N/A';

                return (
                    <React.Fragment key={item.id}>
                        <TableRow className="border-[1px] border-lightGrey hover:bg-lightBg">
                            <TableCell component="th" scope="row" sx={{ 
                                    borderLeft: '1px solid #ccc', 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} className="min-w-[200px]">
                                <div className="flex gap-x-2 items-center">
                                    <div className="w-12 h-10 mb-3 md:mb-0 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-xl font-bold">
                                        {getInitials(Name)}
                                    </div>
                                    <div className="w-full ml-1">
                                        <span className="cursor-pointer" onClick={() => handleExpandClick(index)}>{Name}</span>
                                    </div>
                                </div>
                            </TableCell>

                            <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{item?.schemeType.toUpperCase() || '-'}</TableCell>
                            <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{item?.transactionDate || '-'}</TableCell>
                            <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{Number(item?.units).toFixed(2) || '-'}</TableCell>
                            <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{"Rs."+Number(item?.amount).toFixed(2) || '-'}</TableCell>
                            <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{"Rs."+Number(item?.currentValue).toFixed(2) || '-'}</TableCell>
                            <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{"Rs."+Number(item?.unrealisedPNL).toFixed(2)+" , "+ item?.unrealisedPNLPercentage+"%" || '-'}</TableCell>
                            <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{Number(item?.currentNAV).toFixed(2) || '-'}</TableCell>
                            <TableCell sx={{ 
                                    borderRight: '1px solid #ccc', 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{item?.folioNumber || '-'}</TableCell>
                        </TableRow>
                        <TableRow sx={{ height: "5%" }}>
                  <TableCell
                    sx={{ border: "none" }}
                    colSpan={8}
                    align="center"
                  ></TableCell>
                </TableRow>
                    </React.Fragment>
                );
            })}
            
        </>
    );
};


export default MFTableRowComponent;
