import api from "../../config/apiConfig";

const getFDInfo = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    console.log('AssetsHolding Service at AssetsHolding APi access token ' , accessToken);

    const response = await api.post("/fd/getHoldings", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    // console.log('FD Service Response : ',response);
    
    return response.data.data;
};

const FDService = { getFDInfo };
export default FDService;