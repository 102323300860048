import React, { FC, useEffect, useRef, useState } from "react";

import { colors } from "../../../constants/colors";


import upload from "../../../assets/icons/lucide_upload.svg";


import * as yup from "yup";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { addHuf } from "../../../redux/nonInd_huf/hufSlice";
import { checkFileSizeAndReset } from "../../../utils/index";
import ConfirmationModal from "../../../components/modals/PopupModal";
import { useUserData } from "../../../hooks/useUserData";

interface InfoSectionProps {
  icon: string;
  title: string;
  bgColor: string;
}

interface FormInputProps {
  label: string;
  onChange?: any;
  value?: any;
  name: string;
}

interface CompanyDetailsProps {
  onCompanyToSign: () => void;
  activeSection: string;
  allowToProceed: () => void;

}

const hufValues = {
  PanNumberOfKarta: "",
};

const hufSchema = yup.object({
  PanNumberOfKarta: yup.string().required(),
});

interface InputFieldProps {
  label: string;
  type: string;
  id: string;
  altText?: string;
  onChange?: any;
  name: string;
  value?: any;
}

const FileInputField: React.FC<InputFieldProps> = ({
  label,
  name,
  onChange,
  value,
}) => {
  const [fileName, setFileName] = React.useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const isValid = checkFileSizeAndReset(file, event); // Validate the file size
      if (isValid) {
        setFileName(file.name); // Update the displayed file name
      } else {
        setFileName(null); // Reset the displayed file name if invalid
      }
    }
  };

  return (
    <div className="flex flex-col mt-10 mb-10 grow text-lg text-neutral-500">
      <div className="mb-5 text-base sm:text-base  md:text-sm lg:text-lg">
        {label}
      </div>
      <label
        className="rounded-lg p-3 flex items-center justify-between "
        style={{
          color: colors.darkGrey,
          backgroundColor: colors.white,
          border: `1px solid ${colors.darkBg}`,
        }}
      >
        <input
          type="file"
          className="hidden sm:w-full"
          style={{ backgroundColor: colors.white }}
          onChange={(e) => {
            onChange(e);
            handleFileChange(e);
          }}
        />
        <span>{fileName ? fileName : ""}</span>
        <img
          src={upload} // Update with your image path
          alt="Upload Icon"
          className="h-8 w-8 ml-2 cursor-pointer"
        />
      </label>
    </div>
  );
};

const FormInput: FC<FormInputProps> = ({ label, onChange, value, name }) => (
  <div className="w-full sm:w-1/2 mb-10 mt-10 sm:mb-0">
    {" "}
    {/* Adjusted width for small screens */}
    <div className="flex flex-col md:text-sm  lg:text-lg mb-10">
      <label
        htmlFor={label.replace(/ /g, "").toLowerCase()}
        className="mb-2"
        style={{ color: colors.darkGrey }}
      >
        {label}
      </label>
      <input
        className={`w-full mt-3 bg-white rounded-lg border border-solid p-4`}
        style={{ color: colors.darkGrey, marginBottom: 0 }}
        type="text"
        id={label.replace(/ /g, "").toLowerCase()}
        aria-label={label}
        onChange={onChange}
        value={value}
        name={name}
      />
    </div>
  </div>
);

const HUF: FC<CompanyDetailsProps> = ({
  onCompanyToSign,
  allowToProceed,
}) => {
  const [completedSections, setCompletedSections] = useState<boolean[]>(
    Array(6).fill(false)
  );
  const dispatch = useDispatch<any>();
  // const organizationId = useSelector((state:any) => state?.general?.organizationId);

  const [organizationId, setOrganizationId] = useState<string>("");
  const organizationIdRef = useRef<string>(""); // Ref to store organizationId

  useEffect(() => {
    // Retrieve organizationId from local storage when component mounts
    const storedOrganizationId = localStorage.getItem("organizationId");
    if (storedOrganizationId) {
      setOrganizationId(storedOrganizationId);
      organizationIdRef.current = storedOrganizationId; // Update ref
    }
  }, []);

  // const onAllowToProceed = () => {
  //   onCompanyToCorporate(); // Call the allowToProceed function when needed
  // };



  const formInputs = [
    "First Name",
    "Last Name",
    "Email ID",
    "Phone",
    "DOB",
    "Gender",
  ];

  const businessOptions = [
    { value: "", label: "" },
    { value: "value1", label: "value1" },
    { value: "value2", label: "value2" },
    { value: "other", label: "Other" },
  ];

  const onAllow = (): void => {
    // Mark the current section as completed
    const updatedSections = [...completedSections];
    updatedSections[5] = true;
    setCompletedSections(updatedSections);
  };

  const [uploadPanHuf, setUploadPanHuf] = useState<File>();
  const [addressHuf, setAddressHuf] = useState<File>();
  const [bankStatement, setBankStatement] = useState<File>();
  const [cancelCheque, setCancelCheque] = useState<File>();
  const [uploadPanKarta, setUploadPanKarta] = useState<File>();
  const [addressKarta, setAddressKarta] = useState<File>();
  const [deed, setDeed] = useState<File>();
  const [fatca, setFatca] = useState<File>();
  const userData = useUserData();
  const [pancardNumber, setPancardNumber] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false); // State for popup visibility
  const formikRef = useRef<any>();

  const handleButtonClick = () => {
    // Open the modal when the "Save and Proceed" button is clicked
    setIsModalVisible(true);
  };

  const handleModalConfirm = () => {
    setIsModalVisible(false); // Hide the modal and proceed with form submission
    if (formikRef.current) {
      formikRef.current.submitForm(); // Submit the form upon confirmation
    }
  };
  const handleModalCancel = () => {
    // Logic for cancelling action (e.g., closing the popup)
    setIsModalVisible(false);
    console.log("Cancelled");
  };

  useEffect(() => {
    if (userData?.kyc?.pancardNumber) {
      setPancardNumber(userData.kyc.pancardNumber); // Store pancardNumber in state
    }
  }, [userData]);

  return (
    <>
      <div
        className="flex flex-col items-center justify-center w-full "
      >
        <div className="w-full max-w-[70%]">
          <div
            className="font-semibold text-base sm:text-3xl lg:mt-12"
            style={{ color: colors.primary }}
          >
            HUF
          </div>
          <div
            className="font-normal text-base mt-4 sm:text-xl lg:mt-12"
            style={{ color: colors.darkGrey }}
          >
            Note: All Documents should be self-attested.
          </div>

          <Formik
            innerRef={formikRef}
            initialValues={hufValues}
            validationSchema={hufSchema}
            onSubmit={(values, { setSubmitting }) => {
              console.log(values, "huf values");

              if (
                addressHuf &&
                uploadPanKarta &&
                addressKarta &&
                deed &&
                fatca
              ) {
                // Create a Company object from form values
                const formData: any = new FormData();
                formData.append("PanNumber", pancardNumber);
                formData.append("PanNumberOfKarta", values.PanNumberOfKarta);
                // formData.append("Pancard", uploadPanHuf);
                formData.append("AddressProof", addressHuf);
                formData.append("BankStatements", bankStatement);
                // formData.append("CancelCheque", cancelCheque);
                formData.append("PancardOfKarta", uploadPanKarta);
                formData.append("AddressProofOfKarta", addressKarta);
                formData.append("DeedDeclaration", deed);
                formData.append("Fatca", fatca);

                formData.append("organizationId", organizationIdRef.current); // Accessing organizationId from ref

                dispatch(addHuf(formData));
                // Call the function to proceed to the next step
                allowToProceed();
                // onCompanyToSign();
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <form
                onSubmit={handleSubmit}
                className="w-full flex flex-col gap-[31px]"
              >
                <div className="flex justify-between">
                  {/* Inputs for screens above 768px */}
                  <div className="hidden sm:flex flex-col w-full gap-5">
                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                      <div className="w-full sm:w-1/2 pr-10">
                            <FileInputField
                              label="Address Proof Of HUF *"
                              name="AddressProof"
                              type="file"
                              id="AddressProof"
                              onChange={(e: any) =>
                                setAddressHuf(e.target.files[0])
                              }
                            />
                        </div>
                      <div className="w-full sm:w-1/2 pr-10">
                            <FileInputField
                              label="Bank Statement (Last 3 months)"
                              name="BankStatements"
                              type="file"
                              id="BankStatements"
                              onChange={(e: any) =>
                                setBankStatement(e.target.files[0])
                              }
                            />
                        </div>
                    </div>
                    <div className="flex flex-row w-full gap-5 lg:gap-20">
                      {/* Third row of file input fields */}
                      <div className="w-full sm:w-1/2">
                        {/* Shared half screen space for Upload CML Copy and Form Input */}
                        <div className="w-full flex flex-row  gap-5">
                          <FormInput
                            label="PAN No. of Karta *"
                            name="PanNumberOfKarta"
                            onChange={handleChange("PanNumberOfKarta")}
                            value={values.PanNumberOfKarta}
                          />
                          <FileInputField
                            label="Upload PAN Copy *"
                            name="PancardOfKarta"
                            type="file"
                            id="PancardOfKarta"
                            onChange={(e: any) =>
                              setUploadPanKarta(e.target.files[0])
                            }
                          />
                        </div>
                      </div>
                      <div className="w-full sm:w-1/2">
                        {/* Address Proof of Karta */}
                        <FileInputField
                          label="Address Proof of Karta *"
                          name="AddressProofOfKarta"
                          type="file"
                          id="AddressProofOfKarta"
                          onChange={(e: any) =>
                            setAddressKarta(e.target.files[0])
                          }
                        />
                      </div>
                    </div>

                    <div className="flex flex-row w-full gap-5 lg:gap-20">
                      {/* Fourth row of file input fields */}
                      <div className="w-full sm:w-1/2">
                        {/* Deed Declaration of HUF / List of Co-Parceners */}
                        <FileInputField
                          label="Deed Declaration of HUF / List of Co-Parceners *"
                          name="DeedDeclaration"
                          type="file"
                          id="DeedDeclaration"
                          onChange={(e: any) => setDeed(e.target.files[0])}
                        />
                      </div>
                      <div className="w-full sm:w-1/2">
                        {/* FATCA Declaration */}
                        <FileInputField
                          label="FATCA Declaration *"
                          name="Fatca"
                          type="file"
                          id="Fatca"
                          onChange={(e: any) => setFatca(e.target.files[0])}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <section className="mt-10 mb-5 max-md:mt-5 max-md:mr-2.5 max-md:max-w-full">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col max-md:ml-0 max-md:w-full">
                      <div className="flex justify-end">
                        <div className="flex gap-4">
                          {/* Skip Button */}
                          <button
                            type="button"
                            style={{
                              backgroundColor: colors.white,
                              color: colors.darkGrey,
                              border: `1px solid ${colors.darkGrey}`,
                            }}
                            className="px-8 py-4 rounded-md border"
                            onClick={() => {
                              // Implement skip logic if needed
                            }}
                          >
                            Skip
                          </button>

                          {/* Save and Proceed Button */}
                          <button
                            type="button"
                            style={{ backgroundColor: colors.darkGrey, color: colors.white }}
                            className="px-8 py-4 rounded-md border border-darkGrey"
                            onClick={handleButtonClick} // Open the confirmation modal
                          >
                            Save and Proceed
                          </button>

                          {/* Confirmation Modal */}
                          <ConfirmationModal
                            isVisible={isModalVisible} // Render conditionally based on state
                            message="Are you sure you want to proceed? After submission, changes cannot be made."
                            onConfirm={handleModalConfirm} // Proceed with submission
                            onCancel={handleModalCancel} // Cancel without proceeding
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </form>
            )}
          </Formik>


        </div>
      </div>
    </>
  );
};

export default HUF;
