import Family from "../../assets/icons/Family.png";
import personal from "../../assets/icons/person.png";
import bankP from "../../assets/icons/Vector.png";
import grow from "../../assets/icons/ss.svg";
import doc from "../../assets/icons/Hr Compliance.png";
import shield from "../../assets/icons/Shield.png";
import filter from "../../assets/icons/Filter.png";
import phone from "../../assets/icons/Phone.png";
import Phone from "../../assets/icons/Phone-white.png";
import preference from "../../assets/icons/preference.png";
import access from "../../assets/icons/access control.png";
import compliance from "../../assets/icons/compliance.png";
import demat from "../../assets/icons/demat.png";
import bank from "../../assets/icons/bank.png";
import person from "../../assets/icons/person-white.png";
import login from "../../assets/icons/Vector (2).png";
import updateicon from "../../assets/icons/reset-password.png";
import logoutIcon from "../../assets/icons/logout.svg";
import { Box, Button, Container, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import Breadcrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useUserData } from "../../hooks/useUserData";
import getInitials from "../../utils/getInitailasIMGUtils";
import GeneralDetails from "../../components/Profile/GeneralDetails";
import DematDetails from "../../components/Profile/DematDetails";
import BankDetails from "../../components/Profile/BankDetails";
import NomineDetails from "../../components/Profile/NomineeDetails";
import AccessControl from "../../components/Profile/AccessControl";
import Preferences from "../../components/Profile/Preferences";
import ReachOut from "../../components/Profile/ReachOut";
import NomineeForm from "../../components/Profile/NomineeDetails";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetState } from "../../redux/kyc/kycSlice";
import authService from "../../redux/auth/authService";
import { colors } from "../../constants/colors";
import UpdatePassword from "../../components/Profile/UpdatePassword";
import Compliance from "../../components/Profile/Compliance";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const imageMap: any = {
  "General Details": personal,
  "Bank Details": bankP,
  "Demat Details": grow,
  Nominees: Family,
  Compliance: doc,
  "Access Control": shield,
  Preferences: filter,
  "Reach Out": phone,
  // 'Logout': login,
  "Update Password": updateicon,
};

const imageMapSelected: any = {
  "General Details": person, // New icon when clicked
  "Bank Details": bank,
  "Demat Details": demat,
  Nominees: Family, // Keep the same or change as needed
  Compliance: compliance,
  "Access Control": access,
  Preferences: preference,
  "Reach Out": Phone, // Use white phone icon
  "Update Password": updateicon, // Can be the same or change
};

const breadcrumbItems = [
  { label: "Home", href: "/" }, // Breadcrumb item with link
  { label: "Profile" }, // Current page, no link
];

function Profile() {
  const [value, setValue] = useState(0);
  const user = useUserData();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const logout = () => {
    authService.logout();
    dispatch(resetState());
    navigate("/");
  };

  return (
    <div className="">
      <div className="p-8 md:p-[60px] md:max-w-[1640px] w-full mx-auto flex flex-col md:flex-col gap-x-6">
        <div className="w-[100vh] h-full">
          <div className="md:pb-10">
            <Breadcrumbs items={breadcrumbItems} />
          </div>
        </div>

        <div className="flex flex-row">
          <div className="w-1/4 min-h-screen">
            <div className="border border-lightGrey rounded-lg mr-4 h-full flex flex-col justify-between">
              <div>
                <div className="p-8 bg-lightBg rounded-lg">
                  <div className="flex flex-row items-center justify-start gap-x-4">
                    <div className="w-14 h-14 mb-3 md:mb-0 md:w-20 md:h-20 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-4xl font-bold">
                      {getInitials(user.firstName || "")}
                    </div>
                    <div className="flex flex-col">
                      <span className="text-2xl text-darkGrey font-bold">
                        {`${
                          user.firstName?.toUpperCase()
                        } ${
                          user.lastName?.toUpperCase()
                        }`}
                      </span>
                    </div>
                  </div>
                </div>

                {/* <div className='flex  flex-col items-center justify-around'>
                                {['General Details', 'Bank Details', 'Demat Details', 'Nominees', 'Compliance', 'Access Control', 'Preferences', 'Reach out', 'Logout'].map((label, index) => (
                                    <Button key={index} onClick={() => setValue(index)} {...a11yProps(index)}
                                        sx={{
                                            width: '85%',
                                            height: '50px',  // Set a consistent height for each button
                                            borderBottom: '1px solid #ccc',  // Apply a bottom border for separation
                                            justifyContent: 'flex-start',  // Align content to the left
                                            textTransform: 'none',  // Keep the text case as is
                                            padding: '0 16px',
                                            margin: '10px 0px',  // Add padding for better spacing
                                        }}
                                    >
                                        <Box display="flex" alignItems="center" width="100%">
                                            <img src={imageMap[label]} alt={label} style={{ marginRight: '8px' }} />
                                            <span>{label}</span>
                                        </Box>
                                    </Button>
                                ))}
                            </div> */}

                <div className="flex flex-col justify-between">
                  <div className="flex flex-col w-full items-center justify-around ">
                    {[
                      "General Details",
                      "Bank Details",
                      "Demat Details",
                      "Nominees",
                      "Compliance",
                      "Preferences",
                      "Access Control",
                      "Reach Out",
                      "Update Password",
                    ].map((label, index) => (
                      <Button
                        key={index}
                        onClick={() => setValue(index)}
                        {...a11yProps(index)}
                        sx={{
                          width: "85%",
                          height: "50px",
                          justifyContent: "flex-start",
                          textTransform: "none",
                          padding: "0 16px",
                          margin: "10px 0px",
                          backgroundColor:
                            value === index ? colors.primary : "transparent",
                          color: value === index ? "#fff" : "initial", // change to white text if selected
                          "&:hover": {
                            backgroundColor:
                              value === index
                                ? colors.primary
                                : "rgba(0, 0, 0, 0.04)", // adjust hover effect
                          },
                        }}
                      >
                        <Box display="flex" alignItems="center" width="100%">
                          <img
                            src={
                              value === index
                                ? imageMapSelected[label]
                                : imageMap[label]
                            }
                            alt={label}
                            style={{ marginRight: "8px", maxWidth: "30px" }}
                          />
                          <span>{label}</span>
                        </Box>
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-col mb-[40%] mx-6 gap-y-4">
                <div
                  className="flex items-center gap-x-2 cursor-pointer"
                  style={{ padding: "0 16px", margin: "10px 0px" }}
                >
                  <span>
                    <img src={logoutIcon} alt="Logout" />
                  </span>
                  <span className="text-secondary text-xl" onClick={logout}>
                    Logout
                  </span>
                </div>
                {/* <Typography
                                    variant="subtitle2"
                                    color={colors.lightGrey}
                                    style={{ fontStyle: 'italic' }}
                                >
                                    Last logged out on 10 May 2024 at 12:24 PM
                                </Typography> */}
              </div>
            </div>
          </div>
          <div className="w-3/4 ml-4 max-h-screen overflow-y-auto">
            {value === 0 && <GeneralDetails />}
            {value === 1 && <BankDetails />}
            {value === 2 && <DematDetails />}
            {value === 3 && <NomineeForm />}
            {value === 4 && <Compliance />}
            {/* {value === 4 && <div>Item Six</div>} */}
            {value === 5 && <Preferences />}
            {value === 6 && <AccessControl />}
            {/* {value === 6 && <ReachOut />} */}
            {value === 7 && <ReachOut />}
            {value === 8 && <UpdatePassword />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
