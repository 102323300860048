import React, { FC, useEffect, useRef, useState } from 'react';
import { colors } from '../../../constants/colors';
import upload from '../../../assets/icons/lucide_upload.svg';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from "yup";
import { Formik } from "formik";
import { addCorporate } from '../../../redux/nonInd_corporate/corporateSlice';
import { checkFileSizeAndReset } from "../../../utils/index";
import ConfirmationModal from '../../../components/modals/PopupModal';
import { useUserData } from "../../../hooks/useUserData";
import { FaPlus, FaTrash } from 'react-icons/fa'; // Import the plus and trash icons


interface FormInputProps {
  label: string;
  onChange?: any;
  value?: any;
  name: string;
}

interface CorporateDetailsProps {
  onCorporateToSign: () => void;
  activeSection: string;
  allowToProceed: () => void;
}



interface InputFieldProps {
  label: string;
  type: string;
  id: string;
  altText?: string;
  onChange?: any;
}

const corporateValues = {
  PanNumberOfAuthSignatory: "",
  Director1PanNumber: "",
  Director2PanNumber: "",
  ShareHolder1PanNumber: "",
  CompanyPanNumber: ""
}

const corporateSchema = yup.object({
  PanNumberOfAuthSignatory: yup.string().required(),
  Director1PanNumber: yup.string().required(),
  Director2PanNumber: yup.string().required(),
  ShareHolder1PanNumber: yup.string().required(),
  CompanyPanNumber: yup.string().required(),
});

const FileInputField: React.FC<InputFieldProps> = ({ label, onChange }) => {
  const [fileName, setFileName] = React.useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const isValid = checkFileSizeAndReset(file, event); // Validate the file size
      if (isValid) {
        setFileName(file.name); // Update the displayed file name
      } else {
        setFileName(null); // Reset the displayed file name if invalid
      }
    }
  };

  return (
    <div className="flex flex-col mt-10 mb-10 grow text-lg text-neutral-500">
      <div className="mb-5 text-base sm:text-base  md:text-sm lg:text-lg">{label}</div>
      <label className="rounded-lg p-3 flex items-center justify-between " style={{ color: colors.darkGrey, backgroundColor: colors.white, border: `1px solid ${colors.darkBg}` }}>
        <input
          type="file"
          className="hidden sm:w-full"
          style={{ backgroundColor: colors.white }}
          onChange={(e) => {
            onChange(e);
            handleFileChange(e);
          }}
        />
        <span>{fileName ? fileName : ""}</span>
        <img
          src={upload} // Update with your image path
          alt="Upload Icon"
          className="h-8 w-8 ml-2 cursor-pointer"
        />
      </label>

    </div>
  );
};

const FormInput: FC<FormInputProps> = ({ label, onChange, value, name }) => (
  <div className="w-full sm:w-1/2 mb-10 mt-10 sm:mb-0"> {/* Adjusted width for small screens */}
    <div className="flex flex-col md:text-sm  lg:text-lg mb-10">
      <label htmlFor={label.replace(/ /g, '').toLowerCase()} className='mb-2' style={{ color: colors.darkGrey }}>{label}</label>
      <input
        className={`w-full mt-3 bg-white rounded-lg border border-solid p-4`}
        style={{ color: colors.darkGrey, marginBottom: 0 }}
        type="text"
        id={label.replace(/ /g, '').toLowerCase()}
        aria-label={label}
        onChange={onChange}
        value={value}
        name={name}
      />
    </div>
  </div>
);

const CorporateDetails: FC<CorporateDetailsProps> = ({ onCorporateToSign, allowToProceed }) => {
  const dispatch = useDispatch<any>();
  const userData = useUserData();
  const [pancardNumber, setPancardNumber] = useState<string>("");
  useEffect(() => {
    if (userData?.kyc?.pancardNumber) {
      setPancardNumber(userData.kyc.pancardNumber); // Store pancardNumber in state
    }
  }, [userData]);

  const [organizationId, setOrganizationId] = useState<string>('');
  const organizationIdRef = useRef<string>(''); // Ref to store organizationId

  useEffect(() => {
    const storedOrganizationId = localStorage.getItem('organizationId');
    if (storedOrganizationId) {
      setOrganizationId(storedOrganizationId);
      organizationIdRef.current = storedOrganizationId; // Update ref
    }
  }, []);

  const [pancard, setPancard] = useState<File>();
  const [address, setAddress] = useState<File>();
  const [bankStatement, setBankStatement] = useState<File>();
  const [cancelCheque, setCancelCheque] = useState<File>();
  const [authSignatoryPancard, setauthSignatoryPancard] = useState<File>();
  const [authSignatoryAddressProof, setAuthSignatoryAddressProof] = useState<File>();
  const [director1AddressProof, setDirector1AddressProof] = useState<File>();
  const [director2AddressProof, setDirector2AddressProof] = useState<File>();
  const [directorsList, setDirectorsList] = useState<File>();
  const [director1Pancard, setDirector1Pancard] = useState<File>();
  const [director2Pancard, setDirector2Pancard] = useState<File>();
  const [directorsContact, setDirectorsContact] = useState<File>();
  const [balanceSheet, setBalanceSheet] = useState<File>();
  const [memorandam, setMemorandam] = useState<File>();
  const [shareholding, setShareholding] = useState<File>();
  const [clientMaster, setClientMaster] = useState<File>();
  const [form32, setForm32] = useState<File>();
  const [form22, setForm22] = useState<File>();
  const [boardResolution, setBoardResolution] = useState<File>();
  const [shareHolder1Pancard, setShareHolder1Pancard] = useState<File>();
  const [shareHolder1AddressProof, setShareHolder1AddressProof] = useState<File>();
  const [companyPancard, setCompanyPancard] = useState<File>();
  const [companyAddressProof, setCompanyAddressProof] = useState<File>();
  const [fatca, setFatca] = useState<File>();


  const [isModalVisible, setIsModalVisible] = useState(false); // State for popup visibility
  const formikRef = useRef<any>();

  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalConfirm = () => {
    setIsModalVisible(false); // Hide the modal and proceed with form submission
    if (formikRef.current) {
      formikRef.current.submitForm(); // Submit the form upon confirmation
    }
  };
  const handleModalCancel = () => {
    // Logic for cancelling action (e.g., closing the popup)
    setIsModalVisible(false);
    console.log("Cancelled");
  };


    const [shareholders, setShareholders] = useState([
      { ShareHolderPanNumber: '', ShareHolderPancard: null, ShareHolderAddressProof: null }
    ]);

    const addShareholder = () => {
      setShareholders([...shareholders, { ShareHolderPanNumber: '', ShareHolderPancard: null, ShareHolderAddressProof: null }]);
    };

    const removeShareholder = (index: number) => {
      const updatedShareholders = shareholders.filter((_, i) => i !== index);
      setShareholders(updatedShareholders);
    };
  
  
  return (

    <div
      className="flex flex-col items-center justify-center w-full "
    >
      <div className="w-full max-w-[70%]">
        <div
          className="font-semibold text-base sm:text-3xl lg:mt-12"
          style={{ color: colors.primary }}
        >
          Corporate
        </div>
        <div
          className="font-normal text-base mt-4 sm:text-xl lg:mt-12"
          style={{ color: colors.darkGrey }}
        >
          Note: All Documents should be self-attested.
        </div>

        <Formik
          innerRef={formikRef}
          initialValues={corporateValues}
          validationSchema={corporateSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values, "corp values");

            if (bankStatement && address && authSignatoryPancard && director1Pancard && director2Pancard && fatca && directorsContact && balanceSheet && memorandam && shareholding && clientMaster && form32 && form22 && boardResolution && shareHolder1Pancard && shareHolder1AddressProof && companyPancard && companyAddressProof && authSignatoryAddressProof && director1AddressProof && director2AddressProof && directorsList) {

              const formData = new FormData();
              formData.append("PanNumber", pancardNumber);
              formData.append("PanNumberOfAuthSignatory", values.PanNumberOfAuthSignatory);
              formData.append("BankStatements", bankStatement);
              formData.append("Director1PanNumber", values.Director1PanNumber);
              formData.append("Director2PanNumber", values.Director2PanNumber);
              formData.append("CompanyPanNumber", values.CompanyPanNumber);
              formData.append("AddressProof", address);
              formData.append("AuthSignatoryPancard", authSignatoryPancard);
              formData.append("Director1Pancard", director1Pancard);
              formData.append("Director2Pancard", director2Pancard);
              formData.append("AuthSignatoryAddressProof", authSignatoryAddressProof);
              formData.append("Director1AddressProof", director1AddressProof);
              formData.append("Director2AddressProof", director2AddressProof);
              formData.append("DirectorsList", directorsList);
              formData.append("DirectorsContact", directorsContact);
              formData.append("BalanceSheet", balanceSheet);
              formData.append("Memorandam", memorandam);
              formData.append("Shareholding", shareholding);
              formData.append("ClientMaster", clientMaster);
              formData.append("Form32", form32);
              formData.append("Form22", form22);
              formData.append("BoardResolution", boardResolution);
              formData.append("ShareHolder1PanNumber", values.ShareHolder1PanNumber);
              formData.append("ShareHolder1Pancard", shareHolder1Pancard);
              formData.append("ShareHolder1AddressProof", shareHolder1AddressProof);
              formData.append("CompanyPancard", companyPancard);
              formData.append("CompanyAddressProof", companyAddressProof);
              formData.append("Fatca", fatca);
              formData.append("organizationId", organizationIdRef.current); // Accessing organizationId from ref
              dispatch(addCorporate(formData))
              allowToProceed();
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (

            <form onSubmit={handleSubmit}>
              <div className='flex justify-between'>
                <div className="hidden sm:flex flex-col w-full gap-5 ">
                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    <div className="w-full sm:w-1/2">
                      <FileInputField label="Address Proof of Company *" type='file' id='' onChange={(e: any) =>
                        setAddress(e.target.files[0])
                      } />
                    </div>
                    <div className="w-full sm:w-1/2">
                      <FileInputField label="Bank Statement (Last 3 months) *" type="file" id="" onChange={(e: any) =>
                        setBankStatement(e.target.files[0])
                      } />
                  </div>
                  </div>

                  <div className={`font-semibold max-md:max-w-full text-center md:text-left  lg:text-left sm:text-3xl text-base lg:mt-12`} style={{ color: colors.darkGrey }}>
                    Authorised Signatory & Directors Details
                  </div>

                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row  gap-5">
                        <FormInput label="PAN No. of Auth. Signatory *" name={'PanNumberOfAuthSignatory'} onChange={handleChange("PanNumberOfAuthSignatory")} value={values.PanNumberOfAuthSignatory} />
                        <FileInputField label="Upload PAN Copy *" type="file" id="" onChange={(e: any) =>
                          setauthSignatoryPancard(e.target.files[0])
                        } />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row  gap-5">
                        <FormInput label="PAN No. of Director 1 *" name={'Director1PanNumber'} onChange={handleChange("Director1PanNumber")} value={values.Director1PanNumber} />
                        <FileInputField label="Upload PAN Copy *" type="file" id="" onChange={(e: any) =>
                          setDirector1Pancard(e.target.files[0])
                        } />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row  gap-5">
                        <FormInput label="PAN No. of Director 2 *" name={'Director2PanNumber'} onChange={handleChange("Director2PanNumber")} value={values.Director2PanNumber} />
                        <FileInputField label="Upload PAN Copy *" type="file" id="" onChange={(e: any) =>
                          setDirector2Pancard(e.target.files[0])
                        } />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row  gap-5">
                        <FileInputField label="Address Proof of Authorised Signatory *" type="file" id="" onChange={(e: any) =>
                          setAuthSignatoryAddressProof(e.target.files[0])
                        } />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row  gap-5">
                        <FileInputField label="Address Proof of Director 1 *" type="file" id="" onChange={(e: any) =>
                          setDirector1AddressProof(e.target.files[0])
                        } />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row  gap-5">
                        <FileInputField label="Address Proof of Director 2 *" type="file" id="" onChange={(e: any) =>
                          setDirector2AddressProof(e.target.files[0])
                        } />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row  gap-5">
                        <FileInputField label="List of Directors and Authorised Signatories *" type="file" id="" onChange={(e: any) =>
                          setDirectorsList(e.target.files[0])
                        } />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row  gap-5">
                        <FileInputField label="Mobile Number & Email of Authorised Signatory & Directors *" type="file" id="" onChange={(e: any) =>
                          setDirectorsContact(e.target.files[0])
                        } />
                      </div>
                    </div>
                  </div>

                  <div className={`font-semibold max-md:max-w-full text-center md:text-left  lg:text-left sm:text-3xl text-base lg:mt-12`} style={{ color: colors.darkGrey }}>
                    Financial Details
                  </div>

                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row  gap-5">
                        <FileInputField label="Balance  Sheet for Last 2 Financial Years *" type="file" id="" onChange={(e: any) =>
                          setBalanceSheet(e.target.files[0])
                        } />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row  gap-5">
                        <FileInputField label="Memorandam & Articles of Association *" type="file" id="" onChange={(e: any) =>
                          setMemorandam(e.target.files[0])
                        } />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row  gap-5">
                        <FileInputField label="Latest Shareholding Pattern Copy *" type="file" id="" onChange={(e: any) =>
                          setShareholding(e.target.files[0])
                        } />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row  gap-5">
                        <FileInputField label="Client Master List *" type="file" id="" onChange={(e: any) =>
                          setClientMaster(e.target.files[0])
                        } />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row  gap-5">
                        <FileInputField label="Form 32 (Director Change History) *" type="file" id="" onChange={(e: any) =>
                          setForm32(e.target.files[0])
                        } />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row  gap-5">
                        <FileInputField label="Board Resolution Copy (For Investment in Securities Market) *" type="file" id="" onChange={(e: any) =>
                          setBoardResolution(e.target.files[0])
                        } />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row  gap-5">
                        <FileInputField label="Form INC 22 *" type="file" id="" onChange={(e: any) =>
                          setForm22(e.target.files[0])
                        } />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2"></div>
                  </div>

                  <div className={`font-semibold max-md:max-w-full text-center md:text-left  lg:text-left sm:text-3xl text-base lg:mt-12`} style={{ color: colors.darkGrey }}>
                    Shareholder Details
                  </div>

                  <div className={`font-normal max-md:max-w-full text-center md:text-left lg:text-left sm:text-xl text-base lg:mt-12`} style={{ color: colors.darkGrey }}>
                    Note :  Shareholder holding 25% or more of the paid up capital of the company
                  </div>

                  {shareholders.map((shareholder, index) => (
                    <div key={index} className="flex flex-row w-full gap-5 mt-5">
                      <div className="w-full sm:w-1/2">
                        <div className="w-full flex flex-row gap-5">
                          <FormInput 
                            label={`PAN No. of Company *`} 
                            name={`ShareHolder${index + 1}PanNumber`} 
                            onChange={(e:any) => handleChange(e.target.value)} 
                            value={shareholder.ShareHolderPanNumber} 
                          />
                          <FileInputField 
                            label="Upload PAN Copy *"
                            type="file"
                            onChange={(e: any) => handleChange(e.target.files[0])} id={''}              />
                        </div>
                      </div>
                      <div className="w-full sm:w-1/2">
                          <FileInputField 
                            label="Address Proof of Company *"
                            type="file"
                            onChange={(e: any) => handleChange(e.target.files[0])} id={''}            />
                      </div>
                      <div className="flex items-center">
                      {index === shareholders.length - 1 ? (
                        <button
                          onClick={addShareholder}
                          className="flex items-center justify-center w-11 h-10 rounded-full bg-gray-200 hover:bg-gray-300 mt-10"
                        >
                          <FaPlus size={20} className="text-gray-600" />
                        </button>
                      ) : null}
                       {index > 0 && shareholders.length > 1 && ( // Only show trash icon if index is greater than 0
                          <button
                            onClick={() => removeShareholder(index)}
                            className="flex items-center justify-center w-11 h-10 rounded-full mt-10 ml-3"
                          >
                            <FaTrash size={20} className="text-secondary" />
                          </button>
                        )}
                    </div>
                    </div>
                  ))}
                  
                  <div className={`font-semibold max-md:max-w-full text-center md:text-left  lg:text-left sm:text-3xl text-base lg:mt-12`} style={{ color: colors.darkGrey }}>
                    Ultimate Beneficial Owner Details
                  </div>

                  <div className="flex flex-row w-full gap-5 lg:gap-20">
                    <div className="w-full sm:w-1/2">
                      <div className="w-full flex flex-row gap-5">
                        <FormInput label="PAN No. of Company *" name={'CompanyPanNumber'} onChange={handleChange("CompanyPanNumber")} value={values.CompanyPanNumber} />
                        <FileInputField label="Upload PAN Copy *" type="file" id="" onChange={(e: any) =>
                          setCompanyPancard(e.target.files[0])
                        } />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2">
                      <FileInputField label="Address Proof of Company *" type='file' id='' onChange={(e: any) =>
                        setCompanyAddressProof(e.target.files[0])
                      } />
                    </div>
                  </div>
                  <div className="flex flex-row w-full gap-5 lg:gap-20 pr-16">
                    <div className="w-full sm:w-1/2">
                      <FileInputField label="FATCA Declaration *" type='file' id='' onChange={(e: any) =>
                        setFatca(e.target.files[0])
                      } />
                    </div>
                  </div>
                </div>
              </div>
              <section className="mt-10 mb-5 max-md:mt-5 max-md:mr-2.5 max-md:max-w-full">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col max-md:ml-0 max-md:w-full">
                      <div className="flex justify-end">
                        <div className="flex gap-4">
                          <button
                            type="button"
                            style={{
                              backgroundColor: colors.white,
                              color: colors.darkGrey,
                              border: `1px solid ${colors.darkGrey}`,
                            }}
                            className="px-8 py-4 rounded-md border"
                            onClick={() => {
                            }}
                          >
                            Skip
                          </button>
                          <button
                            type="button"
                            style={{ backgroundColor: colors.darkGrey, color: colors.white }}
                            className="px-8 py-4 rounded-md border border-darkGrey"
                            onClick={handleButtonClick} 
                          >
                            Save and Proceed
                          </button>
                          <ConfirmationModal
                            isVisible={isModalVisible} // Render conditionally based on state
                            message="Are you sure you want to proceed? After submission, changes cannot be made."
                            onConfirm={handleModalConfirm} // Proceed with submission
                            onCancel={handleModalCancel} // Cancel without proceeding
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
            </form>)}
        </Formik>
      </div>
    </div>
  );
};

export default CorporateDetails;
