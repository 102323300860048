import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { colors } from "../../constants/colors";

interface OrderRow {
  Date: string;
  Type: string;
  SchemeName: string;
  Price: number;
  Status: string;
}

interface OrderHistoryTableProps {
  headings: string[];
  rows: OrderRow[];
}

const OrderHistoryTable: React.FC<OrderHistoryTableProps> = ({ headings, rows }) => {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 400, overflowY: 'auto', overflowX: 'auto', boxShadow: 'none', border: '2px solid #ccc' }}>
      <Table aria-label="collapsible table" stickyHeader>
        <TableHead sx={{
          backgroundColor: colors.lightBg,
          borderBottom: '2px solid #ccc',
          '& th': {
            backgroundColor: colors.lightBg,
            fontWeight: 600,
            color: colors.primary,
            fontSize: '16px',
            borderBottom: '2px solid #ccc',
          },
        }}>
          <TableRow>
            {headings.map((heading, index) => (
              <TableCell key={index} sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }}>{heading==="SchemeName"?"Scheme Name":heading}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {headings.map((heading, cellIndex) => (
                <TableCell key={cellIndex} sx={{ fontSize: '16px' }}>
                  {row[heading as keyof OrderRow]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderHistoryTable;
