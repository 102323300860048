import api from "../../config/apiConfig";

const addDematDetails = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("/user/addDemat", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response.data;
};

const dematService = { addDematDetails };
export default dematService;
