const schemeTypes = [
    {label: 'EQUITY', value: 'EQUITY'},
    {label: 'DEBT', value: 'DEBT'},
    {label: 'GILT', value: 'GILT'},
    {label: 'ELSS', value: 'ELSS'},
    {label: 'MIP', value: 'MIP'},
    {label: 'BALANCED', value: 'BALANCED'},
    {label: 'STP', value: 'STP'},
    {label: 'FOF', value: 'FOF'},
    {label: 'LIQUID', value: 'LIQUID'},
    {label: 'HYBRID', value: 'HYBRID'},
    {label: 'BOND', value: 'BOND'},
    {label: 'INCOME', value: 'INCOME'},
]

export default schemeTypes;