import { Box, Card, Typography } from '@mui/material';
import pen from '../../assets/icons/pen.svg';
import React from 'react';
import { useUserData } from '../../hooks/useUserData';
import { colors } from '../../constants/colors';
import getInitials from '../../utils/getInitailasIMGUtils';

function DematDetails() {
    const { demat } = useUserData();

    function editFunction() {
        console.log('Edit');
    }

    // Check if demat data is available
    if (!demat || Object.keys(demat).length === 0) {
        return (
            <div className='border border-lightGrey rounded-lg'>
                <Card style={{ backgroundColor: colors.lightBg, minHeight: '60px', display: 'flex', alignItems: 'center', padding: '0' }} className='rounded-md h-8 w-full'>
                    <Typography variant='h5' marginLeft={2} color={colors.primary}>Demat Details</Typography>
                </Card>
                <div className='justify-center'>
                    <Typography variant='body1' className='p-4 text-center text-gray-600'>No Demat details available.</Typography>
                </div>
            </div>
        );
    }

    return (

        <div className=''>
            <Card style={{ backgroundColor: colors.lightBg, minHeight: '60px', display: 'flex', alignItems: 'center', padding: '0' }} className='rounded-md h-8 w-full'>
            <Typography variant="h5" marginLeft={2} color={colors.darkGrey} fontWeight='bold'>Demat Details</Typography>
            </Card>
            <div className='flex flex-col justify-center my-8 items-center'>
                <Box key={demat.id} className='flex flex-col border border-lightGrey rounded-lg h-52 justify-center items-center p-4 w-full mb-4'>
                    <div className='flex justify-start items-start w-full '>
                        <span className='text-xl font-semibold'> Account <span className='text-xl font-semibold'> {demat.id}</span></span>
                    </div>
                    <div className='flex w-full justify-between items-center mt-4'>
                        <div className='w-1/4 h-[60px] flex flex-col justify-between'>
                            <span className='flex text-textGrey font-semibold'>DP ID</span>
                            <span className='flex text-darkGrey font-semibold'>{demat.dpId || '-'}</span>
                        </div>
                        <div className='w-1/4 h-[60px] flex flex-col justify-between'>
                            <span className='flex text-textGrey font-semibold'>DP</span>
                            <span className='flex text-darkGrey font-semibold'>{demat.depositoryName || '-'}</span>
                        </div>
                        <div className='w-1/4 h-[60px] flex flex-col justify-between'>
                            <span className='flex text-textGrey font-semibold'>BO ID</span>
                            <span className='flex text-darkGrey font-semibold'>{demat.clientId || '-'}</span>
                        </div>
                        <div className='w-1/4 h-[60px] flex flex-col justify-between'>
                            <span className='flex text-textGrey font-semibold pl-2'>Depository</span>
                            <div className='flex items-center w-full gap-x-2'>
                                <div className="w-[40px] h-[40px] rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-xl font-bold">
                                    {getInitials(demat.depositoryName)}
                                </div>
                                <span className='flex text-darkGrey font-semibold'>{demat.depositoryName}</span>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>
        </div>
    );
}

export default DematDetails;
