import api from "../../config/apiConfig";


const addTrust = async (data: FormData) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/org/addTrust", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
};

const trustService = { addTrust };
export default trustService;