import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import trustService from "../nonInd_Trust/trustService";

export const addTrust = createAsyncThunk(
  "trust/addTrust",
  async (data: FormData, thunkAPI: any) => {
    try {
      return await trustService.addTrust(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  data: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const trustSlice = createSlice({
  name: "trust",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTrust.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addTrust.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(addTrust.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      });
  },
});

export const { resetState } = trustSlice.actions;
export default trustSlice.reducer;
