import React, { FC } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePickerProps, DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en-in";

interface DatePickerPropsWithoutRenderInput
  extends Omit<DatePickerProps<Dayjs>, "renderInput"> {
  inputFormat?: string;
}

interface FormDatePickerProps extends DatePickerPropsWithoutRenderInput {
  label: string;
  name?: string;
  error?: string;
  touched?: boolean;
  hintText?: string;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  renderInput?: (params: TextFieldProps) => React.ReactElement;
}

dayjs.locale("en-in");

const FormDatePicker: FC<FormDatePickerProps> = ({
  label,
  renderInput,
  error,
  touched,
  hintText,
  maxDate,
  minDate = dayjs(), // Default minDate to current date
  name,
  ...datePickerProps
}) => {
  // Determine if date is for dateOfBirth and remove minDate and maxDate if so
  const isDateOfBirth = name === "dateOfBirth" || "nominees[${index}].dateOfBirth";

  return (
    <div className="mb-4 sm:mb-0">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-in">
        <div className="flex flex-col text-lg text-neutral-500 mb-10">
          <label
            htmlFor={label.replace(/ /g, "").toLowerCase()}
            className="text-neutral-500 mb-3"
          >
            {label}
          </label>
          <DatePicker
            inputFormat="DD/MM/YYYY"
            {...datePickerProps}
            sx={{
              "& .MuiInputBase-input": {
                border: "none",
                padding: "1.2rem",
              },
            }}
            className="w-full bg-white rounded-lg bg-white-A700 p-4"
            defaultValue={dayjs("2000-01-01", "DD-MM-YYYY")}
            minDate={isDateOfBirth ? undefined : minDate}
            maxDate={isDateOfBirth ? undefined : maxDate}
            name={name}
          />
          {!!error && touched ? (
            <p className="text-secondary h-1.5">{error}</p>
          ) : !!hintText ? (
            <p className="text-textGrey h-1.5 text-xs mt-1">{hintText}</p>
          ) : (
            <p className="text-secondary h-1.5"></p>
          )}
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default FormDatePicker;
